export const Checkbox = ({ name, checked, onChange, label, isDisabled = false }) => {
	return (
		<div>
			<input
				type="checkbox"
				id={name}
				name={name}
				style={{ margin: "1rem 0.5rem" }}
				checked={checked || false}
				onChange={onChange}
				disabled={isDisabled}

			/>
			<label htmlFor={name}>{label}</label>
		</div>
	);
};
