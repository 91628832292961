export const readFile = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
};

export const formatCurrency = (currency) => {
	return currency.toLocaleString("pt-br", {
		style: "currency",
		currency: "BRL",
	});
};

export const formatMileage = (mileage) => {
	return mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
