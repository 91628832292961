import { IonRouterLink } from "@ionic/react";

import styled, { css } from "styled-components";

export const PageUI = styled.div`
	//height: 100%;
`;

export const PageBackground = styled.div`
	background: #da5744;
	padding: 0;
	//height: auto;
	//overflow: scroll;
`;

export const PageTitle = styled.h1`
	color: #000 !important;
	font-weight: 800 !important;
	margin: 0 !important;
`;

export const PageSubTitle = styled.p`
	color: #898a8d;
	font-size: 16px;
`;

export const Panel = styled.div`
	background-color: #fff;
	border-radius: 30px 30px 0px 0px;
	padding: 0 2rem;
	text-align: left;
	overflow: auto;
	padding-bottom: 1rem;
`;

export const Button = styled(IonRouterLink)`
	font-weight: 800;
	font-size: 16px;
	padding: 16px 0px;    
        display: flex;
        align-items: center;
        justify-content: center;
	flex: 1;
	text-align: center;
	background: #d63d2f;
	border-radius: 72px;
	color: #fff;
	display: block;
	text-transform: uppercase;
	width: 100%;

	${(props) =>
		props.color === "secondary" &&
		css`
			color: #616161;
			background: transparent;
			border: solid 1px #d63d2f;
		`}
`;
