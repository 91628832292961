import React, { useState, useContext, useEffect } from "react";
import { IonIcon, IonRouterLink, useIonAlert } from "@ionic/react";

import {
  StyledTitle,
  Logo,
  StyledPage,
  ComoFunciona,
  ComoFuncionaTitle,
  Bullet,
  OResto,
  Panel,
  Welcome,
  PageBackground,
  Daremos,
} from "./Home.style";

import "./Initial.css";

import { AppContext } from "../../State";
import { useHistory } from "react-router-dom";

import styled from "styled-components";

const StyledIonIcon = styled(IonIcon)`
	color: rgb(214, 61, 47); 
`;

const HomeNoCars: React.FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(AppContext);
  const [showLoading, setShowLoading] = useState(false);
  const [presentAlert] = useIonAlert();

  const error = (message) => {
    presentAlert({
      header: "Erro",
      message,
      buttons: ["Ok"],
    });
  };

  const proxPagina = (obj) => {
		// console.log('pagina : ' + obj)
		history.push(obj);
		
	};

  return (
    <StyledPage>
      <PageBackground>
        <Welcome>
          <StyledTitle>Bem Vindo à</StyledTitle>
          <Logo src="assets/logo_final.svg" />
        </Welcome>
        <Panel>
            <div id="Initial">
              <div className="content"><br></br>
                  <div className="socialLogin" style={{ cursor: "pointer" }} onClick={() => proxPagina('cars')}>
                    <div className="imgColu1">
                      <StyledIonIcon icon={"assets/menu/car.svg"} size="large"></StyledIonIcon>
                      </div>
                    <div className="textColu">CADASTRE SEU VEÍCULO</div>
                  </div><br></br>
                  <div className="socialLogin" style={{ cursor: "pointer" }}  onClick={() => proxPagina('findcars')}>
                    <div className="imgColu2"><img src="assets/viewicon.svg" alt="menu"/></div>
                    <div className="textColu">VEÍCULOS DISPONÍVEIS</div>						
                  </div><br></br>
                  <div className="socialLogin" style={{ cursor: "pointer" }} onClick={() => proxPagina('premium')}>
                    <div className="imgColu"><img src="assets/diamante.svg" alt="menu"/></div>	
                    <div className="textColu">CONHEÇA O PLANO PREMIUM</div>
                  </div>
              </div>
            </div>
        </Panel>
      </PageBackground>
    </StyledPage>
  );
};

export default HomeNoCars;
