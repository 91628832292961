import React, { useState, useEffect } from "react";

import { API_HOME, MEDIA_HOME } from "../config";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import styled from "styled-components";
import { close } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

const Wrapper = styled.div`
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	& .react-transform-wrapper {
		height: 80vh;

		//ae
	}
`;

const Image = styled.img`
	width: 100%;
	//height: auto;
	//max-width: unset;
`;

const poses = [
	"Frente",
	"Traseira",
	"Esquerda",
	"Direita",
	"Interior",
	"Motor",
];

const poseImages = ["front", "back", "left", "right", "interior", "engine"];

const imageURL = (carId, pose, type = "") =>
	`${MEDIA_HOME}/car/${carId}/${pose}${type}.jpg`;

const PoseButtons = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1rem;
`;

const StyledPoseButton = styled.div`
	color: #fff;
	font-size: 12px;
	text-align: center;
	width: 55px;
	border-radius: 10px;
	padding-bottom: 5px;

	margin-right: 5px;
	&:last-child {
		margin-right: 0;
	}

	& img {
		width: 50px;
		height: 50px;
		object-fit: cover;
		display: block;
		margin: 0 auto;
		margin-bottom: 5px;
	}
`;

const PoseButton = (props) => {
	return (
		<StyledPoseButton onClick={props.onClick}>
			<img src={imageURL(props.car.id, poseImages[props.pose], "_small")} />
			{poses[props.pose]}
		</StyledPoseButton>
	);
};

const CloseButton = styled.div`
	position: absolute;
	top: 5px;
	cursor: pointer;
	right: 5px;
	color: yellow;
	z-index: 999;
	background-color: black;
	padding: 5px;
	color: #fff;
	border-radius: 50%;
	width: 40px;
	border: solid 2px white;
	height: 40px;
	border: solid 2px white;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const GalleryModal: any = ({ car, onClose, startingPose = 0 }) => {
	// console.log("GalleryModal received", { startingPose });
	const [currentPose, setCurrentPose] = useState(startingPose);
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
	const [loading, setLoading] = useState(true);
	const [galleryRef, setGalleryRef] = useState<any>(null);

	useEffect(() => {
		const preload = document.createElement("img");
		preload.onload = () => {
			setWidth(preload.width);
			setHeight(preload.height);
			setLoading(false);

			galleryRef?.centerView(1, 0, "linear");
			// console.log(
			// 	"Set width to ",
			// 	preload.width,
			// 	"height to ",
			// 	preload.height,
			// 	"and loading to false"
			// );
		};
		preload.src = imageURL(car.id, poseImages[currentPose]);
		// console.log(
		// 	"Preloading this url: ",
		// 	imageURL(car.id, poseImages[currentPose]),
		// 	"carId",
		// 	car?.id,
		// 	"car: ",
		// 	car,
		// 	"currentPose = ",
		// 	currentPose,
		// 	" found images = ",
		// 	poseImages[currentPose]
		// );
	}, [car, currentPose, startingPose, galleryRef]);

	const ratio = isNaN(height / width) ? 1 : height / width;
	const windowWidth = (window as any).document.querySelector(
		"body"
	).offsetWidth;
	// console.log({ width, height, ratio, windowWidth });

	return (
		<Wrapper>
			<CloseButton
				onClick={() => {
					onClose();
				}}
			>
				<IonIcon size="large" icon={close} style={{ pointerEvents: 'none' }}/>
			</CloseButton>

			<TransformWrapper
				centerZoomedOut={true}
				centerOnInit={true}
				//initialScale={2}
				onInit={(ref: any) => {
					setGalleryRef(ref);
				}}
			>
				<TransformComponent>
					<Image
						width={windowWidth}
						height={windowWidth * ratio}
						src={imageURL(car.id, poseImages[currentPose])}
					/>
				</TransformComponent>
			</TransformWrapper>
			<PoseButtons>
				{poses.map((p, i) => (
					<PoseButton
						key={p}
						car={car}
						pose={i}
						onClick={() => {
							if (currentPose !== i) {
								setLoading(true);
							}

							setCurrentPose(i);
						}}
					/>
				))}
			</PoseButtons>
		</Wrapper>
	);
};

export default GalleryModal;
