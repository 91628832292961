
//export let API_HOME ="https://api.autotroca.com.br";// "http://autotroca-prod-stage.sa-east-1.elasticbeanstalk.com"//"http://127.0.0.1:3001"; 
//export let API_HOME = 

// export let API_HOME = "https://www.devauto.com.br";
// export let API_HOME = "https://dev.autotroca.com.br";
//export let API_HOME = "http://localhost:3001";
export let API_HOME = "https://dev.autotroca.com.br";


export const MEDIA_HOME = `https://s3.sa-east-1.amazonaws.com/autotroca.uploads`;
export const mileageFactor = 5000;
export const priceFactor = 5000;
export const APP_VERSION = process.env.REACT_APP_APP_VERSION || "2.0.25";
export const FirebaseClientId = process.env.REACT_APP_FIREBASE_CLIENTID || "";