import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useIonAlert } from "@ionic/react";
import { close } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

import removeAccents from "remove-accents";

const Input = styled.input`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	padding-right: 1rem;
	margin-bottom: 6px;
	width: 100%;
	border: none;
	outline: none;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 30px;
	cursor: pointer !important;

	&::placeholder {
		color: #b5b5b5;
	}
`;

const SuggestionsBox = styled.div`
	border: solid 1px black;
	border-radius: 5px;
	margin: 0 10%;
	margin-bottom: 0.5rem;
	max-height: 14rem;
	overflow-y: scroll;
	padding-left: 1rem;
	background-color: #f5f5f5;
	color: #6b6b6b;

	& p {
		cursor: pointer;
		margin: 0;
		padding: 0.5rem 0;
	}
`;

const ClearButton = styled.div`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 5px;
	cursor: pointer;
	

	& ion-icon {
		width: 15px;
		height: 15px;
		border: solid 1px #bbb;
		border-radius: 50%;
		color: #bbb;
		pointer-events: none;
	}
	
`;

const SearchableSelect: any = ({
	placeholder = "",
	labelKey = "name",
	values = [],
	onSelect,
	autoComplete,
	showClearButton = false,
	onInvalidMessage,
	value,
}) => {

	useEffect(() => {
		// console.log("Searchable select of ", placeholder, "initializing with ", value)
	}, [])

	useEffect(() => {
		// console.log("Searchable select of ", placeholder, "had value updated with ", value);
		const updatedValueObject = (values as any).find(v => {
			if(v.id) {
				return v.id == value
			}
	
			if(v.codigo_ibge) {
				return v.codigo_ibge == value
			}
	
			if(v.codigo_uf) {
				return v.codigo_uf == value
			}
	
			return false;
	
			
		});
	
		const initialId = updatedValueObject ? updatedValueObject[labelKey] : '';

		setTextValue(initialId || '');
	}, [value, values])
	const initialValueObject = (values as any).find(v => {
		if(v.id) {
			return v.id == value
		}

		if(v.codigo_ibge) {
			return v.codigo_ibge == value
		}

		if(v.codigo_uf) {
			return v.codigo_uf == value
		}

		return false;

		
	});

	const initialId = initialValueObject ? initialValueObject[labelKey] : '';
	const [textValue, setTextValue] = useState(initialId);
	const [focused, setFocused] = useState(false);
	const [presentAlert] = useIonAlert();

	useEffect(() => {
		const _initialValueObject = (values as any).find(v => {
			if(v.id) {
				return v.id == value
			}
	
			if(v.codigo_ibge) {
				return v.codigo_ibge == value
			}
	
			if(v.codigo_uf) {
				return v.codigo_uf == value
			}
	
			return false;
	
			
		});

		const initialId = initialValueObject ? initialValueObject[labelKey] : '';

		setTextValue(initialId || '');
	}, [values])

	useEffect(() => {
		if (!value) {
			setTextValue("");
		}
	}, [value]);

	let filteredValues = [] as any;

	if (focused) {
		filteredValues = values.filter((entry: any, i) => {
			let textFiltering = true;

			if (textValue.length > 0) {
				textFiltering = removeAccents(entry[labelKey])
					.toLowerCase()
					.trim()
					.includes(removeAccents(textValue.toLowerCase()));
			}
			return textFiltering;
		});
	}

	return (
		<div style={{position: "relative"}}>
			{!focused && showClearButton && value && <ClearButton
				title="Limpar"
				onClick={() => {
					setTextValue("");
					//setFocused(true);
					onSelect(null)
				}}
			>
				<IonIcon size="large" icon={close}  style={{ pointerEvents: 'none' }}/>
			</ClearButton> }
			<Input
				type="text"
				onFocus={() => {
					setFocused(true);
					if(textValue.length) {
						setTextValue("");
					}
				}}
				onBlur={() => {
					if (textValue.length > 0) {
						if (filteredValues.length === 0) {
							setTextValue("");
							onSelect(null);
							setFocused(false); // test
						}

						if (filteredValues.length === 1) {
							setTextValue(filteredValues[0][labelKey]);
							onSelect(filteredValues[0]);
							setFocused(false);
						}

						if (filteredValues.length > 1) {
						}
					}
					if (textValue.length === 0) {
						onSelect(null);
						//setFocused(false); //test
						/*name={labelKey}*/
					}
				}}
				value={textValue}
				onChange={(event) => {
					setTextValue(event.target.value);
				}}
				placeholder={placeholder}
				autoComplete={autoComplete ? autoComplete : `att-${labelKey}`}
			/>
			{focused && filteredValues.length > 0 && (
				<SuggestionsBox>
					{filteredValues.map((value, i) => {
						return (
							<p
								key={i}
								onClick={() => {
									setFocused(false);
									onSelect(value);
									setTextValue(value[labelKey]);
								}}
							>
								{value[labelKey]}
							</p>
						);
					})}
				</SuggestionsBox>
			)}
		</div>
	);
};

export default SearchableSelect;
