import React, { useState, useContext, useEffect } from "react";
import { API_HOME, mileageFactor, MEDIA_HOME, priceFactor } from "../../config";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonBackButton,
	IonRouterLink,
	IonTextarea,
	useIonModal,
	IonModal,
	IonSpinner,
	useIonViewWillEnter,
	IonRow,
	IonGrid,
	IonCol,
	IonMenuToggle,
	IonProgressBar,
	IonAlert
} from "@ionic/react";

import {
	formatCurrency,
	formatMileage,
} from "../../common/util";

import {
	StyledPage,
	ComoFunciona,
	CarBackground,
	Avatar,
	InputBox,
	FilterInputBox,
	Input,
	CarCard
} from "../home/Home.style";


import { useHistory } from "react-router-dom";
import { AppContext } from "../../State";
import styled, { css } from "styled-components";
import "./Vote.css";
import { getVoteCars } from "../../sevices/GetPossibleMatchService";
import FindCarsFilter from "./FindCarsFilter";
import { PageTitle } from "../../components/ui";

export const WrapperPai = styled.div`
	height: 100%;
	width: 100%;
	display: grid;
	position: relative;
	align-items: center;
	padding: 12px 12px 8px;
	justify-content: center;
	grid-template-columns: 1fr;
	grid-template-rows: 85% 15%;

`;

export const Img = styled.img`	
	@media (min-height: 716px){
		width: 12.5vh !important;
		height: 8.0vh !important;
	}
`;

export const ImageCar = styled.img`
  width: 100%;
  max-height: 90px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const StyledTitle = styled(PageTitle)`
	${(props) =>
		props.color &&
		css`
			color: ${props.color}!important;
	`}
			
		width: 100%;
		padding: 8px 16px;
`;

export const IonProgressBar1 = styled(IonProgressBar)`
  height: 10px;
  border-radius: 20px;
`;

const Vote: React.FC = () => {
	const [showModal, setShowModal] = useState(false);
	const [showModalCar, setShowModalCar] = useState(false);
	const { state } = useContext(AppContext);
	const { user, findCarsFilter } = state;

	const history = useHistory();

	const [loading, setLoading] = useState(false); // temporario no false

	const [carsList, setCarsList] = useState([]);
	const [alreadyVoted, setAlreadyVoted] = useState(1);
	const [disable, setDisable] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [voteCarId, setVoteCarId] = useState();
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		setLoading(true);
		getVoteCarsEvent();
	}, []);


	const getVoteCarsEvent = () => {
		getVoteCars(user)
			.then((response) => {
				// console.log(response);

				if (response.disable) {
					setDisable(true);
					setAlreadyVoted(1);
				} else {
					setAlreadyVoted(response.alreadyVoted);
				}

				setCarsList(response.data);
			})
			.catch((error) => {
				console.log('ERRO', error);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const imageOnError = (e) => {
		e.currentTarget.onerror = null;
		e.currentTarget.src = 'assets/logo_final.svg'
	};

	const carClick = (car: any) => {
		history.push("/tabs/FindCarsDetail", { car });
	}

	const voteClick = async (event: any, carId: any) => {

		event.stopPropagation();

		setVoteCarId(carId);
		setShowAlert(true);
	}

	const voteAction = async () => {
		const req = await fetch(`${API_HOME}/car/vote`,
			{
				method: "POST",
				headers: {
					"Content-type": "application/json",
					authorization: `Bearer ${user.token}`,
				},
				body: JSON.stringify({ car_id: voteCarId }),
			});

		let resp = await req.json();
		if (resp.success) {
			// console.log('GRAVADO COM SUCESSO');
			getVoteCarsEvent();
		}
	};

	return (
		<IonPage>
			<IonHeader className="height">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/tabs/vote" text="Campanha Project Car BR" />
					</IonButtons>
					{user?.id && (
						<IonButtons slot="end">
							<IonButton routerLink="/tabs/profile">
								<Avatar src={user.profile_picture} />
							</IonButton>
						</IonButtons>
					)}

				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen class="no-scroll" scroll-y="true">
				<StyledTitle color="#d63d2f">
					<br />Enquete do <a href="https://www.youtube.com/watch?v=ZDuxWBwRvFc&list=PLunWIBpSHmVxFPky4Vzr3JfRXS_qnpN52" target="_blank" style={{color: '#1E90FF'}}>Honda Civic</a> encerrada!
				</StyledTitle>

				{(loading) && (
					<IonSpinner
						color={"primary"}
						style={{
							display: "block",
							margin: "auto",
							width: "75%",
							height: "100%",
						}}
						name="crescent"
					/>
				)}

				{(!loading && !disable && carsList.length === 0) && (
					<StyledTitle color="#d63d2f">
						<br />
						Não foi encontrado nenhum veículo com as especificações
						selecionadas
					</StyledTitle>
				)}

				{!loading && carsList.length > 0 && (
					<>
						<IonGrid>
							{carsList?.map((item: any, i) => {
								return (
									<div key={item.id}>
										{/* {item.id == 16205 ?
											<div className="desclassificado">DESCLASSIFICADO</div> : ""} */}
										{item.id == 16205 ?
											"" : 
											<IonRow style={{ cursor: "pointer" }} onClick={() => carClick(item)} className="detailcar">
											<IonCol>
												{item.id == 16205 ? <ImageCar
													onError={imageOnError}
													src={`${MEDIA_HOME}/car/${item.id}/front_small.jpg`}
													style={{ filter: 'grayscale(100%)' }}
												/> :
													<ImageCar
														onError={imageOnError}
														src={`${MEDIA_HOME}/car/${item.id}/front_small.jpg`}
													/>}

											</IonCol>
											<IonCol>
												<div className="detcartitulo">
													{item.carMake ? item.carMake.name : ""} {item.carModel ? item.carModel.name : "Outro"} {item.year}
												</div>
												<div>
													{item?.mileage < 21
														? `Até ${formatMileage(
															item?.mileage === 0
																? mileageFactor
																: item?.mileage * mileageFactor
														)}  Km`
														: "+100.000 Km"}
												</div>
												<div>
													{/* <div className="votar" style={{ backgroundColor: '#ccc', color: '#eee' }}>Em breve</div> */}


													{item.id == 16205 && !alreadyVoted && (
														<div className="votar" style={{ backgroundColor: '#ccc', color: '#eee' }}>Votar</div>
													)}

													{item.id != 16205 && !alreadyVoted && (
														<div onClick={(event) => voteClick(event, item.id)} className="votar">Votar</div>
													)}

													{alreadyVoted && item.votes ? (
														<div>
															<div className="progress-outer">
																<div className="progress-inner" style={{ width: item.votesPercent }}>{item.votes}</div>
															</div>
														</div>
													) : ""}

													{alreadyVoted && !item.votes ? (
														<div>
															<div className="progress-outer">
																<div className="progress-inner" style={{ width: 10 }}>0</div>
															</div>
														</div>
													) : ""}
												</div>

											</IonCol>
										</IonRow>	
											}

										
									</div>

								)
							})}
						</IonGrid>

						<div className="regra">
							<IonButton href="/vote-policy">Conheça as regras</IonButton>
						{/* * Caso o carro ganhador não esteja com a documentação em dia para a transferência imediata, serão analisados os mais votados subsequentes. */}
						</div>
					</>
				)}


			</IonContent>

			<IonAlert
				header="Confirmar voto"
				message="Você só poderá votar uma vez. Deseja confirmar o voto?"
				buttons={[
					{
						text: 'Cancelar',
						role: 'cancel',
						handler: () => {
							setShowAlert(false);
						},
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: async () => {
							await voteAction();

							setShowSuccess(true);
							setShowAlert(false);
						},
					},
				]}
				isOpen={showAlert}></IonAlert>

			<IonAlert
				header="Voto computado"
				message="Seu voto foi computado com sucesso!"
				buttons={[
					{
						text: 'OK',
						role: 'confirm',
						handler: async () => {
							setShowSuccess(false);
						},
					},
				]}
				isOpen={showSuccess}></IonAlert>

		</IonPage>


	);
};

export default Vote;
