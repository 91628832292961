import React, { useState, useContext, useEffect } from "react";
import { IonSpinner, useIonAlert } from "@ionic/react";


import { AppContext } from "../../State";
import { Logo, StyledTitle } from "../home/Home.style";
import { Col, Row } from "../mycars/MyCarsComponents";
import { getSubscription, cancelSubscription } from "./Payment.service";
import { StyledText, ActionButton } from "./Payment.style";
import { PageUI, PageBackground, Panel } from "../../components/ui";


const PaymentSubscription: React.FC = () => {
    const { state, dispatch } = useContext(AppContext);
    const { user } = state;
    const [showLoading, setShowLoading] = useState(false);
    const [subscription, setSubscription] = useState<any>({});
    const [dueDate, setDueDate] = useState('');

    const [presentAlert] = useIonAlert();

    const error = (message) => {
        presentAlert({
            header: "Erro",
            message,
            buttons: ["Ok"],
        });
    };

    const cancelHandler = async () => {
        setShowLoading(true);
        try {
            const request = await cancelSubscription(user);

            if (request.success) {
                dispatch({
                    type: "setPaymentStatus",
                    subscription: {
                        is_active: true,
                        cancelDate: new Date()
                    },
                });
                
                let aux = subscription;
                aux.canceled = true;
                setSubscription(aux);

                presentAlert({
                    header: "Cancelamento",
                    message: "Cancelamento de assinatura realizado com sucesso!",
                    buttons: ["Ok"],
                });                
            } else {
                presentAlert({
                    header: "Cancelamento",
                    message: "Não foi possível realizar o cancelamento no momento. Tente novamente mais tarde ou entre em contato com o suporte.",
                    buttons: ["Ok"],
                });
            }
        } catch (err) {
            presentAlert({
                header: "Cancelamento",
                message: "Não foi possível realizar o cancelamento no momento. Tente novamente mais tarde ou entre em contato com o suporte.",
                buttons: ["Ok"],
            });
        } finally {
            setShowLoading(false);
        }

    }

	const cancel = () => {
		presentAlert({
			header: "Cancelamento",
			message: "Tem certeza de que deseja cancelar o seu plano?",
			buttons: [
				{ 
                    text: "Não, manter meu plano",
                    role: 'cancel'
                },
				{
					text: "SIM, cancelar meu plano",
                    role: 'confirm',
					handler: cancelHandler
				}
			],
		});
	}

    useEffect(() => {
        getSubscription(user)
            .then((sub) => {
                // console.log('sub.data', sub.data);

                setSubscription(sub.data);
            }).catch((err) => {
                console.error("Error fetching car makes", err);
            })
    }, []);

    return (
        <>
            {showLoading && (
                <IonSpinner
                    color={"primary"}
                    style={{
                        display: "block",
                        margin: "auto",
                        width: "75%",
                        height: "75%",
                    }}
                    name="crescent"
                />
            )}
            {!showLoading && (
                <PageUI>
                    <PageBackground style={{ paddingTop: "5%" }}>
                        <Panel>
                            <br />
                            <Logo src="assets/logo_final.svg" />
                            
                            {subscription?.is_active && (
                                <>
                                <Row>
                                    <Col size="12">
                                    {!subscription?.cancelDate && (
                                        <StyledTitle>
                                        Você já possui o Plano Premium ativo!
                                        </StyledTitle>
                                        )}

                                    {subscription?.cancelDate && (
                                        <StyledTitle>
                                        Seu plano foi cancelado em <span style={{ fontWeight: "bold" }}>{new Date(subscription?.cancelDate).toLocaleDateString("pt-br")}</span>
                                        </StyledTitle>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col size="12">
                                        <StyledTitle>
                                            Os benefícios do seu plano expiram em <span style={{ fontWeight: "bold" }}>{new Date(subscription?.expireDate).toLocaleDateString("pt-br")}</span>.
                                        </StyledTitle>
                                    </Col>
                                </Row>
                                </>)
                            }

                            {subscription?.is_active && !subscription?.cancelDate && (
                                <>
                                    <Row>
                                        <Col size="12">
                                            <StyledText>
                                                Deseja cancelar a assinatura? {subscription?.cancelDate}
                                            </StyledText>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col size="12">
                                            <ActionButton onClick={cancel} expand="block" type="button">Cancelar assinatura</ActionButton>
                                        </Col>
                                    </Row>
                                </>)}
                        </Panel>
                    </PageBackground>
                </PageUI>)}
        </>
    );
};

export default PaymentSubscription;
