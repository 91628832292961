import { IonButton, IonTextarea } from "@ionic/react";
import styled, { css } from "styled-components";
import { PageTitle } from "../../components/ui";

export const UserName = styled.div`
color: #000;
font-weight: bold;
`;

export const StyledTitle = styled(PageTitle)`
	width: fit-content;
	margin: 0 auto!important;
  ${(props) =>
		props.color &&
		css`
			color: ${props.color}!important;
		`}
`;

export const ActionButton = styled(IonButton)`
	font-weight: 800;
	user-select: none;
	cursor: pointer;
	font-size: 18px;
	flex: 1;
	text-align: center;
	background: #2dd36f;
	border-radius: 72px;
	color: #fff;
	display: block;
  &:click {
    background-color: #2dd36f;
  }
`;

export const StyledTextArea = styled(IonTextarea)`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border: 1px solid #cecece !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
`;

export const UserWidget = styled.div`
display: flex;
margin: 0.25rem 0;
margin-left: 1rem;
`;

export const UserWidgetText = styled.div`
margin-left: 0.5rem;
height: 100%;
`;

export const UserStatus = styled.div``;

export const StyledMessage = styled.div`
padding: 0 1rem;
display: flex;
flex-direction: column;
overflow: visible;
`;


interface MessageProps { fromMe: boolean }

export const Bubble = styled.div<MessageProps>`
	padding: 1rem;
	display: inline-block;
	color: #fff;
	overflow: visible;
	max-width: 90vw;
	white-space: pre-wrap;

	${(props) =>
		!props.fromMe
			? css`
					background-color: #50555c;
					border-radius: 20px 0px 20px 20px;
					margin-left: auto;
			  `
			: css`
					border-radius: 20px 20px 20px 0px;
					background-color: #d63d2f;
					margin-right: auto;
			  `}
`;

export const MessageDate = styled.div<MessageProps>`
	margin: 0.5rem 0;

	${(props) =>
		!props.fromMe &&
		css`
			margin-left: auto;
		`}
`;


export const Messages = styled.div`
	margin-top: 1rem;
`;

export const InputWrapper = styled.div`
	background-color: #fff;
	padding: 0.5rem 1rem;
	display: flex;
`;

export const StyledInput = styled(IonTextarea) <{ ref: any }>`
	border: 1px solid #c4c4c4;
	border-radius: 20px;
	display: flex;
	margin-top: 0;

	& .native-textarea,
	.textarea-wrapper {
	}

	& .textarea-wrapper {
		margin: auto 0;
	}

	& .native-textarea {
		padding: 0.5rem 1rem;
		min-height: 1rem;
	}
`;

export const SubmitButton = styled.div`
	margin-left: 0.5rem;
	margin-top: auto;
	background-color: #000;
	background: #596e79;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;

	& img {
		max-width: 100%;
		max-height: 100%;
	}
`;