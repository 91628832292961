import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../State";
import { API_HOME, MEDIA_HOME, mileageFactor, priceFactor } from "../../config";

import { formatCurrency, formatMileage } from "../../common/util";
import {
	shifts_types,
	fuel_types,
	body_types,
	categories,
	optionals,
} from "../../common/data";

import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { add } from "ionicons/icons";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonInput,
	IonGrid,
	IonRouterLink,
	IonRow,
	IonCol,
	IonFab,
	IonFabButton,
	IonIcon,
	useIonActionSheet,
	useIonAlert
} from "@ionic/react";

import { menuController } from "@ionic/core";

import styled, { css } from "styled-components";

import {
	Wrapper,
	CarCard,
	CarHeader,
	CarNumber,
	MyCarTab,
	CarContent,
	CarTitle,
	CarLastEdit,
	CarImage,
	Tabs,
	Row,
	Col
} from "./MyCarsComponents";

import { estados } from "../../common/estados";
import { municipios } from "../../common/municipios";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import { useIonModal, IonSelect, IonSelectOption } from '@ionic/react';

const StyledInput = styled(IonInput)`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
`;

const StyledField = styled.div`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	padding: 10px;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
`;

const findCity = (id) => {
	return municipios.find((m) => m.codigo_ibge === id)?.nome;
};

const findState = (id) => {
	return estados.find((m) => m.codigo_uf === id)?.uf;
};

const Field = ({ placeholder }: any) => {
	return <StyledField>{placeholder}</StyledField>;
};

const ActionButton = styled(IonRouterLink)`
	font-weight: 800;
	cursor: pointer;
	font-size: 18px;
	padding: 18px 0px;
	flex: 1;
	text-align: center;
	background: #d63d2f;
	border-radius: 30px;
	color: #fff;
	display: block;
	text-transform: uppercase;

	margin: 10px 20px 10px 20px;
`;

const Modal = styled.div`
@media screen and (min-width: 768px) and (min-height: 600px){
	margin-top: 165px;
	height: auto !important;
}
`;

const Car: any = ({ car, number }) => {
	const history = useHistory();
	const { state, dispatch } = useContext(AppContext);
	const [currentTab, setCurrentTab] = useState(0);
	const [presentActionSheet, dismissActionSheet] = useIonActionSheet();
	const [presentAlert] = useIonAlert();
	const [presentCarDeleteConfirmation] = useIonAlert();
	const [presentCarDeleteReason] = useIonAlert();
	const [successAlert] = useIonAlert();
	const vehicleTypes = [{ name: 'Carro', id: 1 }, { name: 'Moto', id: 2 }]
	const error = (message) => {
		presentAlert({
			header: "Erro",
			message,
			buttons: ["Ok"],
		});
	};

	const removeCarHandler = async () => {
		const body = {
			inactive_reason: reason
		}
		try {
			const req = await fetch(`${API_HOME}/car/${car.id}`, {
				method: "DELETE",
				headers: {
					authorization: `Bearer ${state.user.token}`,
					"Content-type": "application/json",
				},
				body: JSON.stringify(body)
			});

			const resp = await req.json();

			if (!resp.success) {
				Sentry.captureException(new Error(resp.error));
				return error(resp.error);
			} else {

				dispatch({
					type: "deleteCar",
					id: parseInt(car.id),

				});

				successAlert({
					header: "Sucesso",
					message: "Seu carro foi excluído.",
					buttons: [
						{
							text: "Ok",
							handler: () => {
								history.replace("/tabs/cars");
							},
						},
					],
				});
			}
		} catch (err) {
			console.error("car delete errored with", err);
			Sentry.captureException(err);
		}
	}

	const desiredCar = car?.desiredCar;

	let lastEdit: any = new Date(car.updatedAt);

	const isDate = function (obj) {
		return Object.prototype.toString.call(obj) === "[object Date]";
	};

	const isValidDate = function (obj) {
		return isDate(obj) && !isNaN(obj.getTime());
	};

	if (isValidDate(lastEdit)) {
		lastEdit = format(new Date(car.updatedAt), "P", {
			locale: pt,
		});
	}

	const formatYearRange = (min, max) => {
		const currentYear = new Date().getFullYear();

		if (min === 0 && max === 0) {
			return `Ano: apenas ano atual (${currentYear})`;
		}

		if (min !== 0 && max === 0) {
			return `Ano a partir de ${min}`;
		}

		return `Ano entre ${min} e ${max}`;

		//`Ano ${desiredCar.starting_year === 0 && desiredCar.startingYear === 0 ? 'atual' : 'entre'} ${desiredCar.starting_year === 0 ? new Date().getFullYear() : desiredCar.starting_year} e ${desiredCar.max_year === 0 ? `o ano atual (${new Date().getFullYear()})` : desiredCar.max_year}`
	}
	const [reason, setReason] = useState('0');
	const Body: React.FC<{
		count: number;
		onDismiss: () => void;
		onIncrement: () => void;
	}> = ({ count, onDismiss, onIncrement }) => (
		<Modal style={{ padding: "20px", height: "100%", display: "grid", alignItems: "center" }}>
			<p style={{ textAlign: "center", marginTop: "0px" }}>Selecione um motivo:</p>
			<IonSelect
				style={{ border: "1px solid grey", borderRadius: "7px", marginTop: "0px" }}
				interface="action-sheet"
				value={reason}
				okText="Ok"
				cancelText="Cancelar"
				placeholder="Selecione"
				onIonChange={e => setReason(e.detail.value)}
			>
				<IonSelectOption value="Troquei pela AutoTroca">Troquei pela AutoTroca</IonSelectOption>
				<IonSelectOption value="Vendi por outro meio">Vendi por outro meio</IonSelectOption>
				<IonSelectOption value="Desisti de trocá-lo">Desisti de trocá-lo</IonSelectOption>
			</IonSelect>

			<div style={{ marginTop: "15px" }}>
				<IonButton expand="block" onClick={() => onIncrement()}>
					Confirmar
				</IonButton>
				<IonButton style={{ marginTop: "10px" }} expand="block" onClick={() => onDismiss()}>
					Cancelar
				</IonButton>
			</div>
		</Modal>
	);

	const [count, setCount] = useState(0);

	const handleConfirm = () => {
		dismiss();
		presentCarDeleteConfirmation({
			header: "Excluir carro",
			message: "Tem certeza de que deseja excluir este carro? A operação não poderá ser desfeita.",
			buttons: [
				"Cancelar",
				{
					text: "Continuar e excluir",
					handler: removeCarHandler
				}
			],
		})

	};

	const handleDismiss = () => {
		dismiss();
	};


	const [present, dismiss] = useIonModal(Body, {
		count,
		onDismiss: handleDismiss,
		onIncrement: handleConfirm,
	});

	return (
		<CarCard>
			<CarHeader>
				<IonGrid>
					<IonRow>
						<IonCol>
							<MyCarTab active={currentTab === 0} onClick={() => setCurrentTab(0)}>
								<span style={{ color: currentTab === 0 ? "#D63D2F" : "#979797" }} >	Meu Carro</span>
							</MyCarTab>
						</IonCol>
						<IonCol>
							<MyCarTab active={currentTab === 1} onClick={() => setCurrentTab(1)}>
								<span style={{ color: currentTab === 1 ? "#D63D2F" : "#979797" }} >Carro Desejado</span>
							</MyCarTab>
						</IonCol>
					</IonRow>
				</IonGrid>
			</CarHeader>
			<CarContent>
				<div
					style={{ display: currentTab === 0 ? "block" : "none" }}
					onClick={() => {
						presentActionSheet({
							buttons: [
								{
									text: "Editar",
									handler: () => {
										history.push(`/tabs/cars/edit/${car.id}`);
									},
								},
								{
									text: "Excluir", handler: () => {
										present({
											cssClass: 'my-class',
										});
									}
								},
								{ text: "Cancelar" },
							],
							header: "Editar Carro",
						});
					}}
				>
					<Row>
						<Col size="11">
							<CarTitle>{`${car?.carModel?.carMake?.name || ''} ${car?.carModel?.name || "Outro"}`}</CarTitle>
							<CarLastEdit>{`Última edição ${lastEdit}`}</CarLastEdit>
						</Col>
						{
							<Col>
								<img
									style={{ cursor: "pointer" }}
									src="assets/iconOption.svg"
								/>
							</Col>
						}
					</Row>

					<CarImage src={`${MEDIA_HOME}/car/${car.id}/front_small.jpg`} />
				</div>

				<div style={{ display: currentTab === 1 ? "block" : "none" }}>
					{!desiredCar && (
						<ActionButton
							onClick={() => {
								history.push(`/tabs/cars/edit/desired/${car.id}`);
							}}
						>
							Adicionar
						</ActionButton>
					)}

					{desiredCar && (
						<>
							{(desiredCar.city && desiredCar.state) && (
								<Field
									placeholder={`${findCity(desiredCar.city)} - ${findState(
										desiredCar.state
									)}`}
								/>
							)}
							{(desiredCar.city == undefined && desiredCar.state) && (
								<Field
									placeholder={`${findState(desiredCar.state)}`}
								/>
							)}
							{vehicleTypes.map(opt => {
								if (opt.id == desiredCar.vehicleType) {
									return (
										<Field
											key={opt.id}
											placeholder={opt.name}
										/>
									)
								}



							})}
							{(desiredCar.make || desiredCar.model) && (
								<Field placeholder={`${desiredCar?.carMake?.name} ${desiredCar?.carModel?.name || ''}`} />
							)}
							{!isNaN(desiredCar.starting_year) && (
								<Field placeholder={formatYearRange(desiredCar.starting_year, desiredCar.max_year)} />
							)}
							{/* {!isNaN(desiredCar.mileage) && (
								<Field
									placeholder={desiredCar.mileage < 21 ? `Até ${formatMileage(
										desiredCar.mileage === 0
											? mileageFactor
											: desiredCar.mileage * mileageFactor
									)}km rodados` : "Quilometragem: Sem restrição"}
								/>
							)} */}
							{!isNaN(desiredCar.price_range) && (
								<Field
									placeholder={desiredCar.price_range < 41 ? `Preço até ${formatCurrency(
										desiredCar.price_range * priceFactor
									)}` : 'Preço: Sem restrição'}
								/>
							)}
							{desiredCar.shift_type && (
								<Field
									placeholder={`Câmbio: ${shifts_types[desiredCar.shift_type]}`}
								/>
							)}
							{desiredCar.fuel_type && (
								<Field
									placeholder={`Combustível: ${fuel_types[desiredCar.fuel_type]
										}`}
								/>
							)}

							{/* {desiredCar.category && (
								<Field
									placeholder={`Categoria: ${categories[desiredCar.category]}`}
								/>
							)} */}
							{desiredCar?.carOptionals?.map((opt) => {
								return (
									<Field
										key={opt.optional}
										placeholder={optionals[opt.optional]}
									/>
								);
							})}
							<ActionButton
								style={{ fontSize: "1rem", padding: "0.5rem", margin: "1rem auto", width: "8rem" }}
								onClick={() => {
									history.push(`/tabs/cars/edit/desired/${car.id}`);
								}}
							>
								Editar filtros
							</ActionButton>
						</>
					)}
				</div>
			</CarContent>
		</CarCard>
	);
};

const MyCars: React.FC = () => {
	const { state, dispatch } = useContext(AppContext);
	const { cars, user } = state;

	const history = useHistory();

	useEffect(() => {
		fetch(`${API_HOME}/car`, {
			headers: {
				authorization: `Bearer ${state.user.token}`,
			},
		})
			.then((resp) => resp.json())
			.then((cars) => {
				if (cars?.length > 0) {
					dispatch({
						type: "loadCars",
						cars,
					});
				}
			})
			.catch((err) => {
				console.error("Failed loading cars", err);
				Sentry.captureException(err);
			});
	}, []);

	const verify = async () => {
		if (cars?.length > 0 && !user?.subscription?.is_active) {
			history.push("/tabs/funcpremium");
		}
		else
		{
			history.push("/tabs/cars/addcar");
		}
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton onClick={() => menuController.open()}>
							<img src="assets/btnLeftMenu.svg" />
						</IonButton>
					</IonButtons>
					<IonTitle>Meus Carros</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonFab vertical="center" edge={true} horizontal="end" slot="fixed">
					<IonFabButton onClick={verify}>
						<IonIcon icon={add} style={{ pointerEvents: 'none' }}/>
					</IonFabButton>
				</IonFab>
				<Wrapper>
					{cars &&
						cars?.map((car, i) => <Car key={i} number={i + 1} car={car} />)}
				</Wrapper>
				
				{!cars?.length && (
					<Wrapper style={{padding: '10px'}}>
					<ActionButton routerLink="/tabs/cars/addcar">
						Adicione seu primeiro carro!
					</ActionButton>
					</Wrapper>
				)}

				
			</IonContent>
		</IonPage>
	);
};

export default MyCars;