import React, { useState, useContext, useEffect } from "react";
import { API_HOME, MEDIA_HOME, mileageFactor, priceFactor } from "../../config";
import { formatMileage } from "../../common/util";
import * as Sentry from "@sentry/react";

import { AppContext } from "../../State";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  useIonAlert,
  IonIcon,
  IonModal,
  IonSpinner,
  IonFabButton,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  useIonViewDidEnter,
} from "@ionic/react";

import { menuController } from "@ionic/core";

import { Panel, PageSubTitle } from "../../components/ui";

import styled from "styled-components";
import { checkmark, close } from "ionicons/icons";
import { ActionButton, Checkbox } from "../../common/input";
import { Row, Col, CarImage } from "../mycars/MyCarsComponents";
import { CardItem, CarCard, LogoHeader, StyledPage } from "./LikedYou.style";

import { useHistory } from "react-router";

import {
  Avatar,
  InputBox,
  FilterInputBox,
  Input,
} from "../home/Home.style";

import { ComoFunciona, NoLikeTitle } from "./LikedYou.style";
import { getLikesReceived } from "../../sevices/GetLikesService";
import { Car } from "../../components/Car";
import { BtnsReactions } from "../../components/BtnsReactions";

import { ModalRealTimeMatch } from "../../components/ModalRealTimeMatch";

const LikedYou: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const { user, selectedDesiredCar, selectedCar, cars } = state;
  // console.log("user init", user);

  const hasValidSubscription: boolean = user?.subscription?.is_active;
  const [showModalPlano, setShowModalPlano] = useState(false);
  const [carModalMatch, setCarModalMatch] = useState<any>({
    car: null,
    desiredCar: null,
    type: null,
    matchId: null,
  });

  const [presentAlert] = useIonAlert();
  const [liked, setLiked] = useState({
    id: 0,
    year: 0,
    carModel: {
      name: "0",
      carMake: { name: "" },
    },
    mileage: 0,
    fuel_type: 1,
    body_type: 1,
    shift_type: 1,
    category: 1,
    carOptionals: [],
    price_range: 1,
    comment: "",
    municipio: { nome: "", estado: { nome: "" } },
  });
  const [carList, setCarList] = useState([]);
  const [myCar, setMyCar] = useState(
    selectedDesiredCar || {
      id: 0,
      year: 0,
      carModel: {
        name: "0",
        carMake: { name: "" },
      },
      mileage: 0,
      fuel_type: 1,
      body_type: 1,
      shift_type: 1,
      category: 1,
      carOptionals: [],
      price_range: 1,
      municipio: { nome: "", estado: { nome: "" } },
    }
  );
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [likes, setLikes] = useState([]);
  const [car, setCar] = useState({
    id: 0,
    year: 0,
    carModel: {
      name: "0",
      carMake: { name: "" },
    },
    mileage: 0,
    fuel_type: 1,
    body_type: 1,
    shift_type: 1,
    category: 1,
    comment: "",
    carOptionals: [
      {
        active: null,
        carId: 127,
        desiredCarId: null,
        id: 451,
        inactive_reason: null,
        optional: 1,
      },
    ],
    price_range: 1,
    vehycleType: 0,
    municipio: { nome: "", estado: { nome: "" } },
  });

  useEffect(() => {
    handleCarChange(selectedCar);
  }, [selectedCar]);

  useEffect(() => {
    try {
      // console.log("cars===>", cars);
      setLoading(true);

      let payload = [0];
      cars.forEach((car: any) => {
        const id = car.id;
        payload.push(id);
      });
      // console.log("cars===>", payload);

      // debugger
      // console.log('teste: ' + hasValidSubscription)
      if (!hasValidSubscription) {
        setShowModalPlano(true);
      }
      // history.push('/tabs/planos')

      if (hasValidSubscription) {
        // getLikesReceived(state, payload, setLikes);
        // return setLoading(false);
      }

      return setLoading(false);
    } catch (error) {
      // console.log(error);
      Sentry.captureException(error);
      setLoading(false);
    }
  }, []);

  const like = async (match) => {
    // console.log("match likes", match);
    const req = await fetch(`${API_HOME}/match/start`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        authorization: `Bearer ${state.user.token}`,
      },
      body: JSON.stringify({
        carId: match.carIdB,
        desiredCarId: match.car.id,
      }),
    });
    const resp = await req.json();
    let newLikes = likes.filter((like: any) => like.carIdA != match.car.id);
    //console.log('likes', likes);
    setLikes(newLikes);
    //console.log('newLikes', newLikes);

    const isMatch =
      resp?.match?.carIdA &&
      resp?.match?.carIdB &&
      resp?.match?.userIdA &&
      resp?.match?.userIdB;

    if (isMatch) {
      setCarModalMatch({
        car: resp.match.carIdA,
        desiredCar: resp.match.carIdB,
        type: "like",
        matchId: resp.match.id,
      });
      return;
    }
  };

  const dislike = async (match) => {
    const req = await fetch(`${API_HOME}/match/dislike`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        authorization: `Bearer ${state.user.token}`,
      },
      body: JSON.stringify({
        carA: match.carIdB,
        carB: match.car.id,
      }),
    });
    const resp = req.json();
    let newLikes = likes.filter((like: any) => like.carIdA != match.car.id);
    // console.log("likes dislikes", likes);
    // console.log("dislikes new likes", newLikes);
    setLikes(newLikes);
  };

  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const changeValueFuel = (number) => {
    switch (number) {
      case 1:
        return "Gasolina";
        break;
      case 2:
        return "Álcool";
        break;
      case 3:
        return "Flex";
        break;
      case 4:
        return "Diesel";
        break;
      case 5:
        return "Híbrido";
        break;
      case 6:
        return "Elétrico";
        break;

      default:
        break;
    }
  };

  const changeValueShiftType = (number) => {
    switch (number) {
      case 1:
        return "Manual";
        break;
      case 2:
        return "Automático";
        break;
      case 3:
        return "Automatizado";
        break;
      case 1:
        return "Manual";
        break;
      case 2:
        return "Automático";
        break;
      case 3:
        return "Automatizado";
        break;
      default:
        break;
    }
  };

  const changeValueBodyType = (number) => {
    switch (number) {
      case 1:
        return "Hatch";
        break;
      case 2:
        return "Sedan";
        break;
      case 3:
        return "SUV";
        break;
      case 4:
        return "Perua";
        break;
      case 5:
        return "Pick-up";
        break;
      case 6:
        return "Utilitário";
        break;
      case 7:
        return "Coupê";
        break;
      case 8:
        return "Conversível";
        break;
      case 9:
        return "Minivan";
        break;
      default:
        break;
    }
  };

  const changeValueCategory = (number) => {
    switch (number) {
      case 1:
        return "Urbano";
        break;
      case 2:
        return "4x4";
        break;
      case 3:
        return "Antigo";
        break;
      case 4:
        return "Família";
        break;
      case 5:
        return "Esportivo";
        break;
      case 6:
        return "Luxo";
        break;
      default:
        break;
    }
  };

  const handleCarChange = async (car) => {
    if (state.cars && state.cars.length > 0 && car && car.id) {
      setMyCar(state.cars.find((c) => c.id === car.id));
      getLikesReceived(state, [car.id], setLikes);
    }
  };

  return (
    <IonPage>
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => menuController.open()}>
                  <img src="assets/btnLeftMenu.svg" />
                </IonButton>
              </IonButtons>
              <IonTitle>Likes Recebidos</IonTitle>
              {user?.id && (
                <IonButtons slot="end">
                  <IonButton routerLink="/tabs/profile">
                    <Avatar src={user.profile_picture} />
                  </IonButton>
                </IonButtons>
              )}
            </IonToolbar>
          </IonHeader>

          <IonRow
            style={{
              zIndex: 9999,
              background: "white",
            }}
          >
            <IonCol size="10">
              {" "}
              {state?.cars && (
                <IonSelect
                  style={{
                    paddingTop: "10px ",
                    boxShadow: "5px 5x 5px rgba(0, 0, 0, 0.4)",
                    justifyContent: "center",
                    //backgroundColor: '#ffffff',
                    borderRadius: "15px 15px 15px 15px ",
                  }}
                  value={myCar}
                  onIonChange={(e) => handleCarChange(e.detail.value)}
                >
                  {state?.cars.map((car, id) => (
                    <IonSelectOption key={car.id} value={car}>
                      {car?.carModel?.carMake?.name + " " + car?.carModel?.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              )}
            </IonCol>
            <IonCol size="2">
              {myCar && (
                <Col style={{ margin: "10px" }}>
                  <img
                    onClick={() => {
                      history.push(`/tabs/cars/edit/desired/${myCar.id}`);
                    }}
                    style={{ cursor: "pointer" }}
                    src="assets/iconOption.svg"
                    alt="menu"
                  />
                </Col>
              )}
            </IonCol>
          </IonRow>

          <IonContent fullscreen>
            {loading && (
              <IonSpinner
                color={"primary"}
                style={{
                  display: "block",
                  margin: "auto",
                  width: "75%",
                  height: "75%",
                }}
                name="crescent"
              />
            )}
            {!loading && likes && likes.length == 0 && (
              <CarCard>
                <LogoHeader>
                  <img src="assets/logo_final.svg" />
                </LogoHeader>
                <ComoFunciona>
                  <NoLikeTitle>
                    Seus veículos ainda não receberam likes ou não
                    possui veículo cadastrado
                  </NoLikeTitle>
                </ComoFunciona>
              </CarCard>
            )}
            {!loading && likes && likes.length > 0 && (
              <StyledPage>
                {likes?.map((item: any, i) => {
                  return (
                    <CardItem
                      onClick={() => {
                        setLiked(item.car);
                        setShowModal(true);
                      }}
                      key={i}>
                      <Car
                        car={item.car}
                        isHome={false}
                        onClick={() => {
                          setShowModal(true);
                        }}>
                      </Car>
                      <BtnsReactions
                        isHome={false}
                        swipe={(type) => {
                          if (type === "left") return dislike(item);
                          if (type === "right") return like(item);
                        }}>
                      </BtnsReactions>
                    </CardItem>
                  );
                })}
              </StyledPage>
            )}

            <IonModal
              isOpen={showModal}
              onDidDismiss={() => setShowModal(false)}
            >
              <Panel>
                <form autoComplete="off">
                  <>
                    <PageSubTitle>
                      {`${liked?.carModel?.carMake?.name || ""} ${liked?.carModel?.name || "Outro"
                        }`}
                    </PageSubTitle>
                    <CarImage
                      src={`${MEDIA_HOME}/car/${liked?.id}/front_small.jpg`}
                    />
                    <CarImage
                      src={`${MEDIA_HOME}/car/${liked?.id}/back_small.jpg`}
                    />
                    <CarImage
                      src={`${MEDIA_HOME}/car/${liked?.id}/right_small.jpg`}
                    />
                    <CarImage
                      src={`${MEDIA_HOME}/car/${liked?.id}/left_small.jpg`}
                    />
                    <CarImage
                      src={`${MEDIA_HOME}/car/${liked?.id}/interior_small.jpg`}
                    />
                    <CarImage
                      src={`${MEDIA_HOME}/car/${liked?.id}/engine_small.jpg`}
                    />
                    <FilterInputBox>
                      <div>
                        <p>Preço:</p>
                        {"Entre R$" +
                          (liked?.price_range - 1) * priceFactor +
                          " e  R$" +
                          liked?.price_range * priceFactor}
                      </div>
                    </FilterInputBox>
                    <FilterInputBox>
                      <p>Quilometragem:</p>
                      <div>
                        <div style={{ margin: "1rem 0", textAlign: "left" }}>
                          {liked?.mileage < 21
                            ? `Até ${formatMileage(
                              liked?.mileage === 0
                                ? mileageFactor
                                : liked?.mileage * mileageFactor
                            )}  Km`
                            : "Acima de 100.000 Km"}
                        </div>
                        <Input
                          type="range"
                          name="mileage"
                          min="0"
                          max="21"
                          step="1"
                          value={liked?.mileage}
                          readOnly
                        />
                      </div>
                    </FilterInputBox>
                    <FilterInputBox>
                      <div> Ano: {liked?.year} </div>
                    </FilterInputBox>
                    <FilterInputBox>
                      <div> Cidade: {liked?.municipio?.nome} </div>
                    </FilterInputBox>
                    <FilterInputBox>
                      <div> Estado {liked?.municipio?.estado.nome} </div>
                    </FilterInputBox>
                    <FilterInputBox>
                      <div>
                        Combustivel: {changeValueFuel(liked?.fuel_type)}
                      </div>
                    </FilterInputBox>
                    <FilterInputBox>
                      <div>
                        Cambio: {changeValueShiftType(liked?.shift_type)}
                      </div>
                    </FilterInputBox>
                    <FilterInputBox>
                      <div>
                        Carroceria:
                        {changeValueBodyType(liked?.body_type)}
                      </div>
                    </FilterInputBox>
                    <FilterInputBox>
                      <div>
                        Categoria:
                        {changeValueCategory(liked?.category)}
                      </div>
                    </FilterInputBox>

                    {liked?.comment && liked?.comment.length > 0 && (
                      <FilterInputBox>
                        <div>Comentários: {liked?.comment}</div>
                      </FilterInputBox>
                    )}

                    {liked?.carOptionals && liked?.carOptionals.length > 0 && (
                      <FilterInputBox>
                        <div>
                          Opcionais:
                          {/*(Ar Condicionado; Direção Assistida; AirBag; ABS*/}
                          <Checkbox
                            name="airconditioning"
                            checked={liked?.carOptionals.find(
                              (op: any) => op.optional == 1
                            )}
                            label="Ar condicionado"
                            onChange={() => { }}
                            isDisabled={true}
                          />
                          <Checkbox
                            name="assisteddriving"
                            checked={liked?.carOptionals.find(
                              (op: any) => op.optional == 2
                            )}
                            label="Direção assistida"
                            onChange={() => { }}
                            isDisabled={true}
                          />
                          <Checkbox
                            name="airbag"
                            checked={liked?.carOptionals.find(
                              (op: any) => op.optional == 3
                            )}
                            label="AirBag"
                            onChange={() => { }}
                            isDisabled={true}
                          />
                          <Checkbox
                            name="abs"
                            checked={liked?.carOptionals.find(
                              (op: any) => op.optional == 4
                            )}
                            label="ABS"
                            onChange={() => { }}
                            isDisabled={true}
                          />
                        </div>
                      </FilterInputBox>
                    )}

                    <InputBox>
                      <ActionButton
                        onClick={() => {
                          setShowModal(false);
                        }}
                      >
                        Fechar
                      </ActionButton>
                    </InputBox>
                  </>
                </form>
              </Panel>
            </IonModal>

            {/* Modal match em tempo real */}
            {/* <ModalRealTimeMatch
              showModal={showModalMatch}
              car={carModalMatch}
              setOpen={(evt) => {
                setShowModalMatch(evt);
              }}
            ></ModalRealTimeMatch> */}
          </IonContent>
        </>
    </IonPage>
  );
};

export default LikedYou;
