import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { API_HOME, APP_VERSION } from "./config";
import { isPlatform } from '@ionic/react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: '5c530e597045394e29a94bac5e239a63',
  plugins: [new BugsnagPluginReact()]
});

let isWeb = (!isPlatform('android') && !isPlatform('ios')) || isPlatform('mobileweb');

if (!isWeb) {
	CapacitorUpdater.notifyAppReady();
}

const firebaseConfig = {
	apiKey: "AIzaSyBQEU_ulI-5JeS6QdhIn-dFyKH3QndoQIA",
	authDomain: "autotroca-app.firebaseapp.com",
	projectId: "autotroca-app",
	storageBucket: "autotroca-app.appspot.com",
	messagingSenderId: "382553775923",
	appId: "1:382553775923:web:bd72e01204aaa89314a846",
	measurementId: "G-MBBM2CC6KB",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// const auth = initializeAuth(app);
// auth.useDeviceLanguage();
// firebase.analytics();

if (isWeb) {
	// GoogleAuth.initialize({
	// 	clientId: "382553775923-kk164lcm01eg5cc310n6oapjlroir7j1.apps.googleusercontent.com",
	// 	grantOfflineAccess: true,
	//   });
	// FacebookLogin.initialize({appId: '1117330492446814'});
}

const bugsnagPlugin = Bugsnag.getPlugin('react');

if (bugsnagPlugin) {
	const ErrorBoundary =  bugsnagPlugin.createErrorBoundary(React);
	ReactDOM.render(
		<ErrorBoundary>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</ErrorBoundary>,
		document.getElementById("root")
	);
} else { 
	ReactDOM.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
		document.getElementById("root")
	);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (!navigator.userAgent.includes("Gecko")) {
	serviceWorkerRegistration.unregister();
	serviceWorkerRegistration.register();

	navigator?.serviceWorker?.getRegistrations().then((regs) => {
		regs.map((reg: any) => {
			if (reg?.active?.scriptURL.includes("/sw.js")) {
				reg.unregister();
			}
		});
	});

	if ("serviceWorker" in navigator) {
		navigator.serviceWorker
			.register("/sw.js") //
			.then(function (reg) {
				console.log("service worker registered", reg);
			})
			.catch(function (err) {
				console.log(err);
			});
	}
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
