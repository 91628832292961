import React, { useState, useEffect, useContext } from "react";
import { API_HOME, MEDIA_HOME, FirebaseClientId } from "./config";
import { AppContext } from "./State";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

import {
	ActionPerformed,
	PushNotificationSchema,
	PushNotifications,
	Token,
  } from '@capacitor/push-notifications';
  
import { platform } from "os";
import { FCM } from "@capacitor-community/fcm";
// import { useIonRouter } from '@ionic/react';

import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";

const AppShell: React.FC = (props) => {
	const { state, dispatch } = useContext(AppContext);
	// const [showModal, setShowModal] = useState(!state?.surveyClicked && state?.user);
	// const router = useIonRouter();

	const { user } = state;

	const history = useHistory();

	// let messaging;

	// if (firebase.messaging.isSupported()) {
	// 	messaging = firebase.messaging();
	// 	messaging.onMessage((payload) => {
	// 		console.warn("Received this foreground message", payload);
	// 		const { title, ...options } = payload.notification;

	// 		const data = JSON.parse(payload?.data?.payload || "{}");
	// 		const type = payload?.data?.type;

	// 		// if (type === "chatMessage") {
	// 		// 	if (!state?.socket?.connected) {
	// 		// 		console.log("############ updating by push", state);
	// 		// 		notifyOfNewChatMessage(data);
	// 		// 	}
	// 		// }

	// 		// if (type === "match") {
	// 		// 	if (!state?.socket?.connected) {
	// 		// 		notifyOfNewMatch(data);
	// 		// 	}
	// 		// }
	// 		console.warn("Received this foreground payload", data);
	// 	});
	// }

	// const notifyOfNewMatch = (match, message?) => {
	// 	dispatch({
	// 		type: "addMatch",
	// 		match,
	// 	});
	// 	// console.log('notifyOfNewMatch');
	// 	// showToast({
	// 	// 	buttons: [
	// 	// 		{
	// 	// 			text: "Visualizar",
	// 	// 			handler: () => {
	// 	// 				dismissToast();
	// 	// 				history.push(`/tabs/match/${match.id}`);
	// 	// 			},
	// 	// 		},
	// 	// 	],
	// 	// 	message: `Novo match para o seu carro`,
	// 	// 	duration: 2000,
	// 	// });
	// };

	const notifyOfNewChatMessage = (message) => {
		const read = (window as any).location.pathname === `/tabs/chat/${message.channel}`
		 || message.from == user?.id;

		// console.log('TELA', (window as any).location.pathname);
		// console.log('LIDO =>', read);

		dispatch({
			type: "addChat",
			message: {
				...message,
				read,
			},
		});

		const match = state.matches?.find((m) => m.user?.id === message.from);

		if (match) {
			const fromUser = match.user;

			// console.log(
			// 	"Found this fromUser",
			// 	fromUser,
			// 	"when we looked at",
			// 	state.matches,
			// 	"for the id",
			// 	message.from
			// );
	
			if (!read) {
				// showToast({
				// 	buttons: [
				// 		{
				// 			text: "Visualizar",
				// 			handler: () => {
				// 				dismissToast();
				// 				history.push(`/tabs/chat/${match.id}`);
				// 			},
				// 		},
				// 	],
				// 	message: `${fromUser.first_name}: ${message.body}`,
				// 	duration: 2000,
				// });
				
			}
		} else {
			if (!read) {
				
			}
		}
		
	};

	useEffect(() => {
		if (user?.id && !(window as any).location.pathname.includes("/tabs/")) {
			// console.log("AppShell will load cars");
			fetch(`${API_HOME}/car`, {
				headers: {
					authorization: `Bearer ${user.token}`,
				},
			})
				.then((resp) => resp.json())
				.then((cars) => {
					dispatch({
						type: "loadCars",
						cars,
					});
					// console.log("AppShell will load cars", cars);

				})
				.catch((err) => {
					Sentry.captureException(err);
				});

			// console.log("AppShell will load matches");
			fetch(`${API_HOME}/user/${user?.id}/matches`, {
				headers: {
					authorization: `Bearer ${user.token}`,
				},
			})
				.then((resp) => resp.json())
				.then((matches) => {
					dispatch({
						type: "loadMatches",
						matches,
					});
				})
				.catch((err) => {
					Sentry.captureException(err);
				});

			// console.log("AppShell will load chats");
			fetch(`${API_HOME}/chat`, {
				headers: {
					authorization: `Bearer ${user.token}`,
				},
			})
				.then((resp) => resp.json())
				.then((chats) => {
					dispatch({
						type: "loadChats",
						chats,
					});
				})
				.catch((err) => {
					Sentry.captureException(err);
				});
		}

	}, []);

	useEffect(() => {
		// console.log("data listener...");

		const db = getFirestore();

		const userRef = collection(db, "users-" + user?.id);
		const unsubscribe = onSnapshot(userRef, (snapM) => {
			snapM.docChanges().forEach((changeM) => {
				const doc = changeM.doc;
				const match = doc.data();

				if (changeM.type === "added") { 
					if (state.matches?.some((m) => m.id !== match.id)) {
						loadNewMatches().then(() => {
							// console.log('MATCHES CARREGADOS');
						});
					}
				} else {
					loadNewMessages(match);
				}
			});

		});

		return () => unsubscribe();
	}, [user]);

	const loadNewMessages = async (match) => {
		const req = await fetch(
			`${API_HOME}/chat/${match.id}?since=${match.updatedAt.toDate().toISOString()}&dontread=true`,
			{
				headers: {
					authorization: `Bearer ${user.token}`,
				},
			}
		);

		const newChats = await req.json();
		console.warn('CHATS NOVOS', newChats);
		if (newChats && !Object.hasOwn(newChats, 'success'))
		{
			// debugger;
			newChats.forEach(e => {
				notifyOfNewChatMessage(e);
				
			});
		}

	};

	const loadNewMatches = async () => {
		return fetch(`${API_HOME}/user/${user?.id}/matches`, {
			headers: {
				authorization: `Bearer ${user.token}`,
			},})
			.then((resp) => resp.json())
			.then((matches) => {
				dispatch({
					type: "loadMatches",
					matches,
				});

				fetch(`${API_HOME}/chat`, {
					headers: {
						authorization: `Bearer ${user.token}`,
					},
				})
				.then((resp) => resp.json())
				.then((chats) => {
					dispatch({
						type: "loadChats",
						chats,
					});
				})
				.catch((err) => {
					Sentry.captureException(err);
				});
			})
			.catch((err) => {
				Sentry.captureException(err);
			});

	}

	useEffect(() => {
		// console.warn('CHAMOU BEM?');
		// debugger;
		
		const registerToken = async (user) => {
			if (!user || !user.token) {
				return;
			}

			// console.log('PLATAFORMA', Capacitor.getPlatform(), user);

			if (Capacitor.getPlatform() === 'android') {
				await registerTokenAndroid(user);
			} else {
				// web ou ios
				// await registerTokenWeb(user);
			}
		};

		const registerTokenWeb = async (user) => {
			// console.log('CHAMOU O REGISTER WEB');
			// console.log('registerToken', firebase.messaging.isSupported());
			// if (
			// 	//firebase.messaging.isSupported()
			// 	//  &&
			// 	// Notification.permission !== "granted"
				
			// ) {
				// console.log('entroui mesmo notification', firebase.messaging.isSupported());

				const messaging = getMessaging();

				// console.log('messaging notification', messaging);

				// console.log('FirebaseClientId', FirebaseClientId);

				try {
					const token = await getToken(messaging, {
						vapidKey: FirebaseClientId,
					});

					// console.log('token notification', token);
					fetch(`${API_HOME}/push`, {
						method: "POST",
						headers: {
							"Content-type": "application/json",
							authorization: `Bearer ${user.token}`,
						},
						body: JSON.stringify({
							token: token,
						}),
					}).then((resp) => { 
						// console.log('resp push notification ',  resp);
					});

					// console.log('sucesso notification');

				} catch (err) {
					console.error("Error registering push token", err);

					Notification.requestPermission().then((permission) => {
						// console.log('Notification permission', permission);
						if (permission === 'granted') {
							// console.log('Notification permission granted.');

							registerToken(user);
						}
					});
					
					Sentry.captureException(err);
				}
			// }
		};

		const registerTokenAndroid = async (user) => {
			// console.log('CHAMOU O REGISTER ANDROID');
			// console.log('FirebaseClientId', FirebaseClientId);

			FCM.setAutoInit({ enabled: true });

			FCM.getToken()
			.then((r) => {
				// console.log(`Token ${r.token}`);
				sendToServer(user, r.token);
			})
			.catch((err) => {
				registerTokenFCM(user);
			});

		};

		const registerTokenFCM = async (user) => {
			
			// debugger;
			// console.log('VAI PEDIR PERMISSAO');

			PushNotifications.requestPermissions().then(result => {
				if (result.receive === 'granted') {
				PushNotifications.register();
				} else {
				// Show some error
				Sentry.captureMessage('FCM registration failed');
				}
			});
		
			PushNotifications.addListener('registration',
				(token: Token) => {
				// console.log('Push registration success, token: ' + token.value);

				sendToServer(user, token.value);
				}
			);
		
			// Some issue with our setup and push will not work
			PushNotifications.addListener('registrationError',
				(error: any) => {
				console.log('Error on registration: ' + JSON.stringify(error));
				}
			);
		};

		const sendToServer = (user, token) => {
			// console.log('token notification', token);
			fetch(`${API_HOME}/push`, {
				method: "POST",
				headers: {
					"Content-type": "application/json",
					authorization: `Bearer ${user.token}`,
				},
				body: JSON.stringify({
					token: token,
				}),
			}).then((resp) => console.log('resp push notification', resp));

		}

		if (Capacitor.getPlatform() === 'android') {
		  // Show us the notification payload if the app is open on our device
		  PushNotifications.addListener('pushNotificationReceived',
			(notification: PushNotificationSchema) => {
			//   console.log('Push received: ' + JSON.stringify(notification));
			}
		  );
	
		  // Method called when tapping on a notification
		  PushNotifications.addListener('pushNotificationActionPerformed',
			(action: ActionPerformed) => {
				const notification = action.notification;
				// console.log('Push action performed: ' + JSON.stringify(notification));
				
				const payload = JSON.parse(notification.data.payload);
				
				switch(notification.data.type) {
					case "chatMessage":
						setTimeout(() => {
							history.push(`/tabs/chat/${payload.channel}`);
						}, 500);
					break;
					case "match":
						setTimeout(() => {
							history.push(`/tabs/match/${payload.id}`);
						}, 500);
					break;
				}

			}
		  );

		}

		registerToken(user);
	}, [user]);


	// useEffect(() => {
	// 	FirebaseAuthentication.addListener('authStateChange', async event => {
	// 		console.warn('AUTH EVENT', event);

	// 		const user = event.user;
	// 		if (user) {
	// 		  var uid = user.uid;
	// 		  console.warn('USER ATUAL', user);
	// 			// if (currentUser?.getIdToken())

	// 			// user.getIdToken()
	// 			// .then(token => {
	// 			// 	console.log(token)
	// 			// 	return fetch(`${API_HOME}/auth/firebase`, {
	// 			// 		method: "POST",
	// 			// 		headers: {
	// 			// 			"Content-type": "application/json",
	// 			// 		},
	// 			// 		body: JSON.stringify({
	// 			// 			idtoken: token
	// 			// 		}),
	// 			// 	})
	// 			// 	.then((resp) => resp.json())
	// 			// 	.then((user) => {
	
	// 			// 		console.log('usuario retornado:', user);
						
	// 			// 		// if (user?.id) {
	// 			// 			dispatch({
	// 			// 				type: "signIn",
	// 			// 				user,
	// 			// 			});
	
	// 			// 			localStorage.setItem('showTutorial', JSON.stringify(true));
	// 			// 			if (!JSON.parse(localStorage.getItem('notShowTutorialAgain') as any)) {
	// 			// 				localStorage.setItem('notShowTutorialAgain', JSON.stringify(false));
	// 			// 			}
	
	// 			// 			history.replace("/tabs/home");
	// 			// 		// } else {
	// 			// 		// 	presentAlert({
	// 			// 		// 		header: "Erro",
	// 			// 		// 		message: "Usuário ou senha inválidos",
	// 			// 		// 		buttons: ["Ok"],
	// 			// 		// 	});
	// 			// 		// }
	// 			// 	});
	// 			// })

				
	// 			// history.replace("/tabs/cars");
	// 		} else {
	// 			console.warn('USUARIO NAO ESTA LOGADO.');
	// 		  // User is signed out	
	// 			// history.replace("/");
	// 		}
	// 	});
		
	// }, []);

	// // Show us the notification payload if the app is open on our device
	// PushNotifications.addListener('pushNotificationReceived',
	// (notification: PushNotificationSchema) => {
	// 	console.log('Push received: ' + JSON.stringify(notification));
	// }
	// );

	// // Method called when tapping on a notification
	// PushNotifications.addListener('pushNotificationActionPerformed',
	// (notification: ActionPerformed) => {
	// 	console.log('Push action performed: ' + JSON.stringify(notification));
	// }
	// );
	  
	return (
		<>
			{props.children}
		</>
	);
};

export default AppShell;

