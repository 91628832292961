//TODO: add pull to refresh
//https://ionicframework.com/docs/api/refresher
import React, { useState, useContext } from "react";
import { API_HOME, MEDIA_HOME } from "../../config";
import { AppContext } from "../../State";
import * as Sentry from "@sentry/react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonInput,
	IonRouterLink,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonSearchbar,
	IonRippleEffect,
	useIonViewDidEnter,
} from "@ionic/react";

import { menuController } from "@ionic/core";
import {
	PageUI,
	PageBackground,
	Panel,
	PageTitle,
	PageSubTitle,
} from "../../components/ui";

import styled from "styled-components";
import Thumbnail from "./Thumbnail";
import { formatDistance } from "date-fns";
import pt from "date-fns/locale/pt";

import { RouteComponentProps } from "react-router-dom";

import stringSimilarity from "string-similarity";

const StyledChats = styled.div``;
const Person = styled.div`
	padding: 0 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	display: flex;
	position: relative;
	overflow: hidden;
`;

const Summary = styled.div`
	margin-left: 1rem;
	width: calc(100% - 70px - 1rem);
`;

const PersonName = styled.span`
	display: flex;
	align-items: center;
	font-weight: bold;
	margin-bottom: 0.5rem;
	color: #000;
`;
const LastMessage = styled.div`
	margin-bottom: 0.5rem;
	color: #000;
`;

const LastMessageDate = styled.div`
	font-size: 0.8rem;
	color: #c4c4c4;
`;

const SearchBackground = styled.div`
	background-color: #fff;
`;

const NewConversationIndicator = styled.div`
	width: 11px;
	height: 11px;
	display: inline-block;
	margin-right: 0.5rem;
	background-color: #d63d2f;
	border-radius: 50%;
`;

const CarName = styled.span`
	color: #ccc;
	font-weight: normal;
	font-size: 0.8rem;
	display: block;
	margin-left: 0;
	max-width: 60%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ReadIndicator = styled.div`
	color: rgba(39, 125, 68, 1);
	font-size: 12px;
`;

const Chats: React.FC = () => {
	const [searchText, setSearchText] = useState("");
	const { state, dispatch } = useContext(AppContext);
	const { matches, chats, user, cars } = state;

	const users = matches?.map((match) => {
		return {
			...match.user,
			matchId: match.id,
		};
	});

	let filteredMatches =
		matches?.length > 0
			? [
					...matches.filter(m => {				
						return chats.filter((c) => c.channel === m.id)?.length > 0
					})
					.sort((a, b) => {
						
						const chatsForA = chats.filter((c) => c.channel === a.id) || [];
						const chatsForB = chats.filter((c) => c.channel === b.id) || [];

						if (chatsForA.length > 0 && chatsForB.length > 0) {
							const lastChatForA = chatsForA[chatsForA.length - 1];
							const lastChatForB = chatsForB[chatsForB.length - 1];
							const ATime = new Date(lastChatForA?.createdAt).getTime();
							const BTime = new Date(lastChatForB?.createdAt).getTime();
							const result = BTime - ATime;

							return result;
						}

						if (chatsForA.length > 0 && chatsForB.length === 0) {
							return -1;
						}

						if (chatsForB.length > 0 && chatsForA.length === 0) {
							return 1;
						}

						return 0;
					}),
			  ]
			: [];

	filteredMatches = filteredMatches.map((match)=> { return { ...match, myCar: cars.find((c) => c.id === match.forCar)}} );
	
	if (searchText.length) {
		filteredMatches = filteredMatches?.filter((match) => {

			return (
					match.user.first_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1					
			);
		});	
	}

	const loadChats = () => {
		fetch(`${API_HOME}/chat`, {
			headers: {
				authorization: `Bearer ${user.token}`,
			},
		})
			.then((resp) => resp.json())
			.then((chats) => {
				dispatch({
					type: "loadChats",
					chats,
				});
			})
			.catch((err) => {
				// console.log("Failed loading chats: ", err);
				Sentry.captureException(err);
			});
	};

	useIonViewDidEnter(() => {
		loadChats();
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton onClick={() => menuController.open()}>
							<img src="assets/btnLeftMenu.svg" />
						</IonButton>
					</IonButtons>
					<IonTitle>Chat</IonTitle>
					{/*<IonButtons slot="end">
						<IonButton routerLink="/edit-profile">
							<img src="assets/iconOption.svg" />
						</IonButton>
    </IonButtons>*/}
				</IonToolbar>
				<SearchBackground>
					<IonSearchbar
						value={searchText}
						placeholder="Procurar"
						onIonChange={(e) => setSearchText(e.detail.value!)}
					></IonSearchbar>
				</SearchBackground>
			</IonHeader>
			<IonContent fullscreen>
				<StyledChats>
					{filteredMatches.length < 1 &&
						<p className="ion-text-center">Nenhum resultado! </p>
					}
					{filteredMatches?.map((match, i) => {
						const user = {
							...match.user,
							matchId: match.id,
						};

						const chatRouterLink = `/tabs/chat/${user.matchId}`;
						// console.log("Iterating this user: ", user, "for this match", match);

						const allMessages =
							chats?.filter((c) => c.channel === user.matchId) || [];

						const lastMessage =
							allMessages.length > 0
								? allMessages[allMessages.length - 1]
								: null;

						const showNewConversationIndicator =
							(lastMessage &&
								lastMessage.to === state.user?.id &&
								!lastMessage.read) ||
							false;

						return (
							<IonRouterLink key={i} routerLink={chatRouterLink}>
								<Person className="ion-activatable ripple-parent">
									<IonRippleEffect></IonRippleEffect>

									<Thumbnail
										src={`${MEDIA_HOME}/user/${user?.id}/profile.jpg`}
										online={user.online}
									/>

									<Summary>
										<div>
											<PersonName>
												{showNewConversationIndicator && (
													<NewConversationIndicator />
												)}
												<span style={{ flex: 1 }}>{user.first_name} </span>
												<CarName>
													{`${match?.myCar?.carModel?.name} + ${match?.car?.carModel?.name || "Outro"}`} 
												</CarName>
											</PersonName>
										</div>
										{lastMessage && (
											<>
												<LastMessage>{lastMessage.body}</LastMessage>
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "flex-end",
													}}
												>
													<LastMessageDate>
														{formatDistance(
															new Date(lastMessage?.createdAt),
															new Date(),
															{
																addSuffix: true,
																locale: pt,
															}
														)}
													</LastMessageDate>
													{/* {state?.user?.id} */}
													{lastMessage &&
														(lastMessage.read) && (
															<ReadIndicator>Lida ✔</ReadIndicator>
														)}
												</div>
											</>
										)}
									</Summary>
								</Person>
							</IonRouterLink>
						);
					})}
				</StyledChats>
			</IonContent>
		</IonPage>
	);
};

export default Chats;
