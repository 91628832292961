export const shifts_types = {
	1: "Manual",
	2: "Automático",
	3: "Automatizado",
};

export const fuel_types = {
	1: "Gasolina",
	2: "Álcool",
	3: "Flex",
	4: "Diesel",
	5: "Híbrido",
	6: "Elétrico",
};

export const body_types = {
	1: "Hatch",
	2: "Sedan",
	3: "SUV",
	4: "Perua",
	5: "Pick-up",
	6: "Utilitário",
	7: "Coupê",
	8: "Conversível",
	9: "Minivan",
};

export const categories = {
	1: "Urbano",
	2: "4x4",
	3: "Antigo",
	4: "Família",
	5: "Esportivo",
	6: "Luxo",
};

export const optionals = {
	1: "Ar Condicionado",
	2: "Direção assistida",
	3: "AirBag",
	4: "ABS",
};
