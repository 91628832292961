import styled, { css } from "styled-components";

import { API_HOME, MEDIA_HOME } from "../config";

export const StyledCarsList = styled.div`
	//display: flex;
	//flex-wrap: wrap;
	//justify-content: space-between;

	& img {
		border-radius: 8px;
		width: 100%;
	}
`;

export const StyledCar = styled.div<{ index: number }>`
	width: calc(50% - 0.5rem);
	margin-bottom: 0.5rem;

	${(props) =>
		props.index === 0 &&
		css`
			width: 60%;
			margin-right: 0;
		`}

	${(props) =>
		[1, 2].includes(props.index) &&
		css`
			width: calc(40% - 1rem);
			margin-right: 0;
		`}

	& img {
		border-radius: 8px;
		width: 100%;
	}
`;

export const Car = ({ image, index }: any) => {
	return (
		<StyledCar index={index}>
			<img src={image} alt="" />
		</StyledCar>
	);
};

interface CarsListProps {
	carId?: number;
}

const Featured = styled.div`
	width: 60%;
	max-height: 115px;
`;
const FlexVertical = styled.div`
	display: flex;
	flex-direction: column;
	&.side-vertical {
		height: 115px;
	}

	&.side-vertical img {
		width: auto;
		height: calc(50% - 0.25rem);
		margin-left: auto;
		margin-bottom: 0.5rem;
	}
`;
const FlexHorizontal = styled.div`
	display: flex;
	justify-content: space-between;

	&.top-horizontal {
		margin-bottom: 1rem;
	}

	&.lower-horizontal img {
		width: calc(50% - 0.5rem);
	}
`;

const SideVertical = styled.div`
	width: calc(40% - 1rem);
`;

const FrontImage = styled.img`
	height: 150px;
	width: 100%;
	border-radius: 12px;
	object-position: center;

	object-fit: cover;
	display: block;
`;
const Image2 = styled.img`
	width: 100%;
	border-radius: 12px;
	object-fit: cover;

	height: 47.5px;
	display: block;
	margin-bottom: 5px;
`;
const Image3 = styled.img`
	width: 100%;
	border-radius: 12px;
	object-fit: cover;
	height: 47.5px;
`;
const Image4 = styled.img`
	width: 100%;
	border-radius: 12px;
	object-fit: cover;
	height: 100px;
`;
const Image5 = styled.img`
	width: 100%;
	border-radius: 12px;
	object-fit: cover;
	height: 100px;
`;

const Row = styled.div`
	display: flex;
`;

const Col: any = styled.div<{ width?: number }>`
	width: ${(props) => props.width || 50}%;
	&:first-child {
		width: calc(50%-5px);
		margin-right: 5px;
	}
`;

const Thumbs = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
`;
const Thumb = styled.img`
	border-radius: 8px;
	width: 50px;
	height: 50px;
	object-fit: cover;
`;

const imageURL = (carId, pose, type = "small") =>
	`${MEDIA_HOME}/car/${carId}/${pose}_${type}.jpg`;

export const CarsList: any = ({ carId = null, onClick }) => {
	const poses = ["front", "back", "left", "right", "interior", "engine"];
	const images: string[] = [];
	if (carId) {
		poses.forEach((pose, i) => {
			images.push(`${MEDIA_HOME}/car/${carId}/${pose}_small.jpg`);
		});
	}
	
	const imageOnError = (e) => {
		e.currentTarget.onerror = null; 
		e.currentTarget.src = 'assets/logo_final.svg'
	};
	
	return (
		<div>
			<FrontImage
				src={imageURL(carId, "front")}
				onClick={() => {
					onClick(poses.findIndex((p) => p === "front"));
				}}
				onError={imageOnError} 
			/>
			<Thumbs>
				{poses.slice(1, poses.length).map((pose, i) => (
					<Thumb
						key={pose}
						src={imageURL(carId, pose, "mini")}
						onClick={() => {
							onClick(poses.findIndex((p) => p === pose));
						}}
						onError={imageOnError} 
					/>
				))}
			</Thumbs>
		</div>
	);
};
