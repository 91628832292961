import styled, { css } from "styled-components";

export const Wrapper = styled.div`
	margin-top: 10px;
`;

export const CarCard = styled.div`
	background-color: #f6f6f6;
	border-radius: 30px;
	
	margin-top: 2em;
	margin-bottom: 2em;
	margin-left: 0.5em;
	margin-right: 0.5em;

	box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.11);

	@media (max-height: 745px) { 	
	}
	`;

export const CarHeader = styled.div`
	display: flex;
	padding-top: 12px;
	margin-bottom: 6px;

	@media (max-height: 745px) {
		padding-top: .5em;
	}
`;

export const CarNumber = styled.div`
	width: 100%;
	align-content: center;
	color: #596e79;
	font-weight: 800;
	text-align: center;
`;

export const MyCarTab = styled.div<{ active?: Boolean }>`
	cursor: pointer;
	width: 100%;
	text-align: center;
	user-select: none;
	border-radius: 5px;
	padding: 3px;
	color: #596e79;
	${(props) =>
		props.active &&
		css`
			font-weight: 700;
			`}
			background: #fff;
			border-right: 1px solid #cecece;
			border-bottom: 1px solid #cecece;
`;

export const CarContent = styled.div`
	background-color: white;
	border-radius: 30px;
	padding: 1em 2rem .2rem 2rem;
	
	margin-left: 1em;
	margin-right: 1em;
	margin-bottom: 1em;
`;

export const CarTitle = styled.div`
	font-size: 16px;
	color: #000;
	font-weight: 600;
`;

export const CarLastEdit = styled.div`
	font-size: 12px;
	color: #898a8d;
`;

export const CarImage = styled.img`
	width: 100%;
	height: 100%;
	max-height: 20vh;
	margin-bottom: 1em;
	object-fit: cover;
	border-bottom: 0px solid ;
	`;

export const Tabs = styled.div`
	display: flex;
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;

	@media (max-height: 670px) {
		margin-bottom: 16px;
	}
`;

export const Col = styled.div<{ size?: any }>`
	${(props) =>
		props.size &&
		css`
			width: ${(100 / 12) * parseInt(props.size)}%;
		`}
`;
