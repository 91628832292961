import { API_HOME } from "../config";

// export const getFindCars = async (user, carFiltro) => {

//   console.log(carFiltro);

//   let ret = await fetch(`${API_HOME}/car/findcars/`, {
//     method: "POST",
//     headers: {
//       "Content-type": "application/json",
//       authorization: `Bearer ${user.token}`,
//     },
//     body: JSON.stringify({ carFiltro: carFiltro }),
//   })

//   let data = await ret.json();

//   console.log("carros ===>", data);
 
//   return data;
// };

export const getPosibleMatchs = async (user: any, car: any, body_type: any, carId: any, carMake: any, carModel: any, carOptionals: any, category: any, city: any, createdAt: any, fuel_type: any, id: any, inactive_reason: any, make: any, max_year: any, mileage: any, model: any, price_range: any, shift_type: any, starting_year: any, vehicleType: any, updatedAt: any, year: any, version: any, desiredCar: any) => {
  
  const req = await fetch(`${API_HOME}/car/possiblematches`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        car: car,
        desiredCar: {
          body_type,
          carId,
          carMake,
          carModel,
          carOptionals,
          category,
          city,
          createdAt,
          fuel_type,
          id,
          inactive_reason,
          make,
          max_year,
          mileage,
          model,
          price_range,
          shift_type,
          starting_year,
          vehicleType,
          updatedAt,
          year,
          version,
          state: desiredCar.state,
        },
      }),
    }
  );

  let resp = await req.json();
  
  return resp;
}


export const getFindCars = async (user: any, car: any, body_type: any, carId: any, optionals: any, category: any, 
  city: any, state: any, createdAt: any, fuel_type: any, id: any, inactive_reason: any, make: any, max_year: any, mileage: any, model: any, 
  price_range: any, shift_type: any, starting_year: any, vehicleType: any, updatedAt: any, year: any, version: any) => {
  const req = await fetch(`${API_HOME}/car/findcars`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        desiredCar: {
          body_type,
          carId,
          optionals,
          category,
          state,
          city,
          createdAt,
          fuel_type,
          id,
          inactive_reason,
          make,
          max_year,
          mileage,
          model,
          price_range,
          shift_type,
          starting_year,
          vehicleType,
          updatedAt,
          year,
          version
        },
      }),
    }
  );

  let resp = await req.json();
  
  return resp;
}

export const getVoteCars = async (user: any) => {
  const req = await fetch(`${API_HOME}/car/votecars`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        authorization: `Bearer ${user.token}`,
      },
    }
  );

  let resp = await req.json();
  
  return resp;
}
