import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonRouterLink,
} from "@ionic/react";

import styled from "styled-components";

const Termos = styled.div`
	& ol,
	& ol li {
		list-style-position: inside;
		padding: 0;
		font-weight: 800;
	}
`;
const ServiceTerms: React.FC = () => {
	return (
		<IonPage>
			<IonContent fullscreen>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/tabs/home" text="Voltar" />
						</IonButtons>
						<IonTitle>Termos de Serviço</IonTitle>
					</IonToolbar>
				</IonHeader>
				<div style={{ padding: "0 2rem" }}>
					<Termos>
						<p>Bem Vindo à AUTOTROCA</p>
						<h1>Termos de uso</h1>
						<ol>
							<li>Aceitação dos Termos de uso. </li>
						</ol>
						<p>
							Ao se cadastrar na AUTOTROCA, seja através de dispositivo móvel,
							aplicativo móvel ou computador, você concorda em aceitar os
							presentes Termos de Uso, a nossa{" "}
							<IonRouterLink routerLink="/politica-privacidade">
								Política de Privacidade
							</IonRouterLink>
							, nossa Política de Cookies e nossas Dicas de Segurança.
						</p>
						<p>
							Este Contrato e o Serviço oferecido poderá ser alterado
							periodicamente. Isto poderá ser feito por diversas razões,
							inclusive para refletir as alterações ou exigências legais, novos
							recursos ou mudanças nas práticas comerciais. Você deve consultar
							a versão mais recente regularmente. A versão mais recente é a que
							se aplica. Se as alterações incluírem emendas significativas a
							seus direitos ou obrigações, você será notificado das alterações
							com pelo menos 30 dias de antecedência (salvo se não for possível
							fazer isso nos termos da lei aplicável), por meios razoáveis, o
							que pode incluir notificação por meio do Serviço ou por e-mail. Se
							você continuar utilizando o Serviço após as alterações entrarem em
							vigor, isso significa que você concorda com o Contrato revisado.
						</p>
						<ol start={2}>
							<li>Qualificação.</li>
						</ol>
						<p>
							Você deve ter, no mínimo, 18 anos de idade para se cadastrar na
							AUTOTROCA para poder utilizar o Serviço. Ao se cadastrar e
							utilizar o Serviço, você declara e garante que:
						</p>
						<ul>
							<li>Você pode assumir o contrato com a AUTOTROCA;</li>
							<li>
								Não está impedido de utilizar o Serviço nos termos da lei
								brasileira;
							</li>
							<li>
								Cumprirá este Contrato e todas as leis, normas e regulamentos
								municipais, estaduais e nacionais aplicáveis e
							</li>
							<li>
								Você confirma que não responde a processo por crime que envolva
								negociação de veículos.
							</li>
						</ul>
						<ol start={3}>
							<li>Sua conta.</li>
						</ol>
						<p>
							Para acessar a AUTOTROCA você deve se cadastrar. Assim que for
							concluído o cadastro e criada uma senha, você terá acesso aos
							serviços oferecidos.
						</p>
						<p>
							Você será responsável pelas negociações que forem feitas em seu
							nome. Caso acredite que outra pessoa teve acesso à sua conta,
							entre em contato conosco imediatamente através do e-mail
							suporte@autotroca.com.br.
						</p>
						<ol start={4}>
							<li>Cadastro de veículos.</li>
						</ol>
						<p>
							Você poderá cadastrar quantos veículos quiser na AUTOTROCA.
							Passado o período gratuito, a critério da AUTOTROCA, o cadastro de
							veículos poderá ter um valor como contrapartida da prestação do
							Serviço.
						</p>
						<ol start={5}>
							<li>Alteração do Serviço e Rescisão.</li>
						</ol>
						<p>
							A AUTOTROCA está empenhado em melhorar o Serviço e em oferecer
							funcionalidades adicionais que possam ser úteis e interessantes
							para você. Isso significa que podemos incluir novos recursos ou
							aprimorar os produtos ao longo do tempo, bem como remover alguns
							recursos ou descontinuá-lo. Caso essas ações não afetem os seus
							direitos e obrigações, não será fornecido aviso prévio antes de
							implantá-los.
						</p>
						<p>
							Você pode encerrar sua conta a qualquer momento através da guia
							perfil do site.
						</p>
						<p>
							A AUTOTROCA poderá cancelar sua conta a qualquer momento, sem
							aviso prévio, se acreditar que você violou este Contrato. Após o
							referido cancelamento, você não terá direito a nenhum reembolso.
						</p>
						<p>
							Após o cancelamento de sua conta, este Contrato será rescindido.
						</p>
						<ol start={6}>
							<li>Segurança; suas interações com outros usuários.</li>
						</ol>
						<p>
							A AUTOTROCA se esforça para incentivar uma experiência entre
							usuários com respeito. Para isso, usamos recursos como a dupla
							aceitação, que permite aos usuários se comunicarem apenas se ambos
							tiverem interesse um pelo carro do outro. Porém, a AUTOTROCA não é
							responsável pela conduta do usuário dentro ou fora do Serviço.
							Você concorda em agir com cautela em todas as interações com
							outros usuários, especialmente ao decidir se comunicar fora do
							Serviço ou pessoalmente. Além disso, você concorda em revisar e
							seguir as Dicas de Segurança da AUTOTROCA antes de utilizar o
							Serviço.
						</p>
						<p>
							VOCÊ TEM RESPONSABILIDADE EXCLUSIVA POR SUAS INTERAÇÕES COM OUTROS
							USUÁRIOS. VOCÊ COMPREENDE QUE A AUTOTROCA NÃO INVESTIGA OS
							ANTECEDENTES CRIMINAIS DOS USUÁRIOS, NEM VERIFICA O HISTÓRICO
							DESTES. A AUTOTROCA NÃO MANIFESTA DECLARAÇÕES OU GARANTIAS EM
							RELAÇÃO À CONDUTA DOS USUÁRIOS.
						</p>
						<ol start={7}>
							<li>Direitos concedidos a você pela AUTOTROCA</li>
						</ol>
						<p>
							A AUTOTROCA concede a você uma licença pessoal, intransferível,
							não exclusiva e revogável para acessar e utilizar nosso Serviço.
							Essa licença é para o propósito exclusivo de permitir que você use
							e desfrute dos benefícios do Serviço, conforme a finalidade da
							AUTOTROCA e autorização prevista neste Contrato. Portanto, você
							concorda em não:
						</p>
						<ul>
							<li>
								usar o Serviço, ou qualquer conteúdo contido no Serviço, para
								fins comerciais sem a nossa autorização por escrito;
							</li>
							<li>
								copiar, modificar, transmitir, criar trabalhos derivados, fazer
								uso ou reproduzir em qualquer formato materiais protegidos por
								direitos autorais, imagens, marcas comerciais, nomes comerciais,
								marcas de serviço ou outros direitos de propriedade intelectual,
								conteúdo ou informações de propriedade exclusiva acessíveis
								através do Serviço, sem a autorização prévia por escrito da
								AUTOTROCA;
							</li>
							<li>
								expressar ou sugerir que suas declarações sejam endossadas pela
								AUTOTROCA;
							</li>
							<li>
								utilizar robô, bot, spider, rastreador, scraper, aplicativo de
								busca/recuperação de site, proxy ou outro dispositivo, método ou
								processo manual ou automático para acessar, recuperar, indexar,
								realizar “data mine” ou, de outra forma, reproduzir ou contornar
								a estrutura de navegação ou apresentação do Serviço ou
								conteúdos;
							</li>
							<li>
								utilizar o Serviço de forma que possa interferir, interromper ou
								afetar negativamente o Serviço, os servidores ou redes
								conectadas ao Serviço;
							</li>
							<li>
								enviar vírus ou outro código malicioso ou, de outra forma,
								comprometer a segurança do Serviço;
							</li>
							<li>
								forjar cabeçalhos ou manipular outros identificadores para
								disfarçar a origem das informações transmitidas para ou através
								do Serviço;
							</li>
							<li>
								“enquadrar” ou “espelhar” qualquer parte do Serviço sem
								autorização prévia por escrito da AUTOTROCA;
							</li>
							<li>
								utilizar meta-tags, códigos ou outros dispositivos que contenham
								referências à AUTOTROCA ou ao Serviço (ou marca comercial, marca
								registrada, marca de serviço, logotipo ou slogan da AUTOTROCA)
								para direcionar pessoas para outro site, para qualquer fim;
							</li>
							<li>
								modificar, adaptar, sublicenciar, traduzir, vender, promover
								engenharia reversa, decifrar, descompilar ou desmontar qualquer
								parte do Serviço ou fazer com que outras pessoas o façam;
							</li>
							<li>
								utilizar ou desenvolver aplicativos de terceiros que interajam
								com o Serviço, o conteúdo ou informações de outros usuários sem
								a nossa autorização por escrito;
							</li>
							<li>
								utilizar, acessar ou publicar a interface de programação da
								plataforma AUTOTROCA sem a nossa autorização por escrito;
							</li>
							<li>
								verificar, analisar ou testar a vulnerabilidade de nosso Serviço
								ou de qualquer sistema ou rede;
							</li>
							<li>
								incentivar ou promover qualquer atividade que viole este
								Contrato.
							</li>
						</ul>
						<p>
							A AUTOTROCA poderá investigar e tomar todas as medidas legalmente
							disponíveis em resposta a usos ilegais e/ou não autorizados do
							Serviço, inclusive o cancelamento da sua conta.
						</p>
						<p>
							Você poderá automaticamente baixar e instalar atualizações,
							melhorias ou outros novos recursos da AUTOTROCA. Você poderá
							ajustar downloads automáticos através das configurações do seu
							dispositivo.
						</p>
						<ol start={8}>
							<li>Direitos concedidos à AUTOTROCA por você.</li>
						</ol>
						<p>
							Ao fazer um cadastro, você concede à AUTOTROCA uma licença
							universal, transferível, sublicenciável, isenta de royalties e o
							direito de hospedar, armazenar, utilizar, copiar, exibir,
							reproduzir, adaptar, editar, publicar, modificar e ainda
							distribuir informações que você nos autoriza a acessar de
							terceiros, bem como outras informações que você publicar, fizer
							upload, exibir ou, de outra forma, disponibilizar no Serviço ou
							transmitir a outros usuários. Além disso, para que a AUTOTROCA
							possa evitar o uso de seu Conteúdo fora do Serviço, você autoriza
							a AUTOTROCA a agir em seu nome com relação a usos ilícitos de seu
							Conteúdo retirados do Serviço por outros usuários ou terceiros.
							Isso inclui expressamente a autorização, mas não a obrigação, de
							enviar avisos em seu nome caso o Conteúdo seja retirado e
							utilizado por terceiros fora do Serviço.
						</p>
						<p>
							Você confirma que todas as informações apresentadas no seu
							cadastro são corretas e verdadeiras. Nós concedemos a você tem o
							direito de publicar o seu carro no Serviço, mas você concede a
							licença acima descrita à AUTOTROCA.
						</p>
						<p>
							Você compreende e confirma que podemos monitorar ou revisar
							qualquer Conteúdo que você publicar no Serviço. Podemos excluir
							qualquer Conteúdo, no todo ou em parte, que, a nosso critério
							exclusivo, viole este Contrato ou possa prejudicar a reputação do
							Serviço.
						</p>
						<p>
							Ao se comunicar com os nossos representantes de atendimento ao
							cliente, você concorda em agir com respeito e gentileza. Se
							considerarmos que o seu comportamento em relação a qualquer de
							nossos representantes de atendimento ao cliente ou outros
							funcionários revele-se, em qualquer momento, ameaçador,
							perturbador ou ofensivo, reservamo-nos o direito de cancelar a sua
							conta imediatamente.
						</p>
						<p>
							Em consideração à AUTOTROCA, o qual permite que você utilize o
							Serviço, você concorda que poderá haver publicidade no Serviço
							veiculada por nós, nossas afiliadas e parceiros terceirizados. Ao
							enviar sugestões ou comentários à AUTOTROCA sobre o nosso Serviço,
							você concorda que a AUTOTROCA poderá utilizar e compartilhar as
							sugestões para qualquer finalidade, sem compensação.
						</p>
						<p>
							Você está ciente de que a AUTOTROCA poderá acessar, armazenar e
							divulgar as informações do seu cadastro e Conteúdo se exigido por
							lei ou se acreditar, de boa-fé, que o acesso, armazenamento ou
							divulgação satisfaçam um interesse legítimo, incluindo: (i)
							cumprir com um processo judicial; (ii) fazer cumprir o Contrato;
							(iii) responder a reivindicações de qualquer Conteúdo que viole os
							direitos de terceiros; (iv) responder às suas solicitações de
							atendimento ao cliente; ou (v) proteger os direitos, bens ou
							segurança pessoal da Empresa ou de qualquer outra pessoa.
						</p>
						<ol start={9}>
							<li>Regras da comunidade.</li>
						</ol>
						<p>Ao utilizar o Serviço, você concorda em não:</p>
						<ul>
							<li>
								utilizar o Serviço para qualquer finalidade que seja ilegal ou
								proibida por este Contrato;
							</li>
							<li>
								usar o Serviço para qualquer fim prejudicial ou desonesto;
							</li>
							<li>usar o Serviço com o propósito de prejudicar a AUTOTROCA;</li>
							<li>
								fazer spam, solicitar dinheiro ou fraudar qualquer membro;
							</li>
							<li>
								personificar qualquer pessoa ou entidade, ou publicar imagens de
								outra pessoa sem a permissão dela;
							</li>
							<li>
								ameaçar, perseguir, intimidar, agredir, assediar, maltratar ou
								difamar qualquer pessoa;
							</li>
							<li>
								publicar qualquer Conteúdo que viole ou infrinja os direitos de
								outra pessoa, inclusive direitos de publicidade, privacidade,
								direitos autorais, marca comercial ou outra propriedade
								intelectual ou direito contratual;
							</li>
							<li>
								publicar qualquer Conteúdo de ódio, ameaçador, sexualmente
								explícito ou pornográfico.
							</li>
							<li>
								publicar qualquer Conteúdo que incite a violência, contenha
								nudez, violência gráfica ou gratuita.
							</li>
							<li>
								publicar qualquer Conteúdo que promova racismo, fanatismo, ódio
								ou danos físicos de qualquer natureza contra qualquer grupo ou
								indivíduo;
							</li>
							<li>
								solicitar senhas e informações de identificação pessoal de
								outros usuários, para qualquer propósito, para fins comerciais
								ou ilegais, ou divulgar informações pessoais de outra pessoa sem
								a permissão dela;
							</li>
							<li>
								usar a conta de outro usuário, compartilhar uma conta com outro
								usuário ou manter mais de uma conta;
							</li>
							<li>
								criar outra conta se já tivermos cancelado a sua conta, a menos
								que você tenha a nossa permissão.
							</li>
						</ul>
						<p>
							A AUTOTROCA reserva-se o direito de investigar e/ou cancelar a sua
							conta, sem o reembolso de qualquer compra, se você violar este
							Contrato, utilizar o Serviço de forma inadequada ou se comportar
							de uma forma que a AUTOTROCA considere inadequada ou ilegal,
							incluindo ações ou comunicações que ocorram dentro ou fora do
							Serviço.
						</p>
						<ol start={10}>
							<li>Conteúdo de outros usuários.</li>
						</ol>
						<p>
							Embora a AUTOTROCA reserve-se no direito de revisar e remover o
							Conteúdo que viola este Contrato, o referido Conteúdo é de
							exclusiva responsabilidade do usuário que o publica, e a AUTOTROCA
							não garante que todo o Conteúdo cumprirá com este Contrato. Se
							você se deparar com algum Conteúdo no Serviço que implique na
							violação deste Contrato, informe o problema diretamente ao suporte
							pelo e-mail suporte@autotroca.com.br.
						</p>
						<ol start={11}>
							<li>
								Notificação e procedimento para reivindicação de violação de
								direitos autorais.
							</li>
						</ol>
						<p>
							Se você acreditar que o seu trabalho foi copiado e publicado no
							Serviço de forma que constitua uma violação de direitos autorais,
							envie um pedido de retirada diretamente ao suporte pelo e-mail
							suporte@autotroca.com.br.
						</p>
						<p>
							Se você entrar em contato conosco a respeito de violação de
							direitos autorais, lembre-se de incluir as seguintes informações:
						</p>
						<ul>
							<li>
								uma assinatura eletrônica ou física da pessoa autorizada a agir
								em nome do proprietário dos direitos autorais;
							</li>
							<li>
								uma descrição da obra protegida por direitos autorais que você
								alega ter sido violada;
							</li>
							<li>
								uma descrição de onde o material no qual você alega conter
								infração está localizado no Serviço (a descrição tem de ser
								razoavelmente suficiente para nos permitir localizar o suposto
								material infrator);
							</li>
							<li>
								suas informações de contato, inclusive endereço, número de
								telefone e e-mail, e o nome completo do titular dos direitos
								autorais;
							</li>
							<li>
								uma declaração escrita por você de que acredita, de boa-fé, que
								o uso em questão não está autorizado pelo proprietário dos
								direitos autorais, pelo seu agente ou por lei e
							</li>
							<li>
								uma declaração sua, feita sob pena de perjúrio, de que as
								informações da sua notificação acima são precisas e que você é o
								proprietário dos direitos autorais ou está autorizado a agir em
								nome do proprietário dos direitos autorais.
							</li>
						</ul>
						<p>A AUTOTROCA encerrará as contas de infratores reincidentes.</p>
						<ol start={12}>
							<li>Isenção de responsabilidade.</li>
						</ol>
						<p>
							A AUTOTROCA OFERECE O SERVIÇO “NO ESTADO EM QUE SE ENCONTRA”,
							“CONFORME DISPONÍVEL” E NA EXTENSÃO PERMITIDA PELA LEI APLICÁVEL,
							NÃO CONCEDE GARANTIAS DE QUALQUER TIPO, EXPRESSAS, IMPLÍCITAS,
							ESTATUTÁRIAS OU DE OUTRA FORMA COM RELAÇÃO AO SERVIÇO (INCLUINDO
							TODO O CONTEÚDO CONTIDO NO MESMO) INCLUSIVE, ENTRE OUTRAS,
							GARANTIAS IMPLÍCITAS DE QUALIDADE SATISFATÓRIA, COMERCIALIZAÇÃO,
							ADEQUAÇÃO A UM DETERMINADO FIM OU NÃO VIOLAÇÃO. A AUTOTROCA NÃO
							DECLARA OU GARANTE QUE O SERVIÇO SERÁ ININTERRUPTO, SEGURO OU
							LIVRE DE ERROS, QUAISQUER DEFEITOS OU ERROS NO SERVIÇO SERÃO
							CORRIGIDOS, OU QUE QUALQUER CONTEÚDO OU INFORMAÇÃO QUE VOCÊ
							OBTENHA NO OU ATRAVÉS DO SERVIÇO SERÃO PRECISAS.
						</p>
						<p>
							A AUTOTROCA NÃO ASSUME NENHUMA RESPONSABILIDADE POR QUALQUER
							CONTEÚDO QUE VOCÊ, OUTROS USUÁRIOS OU TERCEIROS PUBLICAM, ENVIAM
							OU RECEBEM ATRAVÉS DO SERVIÇO. QUALQUER MATERIAL BAIXADO OU, DE
							OUTRA FORMA, OBTIDO ATRAVÉS DO USO DO SERVIÇO, É ACESSADO POR SUA
							PRÓPRIA CONTA E RISCO.
						</p>
						<ol start={13}>
							<li>Serviços terceirizados.</li>
						</ol>
						<p>
							O Serviço pode conter anúncios e promoções oferecidas por
							terceiros, bem como links para outros sites ou recursos. A
							AUTOTROCA não é responsável pela disponibilidade (ou falta de
							disponibilidade) desses sites ou recursos externos. Se optar por
							interagir com terceiros disponibilizados através do nosso Serviço,
							os termos desse terceiro controlarão a relação deles com você. A
							AUTOTROCA não é responsável pelos termos ou ações de terceiros.
						</p>
						<ol start={14}>
							<li>Limitação de responsabilidade.</li>
						</ol>
						<p>
							EM TODA A EXTENSÃO PERMITIDA PELA LEI APLICÁVEL, EM NENHUMA
							CIRCUNST NCIA, A AUTOTROCA, SUAS AFILIADAS, FUNCIONÁRIOS,
							LICENCIADORES OU PRESTADORES DE SERVIÇOS SERÃO RESPONSÁVEIS POR
							QUAISQUER DANOS INDIRETOS, CONSEQUENTES, EXEMPLARES, INCIDENTAIS,
							ESPECIAIS, PUNITIVOS OU INCREMENTADOS, INCLUSIVE, ENTRE OUTROS,
							PERDA DE LUCROS, INCORRIDA DIRETA OU INDIRETAMENTE, OU PERDA DE
							DADOS, USO, CLIENTELA E OUTRAS PERDAS INTANGÍVEIS, RESULTANTES DO
							SEGUINTE: (I) O SEU ACESSO, UTILIZAÇÃO OU INCAPACIDADE DE ACESSO
							OU UTILIZAÇÃO DOS SERVIÇOS, (II) A CONDUTA OU CONTEÚDO DE OUTROS
							USUÁRIOS OU TERCEIROS NA UTILIZAÇÃO DOS SERVIÇOS; OU (III) ACESSO,
							USO OU ALTERAÇÃO NÃO AUTORIZADA DO SEU CONTEÚDO, AINDA QUE A
							AUTOTROCA TENHA SIDO AVISADO DA POSSIBILIDADE DOS REFERIDOS DANOS.
							EM NENHUMA CIRCUNST NCIA, A RESPONSABILIDADE DA AUTOTROCA EM
							RELAÇÃO A VOCÊ POR TODAS AS REIVINDICAÇÕES RELACIONADAS AO
							SERVIÇO, EXCEDERÁ O VALOR PAGO PELO SERVIÇO.
						</p>
						<ol start={15}>
							<li>Indenização.</li>
						</ol>
						<p>
							Você concorda, na extensão permitida pela lei aplicável, em
							indenizar, defender e isentar a AUTOTROCA, nossas afiliadas e seus
							respectivos executivos, diretores, agentes e funcionários de e
							contra quaisquer e todas as reclamações, demandas, reivindicações,
							danos, perdas, custos, responsabilidades e despesas, inclusive
							honorários advocatícios decorrentes, resultantes ou relacionados,
							de qualquer forma, com o seu acesso ou uso do Serviço, seu
							Conteúdo ou a sua violação deste Contrato.
						</p>
						<ol start={16}>
							<li>Contrato integral; outros.</li>
						</ol>
						<p>
							Este Contrato, que inclui a{" "}
							<IonRouterLink routerLink="/politica-privacidade">
								Política de Privacidade
							</IonRouterLink>
							, e quaisquer outros termos divulgados e acordados por você,
							aquisição de recursos, produtos ou serviços adicionais que
							oferecemos no Serviço, constitui um Contrato Integral entre você e
							a AUTOTROCA. Se qualquer disposição deste Contrato for considerada
							inválida, as demais disposições deste Contrato permanecerão em
							pleno vigor e efeito. A incapacidade da Empresa em exercer ou
							exigir qualquer direito ou disposição deste Contrato não
							constituirá uma renúncia a este direito ou a esta disposição. Você
							concorda que a sua conta da AUTOTROCA é intransferível e todos os
							seus direitos sob a sua conta e seu conteúdo terminam após a sua
							morte. Nenhuma agência, parceria, joint venture, fiduciária ou
							outra relação ou contratação especial é criada como resultado
							deste Contrato, e você não poderá, de maneira alguma, fazer
							quaisquer declarações ou vincular a AUTOTROCA.
						</p>
					</Termos>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default ServiceTerms;
