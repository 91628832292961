//https://stackoverflow.com/questions/43164554/how-to-implement-authenticated-routes-in-react-router-4/43171515#43171515
//https://stackoverflow.com/questions/47476186/when-user-is-not-logged-in-redirect-to-login-reactjs
import { useContext } from "react";
import { AppContext } from "./State";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = (props) => {
	const { state } = useContext(AppContext);

	if (!state?.user?.id) {
		return <Redirect to="/entrar" />;
	} else {
		return <Route {...props} />;
	}
};

export default PrivateRoute;
