import React, { useState, useEffect, useContext, useRef } from "react";
import { API_HOME, MEDIA_HOME } from "../../config";
import { AppContext } from "../../State";
import useWindowFocus from "use-window-focus";
import * as Sentry from "@sentry/react";
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from "@capacitor/core";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonButton,
	IonRippleEffect,
	IonFooter,
	useIonViewDidEnter,
	useIonActionSheet,
	IonModal,
	useIonAlert
} from "@ionic/react";


import Thumbnail from "./Thumbnail";
import { format } from "date-fns";

import { Logo } from "../home/Home.style";
import { RouteComponentProps } from "react-router-dom";
import { Bubble, StyledTitle, ActionButton, InputWrapper, MessageDate, Messages, StyledInput, StyledMessage, StyledTextArea, SubmitButton, UserName, UserStatus, UserWidget, UserWidgetText } from "./Chat.style";
import { Col, Row } from "../mycars/MyCarsComponents";
import { PageUI, PageBackground, Panel } from "../../components/ui";

interface ChatPageProps
	extends RouteComponentProps<{
		id: string;
	}> { }


const Message = (props: any) => {
	return (
		<StyledMessage>
			<Bubble fromMe={props.from === props.me}>{props.body}</Bubble>
			<MessageDate fromMe={props.from === props.me}>
				{format(new Date(props?.createdAt), "p")}
			</MessageDate>
		</StyledMessage>
	);
};


const Chat: React.FC<ChatPageProps> = ({ match, history }) => {
	const { state, dispatch } = useContext(AppContext);
	const { matches, chats, user } = state;
	
	useEffect(() => {
		if (Capacitor.getPlatform() != 'web') {
	
			Keyboard.addListener('keyboardDidShow', info => {
				scrollToBottom();
			});
		}
	 }, []);
	
	
	const contentRef = useRef<HTMLIonContentElement | null>(null);
	const inputRef = useRef<HTMLIonTextareaElement | null>(null);
	const [inputMessage, setInputMessage] = useState("");
	const [reportMessage, setReportMessage] = useState("");
	const matchId = parseInt(match.params.id);
	const recipient = matches.find((m) => m.id === matchId)?.user;
	const [recipientSuperLike, setRecipientSuperLike] = useState({
		city: 0,
		first_name: "",
		id: 0,
		online: false,
		state: 0
	});
	const messages = chats?.filter((c) => c.channel === matchId) || [];
	const windowFocused = useWindowFocus();
	const [presentActionSheet, dismissActionSheet] = useIonActionSheet();
	const [showModal, setShowModal] = useState(false);
	const [blockedUser, setBlockedUser] = useState(false);
	const [presentAlert] = useIonAlert();

	const scrollToBottom = () => {
		contentRef.current && contentRef.current.scrollToBottom();
	};

	const loadNewMessages = async (date?) => {
		if (!date && messages.length === 0) return;

		const lastMessage = messages[messages.length - 1];

		const req = await fetch(
			`${API_HOME}/chat/${matchId}?since=${date || lastMessage?.createdAt}`,
			{
				headers: {
					authorization: `Bearer ${user.token}`,
				},
			}
		);

		const newChats = await req.json();

		dispatch({
			type: "loadNewChats",
			newChats,
		});

		scrollToBottom();
	};

	const loadMatches = async () => {
		const req = await fetch(
			`${API_HOME}/user/${user?.id}/matches`,
			{
				headers: {
					authorization: `Bearer ${user.token}`,
				},
			}
		);
		let matchesAux = await req.json();
		setRecipientSuperLike(matchesAux.find((m) => m.id === matchId)?.user);
		// console.log('matchesAux', matchesAux);

		// console.log('recipientSuperLike', recipientSuperLike);

	}

	useEffect(() => {
		loadMatches();
		if (windowFocused) {
			loadNewMessages();
		}
	}, [windowFocused]);

	useEffect(() => {
		scrollToBottom();
	}, [chats]);

	useEffect(() => {

		fetch(`${API_HOME}/report/${recipient?.id || recipientSuperLike?.id}`, {
			method: "GET",
			headers: {
				authorization: `Bearer ${user.token}`,
				"Content-type": "application/json",
			}
		})
			.then((resp) => resp.json())
			.then((data) => {
				setBlockedUser(data.bloqued);
			})
			.catch((err) => {
				console.error("Error fetching reported users", err);
				Sentry.captureException(err);
			});

	}, [])

	const sendMessage = () => {
		// console.log('recipientSuperLike?.id', recipientSuperLike?.id);

		if (inputMessage?.length == 0)
			return null;

		fetch(`${API_HOME}/chat`, {
			method: "POST",
			headers: {
				authorization: `Bearer ${user.token}`,
				"Content-type": "application/json",
			},
			body: JSON.stringify({
				to: recipient?.id || recipientSuperLike?.id,
				body: inputMessage,
				channel: matchId,
			}),
		})
			.then((resp) => resp.json())
			.then((message) => {
				setInputMessage("");

				if (message.showPopUp) {
					return history.push("/tabs/funcpremium");
				}

				if (message.showBlockPopUp) {
					setBlockedUser(true);
					return;
				}

				dispatch({
					type: "addChat",
					message: { ...message },
				});

				scrollToBottom();
			});
	};

	useIonViewDidEnter(() => {
		// console.log("Loading new messages from ionviewdidenter");
		
		dispatch({
			type: "markChatsAsRead",
			channel: matchId,
		});

		loadNewMessages();
		scrollToBottom();
	});

	const reportUser = async () => {

		if (!reportMessage) {
			return presentAlert({
				header: "Usuário reportado",
				message: "Preencha o motivo de estar reportando este usuário.",
				buttons: ["Ok"],
			});
		}

		const message = {
			id: recipient?.id || recipientSuperLike?.id,
			reason: reportMessage
		}

		let req = await fetch(`${API_HOME}/report`, {
			method: "POST",
			headers: {
				authorization: `Bearer ${user.token}`,
				"Content-type": "application/json",
			},
			body: JSON.stringify(message)
		});

		// const resp = await req.json();
		
		// console.log(req.ok);
		
		if (req.ok) {
			setShowModal(false);
			presentAlert({
				header: "Usuário reportado",
				message: "O usuário foi reportado com sucesso.",
				buttons: ["Ok"],
			});

		} else {
			presentAlert({
				header: "Usuário reportado",
				message: "Não foi possível bloquear o usuário no momento, Tente novamente mais tarde.",
				buttons: ["Ok"],
			});
		}

		history.goBack();
	}

	return (
		<IonPage>
			{!blockedUser && (<>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/tabs/chat" text="Voltar" />
						</IonButtons>

						{(recipient?.id || recipientSuperLike?.id) && (
							<UserWidget>
								<Thumbnail
									src={`${MEDIA_HOME}/user/${recipient?.id || recipientSuperLike?.id}/profile.jpg`}
									online={(recipient?.online || recipientSuperLike?.online) || false}
									height={40}
								/>
								<UserWidgetText>
									<UserName>{recipient?.first_name || recipientSuperLike?.first_name}</UserName>
									{/* <UserStatus>
										{(recipient?.online || recipientSuperLike?.online) ? "Online" : "Offline"}
									</UserStatus> */}
								</UserWidgetText>
							</UserWidget>
						)}

						<IonButtons slot="end">
							<IonButton
								onClick={() => {
									presentActionSheet({
										buttons: [
											{
												text: "Ver match",
												handler: () => {
													history.push(`/tabs/match/${matchId}`)
												},
											},
											{
												text: "Bloquear usuário",
												handler: () => {
													setShowModal(true)
												},
											},
											{ text: "Cancelar" },
										],
									});
								}}
							>
								<img src="assets/iconOption.svg" />
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent fullscreen ref={contentRef} scrollEvents={true}>
					<Messages>
						{messages.map((message, i) => (
							<Message key={i} {...message} me={user?.id} />
						))}
					</Messages>
				</IonContent>
				<IonFooter>
					<InputWrapper>
						<StyledInput
							value={inputMessage}
							placeholder="Digite uma mensagem"
							rows={1}
							cols={100}
							ref={inputRef}
							autoGrow={true}
							onIonChange={(e) => setInputMessage(e.detail.value!)}
						></StyledInput>
						<SubmitButton
							className="ion-activatable ripple-parent"
							onMouseDown={(event) => {
								event.preventDefault();
								event.stopPropagation();
							}}
							onClick={(event) => {
								event.preventDefault();
								event.stopPropagation();
								sendMessage();
							}}
						>
							<img src="assets/send.svg" />
							<IonRippleEffect />
						</SubmitButton>
					</InputWrapper>
				</IonFooter>
				<IonModal
					isOpen={showModal}
					swipeToClose={true}
					onDidDismiss={() => setShowModal(false)}
				>
					<div
						style={{
							margin: "auto 0",
							padding: "1rem",
							textAlign: "center",
						}}
					>
						<Row>
							<Col size="12">
								<StyledTitle>
									Deseja mesmo bloquear esse usuário?
								</StyledTitle>
							</Col>
						</Row>
						<Row>
							<Col size="12">
								<StyledTextArea
									value={reportMessage}
									rows={4}
									placeholder="Informe o motivo de bloquear este usuário."
									onIonChange={(e) => setReportMessage(e.detail.value!)}
								></StyledTextArea>
							</Col>
						</Row>

						<Row>
							<Col size="6">
								<ActionButton onClick={() => setShowModal(false)}>
									Cancelar
								</ActionButton>
							</Col>
							<Col size="6">
								<ActionButton onClick={reportUser}>
									Bloquear
								</ActionButton>
							</Col>
						</Row>
					</div>
				</IonModal></>)}
			{blockedUser && (<>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/tabs/chat" text="Voltar" />
						</IonButtons>

						{(recipient?.id || recipientSuperLike?.id) && (
							<UserWidget>
								<Thumbnail
									src={`${MEDIA_HOME}/user/${recipient?.id || recipientSuperLike?.id}/profile.jpg`}
									online={(recipient?.online || recipientSuperLike?.online) || false}
									height={40}
								/>
								<UserWidgetText>
									<UserName>{recipient?.first_name || recipientSuperLike?.first_name}</UserName>
									{/* <UserStatus>
										{(recipient?.online || recipientSuperLike?.online) ? "Online" : "Offline"}
									</UserStatus> */}
								</UserWidgetText>
							</UserWidget>
						)}

						<IonButtons slot="end">
							<IonButton
								onClick={() => {
									presentActionSheet({
										buttons: [
											{
												text: "Ver match",
												handler: () => {
													history.push(`/tabs/match/${matchId}`)
												},
											},
											{
												text: "Bloquear usuário",
												handler: () => {
													setShowModal(true)
												},
											},
											{ text: "Cancelar" },
										],
									});
								}}
							>
								<img src="assets/iconOption.svg" />
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent fullscreen>
					<PageBackground>
						<Panel>
							<br />
							<Logo src="assets/logo_final.svg" />
							<Row>
								<Col size="12">
									<StyledTitle style={{ margin: "0 auto !important" }}>
										Este usuário não pode receber mensagens neste momento.
									</StyledTitle>
								</Col>
							</Row>
						</Panel>
					</PageBackground>
				</IonContent>
			</>)}
		</IonPage >

	);
};

export default Chat;
