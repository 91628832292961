import React, { useRef, useState, useEffect, useContext } from "react";
import { AppContext } from "../../State";
import { API_HOME, mileageFactor, priceFactor } from "../../config";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonRouterLink,
	useIonModal,
	useIonAlert,
	IonLoading,
	IonTextarea,
	useIonViewDidEnter,
	IonSelect,
	IonSelectOption,
	IonBackButton
} from "@ionic/react";

import {
	PageUI,
	PageBackground,
	Panel,
	PageSubTitle,
} from "../../components/ui";

import { menuController } from "@ionic/core";
import SearchableSelect from "../../common/SearchableSelect";
import { estados } from "../../common/estados";
import { municipios as municipiosDB } from "../../common/municipios";
import { Checkbox } from "../../common/input";
import styled, { css } from "styled-components";
import PicturesModal from "./PicturesModal";
import removeAccents from "remove-accents";
import VersionSelect from "../../common/VersionSelect ";
import MotorcyleModal from "./MotorcycleModal";

const sortedMunicipios = municipiosDB.sort((a, b) => {
	if (a.capital === 1 && b.capital === 0) return -1;
	if (removeAccents(a.nome) < removeAccents(b.nome)) {
		return -1;
	}
	if (removeAccents(a.nome) > removeAccents(b.nome)) {
		return 1;
	}
	return 0;
});

const Buttons = styled.div`
	padding: 1rem 0;
	display: flex;
`;

const BackButton = styled(IonRouterLink)`
	display: flex;
	width: 60px;
	height: 60px;
	background-color: #ededed;
	border-radius: 50%;
	align-items: center;
	justify-content: center;

	& img {
		width: 29px;
		height: 14px;
	}
`;

const CommentField = styled(IonTextarea)`
	background: transparent;
	border: solid 1px #d3d3d3;
	width: 100%;
	border-radius: 10px;
	overflow: hidden;
	min-height: 3rem;
`;

const ActionButton = styled(IonRouterLink)`
font-weight: 800;
cursor: pointer;
font-size: 18px;
padding: 18px 0px;
flex: 1;
text-align: center;
background: #d63d2f;
border-radius: 30px;
color: #fff;
display: block;
text-transform: uppercase;

margin: 10px 20px 10px 20px;
`;

const InputBox = styled.div`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 15px !important;
	padding: 1rem !important;
	margin-bottom: 6px;
`;

const Input = styled.input`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
	width: 100%;
	border: none;
	outline: none;
	padding-top: 10px;
	padding-bottom: 10px;

	&::placeholder {
		color: #b5b5b5;
	}
`;

const Select = styled.select`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
	width: 100%;
	border: none;
	outline: none;
	padding-top: 10px;
	padding-bottom: 10px;

	&::placeholder {
		color: #b5b5b5;
	}
`;

const UploadedThumbnails = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	& img {
		width: 50px !important;
		height: 50px;
		object-fit: cover;
		margin: 1rem;
		border-radius: 20%;
	}
`;

const Row = styled.div`
	display: flex;
`;

const Col = styled.div`
	&:first-child {
		width: 10rem;
		margin-right: 1rem;
	}

	&:last-child {
		width: 100%;
	}
`;

const AddCar: React.FC = () => {
	const { state, dispatch } = useContext(AppContext);
	const { user } = state;

	const history = useHistory();
	const contentRef = useRef<HTMLIonContentElement | null>(null);
	const vehicleTypeList = [{ name: 'Carro', id: 1 }, { name: 'Moto', id: 2 }]
	const [make, setMake] = useState<any>(null);
	const [model, setModel] = useState<any>(null);
	const [makeName, setMakeName] = useState('');
	const [modelName, setModelName] = useState("");
	const [versionName, setVersionName] = useState("");
	const [motorcycleMake, setMotorcycleMake] = useState<any>(null);
	const [motorcycleModel, setMotorcycleModel] = useState(null);
	const [motorcycleModelName, setMotorcycleModelName] = useState(null);


	const [versionType, setVersionType] = useState<any>(null);
	const [versionTypeList, setVersionTypeList] = useState([]);
	const [year, setYear] = useState<any>(null);
	const [mileage, setMileage] = useState(1);
	const [airConditioning, setAirConditioning] = useState(false);
	const [assistedDriving, setAssistedDriving] = useState(false);
	const [airbag, setAirbag] = useState(false);
	const [abs, setAbs] = useState(false);
	const [shift, setShift] = useState(0);
	const [fuel, setFuel] = useState(0);
	const [body, setBody] = useState(0);
	const [category, setCategory] = useState(0);
	const [geoState, setGeoState] = useState(0);
	const [geoCity, setGeoCity] = useState(0);
	const [vehicleType, setVehicleType] = useState(null);
	// const [mileageEntered, setMileageEntered] = useState(false);
	// const [priceEntered, setPriceEntered] = useState(false);

	const [priceRange, setPriceRange] = useState(1);

	const [images, setImages] = useState({});

	const [showLoading, setShowLoading] = useState(false);

	const [presentAlert] = useIonAlert();
	const [makesList, setMakesList] = useState([]);
	const [modelsList, setModelsList] = useState([]);
	const [versionList, setVersionList] = useState<any>([]);
	const [motorcycleMakesList, setMotorcycleMakesList] = useState([]);
	const [motorcycleModelsList, setMotorcycleModelsList] = useState([]);
	const [comment, setComment] = useState("");

	const [isCommentValid, setIsCommentValid] = useState(true);

	const regex = [
		new RegExp(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,500}\b/i),
		new RegExp(/\(?\d{2}\)?\s?9?\d{4}-?\d{4}/i),
		new RegExp(/\9?\d{4}-?\d{4}/i),
		new RegExp(/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/i),
		new RegExp(/(\d{1,2})[\.]?(\d{3})[\.]?(\d{3})[-.]?(\d{1})/i),
		new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i),
		new RegExp(/([-a-zA-Z0-9@:%._\+~#=]{1,256})[\.]([-a-zA-Z0-9@:%._\+~#=]{1,256})[\.]([-a-zA-Z0-9@:%._\+~#=]{1,256})/i)
	]


	const changeComment = (value) => {
		setComment(value);

		if (regex[0].exec(value) || regex[1].exec(value) || regex[2].exec(value) || regex[3].exec(value) || regex[4].exec(value) || regex[5].exec(value) || regex[6].exec(value)) {
			setIsCommentValid(false);
		} else {
			setIsCommentValid(true);
		}
	}

	useEffect(() => {
		// console.log("Loading makes");
		fetch(`${API_HOME}/car/makes`, {
			headers: {
				authorization: `Bearer ${state.user.token}`,
			},
		})
			.then((resp) => resp.json())
			.then((data) => {
				if (data?.length) {
					data.push({ id: 999999, name: "Outra" });
					setMakesList(data);
					//console.log("Loaded this list of makes", data);
				}
			})
			.catch((err) => {
				console.error("Error fetching car makes", err);
				Sentry.captureException(err);
			});
	}, []);

	const loadModels = (make) => {
		fetch(`${API_HOME}/car/models/${make}`, {
			headers: {
				authorization: `Bearer ${state.user.token}`,
			},
		})
			.then((resp) => resp.json())
			.then((data) => {
				data.push({ id: 999999, name: "Outro" });
				setModelsList(data);
				// console.log(
				// 	"For the make",
				// 	make,
				// 	"We loaded this list of models",
				// 	data
				// );
			})
			.catch((err) => {
				console.error("Error fetching car models", err);
				Sentry.captureException(err);
			});
	}

	const loadVersion = (model) => {
		
		fetch(`${API_HOME}/car/versions/${model}`, {
			headers: {
				authorization: `Bearer ${state.user.token}`,
			},
		})
			.then((resp) => resp.json())
			.then((data) => {
				data.push({ id: 999999, name: "Outra", versiontypes: "Outra" });
				// console.log(data)
				setVersionList(data);
				// console.log(
				// 	"For the model",
				// 	model,
				// 	"We loaded this list of versions",
				// 	data
				// );
			})
			.catch((err) => {
				console.error("Error fetching car models", err);
				Sentry.captureException(err);
			});
	};
	
	
	const loadModelsMoto = (make) => {
		// console.log('motorcycleMakemotorcycleMakemotorcycleMake', make);

		//if (motorcycleMake) {

		fetch(`${API_HOME}/car/models/${make}`, {
			headers: {
				authorization: `Bearer ${state.user.token}`,
			},
		})
			.then((resp) => resp.json())
			.then((data) => {
				data.push({ id: 999999, name: "Outro" });
				setMotorcycleModelsList(data);
				// console.log(
				// 	"For the make",
				// 	motorcycleMake,
				// 	"We loaded this list of models",
				// 	data
				// );
			})
			.catch((err) => {
				console.error("Error fetching car models", err);
				Sentry.captureException(err);
			});
		//}

	};

	// --------------------------------------------------------

	// useEffect(() => {
	// 	fetch(`${API_HOME}/car/models/${make}`, {
	// 		headers: {
	// 			authorization: `Bearer ${state.user.token}`,
	// 		},
	// 	})
	// 		.then((resp) => resp.json())
	// 		.then((data) => {
	// 			data.push({ id: 999999, name: "Outro" });
	// 			setModelsList(data);
	// 			console.log(
	// 				"For the make",
	// 				make,
	// 				"We loaded this list of models",
	// 				data
	// 			);
	// 		})
	// 		.catch((err) => {
	// 			console.error("Error fetching car models", err);
	// 			Sentry.captureException(err);
	// 		});
	// }, [make]);

	// useEffect(() => {
	// 	fetch(`${API_HOME}/car/versions/${model}`, {
	// 		headers: {
	// 			authorization: `Bearer ${state.user.token}`,
	// 		},
	// 	})
	// 		.then((resp) => resp.json())
	// 		.then((data) => {
	// 			data.push({ id: 999999, name: "Outra",versiontypes:"Outra" });
	// 			console.log(data)
	// 			setVersionList(data);
	// 			console.log(
	// 				"For the model",
	// 				model,
	// 				"We loaded this list of versions",
	// 				data
	// 			);
	// 		})
	// 		.catch((err) => {
	// 			console.error("Error fetching car models", err);
	// 			Sentry.captureException(err);
	// 		});
	// }, [model]);

	useEffect(() => {
		fetch(`${API_HOME}/car/motomakes`, {

		})
			.then((resp) => resp.json())
			.then((data) => {
				data.push({ id: 999999, name: "Outro" });
				setMotorcycleMakesList(data);
				// console.log(
				// 	"For the make",
				// 	make,
				// 	"We loaded this list of models",
				// 	data
				// );
			})
			.catch((err) => {
				console.error("Error fetching car models", err);
				Sentry.captureException(err);
			});
	}, [vehicleType]);

	// useEffect(() => {
	// 	if(motorcycleMake){
	// 		fetch(`${API_HOME}/car/models/${make}`,{
	// 			headers: {
	// 				authorization: `Bearer ${state.user.token}`,
	// 			},
	// 		})
	// 		.then((resp) => resp.json())
	// 		.then((data) => {
	// 			data.push({ id: 999999, name: "Outro" });
	// 			setMotorcycleModelsList(data);
	// 			console.log(
	// 				"For the make",
	// 				motorcycleMake,
	// 				"We loaded this list of models",
	// 				data
	// 			);
	// 		})
	// 		.catch((err) => {
	// 			console.error("Error fetching car models", err);
	// 			Sentry.captureException(err);
	// 		});
	// 	}

	// }, [motorcycleMake]);

	const handleDismiss = () => {
		dismissPicturesModal();
	};



	useEffect(() => {

		const formData = {
			geoState,
			geoCity,
			vehicleType,
			make,
			model,
			year,
			mileage,
			airConditioning,
			assistedDriving,
			airbag,
			abs,
			shift,
			fuel,
			body,
			category,
			// mileageEntered,
			// priceEntered,
			priceRange,
			images,
			comment,
			versionName,
			modelName
		};

		// console.log("form changed", formData)

		dispatch({
			type: "createCarFormChanged",
			formData
		});
	}, [
		geoState,
		geoCity,
		vehicleType,
		make,
		model,
		year,
		mileage,
		airConditioning,
		assistedDriving,
		airbag,
		abs,
		shift,
		fuel,
		body,
		category,
		// mileageEntered,
		// priceEntered,
		priceRange,
		images,
		comment,
		versionName,
		modelName
	]);

	useEffect(() => {
		// console.log("state is", state)
	}, [state]);

	const initializeForm = () => {
		if (!state?.addCarForm) return;
		// console.log("############## form being initialized");
		const form = state?.addCarForm || {};

		setGeoState(form?.geoState || 0);
		setGeoCity(form?.geoCity || 0);
		setMake(form?.make || null);
		setModel(form?.model || null);
		setYear(form?.year || null);
		setMileage(form?.mileage || 1);
		setAirConditioning(form?.airConditioning || false);
		setAssistedDriving(form?.assistedDriving || false);
		setAirbag(form?.airbag || false);
		setAbs(form?.abs || false);
		setShift(form?.shift || 0);
		setFuel(form?.fuel || 0);
		setBody(form?.body || 0);
		setCategory(form?.category || 0);
		// setMileageEntered(form?.mileageEntered || false);
		// setPriceEntered(form?.priceEntered || false);
		setPriceRange(form?.priceRange || 1);
		setImages(form?.images || {});
		setShowLoading(false);
		setComment(form?.comment || '');
		setVersionName(form?.versionName || '');
		setModelName(form?.modelName || '');
	};

	const clearForm = () => {
		setGeoState(0);
		setGeoCity(0);
		setMake(null);
		setModel(null);
		setYear(null);
		setMileage(1);
		setAirConditioning(false);
		setAssistedDriving(false);
		setAirbag(false);
		setAbs(false);
		setShift(0);
		setFuel(0);
		setBody(0);
		setCategory(0);
		// setMileageEntered(false);
		// setPriceEntered(false);
		setPriceRange(1);
		setImages({});
		setShowLoading(false);
		setComment("");
		setVersionName('');
		setModelName('');
	};

	useIonViewDidEnter(() => {
		initializeForm();
	});

	const error = (message) => {
		presentAlert({
			header: "Erro",
			message,
			buttons: ["Ok"],
		});
	};

	const uploadImages = async (carId, images) => { };

	// este add aqui serve para quando 
	// NAO precisa adicionar o modelo e versao
	const addCar = async () => {
		const optionals: any = [];
		// console.log('vehicleType', vehicleType);

		if (vehicleType == 2) setBody(20)
		if (airConditioning) {
			optionals.push(1);
		}

		if (assistedDriving) {
			optionals.push(2);
		}

		if (airbag) {
			optionals.push(3);
		}

		if (abs) {
			optionals.push(4);
		}

		const payload = {
			city: geoCity,
			state: geoState,
			make,
			model,
			year,
			mileage,
			vehicleType,
			versionType,
			price_range: priceRange,
			shift_type: shift,
			fuel_type: fuel,
			body_type: body,
			category,
			optionals,
			comment,
			images: Object.entries(images).map((k: any) => {
				return { name: k[0], path: k[1].uploadedFilename };
			}),
		};
		// console.log("Validating form", payload);

		if (!(geoState > 0)) {
			return error("Cidade onde o carro encontra precisa ser selecionada");
		}

		if (!(geoCity > 0)) {
			return error("Cidade onde o carro encontra precisa ser selecionada");
		}

		if (!make) {
			return error("Marca do carro precisa ser selecionada");
		}

		if (make !== 999999 && !model) {
			return error("Modelo do carro precisa ser selecionado");
		}

		if (!(year > 0)) {
			return error("O ano do carro precisa ser selecionado");
		}

		if (!(mileage > 0)) {
			return error("A quilometragem do carro precisa ser selecionada");
		}

		if (!(shift > 0)) {
			return error("O tipo de câmbio do carro precisa ser selecionado");
		}

		if (!(fuel > 0)) {
			return error("O tipo de combustível do carro precisa ser selecionado");
		}

		if (!(body > 0) && vehicleType == 1) {
			// console.log('vehicleType', vehicleType);

			return error("O tipo de carroceria do carro precisa ser selecionado");
		}

		if (!(category > 0) && vehicleType == 1) {
			return error("A categoria do carro precisa ser selecionada");
		}
		if (Object.keys(images).length !== 6) {
			return error("As seis fotos do carro precisam ser selecionadas");
		}

		setShowLoading(true);

		//debugger;

		fetch(`${API_HOME}/car`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
				authorization: `Bearer ${state.user.token}`,
			},
			body: JSON.stringify(payload),
		})
			.then((resp) => resp.json())
			.then((resp) => {
				// console.log("Got this response: ", resp);
				if (resp.errors) {
					return error(
						"Dados inválidos \r\n" +
						resp.errors.map((err) => err.message).join(" \r\n ")
					);
				} else {
					const car = resp;

					uploadImages(car.id, images)
						.then(() => {
							dispatch({
								type: "createCar",
								car,
							});

							history.push(`/tabs/cars/add/desired/${car.id}`);
							dispatch({
								type: "createCarFormChanged",
								formData: null
							});
							clearForm();
						})
						.catch((err) => {
							Sentry.captureException(err);
							return error(err.message);
						})
						.finally(() => {
							setShowLoading(false);
						});
				}
			})
			.catch((err) => {
				console.error("Got this error", err);
				setShowLoading(false);
				Sentry.captureException(err);

				return error("Erro ao cadastrar - " + err.message);
			});
	};

	// este add aqui serve para quando 
	// precisa adicionar o modelo e versao
	const addNewCar = async () => {
		const optionals: any = [];
		if (vehicleType == 2) setBody(20)
		if (airConditioning) {
			optionals.push(1);
		}

		if (assistedDriving) {
			optionals.push(2);
		}

		if (airbag) {
			optionals.push(3);
		}

		if (abs) {
			optionals.push(4);
		}

		const payload = {
			city: geoCity,
			state: geoState,
			make,
			model,
			year,
			mileage,
			makeName,
			modelName,
			versionName,
			price_range: priceRange,
			shift_type: shift,
			fuel_type: fuel,
			body_type: body,
			category,
			optionals,
			comment,
			vehicleType,
			versionType,
			images: Object.entries(images).map((k: any) => {
				return { name: k[0], path: k[1].uploadedFilename };
			}),
		};
		// console.log("Validating form", payload);

		if (!(geoState > 0)) {
			return error("Cidade onde o carro encontra precisa ser selecionada");
		}

		if (!(geoCity > 0)) {
			return error("Cidade onde o carro encontra precisa ser selecionada");
		}

		if (!make) {
			return error("Marca do carro precisa ser selecionada");
		}

		if (make !== 999999 && !model) {
			return error("Modelo do carro precisa ser selecionado");
		}

		if (!(year > 0)) {
			return error("O ano do carro precisa ser selecionado");
		}

		debugger;
		if (!(mileage > 0)) {
			return error("A quilometragem do carro precisa ser selecionada");
		}

		if (!(shift > 0)) {
			return error("O tipo de câmbio do carro precisa ser selecionado");
		}

		if (!(fuel > 0)) {
			return error("O tipo de combustível do carro precisa ser selecionado");
		}

		if (!(body > 0) && vehicleType == 1) {
			return error("O tipo de carroceria do carro precisa ser selecionado");
		}

		if (!(category > 0)) {
			return error("A categoria do carro precisa ser selecionada");
		}
		if (Object.keys(images).length !== 6) {
			return error("As seis fotos do carro precisam ser selecionadas");
		}

		setShowLoading(true);

		fetch(`${API_HOME}/car/createCarAndModel`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
				authorization: `Bearer ${state.user.token}`,
			},
			body: JSON.stringify(payload),
		})
			.then((resp) => resp.json())
			.then((resp) => {
				// console.log("Got this response: ", resp);
				if (resp.errors) {
					return error(
						"Dados inválidos \r\n" +
						resp.errors.map((err) => err.message).join(" \r\n ")
					);
				} else {
					const car = resp;

					uploadImages(car.id, images)
						.then(() => {
							dispatch({
								type: "createCar",
								car,
							});

							history.push(`/tabs/cars/edit/desired/${car.id}`);
							dispatch({
								type: "createCarFormChanged",
								formData: null
							});
							clearForm();
						})
						.catch((err) => {
							Sentry.captureException(err);
							return error(err.message);
						})
						.finally(() => {
							setShowLoading(false);
						});
				}
			})
			.catch((err) => {
				console.error("Got this error", err);
				setShowLoading(false);
				Sentry.captureException(err);

				return error("Erro ao cadastrar - " + err.message);
			});
	};

	const submitForm = async () => {
		if (!isCommentValid) return;
		
		if (model == 999999 || make == 999999 || versionType == 999999) 
			await addNewCar()
		else 
			await addCar()
	}

	const [showPicturesModal, dismissPicturesModal] = useIonModal(PicturesModal, {
		images,
		state,
		onSave: (images) => {
			dismissPicturesModal();
			setImages(images);
			// console.log("Received these images: ", images);
			setTimeout(() => {
				contentRef?.current?.scrollToBottom();
			}, 100);
		},

		onDismiss: handleDismiss,
	});

	const [showMotorcyleModal, dismissMotorcyleModal] = useIonModal(MotorcyleModal, {
		images,
		state,
		onSave: (images) => {
			dismissMotorcyleModal();
			setImages(images);
			// console.log("Received these images: ", images);
			setTimeout(() => {
				contentRef?.current?.scrollToBottom();
			}, 100);
		},

		onDismiss: () => {
			dismissMotorcyleModal();
		}
	});

	useEffect(() => {
		if (model) {
			// console.log('mo0oooooooooodel', model);

			fetch(`${API_HOME}/car/versions/${model}`, {
				headers: {
					authorization: `Bearer ${state.user.token}`,
				},
			})
				.then((resp) => resp.json())
				.then((data) => {
					data.push({ id: 999999, name: "Outra", versiontypes: "Outra" });
					// console.log(data)
					setVersionList(data);
					// console.log(
					// 	"For the model",
					// 	model,
					// 	"We loaded this list of versions",
					// 	data
					// );
				})
				.catch((err) => {
					console.error("Error fetching car models", err);
					Sentry.captureException(err);
				});
		}
	}, [model]);

	const filteredGeoCities = sortedMunicipios.filter((municipio, i) => {
		return municipio.codigo_uf === geoState;
	});

	const formatCurrency = (currency) => {
		return currency.toLocaleString("pt-br", {
			style: "currency",
			currency: "BRL",
		});
	};

	const formatMileage = (mileage) => {
		return mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/tabs/home" text="Voltar" />
					</IonButtons>
					<IonTitle>Adicionar Carro</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen ref={contentRef} scrollEvents={true}>
				<PageUI>
					<PageBackground style={{ paddingTop: "5%" }}>
						<Panel>
							<form autoComplete="off">
								<PageSubTitle>Preencha os campos abaixo: </PageSubTitle>
								<Row>
									<Col>
										<SearchableSelect
											placeholder="Estado"
											values={estados}
											value={geoState}
											labelKey="uf"
											onInvalidMessage="Você precisa selecionar um estado"
											onSelect={(value) => {
												// console.log("onSelect for geoState received", value);
												setGeoState(value?.codigo_uf);
											}}
										/>
									</Col>

									<Col>
										{geoState > 0 && (
											<SearchableSelect
												placeholder="Cidade"
												value={geoCity}
												values={filteredGeoCities}
												labelKey="nome"
												onInvalidMessage="Você precisa selecionar uma cidade"
												onSelect={(value) => {
													// console.log("onSelect for make received", value);
													setGeoCity(value?.codigo_ibge);
												}}
											/>
										)}
									</Col>
								</Row>

								{geoCity > 0 && (
									<SearchableSelect
										placeholder="Tipo de veículo"
										values={vehicleTypeList}
										value={vehicleType}
										onInvalidMessage="Você precisa selecionar o tipo de veículo"
										onSelect={(value) => {
											// console.log("onSelect for make received", value);
											setVehicleType(value?.id);
										}}
									/>
								)}


								{vehicleType == 1 && (
									<SearchableSelect
										placeholder="Marca"
										values={makesList}
										value={make}
										onInvalidMessage="Você precisa selecionar a marca do carro"
										onSelect={(value) => {
											// debugger;
											// console.log("onSelect for make received", value);
											setMake(value?.id);
											// console.log('vehicleTypeeeeeeeeeee', vehicleType);

											if (vehicleType === 1) {
												loadModels(value?.id);
											} else {
												loadModelsMoto(value?.id);
											}
										}}
									/>
								)}
								{make == 999999 && vehicleType == 1 && (
									<Input
										name="make"
										placeholder="Marca"
										value={makeName}
										onChange={(e) => { setMakeName(e.target.value) }}
									/>
								)}
								{make == 999999 && vehicleType == 1 && (
									<Input
										name="modelo"
										placeholder="Modelo"
										value={modelName}
										onChange={(e) => { setModelName(e.target.value) }}
									/>
								)}
								{make == 999999 && vehicleType == 1 && (
									<Input
										placeholder="Versão"
										value={versionName}
										onChange={(event) => {
											setVersionName(event.target.value);
										}}

									/>
								)}
								{vehicleType == 1 && make && make !== 999999 && (
									// <SearchableSelect
									// 	placeholder="Modelo"
									// 	values={modelsList}
									// 	value={model}
									// 	onInvalidMessage="Você precisa selecionar o modelo do carro"
									// 	onSelect={(value) => {
									// 		console.log("onSelect for model received", value);
									// 		setModel(value?.id);
									// 		loadVersion(value?.id);
									// 	}}
									// />
									<SearchableSelect
										placeholder="Modelo"
										values={modelsList}
										value={model}
										onInvalidMessage="Você precisa selecionar o modelo do carro"
										onSelect={(value) => {
											// console.log("onSelect for model received", value);
											setModel(value?.id);
											//loadVersion(value?.id);
										}}
									/>
									// <Select placeholder="Modelo"
									// 	onChange={(event) => {
									// 		console.log('eveeeeeeeeeeeeeent', event.target.value);
									// 		debugger;
									// 		setModel(event.target.value);
									// 		if (event.target.value !== '999999') {
									// 			loadVersion(event.target.value);
									// 		} else {
									// 			setVersionList([]);
									// 		}
									// 	}}
									// >
									// 	<option>Modelo</option>
									// 	{modelsList.map((model: any, index) => {
									// 		return <option key={index} value={model.id}>{model.name}</option>;
									// 	})}
									// </Select>

								)}

								{model == 999999 && vehicleType == 1 && (
									<Input
										name="modelo"
										placeholder="Modelo"
										value={modelName}
										onChange={(e) => { setModelName(e.target.value) }}

									/>

								)}

								{model == 999999 && vehicleType == 1 && (
									<Input
										placeholder="Versão"
										value={versionName}
										onChange={(event) => {
											setVersionName(event.target.value);
										}}

									/>

								)}
								{vehicleType == 2 && (
									// <SearchableSelect
									// 	placeholder="Marca"
									// 	values={motorcycleMakesList}
									// 	value={motorcycleMake}
									// 	onInvalidMessage="Você precisa selecionar a marca da moto"
									// 	onSelect={(value) => {
									// 		console.log("onSelect for make received", value);
									// 		setMake(value?.id);
									// 		setMotorcycleMake(value?.id);
									// 		loadModelsMoto(value?.id);
									// 	}}
									// />
									<Select placeholder="Marca"
										onChange={(event) => {
											// console.log('eveeeeeeeeeeeeeent', event.target.value);
											setMake(event.target.value);
											setMotorcycleMake(event.target.value);
											loadModelsMoto(event.target.value);
										}}
									>
										<option>Marca</option>
										{motorcycleMakesList.map((make: any, index) => {
											return <option key={index} value={make.id}>{make.name}</option>;
										})}
									</Select>
								)}
								{vehicleType == 2 && motorcycleMake && motorcycleMake != 999999 && (
									// <SearchableSelect
									// 	placeholder="Modelo"
									// 	values={motorcycleModelsList}
									// 	value={motorcycleModel}
									// 	onInvalidMessage="Você precisa selecionar o modelo da moto"
									// 	onSelect={(value) => {
									// 		console.log("onSelect for model received", value);
									// 		setModel(value?.id);
									// 		setMotorcycleModel(value?.id);
									// 	}}
									// />
									<Select placeholder="Modelo"
										onChange={(event) => {
											//console.log('eveeeeeeeeeeeeeent', event.target.value);
											setModel(parseInt(event.target.value));
											if (event.target.value !== '999999') {
												// console.log(event.target.value)
												//loadVersion(event.target.value);
												setModel(event.target.value);
											} else {
												setVersionList([]);
											}
										}}
									>
										<option>Modelo</option>
										{motorcycleModelsList.map((model: any, index) => {
											return <option key={index} value={model.id}>{model.name}</option>;
										})}
									</Select>
								)}
								{model == 999999 && vehicleType == 2 && (
									<Input
										name="modelo"
										placeholder="Modelo"
										value={modelName}
										onChange={(e) => { setModelName(e.target.value) }}

									/>

								)}

								{model == 999999 && vehicleType == 2 && (
									<Input
										placeholder="Versão"
										value={versionName}
										onChange={(event) => {
											setVersionName(event.target.value);
										}}

									/>

								)}

								{model && model != 999999 && (
									// <VersionSelect
									// 	placeholder="Versão"
									// 	values={versionList}
									// 	value={versionType}
									// 	onInvalidMessage="Você precisa selecionar o modelo do carro"
									// 	onSelect={(value) => {
									// 		console.log("onSelect for model received", value);
									// 		setVersionType(value?.id);
									// 		loadVersion(value?.id);

									// 	}}
									// />
									<Select placeholder="Versão"
										onChange={(event) => {
											//console.log('eveeeeeeeeeeeeeent', event.target.value);
											setVersionType(event.target.value);
											// loadVersion(event.target.value);
										}}
									>
										<option>Versão</option>
										{versionList.map((version: any, index) => {
											return <option key={index} value={version.id}>{version.versiontypes}</option>;
										})}
									</Select>
								)}
								{make == 999999 && vehicleType == 2 && (
									<Input
										name="make"
										placeholder="Marca"
										value={makeName}
										onChange={(e) => { setMakeName(e.target.value) }}
									/>
								)}
								{make == 999999 && vehicleType == 2 && (
									<Input
										name="modelo"
										placeholder="Modelo"
										value={modelName}
										onChange={(e) => {
											setModel(999999);
											setModelName(e.target.value)
										}}
									/>
								)}

								{make == 999999 && vehicleType == 2 && (
									<Input
										placeholder="Versão"
										value={versionName}
										onChange={(event) => {
											setVersionName(event.target.value);
										}}

									/>
								)}
								{/* {versionType == 999999 && (
									<Input
										placeholder="Versão"
										value={versionName}
										onChange={(event) => {
											setVersionName(event.target.value);
										}}

									/>
								)} */}
								{(model || make === 999999 || motorcycleModel || modelName) && (
									<Select
										onChange={(event) => {
											setYear(parseInt(event.target.value));
										}}
									>
										<option>Ano</option>
										{[...new Array(100)].map((a, b) => {
											const currentYear = new Date().getFullYear();
											return <option key={b}>{currentYear - b}</option>;
										})}
									</Select>
								)}

								{year && (
									<InputBox>
										<div>Quilometragem</div>
										<div style={{ margin: "1rem 0", textAlign: "left" }}>

											{`${formatMileage(
												mileage <= 1 ? 0 : (mileage - 1) * mileageFactor
											)} ${mileage < 21 ? `a ${mileage === 21 ? '+' : ''}${formatMileage(
												mileage <= 1
													? mileageFactor
													: mileage * mileageFactor
											)} Km` : `Km ou mais`}`}
										</div>
										<Input
											type="range"
											name="oi"
											min="1"
											max="21"
											step="1"
											value={mileage}
											onChange={(event) => {
												setMileage(parseInt(event.target.value));
											}}
											onMouseUp={() => {
												// setMileageEntered(true);
												contentRef?.current?.scrollToBottom();
											}}
											onTouchEnd={() => {
												// setMileageEntered(true);
												contentRef?.current?.scrollToBottom();
											}}
										/>
									</InputBox>
								)}

								{year && (
									<InputBox>
										<div>Preço</div>
										<div style={{ margin: "1rem 0", textAlign: "left", fontSize: "0.9rem" }}>
											{`${formatCurrency(
												priceRange === 1
													? priceRange
													: (priceRange - 1) * priceFactor
											)} ${priceRange < 41 ? `a ${formatCurrency(
												priceRange === 1
													? priceFactor
													: priceRange * priceFactor
											)}` : `ou mais`}`}
										</div>
										<Input
											type="range"
											name="oi"
											min="1"
											max="41"
											step="1"
											value={priceRange}
											onChange={(event) => {
												setPriceRange(parseInt(event.target.value));
											}}
											onMouseUp={() => {
												// setPriceEntered(true);
												contentRef?.current?.scrollToBottom();
											}}
											onTouchEnd={() => {
												// setPriceEntered(true);
												contentRef?.current?.scrollToBottom();
											}}
										/>
									</InputBox>
								)}

								{year && (
									<Select
										value={shift}
										onChange={(event) => {
											setShift(parseInt(event.target.value));
											contentRef?.current?.scrollToBottom();
										}}
									>
										<option>Câmbio</option>
										{["Manual", "Automático", "Automatizado"].map((type, i) => (
											<option key={i} value={i + 1}>
												Câmbio: {type}
											</option>
										))}
									</Select>
								)}
								{shift > 0 && (
									<Select
										value={fuel}
										onChange={(event) => {
											setFuel(parseInt(event.target.value));
											contentRef?.current?.scrollToBottom();
										}}
									>
										<option>Combustível</option>
										{[
											"Gasolina",
											"Álcool",
											"Flex",
											"Diesel",
											"Híbrido",
											"Elétrico",
										].map((type, i) => (
											<option key={i} value={i + 1}>
												Combustível: {type}
											</option>
										))}
									</Select>
								)}
								{vehicleType == 1 && fuel > 0 && (
									<Select
										value={body}
										onChange={(event) => {
											setBody(parseInt(event.target.value));
											contentRef?.current?.scrollToBottom();
										}}
									>
										<option>Carroceria</option>
										{[
											"Hatch",
											"Sedan",
											"SUV",
											"Perua",
											"Pick-up",
											"Utilitário",
											"Coupê",
											"Conversível",
											"Minivan",
										].map((type, i) => (
											<option key={i} value={i + 1}>
												Carroceria: {type}
											</option>
										))}
									</Select>
								)}
								{vehicleType == 2 && fuel > 0 && (
									<Select
										value={category}
										onChange={(event) => {
											setCategory(parseInt(event.target.value));
											contentRef?.current?.scrollToBottom();
										}}
									>
										<option> ESTILO </option>
										{[
											"CUSTOM",
											"ELÉTRICA",
											"ESPORTIVA",
											"MINO CROSS",
											"NAKED",
											"OFFROAD",
											"QUADRICICLO",
											"SCOOTER ",
											"STREET ",
											"SUPERMOTARD",
											"TOURING ",
											"TRAIL",
											"TRIAL",
											"UTILITÁRIA",
											" TRICICLO ",
										].map((type, i) => (
											<option key={i} value={i + 1}>
												{type}
											</option>
										))}
									</Select>
								)}
								{vehicleType == 1 && body > 0 && (
									<Select
										value={category}
										onChange={(event) => {
											setCategory(parseInt(event.target.value));
											contentRef?.current?.scrollToBottom();
										}}
									>
										<option>Categoria</option>
										{[
											"Urbano",
											"4x4",
											"Antigo",
											"Família",
											"Esportivo",
											"Luxo",
										].map((type, i) => (
											<option key={i} value={i + 1}>
												Categoria: {type}
											</option>
										))}
									</Select>
								)}

								{vehicleType == 1 && category > 0 && (
									<InputBox>
										<div>Opcionais</div>
										{/*(Ar Condicionado; Direção Assistida; AirBag; ABS*/}
										<Checkbox
											name="airconditioning"
											checked={airConditioning}
											label="Ar condicionado"
											onChange={() => {
												setAirConditioning(!airConditioning);
											}}
										/>
										<Checkbox
											name="assisteddriving"
											checked={assistedDriving}
											label="Direção assistida"
											onChange={() => {
												setAssistedDriving(!assistedDriving);
											}}
										/>
										<Checkbox
											name="airbag"
											checked={airbag}
											label="AirBag"
											onChange={() => {
												setAirbag(!airbag);
											}}
										/>
										<Checkbox
											name="abs"
											checked={abs}
											label="ABS"
											onChange={() => {
												setAbs(!abs);
											}}
										/>
									</InputBox>
								)}

								{(category > 0 || vehicleType == 2) && (
									<div>
										<InputBox>
											Comentários{" "}
											<CommentField
												value={comment}
												onIonChange={(e) => changeComment(e.detail.value!)}
												autoGrow={true}
												placeholder="Ex: recém pintado"
											/>
											{!isCommentValid && (
												<span className="font-red">Não insira informações de contato!</span>
											)}
										</InputBox>
									</div>

								)}

								{/* <BackButton routerLink="/tabs/profile" className="backButton">
									<img src="assets/backArrow.svg" />
								</BackButton>*/}
								{(category > 0 || vehicleType == 2) && (
									<InputBox>
										<ActionButton
											onClick={() => {
												vehicleType == 1 ? showPicturesModal() : showMotorcyleModal();
											}}
										>
											Selecionar fotos
										</ActionButton>
										{Object.keys(images).length === 6 && (
											<UploadedThumbnails>
												{[
													"front",
													"back",
													"right",
													"left",
													"interior",
													"engine",
												].map((type, i) => (
													<img key={i} src={images[type].imageDataUrl} />
												))}
											</UploadedThumbnails>
										)}
									</InputBox>
								)}

								{Object.keys(images).length === 6 && (
									<ActionButton style={!isCommentValid ? { background: "grey" } : { marginTop: "2rem" }} onClick={submitForm}>
										Concluir
									</ActionButton>
								)}
							</form>
						</Panel>
					</PageBackground>
				</PageUI>
				<IonLoading
					isOpen={showLoading}
					onDidDismiss={() => setShowLoading(false)}
					message={"Cadastrando seu carro..."}
				/>
			</IonContent>
		</IonPage>
	);
};

export default AddCar;
