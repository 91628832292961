import { getCarsService } from "../../sevices/GetCarsService";
import { getChats } from "../../sevices/GetChatsService";
import { getMatches } from "../../sevices/GetMatchesService";

export default class HomeController {
  state: any;
  user: any;
  dispatch: any;

  constructor(state: any, user: any, dispatch: any) {
    this.state = state;
    this.user = user;
    this.dispatch = dispatch;
  }

  async loadCars(handleCarChange) {
    if (this.state.cars) return;
    let cars = await getCarsService(this.user);

    this.dispatch({ type: "loadCars", cars });
    
    if (cars && cars.length > 0) {
      handleCarChange(cars[0].id);
    }
  }

  async loadChats(setLoaded) {
    if (this.state.chat) return;
    let chats = await getChats(this.user);
    setLoaded(true);
    this.dispatch({ type: "loadChats", chats });
  }

  async loadMatchs(){
    if (this.state.matches) return;
    let matches = await getMatches(this.user);
    this.dispatch({ type: "loadMatches", matches });
  }
}
