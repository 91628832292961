import { API_HOME } from "../config";

export const getLikesReceived = async (state, cars, setLikes) => {
  let ret = await fetch(`${API_HOME}/match/wholikesmycar`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      authorization: `Bearer ${state.user.token}`,
    },
    body: JSON.stringify({ cars: cars }),
  })

  let data = await ret.json();
  if(setLikes) setLikes(data.likes);

  // console.log("Likes===>", data.likes);
  
   return data.likes;
};
