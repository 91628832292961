import React, { useRef, useState, useEffect, useContext } from "react";

import { useIonAlert, IonIcon } from "@ionic/react";

import styled, { css } from "styled-components";

import { readFile } from "../../common/util";

import { close } from "ionicons/icons";

import { ActionButton } from "../../common/input";

import { API_HOME } from "../../config";

import { IonSpinner } from "@ionic/react";

const RefPicture = styled.img`
	width: 50px;
	height: 50px;
	border-radius: 20%;
	margin-right: 1rem;
	object-fit: contain;
`;

const PictureSlot = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 1rem 0;
`;

const FileInput = styled.input<any>`
	position: absolute;
	width: 0px;
	height: 0px;
	overflow: hidden;
`;

const ModalWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

const CloseButton = styled.div`
	position: absolute;
	top: -6px;
	cursor: pointer;
	right: -24px;
`;

const CustomSpinner = styled(IonSpinner)`
	position: absolute;
	left: 45px;
	color: #fff;
`;

const PicturesModal: React.FC<any> = ({
	images,
	state,
	dismiss,
	onSave,
	onDismiss,
}) => {
	// console.log("PicturesModal sees this state", state);

	const inputRef: any = useRef();

	const [imageSlot, setImageSlot] = useState("");

	const [uploadedImages, setUploadedImages] = useState<any>(images);

	const [presentAlert] = useIonAlert();
	const [uploading, setUploading] = useState(false);

	useEffect(() => {
		// console.log("uploaded images is now", uploadedImages);
	}, [uploadedImages]);

	const handleFileSelect = async (event) => {
		if (!event.target.files || event.target.files.length <= 0) return;

		// console.log("input change event fired for handlefileselect");
		const file = event.target.files[0];
		// console.log("Got this file", file);
		let imageDataUrl: any = await readFile(file);
		setUploading(true);

		var formData = new FormData();
		formData.append("image", file);

		// console.log("Using this formData", formData);

		fetch(`${API_HOME}/car/picture`, {
			method: "POST",
			headers: {
				authorization: `Bearer ${state.user.token}`,
			},
			body: formData,
		})
			.then((resp) => resp.json())
			.then((uploadedImage) => {
				setUploading(false);
				if (uploadedImage.success) {
					setUploadedImages({
						...uploadedImages,
						[imageSlot]: {
							file,
							imageDataUrl,
							uploadedFilename: uploadedImage.file.filename,
						},
					});
				} else {
					presentAlert({
						header: "Imagem não suportada",
						message: "Selecione uma imagem diferente",
						buttons: ["Ok"],
					});
				}
			});
	};

	return (
		<>
			<ModalWrapper>
				<div
					style={{
						overflowY: "scroll",
						padding: "0 2rem",
						paddingBottom: "1rem",
					}}
				>
					<div style={{ position: "relative" }}>
						<p style={{ textAlign: "center" }}>
							Clique para selecionar as fotos do carro
						</p>
						<CloseButton
							onClick={() => {
								onSave(uploadedImages);
							}}
						>
							<IonIcon size="large" icon={close} style={{ pointerEvents: 'none' }}/>
						</CloseButton>
					</div>
					<FileInput
						type="file"
						ref={inputRef}
						multiple={false}
						name="image"
						onChange={handleFileSelect}
						accept="image/png, image/gif, image/jpeg"
					/>
					<PictureSlot
						onClick={() => {
							setImageSlot("front");
							inputRef.current.click();
						}}
					>
						<RefPicture
							src={
								uploadedImages?.front
									? uploadedImages.front.imageDataUrl
									: "assets/pictures-ref/front.jpg"
							}
						/>
						{imageSlot === "front" && uploading && <CustomSpinner />}
						Frente
					</PictureSlot>

					<PictureSlot
						onClick={() => {
							setImageSlot("back");
							inputRef.current.click();
						}}
					>
						<RefPicture
							src={
								uploadedImages?.back
									? uploadedImages.back.imageDataUrl
									: "assets/pictures-ref/back.jpg"
							}
						/>
						{imageSlot === "back" && uploading && <CustomSpinner />}
						Traseira
					</PictureSlot>

					<PictureSlot
						onClick={() => {
							setImageSlot("right");
							inputRef.current.click();
						}}
					>
						<RefPicture
							src={
								uploadedImages?.right
									? uploadedImages.right.imageDataUrl
									: "assets/pictures-ref/right.jpg"
							}
						/>
						{imageSlot === "right" && uploading && <CustomSpinner />}
						Lateral Direita
					</PictureSlot>

					<PictureSlot
						onClick={() => {
							setImageSlot("left");
							inputRef.current.click();
						}}
					>
						<RefPicture
							src={
								uploadedImages?.left
									? uploadedImages.left.imageDataUrl
									: "assets/pictures-ref/left.jpg"
							}
						/>
						{imageSlot === "left" && uploading && <CustomSpinner />}
						Lateral Esquerda
					</PictureSlot>

					<PictureSlot
						onClick={() => {
							setImageSlot("interior");
							inputRef.current.click();
						}}
					>
						<RefPicture
							src={
								uploadedImages?.interior
									? uploadedImages.interior.imageDataUrl
									: "assets/pictures-ref/interior.jpg"
							}
						/>
						{imageSlot === "interior" && uploading && <CustomSpinner />}
						Interior
					</PictureSlot>

					<PictureSlot
						onClick={() => {
							setImageSlot("engine");
							inputRef.current.click();
						}}
					>
						<RefPicture
							src={
								uploadedImages?.engine
									? uploadedImages.engine.imageDataUrl
									: "assets/pictures-ref/engine.jpg"
							}
						/>
						{imageSlot === "engine" && uploading && <CustomSpinner />}
						Motor
					</PictureSlot>
					<ActionButton
						style={{ marginTop: "2rem" }}
						onClick={() => {
							const uploadedImagesCount = Object.keys(uploadedImages).length;

							if (uploadedImagesCount !== 6) {
								presentAlert({
									header: "Aviso",
									message: "As seis images precisam ser selecionadas",
									buttons: ["Ok"],
								});
							} else {
								onSave(uploadedImages);
							}
						}}
					>
						Salvar
					</ActionButton>
				</div>
			</ModalWrapper>
		</>
	);
};

export default PicturesModal;
