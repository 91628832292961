import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../State";
import { API_HOME, MEDIA_HOME, priceFactor, mileageFactor } from "../../config";

import { getCity, getState } from "../../common/geo";
import {
	shifts_types,
	fuel_types,
	body_types,
	categories,
	optionals,
} from "../../common/data";

import {
	formatCurrency,
	formatMileage,
} from "../../common/util";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonInput,
	IonRouterLink,
	IonTextarea,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonModal,
	useIonViewDidEnter,
	useIonModal,
	useIonActionSheet,
} from "@ionic/react";

import GalleryModal from "../../common/GalleryModal";

import { menuController } from "@ionic/core";

import {
	PageUI,
	PageBackground,
	Panel,
	PageTitle,
	PageSubTitle,
} from "../../components/ui";

import styled from "styled-components";
import { RouteComponentProps } from "react-router-dom";
import { CarsList, Car } from "../../components/CarsList";

interface MatchPageProps
	extends RouteComponentProps<{
		id: string;
	}> { }

const Row = styled.div`
	display: flex;
	align-items: center;
`;

const Col = styled.div`
	&:nth-child(1) {
		width: 70%;
	}
`;

const Buttons = styled.div`
	padding: 1rem 0;
	display: flex;
`;

const BackButton = styled(IonRouterLink)`
	display: flex;
	width: 60px;
	height: 60px;
	background-color: #ededed;
	border-radius: 50%;
	align-items: center;
	justify-content: center;

	& img {
		width: 29px;
		height: 14px;
	}
`;

const ActionButton = styled(IonRouterLink)`
  font-weight: 800;
  cursor: pointer;
  font-size: 18px;
  padding: 18px 0px;
  flex: 1;
  text-align: center;
  background: #d63d2f;
  border-radius: 30px;
  color: #fff;
  display: block;
  text-transform: uppercase;

  margin: 10px 20px 10px 20px;
`;

const Avatar = styled.img`
	margin-left: 2rem;
	object-fit: cover;
	border-radius: 50%;
	width: 70px;
	height: 70px;
`;

const FieldBox = styled.div`
	background-color: #fafafa !important;
	color: #6b6b6b !important;
	border-radius: 15px !important;
	padding: 1rem !important;
	margin-bottom: 6px;
`;

const Field = styled.div`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding: 10px;
	padding-left: 1rem !important;
	margin-bottom: 6px;
`;

const Fields = styled.div`
	margin-top: 0.5rem;
`;

const StyledModal = styled(IonModal)`
	color: #f00 !important;
	& .modal-wrapper {
		background-color: transparent;
		box-shadow: none;
	}

	& ion-backdrop {
		--backdrop-opacity: 0.95;
	}
`;

const InputComment = styled(IonTextarea)`
	border: solid 1px black;
	margin-bottom: 1rem;

	& .native-textarea {
		padding: 1rem;
	}
`;

const ReportTitle = styled.h1`
	margin-top: 0;
	text-align: center;
`;

const ReportWrapper = styled.div`
	padding: 2rem;
`;

const RadioButton = styled.input`
	margin-right: 1rem;
`;

const ReportHeading = styled.p`
	font-weight: bold;
	padding-bottom: 0.5rem;
	border-bottom: solid 1px #ccc;
`;

const ReportForm = () => {
	const [reportReason, setReportReason] = useState(0);
	const [reportComment, setReportComment] = useState<string>();

	const options = [
		{
			label: "Lojista / Comerciante",
		},
		{
			label: "Informações falsas / indevidas",
		},
		{
			label: "Má fé",
		},
	];

	useEffect(() => {
		// console.log("Report reason updated to ", { reportReason, reportComment });
	}, [reportReason, reportComment]);

	return (
		<form>
			<ReportWrapper>
				<ReportTitle>Bloquear</ReportTitle>
				<ReportHeading>Motivo</ReportHeading>
				{options.map((option, i) => {
					return (
						<p key={i}>
							<RadioButton
								id={`report-opt-${i}`}
								type="radio"
								name="report_reason"
								value={i}
								onClick={() => {
									setReportReason(i);
								}}
							/>
							<label htmlFor={`report-opt-${i}`}>{option.label}</label>
						</p>
					);
				})}
				{/*<p>
				<input id="salesman" type="radio" name="report_reason" value="1"  />
				<label htmlFor="salesman">Lojista / Comerciante</label>
			</p>
			<p>
				<input id="bad_info" type="radio" name="report_reason" value="1"  />
				<label htmlFor="bad_info">Informações falsas / indevidas</label>
			</p>*/}
				<ReportHeading>Detalhes</ReportHeading>
				<InputComment
					value={reportComment}
					rows={10}
					autoGrow={true}
					onIonChange={(e) => setReportComment(e.detail.value!)}
				/>
				<ActionButton style={{ marginLeft: "unset" }}>Enviar</ActionButton>
			</ReportWrapper>
		</form>
	);
};

const Match: React.FC<MatchPageProps> = ({ match, history }) => {
	const { state, dispatch } = useContext(AppContext);

	const { user, matches } = state;

	const matchData = matches?.find((m) => m.id === parseInt(match.params.id));

	// console.log("Match data is: ", matchData);

	const [showGalleryModal, setShowGalleryModal] = useState(false);
	const [showReportModal, setShowReportModal] = useState(false);
	const [startingModalPose, setStartingModalPose] = useState(0);

	const [loadedMatches, setLoadedMatches] = useState(false);
	const [presentActionSheet, dismissActionSheet] = useIonActionSheet();

	useIonViewDidEnter(() => {
		if (!matchData) {
			if (!loadedMatches) {
				fetch(`${API_HOME}/user/${user?.id}/matches`, {
					headers: {
						authorization: `Bearer ${user.token}`,
					},
				})
					.then((resp) => resp.json())
					.then((matches) => {
						dispatch({
							type: "loadMatches",
							matches,
						});
						setLoadedMatches(true);
					});
			}
		}

		if (matchData?.read === false) {
			dispatch({
				type: "markMatchAsRead",
				id: matchData.id,
			});

			fetch(`${API_HOME}/match/read/${match.params.id}`, {
				method: "POST",
				headers: {
					authorization: `Bearer ${state.user.token}`,
				},
			});
		}
	});

	const showReportDialog = () => { };

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/tabs/match" text="Voltar" />
					</IonButtons>
					<IonTitle>Match</IonTitle>
					<IonButtons slot="end">
						{/*<IonButton
							onClick={() => {
								presentActionSheet({
									buttons: [
										{
											text: "Bloquear",
											handler: () => {
												setShowReportModal(true);
											},
										},
										{ text: "Cancelar" },
									],
									//header: "Opções do match",
								});
							}}
						>
							<img src="assets/iconOption.svg" />
						</IonButton>*/}
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				{showReportModal && (
					<IonModal
						isOpen={showReportModal}
						swipeToClose={true}
						onDidDismiss={() => setShowReportModal(false)}
					>
						<ReportForm />
					</IonModal>
				)}
				{showGalleryModal && (
					<StyledModal
						//ref={firstModalRef}
						isOpen={showGalleryModal}
						swipeToClose={true}
						//presentingElement={router || undefined}
						onDidDismiss={() => setShowGalleryModal(false)}
					>
						<GalleryModal
							car={matchData.car}
							startingPose={startingModalPose}
							onClose={() => {
								setShowGalleryModal(false);
							}}
						/>
					</StyledModal>
				)}
				<PageUI>
					<Panel style={{marginTop: 16 }}>
						{!matchData && loadedMatches && <p>Match não encontrado.</p>}
						{matchData && (
							<>
								<Row>
									<Col>
										<PageTitle>{`${matchData?.user?.first_name}`}</PageTitle>
										<PageSubTitle>
											{matchData?.car?.carModel?.name ||
												"Modelo do carro: Outro"}
										</PageSubTitle>
									</Col>
									<Col>
										{matchData?.user?.id && (
											<Avatar
												src={`${MEDIA_HOME}/user/${matchData?.user?.id}/profile.jpg`}
											/>
										)}
									</Col>
								</Row>
								<CarsList
									carId={matchData.car.id}
									onClick={(poseIndex) => {
										setShowGalleryModal(true);
										setStartingModalPose(poseIndex);
									}}
								/>
								<Fields>
									{matchData?.car?.comment && (
										<Field>{matchData.car.comment}</Field>
									)}

									<Field>
										{getCity(matchData.car.city).nome} -{" "}
										{getState(matchData.car.state).uf}
									</Field>
									<Field>{matchData.car.year}</Field>
									<Field>
										{`${formatCurrency(
											matchData.car.price_range === 1
												? matchData.car.price_range
												: (matchData.car.price_range - 1) * priceFactor
										)} ${matchData.car.price_range < 41
											? `a ${formatCurrency(
												matchData.car.price_range === 1
													? priceFactor
													: matchData.car.price_range * priceFactor
											)}`
											: `ou mais`
											}`}
									</Field>
									<Field>
										{`${formatMileage(
											matchData.car.mileage <= 1
												? 0
												: (matchData.car.mileage - 1) * mileageFactor
										)} ${matchData.car.mileage < 21
											? `a ${matchData.car.mileage === 21 ? "+" : ""
											}${formatMileage(
												matchData.car.mileage <= 1
													? mileageFactor
													: matchData.car.mileage * mileageFactor
											)} Km`
											: `Km ou mais`
											}`}
									</Field>
									<Field>
										Combustível: {fuel_types[matchData.car.fuel_type]}
									</Field>
									<Field>
										Câmbio: {shifts_types[matchData.car.shift_type]}
									</Field>
									<Field>
										Carroceria: {body_types[matchData.car.body_type]}
									</Field>
									<Field>
										Categoria: {categories[matchData.car.category]}
									</Field>
									{/*matchData.car?.carOptionals?.length > 0 && (
										<FieldBox>
											<p>Opcionais</p>
											{matchData.car?.carOptionals.map(opt => <Field>{opt.optional}</Field>)}

										</FieldBox>
									)*/}
								</Fields>

								<Buttons>
									<BackButton routerLink="/tabs/match" className="backButton">
										<img src="assets/backArrow.svg" />
									</BackButton>
									<ActionButton
										routerLink={`/tabs/chat/${matchData.id}`}
										className="signupButton"
									>
										Chat
									</ActionButton>
								</Buttons>
							</>
						)}
					</Panel>
				</PageUI>
			</IonContent>
		</IonPage>
	);
};

export default Match;
