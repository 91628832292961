import React, { useEffect, useContext } from "react";
import { AppContext } from "../../State";
import { formatDistance } from "date-fns";
import pt from "date-fns/locale/pt";

import { IonIcon } from "@ionic/react";
import { star } from "ionicons/icons";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonInput,
	IonRouterLink,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonRippleEffect,
	useIonViewDidEnter,
} from "@ionic/react";

import { API_HOME, MEDIA_HOME } from "../../config";

import { menuController } from "@ionic/core";

import {
	PageUI,
	PageBackground,
	Panel,
	PageTitle,
	PageSubTitle,
} from "../../components/ui";

import styled from "styled-components";

const StyledMatchEntry = styled.div<{ read?: Boolean }>`
	//height: 153px;
	background-color: ${(props) => (props.read ? "#fff" : "#fff7f6")};
	padding-top: 22px;
	padding-left: 30px;
	padding-bottom: 16px;
	padding-right: 16px;
	border-bottom: solid 1px #f2eae0;
`;

const Row = styled.div`
	display: flex;
`;

const Col = styled.div`
	&:nth-child(2) {
		margin-left: 1rem;
		width: calc(100% - 50px - 1rem);
	}
`;

const StyledDate = styled.div`
	color: #6b6b6b;
	font-size: 12px;
	margin-bottom: 13px;
`;

const Title = styled.div`
	color: #000;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 8px;
`;

const Action = styled.div`
	font-size: 14px;
	color: #6b6b6b;
`;

const Superlike = styled.div`
	font-size: 22px;
	color: #6b6b6b;
	float: right;
`;

const Thumbnail = styled.img`
	width: 50px;
	height: 50px;
	border-radius: 8px;
	object-fit: cover;
`;

const isDate = function (obj) {
	return Object.prototype.toString.call(obj) === "[object Date]";
};

const isValidDate = function (obj) {
	return isDate(obj) && !isNaN(obj.getTime());
};

const MatchEntry = (props: any) => {
	const { match, ownCarName, thumbnail, read } = props;

	let x = new Date(props.match?.createdAt);

	if (!isValidDate(x)) {
		x = new Date();
	}

	return (
		<StyledMatchEntry read={read}>
			<Row>
				<Col style={{ marginRight: "12px" }}>
					<Thumbnail src={thumbnail} />
				</Col>
				<Col>
					<StyledDate>
						{formatDistance(x, new Date(), {
							addSuffix: true,
							locale: pt,
						})}
					</StyledDate>
					<Title>Parabéns! Novo Match para o seu carro {ownCarName}.</Title>
					<div style={{float: 'left'}}>
						<Action>+ Ver detalhes do carro</Action>
						<Action>+ Iniciar chat</Action>
					</div>
					{
						props.match.superlike === true &&
						<Superlike>
							<IonIcon icon={star} color="secondary" className="iconNoHome" />
						</Superlike>
					}
				</Col>
			</Row>
		</StyledMatchEntry>
	);
};

const RippleParent = styled.div`
	position: relative;
	overflow: hidden;
`;

const Matches: React.FC = () => {
	const { state, dispatch } = useContext(AppContext);
	const { user, cars } = state;

	const matches = state?.matches?.sort((a, b) => 
		new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) || [];

	// console.log("Matches rendering", matches);
	useEffect(() => {
		// console.log("Matches rendering 2", matches);
	});

	useIonViewDidEnter(() => {
		if (!cars || cars.length === 0) {
			fetch(`${API_HOME}/car`, {
				headers: {
					authorization: `Bearer ${user.token}`,
				},
			})
				.then((resp) => resp.json())
				.then((cars) => {
					dispatch({
						type: "loadCars",
						cars,
					});
				});
		}

		fetch(`${API_HOME}/user/${user?.id}/matches`, {
			headers: {
				authorization: `Bearer ${user.token}`,
			},
		})
			.then((resp) => resp.json())
			.then((matches) => {
				dispatch({
					type: "loadMatches",
					matches,
				});
			})
			.catch(err => {
				console.error(err);
			});
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton onClick={() => menuController.open()}>
							<img src="assets/btnLeftMenu.svg" />
						</IonButton>
					</IonButtons>
					<IonTitle>Matchs</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				{matches?.sort((a, b) =>  (a.createdAt < b.createdAt) ? 1 : -1)
					.map((match: any, i) => {

					const ownCar = cars?.find((c) => c.id === parseInt(match.forCar));

					return ownCar ? (
						<RippleParent key={i} className="ion-activatable ripple-parent">
							<IonRouterLink routerLink={`/tabs/match/${match.id}`}>
								<MatchEntry
									ownCarName={`${ownCar?.carModel?.name || "Outro"}`}
									thumbnail={`${MEDIA_HOME}/car/${match.car.id}/front_mini.jpg`}
									read={match.read}
									match={match}
								></MatchEntry>
								<IonRippleEffect></IonRippleEffect>
							</IonRouterLink>
						</RippleParent>
					) : null;
				})}
			</IonContent>
		</IonPage>
	);
};

export default Matches;
