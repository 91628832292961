import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonBackButton,
	IonRouterLink
} from "@ionic/react";

import {
	StyledTitle,
	StyledPage,
	ComoFunciona,
	CarBackground,
	Avatar,
	InputBox,
	FilterInputBox,
	Input,
	CarCard
  } from "../home/Home.style";

import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../State";
import { PageTitle } from "../signin/Signin.style";

import styled, { css } from "styled-components";
import "./Premium.css";


export const WrapperPai = styled.div`
	height: 100%;
	width: 100%;
	display: grid;
	position: relative;
	align-items: center;
	padding: 12px 12px 8px;
	justify-content: center;
	grid-template-columns: 1fr;
	grid-template-rows: 85% 15%;
`;


const ActionButton = styled(IonRouterLink)`
	font-weight: 800;
	cursor: pointer;
	font-size: 18px;
	padding: 18px 0px;
	flex: 1;
	text-align: center;
	background: #d63d2f;
	border-radius: 30px;
	color: #fff;
	display: block;
	text-transform: uppercase;

	margin: 10px 20px 10px 20px;
`;

const FuncPremium: any = ({ tpFuncao }) => {
	const history = useHistory();
	const { state, dispatch } = useContext(AppContext);
	const { user, selectedDesiredCar, selectedCar } = state;

	const proxPagina = (obj) => {
		// console.log('pagina : ' + obj)
		history.push(obj);
		
	};

	return (
		<IonPage>
			<IonContent fullscreen class="no-scroll" scroll-y="true">
				
				<IonHeader className="height">
				<IonToolbar>
				<IonButtons slot="start">
					<IonBackButton defaultHref="/tabs/home" text={'Função Premium'} />
				</IonButtons>				
				{user?.id && (
					<IonButtons slot="end">
					<IonButton routerLink="/tabs/profile">
						<Avatar src={user.profile_picture} />
					</IonButton>
					</IonButtons>
				)}
				</IonToolbar>
			</IonHeader>
				<div id="Premium"><br></br>
				<div className="premium-buttons">
					<div >
						<div style={{ cursor: "pointer" }} onClick={() => proxPagina('payment')}>					
							<img src={'assets/premium/titulo-funcao.svg'} alt="titulo"/>
						</div>
						<img src="assets/premium/premium1.svg" alt="prem1"/>
						<img src="assets/premium/premium2.svg" alt="prem2"/>
						<img src="assets/premium/premium3.svg" alt="prem3"/>
						<img src="assets/premium/premium4.svg" alt="prem4"/>
						<img src="assets/premium/premium5.svg" alt="prem5"/>
						<div className="content">
							<div className="assinar" style={{ cursor: "pointer" }} onClick={() => proxPagina('payment')}>
								ASSINAR
							</div><br></br><br></br><br></br>
						</div>	
					</div>
				</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default FuncPremium;
