import styled from "styled-components";
import { IonButton } from "@ionic/react";

export const StyledInput = styled.input`
  background-color: #f5f5f5 !important;
  color: #6b6b6b !important;
  border-radius: 10px !important;
  padding-left: 1rem !important;
  margin-bottom: 6px;
  width: 100%;
  border: none;
  outline: none;
  padding-top: 10px;
  padding-bottom: 10px;

  &::placeholder {
    color: #b5b5b5;
  }
`;

export const StyledField = styled.div`
  background-color: #f5f5f5 !important;
  color: #6b6b6b !important;
  padding: 10px;
  border-radius: 10px !important;
  padding-left: 1rem !important;
  margin-bottom: 6px;
`;

export const InputBox = styled.div`
  background-color: #f5f5f5 !important;
  color: #6b6b6b !important;
  border-radius: 10px !important;
  padding-left: 1rem !important;
  margin-bottom: 6px;
  width: 100%;
  height: 30%;
  border: none;
  outline: none;
  padding-top: 10px;
  padding-bottom: 10px;

  &::placeholder {
    color: #b5b5b5;
  }
`;

export const Input = styled.input`
  background-color: #f5f5f5 !important;
  color: #6b6b6b !important;
  border-radius: 10px !important;
  padding-left: 1rem !important;
  margin-bottom: 6px;
  width: 100%;
  border: none;
  outline: none;
  padding-top: 10px;
  padding-bottom: 10px;

  &::placeholder {
    color: #b5b5b5;
  }
`;

export const ActionButton = styled(IonButton)`
  font-weight: 800;
  cursor: pointer;
  font-size: 18px;
  padding: 18px 0px;
  flex: 1;
  text-align: center;
  background: #d63d2f;
  border-radius: 30px;
  color: #fff;
  display: block;
  text-transform: uppercase;
  height: 50px;

  margin: 10px 20px 10px 20px;
  &:click {
    background-color: #d41d1e;
  }
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0px;

	@media (max-height: 670px) {
		margin-bottom: 0px;
	}
`;

export const Card = styled.div<{ read?: Boolean }>`
    margin: 10px;

    padding-left: 16px;

    padding-right: 16px;
    box-shadow: 0px -1px 10px 4px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
`;
export const StyledTitle = styled.p`
  text-size: 12px;
  margin: 0 auto;
`;
export const StyledText = styled.p`
  width: fit-content;
  margin: 0 auto;
  font-size: 24px;
`;
