import React, { useState, useEffect, useCallback, useRef } from "react";
import { IonMenu } from "@ionic/react";
import styled from "styled-components";
import {
	IonButton,
	IonContent,
	IonPage,
	useIonModal,
	useIonAlert,
} from "@ionic/react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";

const Wrapper = styled.div`
	padding: 1rem 0 1rem 0;
`;

const UploadButton = styled.div<any>`
	background-color: #d63d2f;
	border-radius: 50%;
	width: ${(props) => (props.height ? `${props.height}px` : "150px")};
	height: ${(props) => (props.height ? `${props.height}px` : "150px")};
	margin: 0 auto;

	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
	text-align: center;
	word-break: break-word;
`;

const Photo = styled.img`
	display: block;
	border-radius: 50%;
	width: ${(props) => (props.height ? `${props.height}px` : "150px")};
	height: ${(props) => (props.height ? `${props.height}px` : "150px")};
	margin: 0 auto;
`;

const FileInput = styled.input<{ type: string; ref: any; name: string }>`
	position: absolute;
	width: 0px;
	height: 0px;
	overflow: hidden;
`;

function readFile(file) {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
}

/*function getCroppedImg(image, crop, fileName) {
	const canvas = document.createElement("canvas");
	canvas.width = crop.width;
	canvas.height = crop.height;
	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;

	const ctx: any = canvas.getContext("2d");

	ctx.drawImage(
		image,
		crop.x * scaleX,
		crop.y * scaleY,
		crop.width * scaleX,
		crop.height * scaleY,
		0,
		0,
		crop.width,
		crop.height
	);

	// As Base64 string
	// const base64Image = canvas.toDataURL('image/jpeg');

	// As a blob
	return new Promise((resolve, reject) => {
		canvas.toBlob(
			(blob: any) => {
				if (blob) {
					blob.name = fileName;

					resolve(blob);
				} else {
					resolve(null);
				}
			},
			"image/jpeg",
			1
		);
	});
}*/

const CropModal: React.FC<{
	imageSrc: string;
	onDismiss: () => void;
	onSave: (image: any) => void;
}> = ({ imageSrc, onDismiss, onSave }) => {
	const [crop, setCrop] = useState({ x: 100, y: 100 });
	const [zoom, setZoom] = useState(1);
	const [croppedImage, setCroppedImage] = useState(null);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const rotation = 0;

	const showCroppedImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(
				imageSrc,
				croppedAreaPixels,
				rotation
			);

			onSave(croppedImage);
		} catch (e) {
			console.error(e);
		}
	}, [croppedAreaPixels, rotation]);

	const [presentAlert] = useIonAlert();

	const [cropReady, setCropReady] = useState(false);

	const [containerWidth, setContainerWidth] = useState(0);
	const [containerHeight, setContainerHeight] = useState(0);

	return (
		<div>
			<Cropper
				image={imageSrc}
				crop={crop}
				onMediaLoaded={(mediaSize) => {
					setCropReady(true);
				}}
				zoom={zoom}
				cropShape="round"
				aspect={1}
				showGrid={false}
				onCropChange={setCrop}
				onCropComplete={onCropComplete}
				onZoomChange={setZoom}
			/>
			{cropReady && (
				<div style={{
					position: "absolute",
					bottom: "1rem",
					left: "50%",
					transform: "translateX(-50%)",
					display: "flex"
				}}>
				<IonButton
					expand="block"
					onClick={() => {
						setCroppedImage(null);
						setCroppedAreaPixels(null);
						setZoom(1);
						setCrop({ x: 100, y: 100 });
						onDismiss();
					}}					
				>
					Cancelar
				</IonButton>
				<IonButton
					expand="block"
					onClick={showCroppedImage}

				>
					Salvar
				</IonButton>
				</div>
			)}
		</div>
	);
};

function blobToDataURL(blob: Blob): Promise<string> {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = (_e) => resolve(reader.result as string);
		reader.onerror = (_e) => reject(reader.error);
		reader.onabort = (_e) => reject(new Error("Read aborted"));
		reader.readAsDataURL(blob);
	});
}

const ProfilePictureUploader: any = (props) => {
	const inputRef: any = useRef();
	const [imageSrc, setImageSrc] = useState(null);

	const subirFoto = () => {
		inputRef.current.click();
	};

	const handleDismiss = () => {
		dismiss();
	};

	const [croppedImage, setCroppedImage] = useState("");

	/**
	 * First parameter is the component to show, second is the props to pass
	 */
	const [present, dismiss] = useIonModal(CropModal, {
		imageSrc,
		onSave: (blobURL) => {
			fetch(blobURL)
				.then((res) => res.blob())
				.then((croppedBlob) => {
					setCroppedImage(blobURL);
					props.onSelect(croppedBlob);
					handleDismiss();
				});
		},
		onDismiss: handleDismiss,
	});

	useEffect(() => {
		if (imageSrc) {
			// console.log("presenting with", imageSrc);
			present();
			setTimeout(() => {
				// console.log("Resized");
				window.dispatchEvent(new Event("resize"));
			}, 400);
		}
	}, [imageSrc]);

	return (
		<Wrapper>
			<FileInput
				type="file"
				ref={inputRef}
				multiple={false}
				name="image"
				onChange={async (event) => {
					if (event.target.files && event.target.files.length > 0) {
						const file = event.target.files[0];
						let imageDataUrl: any = await readFile(file);
						setImageSrc(imageDataUrl);
					}
				}}
				accept="image/png, image/gif, image/jpeg"
			/>

			{props.initialImage && croppedImage.length === 0 && (
				<Photo
					style={{objectFit: "cover"}}
					height={props?.height}
					src={props.initialImage}
					onClick={subirFoto}
				/>
			)}

			{croppedImage.length > 0 && (
				<Photo height={props?.height} src={croppedImage} onClick={subirFoto} />
			)}

			{croppedImage.length === 0 && !props.initialImage && (
				<UploadButton onClick={subirFoto} height={props?.height}>
					{props.label ? (
						props.label
					) : (
						<>
							Escolha <br />
							sua foto
						</>
					)}
				</UploadButton>
			)}
		</Wrapper>
	);
};

export default ProfilePictureUploader;
