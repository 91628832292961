import styled, { css } from "styled-components";

import { API_HOME, MEDIA_HOME, mileageFactor, priceFactor } from "../config";
import { formatMileage } from "../common/util";
import {
  changeValueFuel,
  changeValueShiftType,
  changeValueBodyType,
  changeValueCategory,
} from "../pages/home/Home.Helpers";
// import { IonIcon } from "@ionic/react";
// import { list } from "ionicons/icons";
import { createGesture, Gesture, useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave, } from '@ionic/react';
import { useEffect, useRef } from "react";

export const Card = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const ImageCarResponsive = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
  border-radius: 16px;
`;

export const ImageCar = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
  border-radius: 16px;
`;

export const Description = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  display: grid;
  position: absolute;
  align-items: flex-end;

    &>div {
      display: grid;
      align-items: flex-end;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
    }
`;

export const Detail = styled.div`
  cursor: pointer;
  width: 100%;
  display: grid;
  position: absolute;
  top:40%;
  left:-5%;
  justify-content: end;
  padding: 7px 9px 7px 9px;
  font-size: 30px;
  &>div {
    background: #ffffff94;
    border-radius: 50px;
  }
`;

export const Title = styled.h5`
  margin: 0;
  color: white;
  text-shadow: 0 0 0.2em #000, 0 0 0.2em #000;

  @media (min-width: 400px) and (max-width: 420px) {
    font-size: 2.5em;
  }
`;

export const SubTitle = styled.p`
  margin: 0;
  color: #FFC836;
  text-shadow: 0 0 0.2em #FFC836, 0 0 0.2em #000;

  @media (min-width: 400px) and (max-width: 420px) {
    font-size: 2em;
  }
`;

export const Desc = styled.p`
  margin: 0;
  color: white;
  text-shadow: 0 0 0.2em #000, 0 0 0.2em #000;

  @media (min-width: 400px) and (max-width: 420px) {
    font-size: 2em;
  }
`;

export const Car: any = ({ car, isHome = true, setShowModal, getgbmove, setgbmove }) => {

  // useIonViewDidLeave(() => {
  //   console.log('ionViewDidLeave event fired');
  // });

  // useIonViewWillEnter(() => {
  //   console.log('ionViewWillEnter event fired');
  // });

  // useIonViewWillLeave(() => {
  //   console.log('ionViewWillLeave event fired');
  // });

  // useIonViewDidEnter(() => {
  //   console.log('ENTROU NO DID ENTER');
	// });

  const card = useRef(null);
  useEffect(() => {
    if (card.current)
    {
      const gesture = createGesture({
        el: card.current as Node,
        threshold: 0,
        gestureName: 'car',
        disableScroll: true,
        // onStart: (detail) => { console.log('iniciou'); },
        // onMove: (detail) => { console.log('moveu'); },
        onEnd: (detail) => { onEnd(detail); },
        // notCaptured: (detail) => { console.log('nao capturou'); },
      });

      gesture.enable();

      // let lastOnStart = 0;

      // const onStart = (detail) => {
      //   const now = Date.now();
      //   if (Math.abs(now - lastOnStart) <= DOUBLE_CLICK_THRESHOLD) {
      //     setShowModal(true);
      //     lastOnStart = 0;
      //   } else {
      //     lastOnStart = now;
      //   }
      // }

      // const onMove = (detail) => {
      //   const type = detail.type;
      //   const currentX = detail.currentX;
      //   const deltaX = detail.deltaX;
      //   const velocityX = detail.velocityX;

      //   console.log(`
      //     Type: ${type}
      //     Current X: ${currentX}
      //     Delta X: ${deltaX}
      //     Velocity X: ${velocityX}
      //   `);
      // }

      const onEnd = (detail) => {

        // console.log('TERMINOU', detail.startX, detail.currentX, detail.startY, detail.currentY);

        if (
          (detail.startX <= detail.currentX + 5 && detail.startX >= detail.currentX - 5)
          && 
          (detail.startY <= detail.currentY + 5 && detail.startY >= detail.currentY - 5)) {
          setShowModal(true);
        }
      }

    }


    return () => {
      // console.log('SAIU NO USE EFFECT');
    }
  }, []);

  return (
    <Card ref={card}>
      {isHome &&
        <ImageCarResponsive
          id="carImage"
          // onClick={() => setShowModal(true)}
          // onTouchStart={() => touchstart()}
          // onTouchMove={() => touchmove()}
          // onTouchEnd={() => touchend()}
          src={`${MEDIA_HOME}/car/${car?.id}/front_small.jpg`}
        />
      }
      {!isHome &&
        <ImageCar
          id="carImage"
          // onClick={() => setShowModal(true)}
          // onTouchStart={() => touchstart()}
          // onTouchMove={() => touchmove()}
          // onTouchEnd={() => touchend()}
          src={`${MEDIA_HOME}/car/${car?.id}/front_small.jpg`}
        />
      }
      <Description>
        <div>
          <Title>{`${car?.carModel?.carMake?.name || ""} ${car?.carModel?.name || "Outro"}`} {`${car?.year || ""} `}</Title>
          <SubTitle>{car?.mileage < 21
            ? `Até ${formatMileage(
              car?.mileage === 0
                ? mileageFactor
                : car?.mileage * mileageFactor
            )}  Km`
            : "+100.000 Km"}</SubTitle>
          <Desc>
            {Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(car?.price_range * priceFactor).split(',')[0] }
          </Desc>
        </div>
      </Description>
    </Card>
  );
};
