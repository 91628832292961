import React, { useState, useContext, useEffect } from "react";
import { API_HOME, mileageFactor, MEDIA_HOME, priceFactor } from "../../config";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonBackButton,
	IonRouterLink,
	IonTextarea,
	useIonModal,
	IonModal,
	IonSpinner,
	useIonViewWillEnter,
	IonRow,
	IonGrid,
	IonCol,
	IonMenuToggle
} from "@ionic/react";

import {
	formatCurrency,
	formatMileage,
} from "../../common/util";

import {
	StyledPage,
	ComoFunciona,
	CarBackground,
	Avatar,
	InputBox,
	FilterInputBox,
	Input,
	CarCard
  } from "../home/Home.style";


import { useHistory } from "react-router-dom";
import { AppContext } from "../../State";
import styled, { css } from "styled-components";
import "./FindCars.css";
import { getFindCars } from "../../sevices/GetPossibleMatchService";
import FindCarsFilter from "./FindCarsFilter";
import { PageTitle } from "../../components/ui";

export const WrapperPai = styled.div`
	height: 100%;
	width: 100%;
	display: grid;
	position: relative;
	align-items: center;
	padding: 12px 12px 8px;
	justify-content: center;
	grid-template-columns: 1fr;
	grid-template-rows: 85% 15%;

`;

export const Img = styled.img`	
	@media (min-height: 716px){
		width: 12.5vh !important;
		height: 8.0vh !important;
	}
`;

export const ImageCar = styled.img`
  width: 100%;
  max-height: 90px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const StyledTitle = styled(PageTitle)`
	${(props) =>
		props.color &&
		css`
			color: ${props.color}!important;
	`}
			
		width: 100%;
		padding: 8px 16px;
`;


const FindCars: React.FC = () => {
	const [showModal, setShowModal] = useState(false);
	const [showModalCar, setShowModalCar] = useState(false);
	const { state } = useContext(AppContext);
	const { user, findCarsFilter } = state;

	const history = useHistory();
	
	const [loading, setLoading] = useState(false); // temporario no false

	const [carsList, setCarsList] = useState([]);

	// const proxPagina = () => {	

	// 	let carFiltro = {make: make, model: model, priceRange: priceRange, 
	// 					 state: geoState, city: geoCity, maxYear: dtAtual, mileage: mileage, 
	// 					 fuel: fuel}		
		
	// 	setShowModal(false);
	// 	getFindCars(user, carFiltro, setCarsList);
	// };

	// useIonViewWillEnter(async () => {
	// 	setLoading(true);
				
	// 	try {     

	// 	} catch (error) {
		
	// 		console.log('ERRO', error);


	// 	} finally {
	// 		setLoading(false);
	// 	}	  

	// });

	useEffect(() => {	
		setLoading(true);

		console.warn('FILTRO CARROS' , findCarsFilter);
		const { car, body_type, carId, optionals, category, city, state,
			createdAt, fuel_type, id, inactive_reason, make, max_year, mileage, model, 
			price_range, shift_type, starting_year, vehicleType, 
			updatedAt, year, version } = findCarsFilter || {};
			
		getFindCars(user, car, body_type, 
			carId, optionals, category, city, state, 
			createdAt, fuel_type, id, inactive_reason, make, 
			max_year, mileage, model, price_range, 
			shift_type, starting_year, vehicleType, 
			updatedAt, year, version )
			.then((response) => {
				// console.log('RESPONSE', response);
				// console.log('TOTAL', response.total);
				
				setCarsList(response.data);
			})
			.catch((error) => {
				console.log('ERRO', error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [findCarsFilter]);

	const imageOnError = (e) => {
		e.currentTarget.onerror = null; 
		e.currentTarget.src = 'assets/logo_final.svg'
	};

	const  carClick = (car: any) => {
		history.push("/tabs/FindCarsDetail", { car });
	}

	const filterClick = () => {
		history.push("/tabs/FindCarsFilter");
	}

	return (
		<IonPage>
			<IonHeader className="height">
				<IonToolbar>
				<IonButtons slot="start">
					<IonBackButton defaultHref="/tabs/home" text="Veículos Disponíveis" />
				</IonButtons>
				{user?.id && (
					<IonButtons slot="end">
						<IonButton routerLink="/tabs/profile">
							<Avatar src={user.profile_picture} />
						</IonButton>
					</IonButtons>
				)}
				
				</IonToolbar>
				<IonToolbar>
					<div className="filtrar" style={{ cursor: "pointer" }} onClick={() => filterClick()}>
						FILTRAR VEÍCULOS
					</div>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen class="no-scroll" scroll-y="true">
				{(loading) && (
				<IonSpinner
					color={"primary"}
					style={{
					display: "block",
					margin: "auto",
					width: "75%",
					height: "100%",
					}}
					name="crescent"
				/>
				)}

				{(!loading && carsList.length === 0) && (
					<StyledTitle color="#d63d2f">
					<br/>

						Não foi encontrado nenhum veículo com as especificações
						selecionadas
					</StyledTitle>
				)}
				{!loading && carsList.length > 0 && (
					<IonGrid>
					{carsList?.map((item: any, i) => {
					return (
						<IonRow key={item.id} style={{ cursor: "pointer" }} onClick={() => carClick(item)} className="detailcar">
							<IonCol>
								<ImageCar
										onError={imageOnError} 
										src={`${MEDIA_HOME}/car/${item.id}/front_small.jpg`}
										/>
							</IonCol>
							<IonCol>
								<div className="detcartitulo">
									{item.carMake ? item.carMake.name : ""} {item.carModel ? item.carModel.name : "Outro"} {item.year}
								</div>
								<div>
								{item?.mileage < 21
								? `Até ${formatMileage(
								item?.mileage === 0
									? mileageFactor
									: item?.mileage * mileageFactor
								)}  Km`
								: "+100.000 Km"}
								</div>
								<div className="detcarsubtitulo">{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(item.price_range * priceFactor).split(',')[0] }</div>
								
							</IonCol>
						</IonRow>			
					)})}
					</IonGrid>
			)}
			</IonContent>
		</IonPage>
	);
};

export default FindCars;
