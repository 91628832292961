import styled, { css } from "styled-components";

import { IonRouterLink } from "@ionic/react";

export const ActionButton = styled(IonRouterLink)`
font-weight: 800;
cursor: pointer;
font-size: 18px;
padding: 18px 0px;
flex: 1;
text-align: center;
background: #d63d2f;
border-radius: 30px;
color: #fff;
display: block;
text-transform: uppercase;

margin: 10px 20px 10px 20px;
`;
