//https://css-tricks.com/finger-friendly-numerical-inputs-with-inputmode/
import React, { useState, useEffect, useContext } from "react";
import { API_HOME } from "../config";
import * as Sentry from "@sentry/react";
import { AppContext } from "../State";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonLoading,
	IonRouterLink,
	useIonAlert,
} from "@ionic/react";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

import {
	PageUI,
	PageBackground,
	Panel,
	PageTitle,
	PageSubTitle,
} from "../components/ui";

import styled from "styled-components";
import { useHistory } from "react-router";

const Buttons = styled.div`
	padding: 1rem 0;
	display: flex;
`;

const StyledInput = styled.input`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
	width: 100%;
	border: none;
	outline: none;
	padding-top: 10px;
	padding-bottom: 10px;

	&::placeholder {
		color: #b5b5b5;
	}
`;

const BackButton = styled(IonRouterLink)`
	display: flex;
	width: 60px;
	height: 60px;
	background-color: #ededed;
	border-radius: 50%;
	align-items: center;
	justify-content: center;

	& img {
		width: 29px;
		height: 14px;
	}
`;

const SignUpButton = styled(IonRouterLink)`
	font-weight: 800;
	font-size: 16px;
	padding: 16px 0px;    
        display: flex;
        align-items: center;
        justify-content: center;
	flex: 1;
	text-align: center;
	background: #d63d2f;
	border-radius: 72px;
	color: #fff;
	display: block;
	text-transform: uppercase;
	margin-left: 24px;
`;

const ForgotPassword: React.FC = () => {
	const history = useHistory();
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [image, setImage] = useState("");
	const [showLoading, setShowLoading] = useState(false);
	const [presentAlert] = useIonAlert();
	const { state, dispatch } = useContext(AppContext);
	const [email, setEmail] = useState(state.user?.email || "");

	const queryParams = new URLSearchParams(window.location.search);
	const key = queryParams.get("key");

	const error = (message) => {
		presentAlert({
			header: "Erro",
			message,
			buttons: ["Ok"],
		});
	};

	const Form = (
		<>
			<PageSubTitle>Preencha os campos abaixo: </PageSubTitle>

			<StyledInput
				autoComplete="password"
				name="password"
				type="password"
				value={password}
				onChange={(e) => {
					setPassword(e.target.value);
				}}
				placeholder="Senha"
			/>
			<StyledInput
				autoComplete="password"
				name="passwordConfirm"
				type="password"
				value={passwordConfirm}
				onChange={(e) => {
					setPasswordConfirm(e.target.value);
				}}
				placeholder="Confirmação de senha"
			/>
		</>
	);

	const Request = (
		<>
			<PageSubTitle>Seu email:</PageSubTitle>
			<StyledInput
				autoComplete="email"
				name="email"
				type="email"
				value={email}
				onChange={(e) => {
					setEmail(e.target.value);
				}}
				placeholder="Email"
			/>
		</>
	);

	const submit = () => {
		if (key) {
			if (password.length === 0 && passwordConfirm.length === 0) {
				return error("É preciso definir uma nova senha.");
			}
			if (password !== passwordConfirm) {
				return error("O campo verificação deve coincidir.");
			}

			fetch(`${API_HOME}/user/lostpassword`, {
				method: "POST",
				headers: {
					"Content-type": "application/json",
				},
				body: JSON.stringify({
					action: "redefine",
					key,
					password,
				}),
			})
				.then((resp) => resp.json())
				.then((resp) => {
					if (resp.id) {
						dispatch({
							type: "signIn",
							user: resp,
						});
						setShowLoading(false);

						presentAlert({
							header: "Sucesso",
							message: "Sua senha foi alterada. Faça login com a nova senha.",
							buttons: ["Ok"],
							onDidDismiss: async (e) => { 
								history.replace("/entrar");
							},
						});
					} else if (resp.error) {
						console.error("Server errored with", resp.error);
						error(resp.error);
						Sentry.captureException(new Error(resp.error));
					}
				})
				.catch((err) => {
					Sentry.captureException(err);
					console.error("Request errored with", err);
					error(err.error);
					setShowLoading(false);
				});
		} else {
			if (!email) {
				return error("É preciso informar o endereço de email.");
			}

			fetch(`${API_HOME}/user/lostpassword`, {
				method: "POST",
				headers: {
					"Content-type": "application/json",
				},
				body: JSON.stringify({
					action: "request",
					email,
				}),
			})
				.then((resp) => resp.json())
				.then(async (resp) => {
					if (resp.success) {
						setShowLoading(false);

						presentAlert({
							header: "Sucesso",
							message: "Enviamos no seu email um link para redefinir a senha.",
							buttons: ["Ok"],
							onDidDismiss: (e) => history.replace("/entrar"),
						});

						// desloga 
						await FirebaseAuthentication.signOut();
													
						localStorage.removeItem('w');
						localStorage.removeItem('q');

						dispatch({
							type: "signOut",
						});

					} else if (resp.error) {
						console.error("Server errored with", resp.error);
						error(resp.error);
						Sentry.captureException(new Error(resp.error));
					}
				})
				.catch((err) => {
					Sentry.captureException(err);
					console.error("Request errored with", err);
					error("Houve um erro de servidor. Tente novamente.");
					setShowLoading(false);
				});
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/" text="Voltar" />
					</IonButtons>
					<IonTitle>Redefinir senha</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<PageUI>
					<PageBackground style={{ paddingTop: "20%" }}>
						<Panel>
							{key ? Form : Request}
							<Buttons>
								<SignUpButton onClick={submit}>Continuar</SignUpButton>
							</Buttons>
						</Panel>
					</PageBackground>
				</PageUI>
				<IonLoading
					isOpen={showLoading}
					onDidDismiss={() => setShowLoading(false)}
					message={"Cadastrando..."}
				/>
			</IonContent>
		</IonPage>
	);
};

export default ForgotPassword;
