import React, { useRef, useState, useEffect, useContext } from "react";
import { AppContext } from "../../State";
import { API_HOME, mileageFactor, priceFactor } from "../../config";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
	formatCurrency,
	formatMileage
} from "../../common/util";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonRouterLink,
	useIonModal,
	useIonAlert,
	IonLoading,
	IonSelect,
	IonSelectOption,
	IonBackButton,
} from "@ionic/react";

import {
	PageUI,
	PageBackground,
	Panel,
	PageSubTitle,
} from "../../components/ui";

import { menuController } from "@ionic/core";
import { municipios as municipiosDB } from "../../common/municipios";
import styled from "styled-components";
import PicturesModal from "./PicturesModal";
import MotorcyleModal from "./MotorcycleModal";

const Buttons = styled.div`
	padding: 1rem 0;
	display: flex;

	& ion-router-link {
		margin-left: 5px;
		margin-right: 5px;
	}
`;

const BackButton = styled(IonRouterLink)`
	display: flex;
	width: 60px;
	height: 60px;
	background-color: #ededed;
	border-radius: 50%;
	align-items: center;
	justify-content: center;

	& img {
		width: 29px;
		height: 14px;
	}
`;

const ActionButton = styled(IonRouterLink)`
	font-weight: 800;
	cursor: pointer;
	font-size: 18px;
	padding: 18px 0px;
	flex: 1;
	text-align: center;
	background: #d63d2f;
	border-radius: 30px;
	color: #fff;
	display: block;
	text-transform: uppercase;

	margin: 10px 20px 10px 20px;
`;

const InputBox = styled.div`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 15px !important;
	padding: 1rem !important;
	margin-bottom: 6px;
`;

const FilterInputBox = styled(InputBox)`
	background-color: #fafafa !important;
	color: #6b6b6b !important;
	border-radius: 15px !important;
	padding: 1rem !important;
	margin-bottom: 6px;
`;

const Input = styled.input`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
	width: 100%;
	border: none;
	outline: none;
	padding-top: 10px;
	padding-bottom: 10px;

	&::placeholder {
		color: #b5b5b5;
	}
`;

const Select = styled.select`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
	width: 100%;
	border: none;
	outline: none;
	padding-top: 10px;
	padding-bottom: 10px;

	&::placeholder {
		color: #b5b5b5;
	}
`;

const UploadedThumbnails = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	& img {
		width: 50px !important;
		height: 50px;
		object-fit: cover;
		margin: 1rem;
		border-radius: 20%;
	}
`;

const Row = styled.div`
	display: flex;
`;

const Col = styled.div`
	&:first-child {
		width: 6rem;
		margin-right: 1rem;
	}

	&:last-child {
		width: calc(68%);
	}
`;

const HalfCol = styled.div`
	width: 50%;
	&:first-child {
		width: calc(50% - 1rem);
		margin-right: 1rem;
	}
`;

const EditCar: any = ({ match }: any) => {
	const { state, dispatch } = useContext(AppContext);
	const { user, cars } = state;
	const history = useHistory();

	const car = cars.find((c) => c.id === parseInt(match.params.id));

	const [vehicleType, setVehicleType] = useState(car?.vehicleType)

	// console.log("Editing this car: ", car);


	const contentRef = useRef<HTMLIonContentElement | null>(null);

	const [mileage, setMileage] = useState(car?.mileage || 1);
	const [priceRange, setPriceRange] = useState(car?.price_range || 1);
	const [images, setImages] = useState({});
	const [showLoading, setShowLoading] = useState(false);
	const [presentAlert] = useIonAlert();
	const [successAlert] = useIonAlert();
	const [count, setCount] = useState(0);

	const Body: React.FC<{
		count: number,
		onDismiss: () => void;
		onIncrement: () => void;
	}> = ({ count, onDismiss, onIncrement }) => (
		<div style={{ padding: "20px" }}>
			<p style={{ textAlign: "center", marginTop: "20px" }}>Selecione um motivo:</p>
			<IonSelect
				style={{ border: "1px solid grey", borderRadius: "7px", marginTop: "35px" }}
				value='0'
				okText="Ok"
				cancelText="Cancelar"
			>
				<IonSelectOption value="0">Selecione</IonSelectOption>
				<IonSelectOption value="1">Troquei pela AutoTroca</IonSelectOption>
				<IonSelectOption value="2">Vendi por outro meio</IonSelectOption>
				<IonSelectOption value="3">Desisti de trocá-lo</IonSelectOption>
			</IonSelect>

			<div style={{ marginTop: "35px" }}>
				<IonButton expand="block" onClick={() => onIncrement()}>
					Confirmar
				</IonButton>
				<IonButton style={{ marginTop: "10px" }} expand="block" onClick={() => onDismiss()}>
					Cancelar
				</IonButton>
			</div>
		</div>
	);

	const removeCar = () => {
		dismiss();
		presentAlert({
			header: "Excluir carro",
			message: "Tem certeza de que deseja excluir este carro? A operação não poderá ser desfeita.",
			buttons: [
				"Cancelar",
				{
					text: "Continuar e excluir",
					handler: removeCarHandler
				}
			],
		});
	}


	const handleDismissDelete = () => {
		dismiss();
	};

	const [present, dismiss] = useIonModal(Body, {
		count,
		onDismiss: handleDismissDelete,
		onIncrement: removeCar,
	});

	const handleDismiss = () => {
		dismissPicturesModal();
	};
	const [showPicturesModal, dismissPicturesModal] = useIonModal(PicturesModal, {
		images,
		state,
		onSave: (images) => {
			dismissPicturesModal();
			setImages(images);
			// console.log("Received these images: ", images);
			setTimeout(() => {
				contentRef?.current?.scrollToBottom();
			}, 100);
		},

		onDismiss: handleDismiss,
	});
	const [showMotorcyleModal, dismissMotorcyleModal] = useIonModal(MotorcyleModal, {
		images,
		state,
		onSave: (images) => {
			dismissMotorcyleModal();
			setImages(images);
			// console.log("Received these images: ", images);
			setTimeout(() => {
				contentRef?.current?.scrollToBottom();
			}, 100);
		},

		onDismiss: () => {
			dismissMotorcyleModal();
		}
	});


	if (!car) {
		return null;
	}

	const editCar = async () => {
		try {
			const payload = {
				price_range: priceRange,
				mileage: mileage,
				images: Object.entries(images).map((k: any) => {
					return { name: k[0], path: k[1].uploadedFilename };
				}),
			};

			setShowLoading(true);

			const req = await fetch(`${API_HOME}/car/${car.id}`, {

				method: "PUT",
				headers: {
					"Content-type": "application/json",
					authorization: `Bearer ${state.user.token}`,
				},
				body: JSON.stringify(payload),
			});

			const resp = await req.json();

			setShowLoading(false);

			if (!resp?.id) {
				if (resp.error) {
					let msg;

					if (resp.error.type === "desired.exists") {
						msg = "Já existem filtros cadastrados para este carro";
					} else {
						Sentry.captureException(new Error(resp.error));
						msg = resp.error;
					}
					return error(msg);
				}
			} else {
				dispatch({
					type: "updateCar",
					car: resp
				});

				presentAlert({
					header: "Sucesso",
					message: "Suas alterações foram salvas",
					buttons: [
						{
							text: "Ok",
							handler: () => {
								history.replace("/tabs/cars");
							},
						},
					],
				});
			}

			// console.log("Resp was: ", resp);
		} catch (err) {
			setShowLoading(false);
			console.error("Err was: ", err);
		}
	};

	const clearForm = () => {
		setMileage(car.mileage);
		setPriceRange(car.price_range);
		setImages({});
		setShowLoading(false);
	};



	const error = (message) => {
		presentAlert({
			header: "Erro",
			message,
			buttons: ["Ok"],
		});
	};

	const removeCarHandler = async () => {
		// console.log("remove car handler called");
		try {
			setShowLoading(true);
			const req = await fetch(`${API_HOME}/car/${car.id}`, {
				method: "DELETE",
				headers: {
					authorization: `Bearer ${state.user.token}`,
				},
			});

			const resp = await req.json();

			setShowLoading(false);

			if (!resp.success) {
				Sentry.captureException(new Error(resp.error));
				return error(resp.error);
			} else {

				// console.log("success called, placing alert");
				dispatch({
					type: "deleteCar",
					id: parseInt(match.params.id),
				});

				successAlert({
					header: "Sucesso",
					message: "Seu carro foi excluído.",
					buttons: [
						{
							text: "Ok",
							handler: () => {
								history.replace("/tabs/cars");
							},
						},
					],
				});
			}

			// console.log("Resp was: ", resp);
		} catch (err) {
			setShowLoading(false);
			console.error("Err was: ", err);
			error(err);
		}
	};

	const changeValueFuel = (number) => {
		switch (number) {
			case 1:
				return 'Gasolina'
				break;
			case 2:
				return 'Álcool'
				break;
			case 3:
				return 'Flex'
				break;
			case 4:
				return 'Diesel'
				break;
			case 5:
				return 'Híbrido'
				break;
			case 6:
				return 'Elétrico'
				break;

			default:
				break;
		}
	}

	const changeValueShiftType = (number) => {
		switch (number) {
			case 1:
				return 'Manual'
				break;
			case 2:
				return 'Automático'
				break;
			case 3:
				return 'Automatizado'
				break;
			case 1:
				return 'Manual'
				break;
			case 2:
				return 'Automático'
				break;
			case 3:
				return 'Automatizado'
				break;
			default:
				break;
		}
	}

	const changeValueBodyType = (number) => {
		switch (number) {
			case 1:
				return 'Hatch'
				break;
			case 2:
				return 'Sedan'
				break;
			case 3:
				return 'SUV'
				break;
			case 4:
				return 'Perua'
				break;
			case 5:
				return 'Pick-up'
				break;
			case 6:
				return 'Utilitário'
				break;
			case 7:
				return 'Coupê'
				break;
			case 8:
				return 'Conversível'
				break;
			case 9:
				return 'Minivan'
				break;
			default:
				break;
		}
	}

	const changeValueCategory = (number) => {
		switch (number) {
			case 1:
				return 'Urbano'
				break;
			case 2:
				return '4x4'
				break;
			case 3:
				return 'Antigo'
				break;
			case 4:
				return 'Família'
				break;
			case 5:
				return 'Esportivo'
				break;
			case 6:
				return 'Luxo'
				break;
			default:
				break;
		}
	}



	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start" onClick={() => history.goBack()}>
						<IonBackButton defaultHref="/tabs/home" text="Voltar" />
					</IonButtons>
					<IonTitle>Editar Carro</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen ref={contentRef} scrollEvents={true}>
				<PageUI>
					<PageBackground style={{ paddingTop: "5%" }}>
						<Panel>
							<form autoComplete="off">
								<>
									<PageSubTitle>
										Editando{" "}
										{`${car?.carModel?.carMake?.name || ''} ${car?.carModel?.name || "Outro"}`}
									</PageSubTitle>
									<FilterInputBox>
										<div>
											<p>Preço</p>
											<div style={{ margin: "1rem 0", textAlign: "left" }}>
												{`${formatCurrency(
													priceRange === 1
														? priceRange
														: (priceRange - 1) * priceFactor
												)} ${priceRange < 41 ? `a ${formatCurrency(
													priceRange === 1
														? priceFactor
														: priceRange * priceFactor
												)}` : `ou mais`}`}
											</div>
											<Input
												type="range"
												name="price"
												min="1"
												max="41"
												step="1"
												value={priceRange}
												onChange={(event) => {
													setPriceRange(parseInt(event.target.value));
												}}
											/>
										</div>
									</FilterInputBox>
									<FilterInputBox>
										<div>
											<p>Quilometragem</p>
											<div style={{ margin: "1rem 0", textAlign: "left" }}>
												{`${formatMileage(
													mileage <= 1 ? 0 : (mileage - 1) * mileageFactor
												)} ${mileage < 21 ? `a ${formatMileage(
													mileage <= 1 ? mileageFactor : mileage * mileageFactor
												)} Km` : `Km ou mais`}`}
											</div>
											<Input
												type="range"
												name="mileage"
												min="0"
												max="21"
												step="1"
												value={mileage}
												onChange={(event) => {
													setMileage(parseInt(event.target.value));
												}}
											/>
										</div>
									</FilterInputBox>


									<FilterInputBox>
										<div>
											Ano:
											{car?.year}

										</div>
									</FilterInputBox>
									<FilterInputBox>
										<div>
											Combustivel: {changeValueFuel(car?.fuel_type)}

										</div>
									</FilterInputBox>
									<FilterInputBox>
										<div>
											Cambio: {changeValueShiftType(car?.shift_type)}

										</div>
									</FilterInputBox>
									<FilterInputBox>
										<div>
											Carroceria:
											{changeValueBodyType(car?.body_type)}

										</div>
									</FilterInputBox>
									<FilterInputBox>
										<div>
											Categoria:
											{changeValueCategory(car?.category)}
										</div>
									</FilterInputBox>


									{car?.carOptionals && car?.carOptionals.length > 0 && (<FilterInputBox>
										<div>
											Opcionais
											{car?.carOptionals && car?.carOptionals.map(op => {
												<Input
													value={op}
												/>
											})}
										</div>
									</FilterInputBox>
									)}



									<InputBox>
										<ActionButton
											onClick={() => {
												vehicleType == 1 ? showPicturesModal() : showMotorcyleModal();
											}}
										>
											Selecionar fotos
										</ActionButton>
										{Object.keys(images).length === 6 && (
											<UploadedThumbnails>
												{[
													"front",
													"back",
													"right",
													"left",
													"interior",
													"engine",
												].map((type, i) => (
													<img key={i} src={images[type].imageDataUrl} />
												))}
											</UploadedThumbnails>
										)}
									</InputBox>

									<Buttons>
										<BackButton
											routerLink="/tabs/cars"
											className="backButton"
										>
											<img src="assets/backArrow.svg" />
										</BackButton>

										<ActionButton
											style={{
												backgroundColor: "#cf222e",
												fontSize: "1.2rem",
												padding: "0.5rem",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												width: "9rem",
											}}
											onClick={() => {
												present({
													cssClass: 'my-class',
												})
											}}
										>
											Excluir
										</ActionButton>
										<ActionButton onClick={editCar}>Salvar</ActionButton>
									</Buttons>
								</>
							</form>
						</Panel>
					</PageBackground>
				</PageUI>
				<IonLoading
					isOpen={showLoading}
					onDidDismiss={() => setShowLoading(false)}
					message={"Salvando alterações..."}
				/>
			</IonContent>
		</IonPage>
	);
};

export default EditCar;
