//https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
import React, { useEffect, createContext, useReducer } from "react";
import * as Sentry from "@sentry/react";

import { MEDIA_HOME, APP_VERSION } from "./config";

export type GlobalContent = {
	state?: any;
	dispatch?: any;
};

let AppContext = createContext<GlobalContent>({ state: null, dispatch: null });

const initialState = {
	user: null,
	selectedDesiredCar: null,
	selectedCar: null,
	cars: null,
	matches: null,
	chats: null,
	canInstall: false,
	installPrompt: null,
	surveyClicked: false,
};

const isJSON = (jsonString) => {
	if (!(jsonString && typeof jsonString === "string")) {
		return false;
	}

	try {
		JSON.parse(jsonString);
		return true;
	} catch (err) {
		Sentry.captureException(err);
		return false;
	}
};

const persistedStateString = window.localStorage["persistedState"];

const persistedState = isJSON(persistedStateString)
	? {
		...JSON.parse(persistedStateString),
		canInstall: false,
		installPrompt: null,
	}
	: {};

let reducer = (state: any, action: any) => {
	switch (action.type) {
		case "survey.clicked": {
			return {
				...state,
				surveyClicked: true,
			};
		}
		case "setAppVersion": {
			return {
				...state,
				appVersion: action.version,
			};
		}
		case "canInstall": {
			return {
				...state,
				canInstall: true,
				installPrompt: action.prompt,
			};
		}
		// case "initSocket": {
		// 	return {
		// 		...state,
		// 		socket: action.socket,
		// 	};
		// }
		case "signUpFormChanged": {
			// console.log("Updated signupform to: ", action.formData);
			return {
				...state,
				signUpForm: { ...action.formData },
			};
		}
		case "setPaymentStatus": {
			// console.log("Payment confirmed: ", action.subscription);
			const u = { ...state?.user, subscription: action.subscription };
			//console.log('uuuuuuu',u);
			
			return { ...state, user: u };
		}
		case "signIn": {
			Sentry.setUser(action.user);

			// console.log("Got the sign in action of user", action.user);

			const u = { ...action.user };
			u.profile_picture = `${MEDIA_HOME}/user/${action.user?.id
				}/profile.jpg?date=${new Date().getTime()}`;
			return { ...state, user: u };
		}
		case "signOut": {
			const loggedOutState = {
				...initialState,
			};

			window.localStorage["persistedState"] = JSON.stringify(loggedOutState);
			// console.log('exit', loggedOutState);

			return loggedOutState;
		}
		case "markMatchAsRead": {
			const matches = state.matches ? [...state.matches] : [];

			// console.log("State sees this matches: ", matches);

			const matchIndex = matches.findIndex((m) => m.id === action?.id);

			// console.log("Found this ", matchIndex, "for", action?.id);

			matches[matchIndex].read = true;

			const updatedMatches = [...matches];

			// console.log("Updated matches", matches);

			return {
				...state,
				matches: updatedMatches,
			};
		}
		case "setProfilePicture": {
			// console.log(
			// 	"Got the upload profile picture action of ",
			// 	action.profile_picture
			// );
			return {
				...state,
				user: {
					...state.user,
					profile_picture: `${action.profile_picture
						}?date=${new Date().getTime()}`,
				},
			};
		}
		case "loadCars": {
			// console.log("Loaded these cars into state: ", action.cars);
			return {
				...state,
				cars: action.cars?.length > 0 ? action.cars : [],
			};
		}
		case "loadMatches": {
			// console.log("Loaded these matches into state: ", action.matches);			
			return {
				...state,
				matches: action.matches?.length > 0 ? action.matches : [],
			};
		}
		case "loadChats": {
			// debugger;
			// console.log("Loaded these chats into state: ", action.chats);			
			return {
				...state,
				chats: action.chats?.length > 0 ? action.chats : [],
			};
		}
		case "loadNewChats": {
			// console.log("Loaded these new chats into state: ", action.newChats);
			// debugger;
			const chats = state.chats
				? [...state.chats]
				: [].concat(
					action.newChats.map((c) => {
						return { ...c };
					})
				);

			// console.log("Saving this chats array to state", chats);
			return {
				...state,
				chats,
			};
		}
		case "markChatsAsRead": {
			// console.log('marcar como lido' , action);
			// debugger;
			const chats = (state.chats ? [...state.chats] : ([] as any)).map(
				(chat) => {
					if (!chat.read && chat.channel === parseInt(action.channel)) {
						chat.read = true;
					}
					return chat;
				}
			);

			// console.log("Marked all chats as read from ", action.channel);
			return {
				...state,
				chats,
			};
		}
		case "addChat": {
			// debugger;
			const chats = state.chats ? [...state.chats] : [];
			
			// console.log("addchat running with ", action.message);

			const existingChat = chats.find((m) => m.id === action.message.id);

			return {
				...state,
				chats: !existingChat ? chats.concat(action.message) : chats,
			};
		}
		case "addMatch": {
			// console.log("addmatch running with ", action);

			const matches = state.matches ? [...state.matches] : [];

			//const existingMatch = matches.find((m) => m.id === action.match.id);
			const existingMatch = null;

			return {
				...state,
				matches: !existingMatch ? matches.concat(action.match) : matches,
			};
		}
		case "createCar": {
			// console.log("Created this car: ", action.car);
			return {
				...state,
				cars: [...(state.cars ? state.cars : []), action.car],
			};
		}
		case "updateCar": {
			// console.log("Updating this car: ", action.car);

			const carIndex = state.cars.findIndex((c) => c.id === action.car?.id);
			const cars = [...state.cars];

			cars[carIndex] = action.car;

			return {
				...state,
				cars,
			};
		}
		case "createCarFormChanged": {
			// console.log("Updated addCarForm to: ", action.formData);
			return {
				...state,
				addCarForm: { ...action.formData },
			};
		}
		case "deleteCar": {
			// console.log("Deleting this car: ", action?.id);

			const carIndex = state.cars.findIndex((c) => c.id === action?.id);
			const cars = [...state.cars];
			cars.splice(carIndex, 1);

			return {
				...state,
				cars,
			};
		}
		case "setDesiredCar": {
			const cars = state.cars ? [...state.cars] : [];
			const ownedCarIndex = cars.findIndex(
				(car) => car?.id === action.ownedCarId
			);
			const ownedCar = { ...cars[ownedCarIndex] };
			ownedCar.desiredCar = action.desiredCar;
			cars[ownedCarIndex] = ownedCar;

			return {
				...state,
				cars: [...cars],
			};
		}
		case "addDesiredCarFormChanged": {
			// console.log("Updated addDesiredCarForm for car ", action.forCar, "to: ", action.formData);

			const addDesiredCarForms = { ...state?.addDesiredCarForms || {} };

			addDesiredCarForms[action.forCar] = action.formData;


			return {
				...state,
				addDesiredCarForms: { ...addDesiredCarForms },
			};
		}
		case "userOnline": {
			const matches = state?.matches ? [...state.matches] : [];
			let i = 0;
			for (let match of matches) {
				if (match.user?.id === action?.userId) {
					matches[i].user = { ...match.user, online: true };
				}
				i++;
			}

			// console.log("after running userOnline action, matches will be", matches);

			return {
				...state,
				matches,
			};
		}
		case "userOffline": {
			const matches = state?.matches ? [...state.matches] : [];
			let i = 0;
			for (let match of matches) {
				if (match.user?.id === action?.userId) {
					matches[i].user = { ...match.user, online: false };
				}
				i++;
			}

			// console.log("after running userOffline action, matches will be", matches);

			return {
				...state,
				matches,
			};
		}
		case "setSelectedDesiredCar": {
			// console.log("Updated desiredCar to: ", action.desiredCar);
			return {
				...state,
				selectedDesiredCar: { ...action.desiredCar },
			};
		}
		case "setSelectedCar": {
			// console.log("Updated SelectedCar: ", action.selectedCar);
			return {
				...state,
				selectedCar: { ...action.selectedCar },
			};
		}
		case "findCarsFilter": {
			// console.log("Find Cars Filter: ", action.filters);
			return {
				...state,
				findCarsFilter: { ...action.filters },
			};
		}
	}
	return state;
};

const updatedState = { ...persistedState };

if (!persistedState?.appVersion) {
	if (updatedState?.matches) {
		// console.log('1');

		updatedState.matches = updatedState.matches.map(m => {
			if (m['createdAt']) {
				m['createdAt'] = m.updatedAt;
			} else {
				Object.defineProperty(m, 'createdAt', {
					value: m.updatedAt
				})
			}
			delete m.updatedAt;
			return m;
		});

		updatedState.appVersion = "0.4.9";
	}
}

// do something with current version

updatedState.version = APP_VERSION;


function AppContextProvider(props: any) {
	const fullInitialState = {
		...initialState,
		...updatedState,
	};

	let [state, dispatch] = useReducer(reducer, fullInitialState);

	useEffect(() => {
		// Persist any state we want to
		const localState = { ...state };
		delete localState.socket;
		delete localState.canInstall;
		delete localState.addCarForm;
		delete localState.addDesiredCarForms;
		delete localState.signUpForm;

		window.localStorage["persistedState"] = JSON.stringify(localState);
		Sentry.setContext("appState", state);
	}, [state]);
	let value = { state, dispatch };

	return (
		<AppContext.Provider value={value}>{props.children}</AppContext.Provider>
	);
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
