import React, { useState, useContext } from "react";
import * as Sentry from "@sentry/react";
import {
	IonContent, IonIcon, IonPage, IonRouterLink, IonSpinner, useIonAlert, 
	useIonViewDidEnter, useIonViewWillEnter
} from "@ionic/react";
import { AppContext } from "../../State";
import "./Landing.css";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { API_HOME } from "../../config";
import { useHistory } from "react-router";
import { mail } from 'ionicons/icons';

const Landing: React.FC = () => {
	const { dispatch } = useContext(AppContext);
	const [showLoading, setShowLoading] = useState(false);
	const [presentAlert] = useIonAlert();
	const history = useHistory();

	useIonViewWillEnter(async () => {
		// setShowLoading(true);

		const getUser = await FirebaseAuthentication.getCurrentUser();
		// console.warn('USER NA LANDING', getUser.user);
		if (getUser.user) {
			history.replace("/tabs/home");
		}

		// FirebaseAuthentication.addListener('authStateChange', async event => {
		// 	console.warn('AUTH EVENT NA LANDING', event);

		// 	const user = event.user;
		// 	if (user) {
		// 	  var uid = user.uid;
		// 	  console.warn('USER ATUAL LANDIND', user);
				
		// 	  history.replace("/tabs/home");
		// 	}
		// });

		// setShowLoading(false);
	});

	const alert = (title, message) => {
		presentAlert({
			header: title,
			message,
			buttons: ["Ok"],
		});
	};

	const signInWithGoogle = async () => {
		setShowLoading(true);
		
		FirebaseAuthentication.signInWithGoogle( {mode: 'popup'} )
		.then(result => {
			if(!result.user) {
				return;
			}
			
			signInFinish(result);
		})
		.catch(err => {	
			console.log('ERRO', err);
			setShowLoading(false);
			alert('Acesso inválido', 'Não foi possível realizar o login com o Google. Utilize outro tipo de login para entrar.');
		})
	}

	const signInWithFacebook = async () => {
		setShowLoading(true);
		
		FirebaseAuthentication.signInWithFacebook( {mode: 'popup'} )
		.then(result => {
			if(!result.user) {
				return;
			}

			signInFinish(result);
		})
		.catch(err => {	
			console.log('ERRO', err);
 			setShowLoading(false);
			 alert('Acesso inválido', 'Não foi possível realizar o login com o Facebook. Verifique se sua conta já foi criada com o mesmo e-mail. Utilize outro tipo de login para entrar.');
		});

	}
	
	const signInFinish = async (result: any) => {
		const uid = result.user.uid;
		const email = result.user.email || result.additionalUserInfo.profile.email;
		
		FirebaseAuthentication.getIdToken().then(token => {
			const idtoken = token.token;
			
			fetch(`${API_HOME}/auth/firebase`, {
					method: "POST",
					headers: {
						"Content-type": "application/json",
					},
					body: JSON.stringify({
						idtoken: idtoken,
						uid: uid,
						email: email
					}),
				})
				.then((resp) => resp.json())
				.then((userBackend) => {
			
					setShowLoading(false);
			
					if (userBackend.message) {
						alert('Acesso inválido', userBackend.message);
						return false;
					}

					dispatch({
						type: "signIn",
						user: userBackend,
					});
					
					localStorage.setItem('showTutorial', JSON.stringify(true));
					if (!JSON.parse(localStorage.getItem('notShowTutorialAgain') as any)) {
						localStorage.setItem('notShowTutorialAgain', JSON.stringify(false));
					}

					history.replace("/tabs/home");
				})
				.catch((err) => {
					setShowLoading(false);
					console.log('ERRO CHAMADA DO SERVIDOR', err.message);
				});
		});
	}

	return (
		<IonPage>
			<IonContent fullscreen>
				{(showLoading) && (
					<IonSpinner
						color={"primary"}
						style={{
							display: "block",
							margin: "auto",
							width: "75%",
							height: "100%",
						}}
						name="crescent"
					/>
				)}
				<div id="landing">
					<div className="background">
						<img className="logo" src="assets/Logo Black.svg" alt="AutoTroca" />
						<div className="content">
							
							<IonRouterLink onClick={signInWithGoogle}>
								<div className="socialLogin">
									<img
										style={{ cursor: "pointer" }}
										src="assets/googleIcon.png"
										alt="menu"
									/>
									Entrar com Google
								</div>
							</IonRouterLink>
							<IonRouterLink onClick={signInWithFacebook}>
								<div className="socialLogin">
									<img
										style={{ cursor: "pointer" }}
										src="assets/facebookIcon.png"
										alt="menu"
									/>
									Entrar com Facebook
								</div>
							</IonRouterLink>
							<IonRouterLink routerLink="/entrar" style={{ color: "#000" }}>
								<div className="socialLogin">
									<IonIcon icon={mail} size="large"></IonIcon>
									Entrar com e-mail
								</div>
							</IonRouterLink>
							<p className="signup">
								Ou se cadastre &nbsp;
								<IonRouterLink routerLink="/cadastrar">
									clicando aqui
								</IonRouterLink>
							</p>
						</div>
						<p className="privacy">
							Ao entrar você concorda com os nossos{" "}
							<IonRouterLink routerLink="/termos">
								Termos de Serviço
							</IonRouterLink>{" "}
							e nossa{" "}
							<IonRouterLink
								routerLink="/politica-privacidade"
								routerDirection="forward"
							>
								Política de Privacidade
							</IonRouterLink>
							.
						</p>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Landing;
