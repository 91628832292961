import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../State";
import { API_HOME, MEDIA_HOME, priceFactor, mileageFactor } from "../../config";

import { getCity, getState } from "../../common/geo";
import {
	shifts_types,
	fuel_types,
	body_types,
	categories,
	optionals,
} from "../../common/data";

import {
	formatCurrency,
	formatMileage,
} from "../../common/util";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonInput,
	IonRouterLink,
	IonTextarea,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonModal,
	useIonViewDidEnter,
	useIonModal,
	useIonActionSheet,
} from "@ionic/react";

import GalleryModal from "../../common/GalleryModal";

import { menuController } from "@ionic/core";

import {
	PageUI,
	PageBackground,
	Panel,
	PageTitle,
	PageSubTitle,
} from "../../components/ui";

import styled from "styled-components";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { CarsList, Car } from "../../components/CarsList";

// interface MatchPageProps
// 	extends RouteComponentProps<{
// 		id: string;
// 	}> { }

const Row = styled.div`
	display: flex;
	align-items: center;
`;

const Col = styled.div`
	&:nth-child(1) {
		width: 70%;
	}
`;

const Buttons = styled.div`
	padding: 1rem 0;
	display: flex;
`;

const BackButton = styled(IonRouterLink)`
	display: flex;
	width: 60px;
	height: 60px;
	background-color: #ededed;
	border-radius: 50%;
	align-items: center;
	justify-content: center;

	& img {
		width: 29px;
		height: 14px;
	}
`;

const ActionButton = styled(IonRouterLink)`
	font-weight: 800;
	font-size: 16px;
	padding: 16px 0px;    
        display: flex;
        align-items: center;
        justify-content: center;
	flex: 1;
	text-align: center;
	background: #d63d2f;
	border-radius: 72px;
	color: #fff;
	display: block;
	text-transform: uppercase;
	margin-left: 24px;
`;

const Avatar = styled.img`
	margin-left: 2rem;
	object-fit: cover;
	border-radius: 50%;
	width: 70px;
	height: 70px;
`;

const FieldBox = styled.div`
	background-color: #fafafa !important;
	color: #6b6b6b !important;
	border-radius: 15px !important;
	padding: 1rem !important;
	margin-bottom: 6px;
`;

const Field = styled.div`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding: 10px;
	padding-left: 1rem !important;
	margin-bottom: 6px;
`;

const Fields = styled.div`
	margin-top: 0.5rem;
`;

const StyledModal = styled(IonModal)`
	color: #f00 !important;
	& .modal-wrapper {
		background-color: transparent;
		box-shadow: none;
	}

	& ion-backdrop {
		--backdrop-opacity: 0.95;
	}
`;

const InputComment = styled(IonTextarea)`
	border: solid 1px black;
	margin-bottom: 1rem;

	& .native-textarea {
		padding: 1rem;
	}
`;

const ReportTitle = styled.h1`
	margin-top: 0;
	text-align: center;
`;

const ReportWrapper = styled.div`
	padding: 2rem;
`;

const RadioButton = styled.input`
	margin-right: 1rem;
`;

const ReportHeading = styled.p`
	font-weight: bold;
	padding-bottom: 0.5rem;
	border-bottom: solid 1px #ccc;
`;

const FindCarsDetail: React.FC<{}> = () => {
	const { state, dispatch } = useContext(AppContext);

	const { user, matches } = state;

	let car = {} as any;

  	const locationState = useLocation().state as any;
	if (locationState) {
		car = locationState['car'];
		// console.log("CAR NO FILTRO", car);
	}

	const [showGalleryModal, setShowGalleryModal] = useState(false);
	const [startingModalPose, setStartingModalPose] = useState(0);
	
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/tabs/findcars" text="Voltar" />
					</IonButtons>
					<IonTitle>{car.carMake ? car.carMake.name : "Outro"}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				{showGalleryModal && (
					<StyledModal
						isOpen={showGalleryModal}
						swipeToClose={true}
						onDidDismiss={() => setShowGalleryModal(false)}
					>
						<GalleryModal
							car={car}
							startingPose={startingModalPose}
							onClose={() => {
								setShowGalleryModal(false);
							}}
						/>
					</StyledModal>
				)}
				<PageUI>
					<Panel>
						{(
							<>
								<br/>
								<PageTitle>{car?.carModel?.name || "Modelo do carro: Outro"} {car?.carModel?.versiontypes}</PageTitle>
								<br/>
								<CarsList
									carId={car.id}
									onClick={(poseIndex) => {
										setShowGalleryModal(true);
										setStartingModalPose(poseIndex);
									}}
								/>
								<Fields>
									{car?.comment && (
										<Field>{car.comment}</Field>
									)}

									<Field>
										{getCity(car.city)?.nome} -{" "}
										{getState(car.state)?.uf}
									</Field>
									<Field>{car.year}</Field>
									<Field>
										{`${formatCurrency(
											car.price_range === 1
												? car.price_range
												: (car.price_range - 1) * priceFactor
										)} ${car.price_range < 41
											? `a ${formatCurrency(
												car.price_range === 1
													? priceFactor
													: car.price_range * priceFactor
											)}`
											: `ou mais`
											}`}
									</Field>
									<Field>
										{`${formatMileage(
											car.mileage <= 1
												? 0
												: (car.mileage - 1) * mileageFactor
										)} ${car.mileage < 21
											? `a ${car.mileage === 21 ? "+" : ""
											}${formatMileage(
												car.mileage <= 1
													? mileageFactor
													: car.mileage * mileageFactor
											)} Km`
											: `Km ou mais`
											}`}
									</Field>
									<Field>
										Combustível: {fuel_types[car.fuel_type]}
									</Field>
									<Field>
										Câmbio: {shifts_types[car.shift_type]}
									</Field>
									<Field>
										Carroceria: {body_types[car.body_type]}
									</Field>
									<Field>
										Categoria: {categories[car.category]}
									</Field>
								</Fields>

							</>
						)}
					</Panel>
				</PageUI>
			</IonContent>
		</IonPage>
	);
};

export default FindCarsDetail;
