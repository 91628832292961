import { API_HOME } from "../config";
import * as Sentry from "@sentry/react";

export const getMatches = async (user) => {
  try {
    // console.log("AppShell will load matches");

    const matches = await fetch(`${API_HOME}/user/${user?.id}/matches`, {
      headers: {
        authorization: `Bearer ${user.token}`,
      },
    });

    return matches.json();
  } catch (err) {
    Sentry.captureException(err);
  }
};
