import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { AppContext } from "../State";

import { APP_VERSION } from "../config";

import { menuController } from "@ionic/core";
import {
	IonMenu,
	IonContent,
	IonList,
	IonItem,
	useIonModal
} from "@ionic/react";
import styled from "styled-components";
import { Tutorial } from "./Tutorial";

import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

const Wrapper = styled.div`
	margin-left: 40px;
`;

const StyledMenu = styled(IonMenu)`
	--ion-background-color: linear-gradient(180deg, #110c0c 36.46%, #6c6c6c 100%);

	ion-content::part(background) {
		//--background: linear-gradient(180deg, #110c0c 36.46%, #6c6c6c 100%);
	}

	ion-item {
		--color: rgba(255, 255, 255, 0.6);
		--background: transparent;
		padding-left: 25px;
		cursor: pointer;
	}
	ion-item::part(native) {
		--background: transparent;
		/*padding-left: 40px;
		color: rgba(255, 255, 255, 0.6);
		cursor: pointer;
		&:hover {
			color: rgba(255, 255, 255, 1);
		}*/
	}

	ion-list {
		--ion-item-background: transparent;
	}
`;

const Decoration = styled.img`
	position: absolute;
	width: 100%;
	transform: rotate(329deg);
	top: 21rem;
	bottom: 0;
	right: -20%;
	z-index: -1;
`;

const MenuLogo = styled.img`
	margin-top: 28px;
	margin-left: 28px;
	width: 159px;
`;

const MenuTitle = styled.h1`
	margin-left: 28px;
	color: #fff;
`;

const Dot = styled.div`
	display: block;
	width: 11px;
	height: 11px;
	background-color: #d3ab7c;
	margin-left: 0.5rem;
	align-self: center;
	border-radius: 50%;
`;

const MenuItem = ({ routerLink, disabled = false, children }) => {
	const history = useHistory();

	return (
		<IonItem
			disabled={disabled}
			onClick={() => {
				history.replace(routerLink);
				menuController.close();
			}}>
			{children}
		</IonItem>
	);
};

const Menu: React.FC = () => {
	const { state, dispatch } = useContext(AppContext);
	const history = useHistory();
	
	const [present, dismiss] = useIonModal(Tutorial, { onDismiss: (notShowAgain) =>  dismiss() });

	const { cars, user } = state;
	const disableNoCar = !(cars && cars.length > 0);

	const [hrefLikes, setHrefLikes] = useState("/tabs/funcpremium");

	useEffect(() => {
		if (user?.subscription?.is_active) {
			console.log("PLANO", true);

			setHrefLikes("/tabs/likes");
		} else {
			setHrefLikes("/tabs/funcpremium");
		}		
	}, []);

	return (
		<StyledMenu side="start" menuId="first" contentId="main-router-outlet">
			<IonContent>
				<MenuLogo src="assets/Logo Black.svg" alt="AutoTroca" />
				<MenuTitle>Menu</MenuTitle>

				<IonList lines="none" mode="ios">
					<IonItem
						onClick={() => {
							history.push("/tabs/Premium");
							menuController.close();
						}} color="warning">
						Plano Premium
					</IonItem>

					{/* <IonItem onClick={() => {
							history.push("/tabs/vote");
							menuController.close();
						}} color="primary">Campanha Project Car BR</IonItem> */}

					<IonItem onClick={()=>{
	       				present({ cssClass: 'modal-tutorial' });
						menuController.close();
					}}>Como Funciona</IonItem>

					<MenuItem routerLink="/tabs/home">Home</MenuItem>
					<MenuItem routerLink="/tabs/findcars">Veículos Disponíveis</MenuItem>					
					<MenuItem routerLink={hrefLikes} disabled={disableNoCar}>Likes Recebidos</MenuItem>

					<MenuItem routerLink="/tabs/profile">Perfil</MenuItem>
					<MenuItem routerLink="/tabs/cars">Meus carros</MenuItem>
					<MenuItem routerLink="/tabs/match" disabled={disableNoCar}>Matchs</MenuItem>

					<MenuItem routerLink="/tabs/chat" disabled={disableNoCar}>
						Chat 
						{/* <Dot /> */}
					</MenuItem>
					<MenuItem routerLink="/politica-privacidade">Privacidade</MenuItem>
					<MenuItem routerLink="/termos">Termos e Condições</MenuItem>
					<MenuItem routerLink="/support">Contato</MenuItem>
					<IonItem
						onClick={async () => {
							// desloga 
							await FirebaseAuthentication.signOut();
							
							localStorage.removeItem('w');
							localStorage.removeItem('q');

							dispatch({
								type: "signOut",
							});
							
							menuController.close();
							history.replace("/");
						}}
					>
						Sair
					</IonItem>
				</IonList>
				<Wrapper style={{ marginTop: "auto" }}>
					
					<p style={{ color: "#fff", fontSize: "0.8rem" }}>
						Versão: {APP_VERSION}
					</p>
				</Wrapper>
				
				<Decoration src="assets/match-grey.svg" />
			</IonContent>
		</StyledMenu>
	);
};

export default Menu;
