import { IonFabButton, IonIcon, IonModal } from "@ionic/react";
import styled, { css } from "styled-components";
import { useHistory } from "react-router";

import { ActionButton } from "../common/input";
import { PageBackground, PageUI, Panel } from "./ui";
import { MEDIA_HOME } from "../config";
import { checkmark, starOutline } from "ionicons/icons";

const Title = styled.h1`
    text-align: center !important;
    font-size: 2em;
    padding: 17px 0em 40px 0em;
`;

const MatchImage = styled.img`
    width: 44%;
    height: 17vh;
    margin-bottom: 1em;
    object-fit: cover;
    border-radius: 50%;
`;

export const ModalRealTimeMatch: any = ({ showModal, setOpen, car }) => {
    const images: string[] = [];
    const history = useHistory();

    const close = () => {
        setOpen(false)
    }

    const sendMessage = () => {
        history.push(`/tabs/chat/${car?.matchId}`);
        setOpen(false);
    }

    return (
        <>

            <IonModal isOpen={showModal}>
                <PageUI>
                    <PageBackground style={{ paddingTop: "5%" }}>
                        <Panel>
                            <Title>
                                Deu Match!
                            </Title>
                            <div style={{ display: 'flex', marginBottom: '2em', justifyContent: 'center' }}>
                                <MatchImage
                                    src={`${MEDIA_HOME}/car/${car?.car}/front_small.jpg`}
                                />
                                {car && car.type === 'like' &&
                                    <IonIcon
                                        style={{
                                            padding: '7px 5px 9px 4px',
                                            margin: '35px 0px 0px 1px',
                                            color: '#2dd36f',
                                            width: '2em',
                                            height: '2em',
                                            pointerEvents: 'none'
                                        }}
                                        icon={checkmark}
                                    />
                                }

                                {car && car.type === 'superlike' &&
                                    <IonIcon
                                        style={{
                                            padding: '7px 5px 9px 4px',
                                            margin: '35px 0px 0px 1px',
                                            color: '#3dc2ff',
                                            width: '2em',
                                            height: '2em',
                                            pointerEvents: 'none'
                                        }}
                                        icon={starOutline}
                                    />
                                }

                                <MatchImage
                                    src={`${MEDIA_HOME}/car/${car?.desiredCar}/front_small.jpg`}
                                />
                                {/*

                                {car && car.type === 'like' &&
                                    < IonIcon
                                        style={{
                                            padding: '7px 5px 9px 4px',
                                            margin: '35px 0px 0px 1px',
                                            color: '#2dd36f',
                                            width: '2em',
                                            height: '2em'
                                        }}
                                        icon={checkmark}
                                    />
                                }

                                {car && car.type === 'superlike' &&
                                    < IonIcon
                                        style={{
                                            padding: '7px 5px 9px 4px',
                                            margin: '35px 0px 0px 1px',
                                            color: '#3dc2ff',
                                            width: '2em',
                                            height: '2em'
                                        }}
                                        icon={starOutline}
                                    />
                                }

                                */}
                            </div>

                            <div>
                                <ActionButton onClick={sendMessage}>
                                    Enviar mensagem
                                </ActionButton>
                                <br></br>
                                <ActionButton onClick={close}>
                                    Fechar
                                </ActionButton>
                            </div>
                        </Panel>
                    </PageBackground>
                </PageUI>
            </IonModal>
        </>
    );
};
