
export const cpfMask = (value) => {
	return value
		.replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
		.replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
		.replace(/(\d{3})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d{1,2})/, "$1-$2")
		.replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const birthDateMask = (value) => {
	const clean = value.replace(/\D/g, "");

	//26121990

	if (clean.length >= 5) {
		return clean.slice(0, 8).replace(/(\d{2})(\d{2})(.*)/, "$1/$2/$3");
	}

	if (clean.length === 4) {
		const [all, day, month] = clean.match(/^(\d{2})(\d{2})$/);

		if (parseInt(month) > 12) {
			return clean.replace(/(\d{2})(\d+)/, "$1/");
		}
	}

	if (clean.length >= 3) {
		return clean.replace(/(\d{2})(\d+)/, "$1/$2");
	}

	if (clean.length === 2) {
		const [day] = clean.match(/^\d{2}$/);

		if (parseInt(day) > 31) {
			return "";
		}
	}

	return clean;

	/*if (value.length === 2 || value.length === 5) {
			return `${value}/`;
		} else if (value.length === 11) {
			return value.slice(0, 10);
		} else {
			return value;
		}*/
};

export const phoneNumberMask = (value) => {
	const clean = value.replace(/\D/g, "");
	if (clean.length >= 8) {
		return clean
			.slice(0, 11)
			.replace(/(\d{2})(\d)(\d{4})(.*)/, "($1) $2 $3-$4");
	}

	if (clean.length >= 4) {
		return clean.replace(/(\d{2})(\d)(.*)/, "($1) $2 $3");
	}

	if (clean.length >= 4) {
		return clean.replace(/(\d{2})(\d)(.*)/, "($1) $2 $3");
	}

	if (clean.length >= 3) {
		return clean.replace(/(\d{2})(.*)/, "($1) $2");
	}

	return clean;
};
