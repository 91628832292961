import { estados } from "./estados";
import { municipios } from "./municipios";

export const getCity: any = (id) => {
	return municipios.find((municipio) => municipio.codigo_ibge === id);
};

export const getState: any = (id) => {
	return estados.find((estado) => estado.codigo_uf === id);
};
