'use strict'
import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonInput,
	IonIcon,
	IonSelect,
	IonSelectOption,
	useIonAlert,
	IonSpinner,
	IonBackButton
} from "@ionic/react";
import { menuController } from "@ionic/core";
import { addOutline, discOutline } from "ionicons/icons";
import { AppContext } from "../../State";
import { estados } from "../../common/estados";
import { municipios as municipiosDB } from "../../common/municipios";
import SearchableSelect from "../../common/SearchableSelect";
import { birthDateMask, cpfMask } from "../../common/masks";
import {
	PageUI,
	PageBackground,
	Panel,
	PageSubTitle,
} from "../../components/ui";
import { Col } from "../mycars/MyCarsComponents";
import { InputBox, Row, ActionButton } from "./Payment.style";
import removeAccents from "remove-accents";
import PaymentSubscription from "./Payment.subscription";
import { useHistory } from "react-router-dom";
import {
	getPlans, pay, validateCoupon, getSubscription, getSession
} from "./Payment.service";
import styled from "styled-components";

import { IMaskInput, useIMask } from 'react-imask';

export const PoweredBy = styled.div`
	display: flex;
	color: grey;
	font-size: 11px;
	align-items: center;
	justify-content: center;
	margin-top: 12px;
	img {
		width: 10em;
		margin-left: 6px;
	}
`;


export const StyledInput = styled.input`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
	width: 100%;
	border: none;
	outline: none;
	padding-top: 10px;
	padding-bottom: 10px;

	&::placeholder {
		color: #b5b5b5;
	}
`;

const Field = ({
	placeholder,
	value,
	update,
	values,
	name,
	selectedState,
	autoComplete,
	maxlength,
	type = "text",
}: any) => {
	const [focused, setFocused] = useState(true);

	let inputType = "text";

	return (
		<>
			<StyledInput
				type={inputType}
				value={value}
				name={name || undefined}
				required={true}
				autoComplete={autoComplete || undefined}
				inputMode={
						"numeric"
				}
				pattern={
						"[0-9]"
				}
				onFocus={() => {
					setFocused(true);
				}}
				onBlur={() => {

				}}
				onChange={(e) => {
					if (type === "cpf") {
						update(cpfMask(e.target.value));
					} else if (type === "birthdate") {
						update(birthDateMask(e.target.value));
					} else if (type === "credit") {
						update(e.target.value.replace(/\D/g, ""));
					} else {
						update(e.target.value);
					}
				}}
				placeholder={placeholder} maxLength={maxlength}/>

		</>
	);
};

const sortedMunicipios = municipiosDB.sort((a, b) => {
	if (a.capital === 1 && b.capital === 0) return -1;
	if (removeAccents(a.nome) < removeAccents(b.nome)) {
		return -1;
	}
	if (removeAccents(a.nome) > removeAccents(b.nome)) {
		return 1;
	}
	return 0;
});

const Payment: React.FC<any> = ({ match }) => {
	const { state, dispatch } = useContext(AppContext);
	const { user } = state;
	const planId = match?.params?.id;

	const [plans, setPlans] = useState<any>([]);
	const [currency, setCurrency] = useState("");
	const [name, setName] = useState("");
	const [document, setDocument] = useState("");
	const [email, setEmail] = useState("");
	const [birthDate, setBirthDate] = useState("");
	const [street, setStreet] = useState("");
	const [number, setNumber] = useState("");
	const [complement, setComplement] = useState("");
	const [neighborhood, setNeighborhood] = useState("");
	const [city, setCity] = useState("");
	const [cityName, setCityName] = useState("");
	const [geoState, setGeoState] = useState(0);
	const [couponValue, setCouponValue] = useState(0);
	const [stateName, setStateName] = useState("");
	const [postCode, setPostCode] = useState("");
	const [cardNumber, setCardNumber] = useState("");
	const [cardName, setCardName] = useState("");
	const [securityCode, setSecurityCode] = useState("");
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");
	const [coupon, setCoupon] = useState("");
	const [validCoupon, setValidCoupon] = useState(false);
	const [presentAlert] = useIonAlert();
	const [showLoading, setShowLoading] = useState(false);
	const [subscription, setSubscription] = useState<any>({});

	const history = useHistory();
	const [senderHash, setSenderHash] = useState(null);

	// const filteredGeoCities = sortedMunicipios.filter((municipio, i) => {
	// 	return municipio.codigo_uf === geoState;
	// });

	const validateTicket = async (validate) => {
		if (validate && !coupon) {
			setValidCoupon(false);
			return presentAlert({
				header: "Cupom inválido",
				message: "Informe o cupom.",
				buttons: ["Ok"],
			});

		}

		const request = await validateCoupon(coupon, user)

		if (request.success && request.data && request.data.valid) {
			setValidCoupon(true);
			setCouponValue(parseFloat(request.data.value))
		} else {
			setValidCoupon(false);
			presentAlert({
				header: "Cupom inválido",
				message: "O cupom informado é inválido.",
				buttons: ["Ok"],
			});
		}
	}

	const getValue = () => {
		const plan = plans.filter(e => e.id == currency)[0];
		if (!plan)
			return "";
		
		return "R$ " + plan.amount.toFixed(2).replace('.',',');
	}

	const discount = () => {
		let value = plans.filter(e => e.id == currency)[0].amount.toFixed(2);
		return "Valor com desconto: R$ " 
		+ (value - ((couponValue * value) / 100)).toFixed(2).replace('.',',');
	}
	

	useEffect(() => {
		if (plans.length > 0 && planId) setCurrency(planId);
	})

	const error = (message) => {
		presentAlert({
			header: "Erro",
			message,
			buttons: ["Ok"],
		});
	};

	const doPay = async (token) => {
		let payload = {
			payment: {
				name: cardName,
				document: document.replaceAll(".", "").replace("-", ""),
				// email,
				birthDate: birthDate,
				planId: currency,
				coupon,
				token,
				senderHash

				// dueDay: new Date().getDate(),
				// billing: {
				// 	name,
				// 	document: document.replaceAll(".", "").replace("-", ""),
				// 	email,
				// 	birthDate: birthDate,
				// 	notify: false
				// }
			},
			// address: {
			// 	street,
			// 	number,
			// 	complement,
			// 	district: neighborhood,
			// 	city: cityName,
			// 	state: stateName,
			// 	postalCode: postCodeAUX,
			// 	country: "BRA"
			// },
			// cardDetails: {
			// 	token
			// },
			// encrypted: "NAO MANDA MAIS",
		}

		try {

			if (coupon) {
				validateTicket(false);
			}

			let request = await pay(payload, user);

			if (!request.success || request.data.failed) {
				setShowLoading(false);

				let msg = '';
				for (const item in request.data.errors)
				{
					switch (item) {
						case '30400':
						case '30500':
						case '10001':
							msg += 'Cartão de crédito com dados inválidos.\n';
							break;
						case '10006':
							msg += 'CVV código de segurança do cartão inválido.';
							break;
						case '30405':
							msg += 'Data de vencimento inválida.\n';
							break;
						case '53048':
							msg += 'Data de Nascimento inválida.\n';
							break;
						case '61011':
							msg += 'CPF inválido.\n';
							break;
						case '53015':
							msg += 'Nome do titular inválido.\n';
							break;
						default:
							msg += request.data.errors[item];
							break;
					}
				}

				presentAlert({
					header: "Pagamento",
					message: msg,
					buttons: ["Ok"],
				});

			} else {
				setShowLoading(false);
				presentAlert({
					header: "Pagamento",
					message: "Pagamento realizado com sucesso!",
					buttons: ["Ok"],
				});

				dispatch({
					type: "setPaymentStatus",
					subscription: {
						is_active: true
					},
				});
				history.replace("/tabs/home");
			}
			

		} catch (err) {
			setShowLoading(false);
			presentAlert({
				header: "Pagamento",
				message: "Não foi possível realizar o pagamento, tente novamente mais tarde.",
				buttons: ["Ok"],
			});
			console.log(err);
		}
	}

	const onSubmit = async (event) => {

		if (
			!year ||
			!month ||
			!cardName ||
			!cardNumber ||
			!securityCode //||
			// !street ||
			// !number ||
			// !postCode ||
			// !stateName ||
			// !cityName ||
			// !neighborhood 
		) {
			return error("Todos os campos precisam ser preenchidos");
		}
		setShowLoading(true);
		let postCodeAUX = postCode
		let yearAUX = year;

		// validação postCode
		if (postCode.includes('-')) {
			let aux = postCode;
			postCodeAUX = aux.replace('-', '');
		}

		// validação ano cartão de crédito
		if (year.length == 2) {
			yearAUX = '20' + yearAUX;
		}

		try {

			let session = await getSession(user);
			let dp = window["PagSeguroDirectPayment"];

			dp.setSessionId(session.data);

			dp.getBrand({
				cardBin: cardNumber.substring(0, 6),
				success: function (response) {

					let cardData = {
						cardNumber: cardNumber.replaceAll(/\s/g, ''), // Número do cartão de crédito
						brand: response.brand.name, // Bandeira do cartão
						cvv: securityCode, // CVV do cartão
						expirationMonth: month, // Mês da expiração do cartão
						expirationYear: yearAUX, // Ano da expiração do cartão, é necessário os 4 dígitos.
					};

					dp.createCardToken({
						...cardData,
						success: function (response) {
							doPay(response.card.token);
						},
						error: function (response) {

							let msg = '';
							
							for (const item in response.errors)
							{
								switch (item) {
									case '30400':
									case '30500':
									case '10001':
										msg += 'Cartão de crédito com dados inválidos.\n';
										break;
									case '10006':
										msg += 'CVV código de segurança do cartão inválido.';
										break;
									case '30405':
										msg += 'Data de vencimento inválida.\n';
										break;
									case '53048':
										msg += 'Data de Nascimento inválida.\n';
										break;
									case '61011':
										msg += 'CPF inválido.\n';
										break;
									default:
										msg += response.errors[item];
										break;
								}
							}

							setShowLoading(false);
							presentAlert({
								header: "Pagamento",
								message: msg,
								buttons: ["Ok"],
							});

						}
					});

				},
				error: function (response) {				
					setShowLoading(false);
					presentAlert({
						header: "Pagamento",
						message: "O número do cartão está inválido.",
						buttons: ["Ok"],
					});
				}
			});

		} catch (e) { 
			// console.log('passando a transação');
			setShowLoading(false);
			presentAlert({
				header: "Pagamento",
				message: "Não foi possível realizar o pagamento, tente novamente mais tarde.",
				buttons: ["Ok"],
			});
			console.log(e);
		} 
	}

	useEffect(() => {

		if(senderHash)
			return;
		
		window["PagSeguroDirectPayment"].onSenderHashReady(function(response){
			if(response.status == 'error') {
				// console.log(response.message);
				return false;
			}
			setSenderHash(response.senderHash); //Hash estará disponível nesta variável.
			// console.log('senderHash', senderHash);
		});

	}, []);

	useEffect(() => {
		async function fetchPlans() {
			let response = await getPlans(user);

			if (response.success) {
				await setPlans(response.data);
				await setCurrency('r14');
			} else {
				presentAlert({
					header: "Planos",
					message: "Não foi poissível carregar os planos. Tente novamente mais tarde.",
					buttons: ["Ok"],
				});
			}
		}

		try {
			fetchPlans();
		} catch (err) {
			// console.log(err);
		}
	}, []);


	useEffect(() => {
		getSubscription(user)
			.then((sub) => {
				// console.log('sub.data', sub.data);

				setSubscription(sub.data);
			}).catch((err) => {
				console.error("Error fetching car makes", err);
			})
	}, []);
 
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/tabs/home" text="Voltar" />
					</IonButtons>
					<IonTitle>Pagamentos</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen scrollEvents={true}>
				{showLoading && (
					<IonSpinner
						color={"primary"}
						style={{
							display: "block",
							margin: "auto",
							width: "75%",
							height: "75%",
						}}
						name="crescent"
					/>
				)}
				{!showLoading && !subscription?.is_active && (
					<PageUI>
						<PageBackground style={{ paddingTop: "5%" }}>
							<Panel>
								<form autoComplete="off">
									<br />
									<Row>
										<Col size="12">
											<IonSelect
												placeholder="Selecione um plano"
												accessKey="id"
												value={currency}
												onIonChange={(event) => { 
													setCurrency(event.detail.value); 
												}}
												style={{
													backgroundColor: "#f5f5f5",
													color: "#000",
													borderRadius: "10px",
													paddingLeft: "1rem",
													marginBottom: "6px",
													width: "100%",
													border: "none",
												}}
											>
												{plans.map((p) => (
													<IonSelectOption value={p.id} key={p.id}>
														{p.name}
													</IonSelectOption>
												))}
											</IonSelect>
										</Col>
									</Row>
									<br></br>
									{currency && (
										<>
											<Row>
												<Col size="12">
													<div className="ion-text-center">
														Valor {getValue()} por mês
													</div>
												</Col>
											</Row>
											<br></br>
										</>
									)}
									<Row>
										<Col size="10">
											<InputBox>
												<IonInput
													value={coupon}
													placeholder="Código Cupom"
													onIonChange={(event) => {
														setCoupon(event.detail.value || "");
													}}
												></IonInput>

											</InputBox>
										</Col>
										<Col size="2">
											<IonButton onClick={() => validateTicket(true)}>
												<IonIcon size="large" color="red" icon={addOutline} style={{ pointerEvents: 'none' }} />
											</IonButton>
										</Col>
									</Row>
									<Row>
										{validCoupon && (
											<Col size="12">
												<p style={{ color: "green" }}>{discount()}</p>
											</Col>
										)}
								</Row>
	{/* 
								<PageSubTitle>Endereço</PageSubTitle>

								<Row>
									<Col size={12}>
										<InputBox>
											<IonInput
												value={street}
												placeholder="Endereço"
												onIonChange={(event) => {
													setStreet(event.detail.value || "");
												}}
											></IonInput>
										</InputBox>
									</Col>
								</Row>

								<Row>
									<Col size={4}>
										<InputBox>
											<IonInput
												value={number}
												placeholder="Número"
												onIonChange={(event) => {
													setNumber(event.detail.value || "");
												}}
											></IonInput>
										</InputBox>
									</Col>
									<Col size={8} style={{ marginLeft: "2px" }}>
										<InputBox>
											<IonInput
												value={postCode}
												placeholder="CEP"
												onIonChange={(event) => {
													setPostCode(event.detail.value || "");
												}}
											></IonInput>
										</InputBox>
									</Col>
								</Row>
								<Row>
									<Col size={12} style={{ marginLeft: "2px" }}>
										<InputBox>
											<IonInput
												value={complement}
												placeholder="Complemento"
												onIonChange={(event) => {
													setComplement(event.detail.value || "");
												}}
											></IonInput>
										</InputBox>
									</Col>
								</Row>
								<Row>
									<Col size={4}>
										<SearchableSelect
											placeholder="Estado"
											values={estados}
											value={""}
											labelKey="uf"
											onInvalidMessage="Você precisa selecionar um estado"
											onSelect={(value) => {
												setGeoState(value?.codigo_uf);
												setStateName(value?.uf);
												// console.log("onSelect for geoState received", value);
											}}
										/>
									</Col>
									<Col size={8} style={{ marginLeft: "2px" }}>
										{geoState > 0 && (
											<SearchableSelect
												placeholder="Cidade"
												value={city}
												values={filteredGeoCities}
												labelKey="nome"
												onInvalidMessage="Você precisa selecionar uma cidade"
												onSelect={(value) => {
													// console.log("onSelect for make received", value);
													setCity(value?.codigo_ibge);
													setCityName(value?.nome);
												}}
											/>
										)}
									</Col>
								</Row>

								<Row>
									<Col size={12}>
										<InputBox>
											<IonInput
												value={neighborhood}
												placeholder="Bairro"
												onIonChange={(event) => {
													setNeighborhood(event.detail.value || "");
												}}
											></IonInput>
										</InputBox>
									</Col>
								</Row> */}

									{/* <PageSubTitle>Dados Pessoais</PageSubTitle>
									<Row>
										<Col size="12">
											<InputBox>
												<IonInput
													value={name}
													placeholder="Nome"
													onIonChange={(event) => {
														setName(event.detail.value || "");
													}}
												></IonInput>
											</InputBox>
										</Col>
									</Row>

									<Row>
										<Col size={6}>
											<InputBox>
												<IonInput
													maxlength={14}
													value={document}
													placeholder="CPF"
													onIonInput={(event) => {
														let input = event.detail['target'] || "";
														let cpf = cpfMask(input['value'] || "");
														input['value'] = cpf;
														setDocument(cpf);
													}}
												></IonInput>
											</InputBox>
										</Col>
										<Col size={6} style={{ marginLeft: "2px" }}>
											<InputBox>
												<IonInput
													maxlength={10}
													value={birthDate}
													placeholder="Nascimento"
													onIonChange={(event) => {
														setBirthDate(birthDateMask(event.detail.value));
													}}
												/>
											</InputBox>
										</Col>
									</Row> */}

									{/* <Row>
										<Col size={12}>
											<InputBox>
												<IonInput
													value={email}
													placeholder="Email"
													onIonChange={(event) => {
														setEmail(event.detail.value || "");
													}}
												></IonInput>
											</InputBox>
										</Col>
									</Row> */}

									<PageSubTitle>Dados do Cartão</PageSubTitle>

									<Row>
										<Col size={12}>
											<InputBox>
												<IonInput
													value={cardName}
													placeholder="Nome do proprietário do Cartão"
													onIonChange={(event) => {
														setCardName(event.detail.value || "");
													}}
												></IonInput>
											</InputBox>
										</Col>
									</Row>
									<Row>
										<Col size={6}>
											<InputBox>
												<Field
													type="cpf"
													name="cpf"
													autoComplete="off"
													maxlength={14}														
													value={document}
													update={setDocument}
													placeholder="CPF"
												/>
											</InputBox>
										</Col>
										<Col size={6} style={{ marginLeft: "2px" }}>
											<InputBox>
												<Field
													maxlength={10}
													type="birthdate"
													value={birthDate}
													update={setBirthDate}
													placeholder="Data Nascimento"
												/>
											</InputBox>
										</Col>
									</Row>
									<Row>
										<Col size={8}>
											<InputBox>
												<Field
													type="credit"
													value={cardNumber}
													update={setCardNumber}
													maxlength={16}
													placeholder="Número do Cartão"
												/>
											</InputBox>
										</Col>
										<Col size={4}  style={{marginLeft: "4px"}}>
											<InputBox>
												<Field
													type="credit"
													maxlength={3}
													value={securityCode}
													update={setSecurityCode}
													placeholder="CVV"															
												/>
											</InputBox>
										</Col>
									</Row>
									<Row>
										<Col size={4}>
											<InputBox>
												<Field
													type="credit"
													maxlength={2}
													value={month}
													update={setMonth}
													placeholder="Mês (MM)"
												/>
											</InputBox>
										</Col>
													
										<Col size={8} style={{marginLeft: "4px"}}>
											<InputBox>
												<Field
													type="credit"
													maxlength={4}
													value={year}
													update={setYear}
													placeholder="Ano vencimento (AAAA)"
												/>
											</InputBox>
										</Col>
									</Row>
									<PoweredBy>
										Powered by
										<img src={'assets/pagseguro-logo.png'} />
									</PoweredBy>
									<Row>
										<Col size={12}>
											<ActionButton
												style={{ marginTop: "2rem" }}
												onClick={onSubmit}
												type="button">
												Assinar
											</ActionButton>
										</Col>
									</Row>
								</form>
							</Panel>
						</PageBackground>
					</PageUI>
				)}
				{!showLoading && subscription?.is_active && (
					<PaymentSubscription />)
				}
			</IonContent>
		</IonPage>
	);
};

export default Payment;