export function changeValueFuel(number) {
  switch (number) {
    case 1:
      return "Gasolina";
    case 2:
      return "Álcool";
    case 3:
      return "Flex";
    case 4:
      return "Diesel";
    case 5:
      return "Híbrido";
    case 6:
      return "Elétrico";
    default:
      break;
  }
}

export function changeValueShiftType(number) {
  switch (number) {
    case 1:
      return "Manual";
      break;
    case 2:
      return "Automático";
      break;
    case 3:
      return "Automatizado";
      break;
    case 1:
      return "Manual";
      break;
    case 2:
      return "Automático";
      break;
    case 3:
      return "Automatizado";
      break;
    default:
      break;
  }
}

export function changeValueBodyType(number) {
  switch (number) {
    case 1:
      return "Hatch";
      break;
    case 2:
      return "Sedan";
      break;
    case 3:
      return "SUV";
      break;
    case 4:
      return "Perua";
      break;
    case 5:
      return "Pick-up";
      break;
    case 6:
      return "Utilitário";
      break;
    case 7:
      return "Coupê";
      break;
    case 8:
      return "Conversível";
      break;
    case 9:
      return "Minivan";
      break;
    default:
      break;
  }
}

export function changeValueCategory(number) {
  switch (number) {
    case 1:
      return "Urbano";
      break;
    case 2:
      return "4x4";
      break;
    case 3:
      return "Antigo";
      break;
    case 4:
      return "Família";
      break;
    case 5:
      return "Esportivo";
      break;
    case 6:
      return "Luxo";
      break;
    default:
      break;
  }
}
