import { IonFabButton, IonIcon } from "@ionic/react";
import styled, { css } from "styled-components";

import { API_HOME, MEDIA_HOME } from "../config";
import { ButtonActions } from "../pages/home/Home.style";
import { checkmarkSharp, closeSharp, arrowUndo, star } from "ionicons/icons";

export const Actions = styled.div`
  gap: 8px;
  display: grid;
  align-items: flex-start;;
  justify-content: center;
  grid-template-rows: auto;
  grid-template-columns: repeat(4, auto);
`;

export const ButtonSm = styled.div`
    width: 3em;
    height: 3em;
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    background: white;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 0px 6px #eaeaea;

    @media (min-width: 728px){
        width: 2.5em;
        height: 2.5em;
    }

    .iconIsHome {
      font-size: 2em;

      @media (min-width: 728px){
        font-size: 1.6em;
      }
      
      pointer-events: none;
    }

    .iconNoHome {
      font-size: 2em;

      @media (min-width: 728px){
        font-size: 1.2em;
      }
  
      pointer-events: none;
    }
`;

export const ButtonLg = styled.div`
  width: 3.5em;
  display: flex;
  height: 3.5em;
  cursor: pointer;
  margin-top: 8px;
  border-radius: 50%;
  background: white;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 6px #eaeaea;

  @media (min-width: 728px){
    width: 3.25em;
    height: 3.25em;
  }

  .iconIsHome {
    font-size: 2em;

    @media (min-width: 728px){
      font-size: 1.6em;
    }
    
    pointer-events: none;
  }

  .iconNoHome {
    font-size: 2em;

    @media (min-width: 728px){
      font-size: 1.2em;
    }

    pointer-events: none;
  }
`;

export const BtnsReactions: any = ({ car, swipe, back, superlike, isHome = true, isDisabled, idLikeOrDislike }) => {
  // console.log(isDisabled)
  // console.log(idLikeOrDislike)  
  return (
    <Actions className={isDisabled ? 'opacity' : ''}>
      {isHome &&
        <>
          <ButtonSm className={idLikeOrDislike ? "btn-home-likes" : "btn-home-likes opacity"} onClick={() => isDisabled || !idLikeOrDislike ? '' : back()} color="white">
            <IonIcon icon={arrowUndo} color="warning" className="iconIsHome" />
          </ButtonSm>
          <ButtonLg className="btn-home-likes size" onClick={() => isDisabled ? '' : swipe('left')} color="white">
            <IonIcon icon={'assets/icon/icon_close.svg'} color="danger" className="iconIsHome" />
          </ButtonLg>
          <ButtonLg className="btn-home-likes size" onClick={() => isDisabled ? '' : swipe('right')} color="white">
            <IonIcon icon={'assets/icon/icon_check.svg'} color="success" className="iconIsHome" />
          </ButtonLg>
          <ButtonSm className="btn-home-likes" onClick={() => isDisabled ? '' : superlike()} color="white">
            <IonIcon icon={star} color="secondary" className="iconIsHome" />
          </ButtonSm>
        </>
      }
      {!isHome &&
        <>
          <ButtonSm className="btn-home-likes size" onClick={() => swipe('left')} onTouchStart={() => swipe('left')} color="white">
            <IonIcon icon={'assets/icon/icon_close.svg'} color="danger" className="iconNoHome" />
          </ButtonSm>
          <ButtonSm className="btn-home-likes size" onClick={() => swipe('right')} onTouchStart={() => swipe('right')} color="white">
            <IonIcon icon={'assets/icon/icon_check.svg'} color="success" className="iconNoHome" />
          </ButtonSm>
          <ButtonSm className="btn-home-likes" onClick={() => superlike()} onTouchStart={() => superlike()} color="white">
            <IonIcon icon={star} color="secondary" className="iconNoHome" />
          </ButtonSm>
        </>
      }
    </Actions>
  );
};
