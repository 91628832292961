import styled, { css } from "styled-components";
import { IonButton, IonCol } from "@ionic/react";

import { PageTitle } from "../../components/ui";

export const small = window.innerHeight < 600;

export const StyledPage = styled.div`
  gap: 20px;
  height: 100%;
	display: grid;
  padding: 0 16px;
	position: relative;
	align-items: center;
	justify-content: center;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
`;

export const CardItem = styled.div`
  height: 45vh;
  display: grid;
  position: relative;
	align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: 75% 25%;
`;

export const CarCard = styled.div`
  margin: 0;
  padding: 0;
  height: 100%;
  display: grid;
  position: relative;
	align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: 30% 70%;
`;

export const LogoHeader = styled.div`
	width: 100%;
	max-width: 40%;
	margin: 0 auto;
  padding: 8px 0;
`;

export const ComoFunciona = styled.div`
    padding: 0;
    height: 100%;
    display: grid;
    position: relative;
    align-items: center;
    justify-content: center;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    border-radius: 30px 30px 0px 0px;
    background: linear-gradient(180deg, #d63d2f 57.34%, #d6752f 100%);
`;

export const NoLikeTitle = styled.h1`
  margin: 0 !important;
  color: #FFF !important;
  font-weight: 800 !important;
  padding: 8px 16px !important;

  ${(props) =>
    props.color &&
    css`
      color: ${props.color}!important;
    `}
`;







export const StyledTitle = styled(PageTitle)`
  margin-left: 28px !important;
  margin-bottom: 39px !important;
`;

export const CarContent = styled.div`
  background-color: white;
  margin: 0 15px 15px 15px;
  border-radius: 10px;
  padding: 10px 20px 0px 20px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.11);
  padding-bottom: 10px;
`;

export const Logo = styled.img`
  display: block;
  margin: auto;
  margin-bottom: 38px;
`;



export const FlexPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;


export const ReactionCol = styled(IonCol)`
  display: flex;
  width: 50%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const ReactionButton = styled(IonButton)`
  width: 80%;
`;


export const ComoFuncionaTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #ffffff;
`;

export const Bullet = styled.p`
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 16px;
  & img {
    margin-right: 10px;
  }

  color: #ffffff;
`;

export const OResto = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
`;

export const Daremos = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
`;

export const InputBox = styled.div`
  background-color: #f5f5f5 !important;
  color: #6b6b6b !important;
  border-radius: 15px !important;
  padding: 1rem !important;
  margin-bottom: 6px;
`;

export const FilterInputBox = styled(InputBox)`
  background-color: #fafafa !important;
  color: #6b6b6b !important;
  border-radius: 15px !important;
  padding: 1rem !important;
  margin-bottom: 6px;
`;

export const Input = styled.input`
  background-color: #f5f5f5 !important;
  color: #6b6b6b !important;
  border-radius: 10px !important;
  padding-left: 1rem !important;
  margin-bottom: 6px;
  width: 100%;
  border: none;
  outline: none;
  padding-top: 10px;
  padding-bottom: 10px;

  &::placeholder {
    color: #b5b5b5;
  }
`;

export const CarTitle = styled.div`
  font-size: 16px;
  color: #000;
  font-weight: 600;
  height: 10%;
`;

export const CardLike = styled.div<{ read?: Boolean }>`
  margin: 10px;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0px -1px 10px 4px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
`;

export const Thumbnail = styled.img`
  border-radius: 8px;
  border:5px;
  object-fit: cover;
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
`;
