import { API_HOME } from "../../config";

export const validateCoupon = async (code: string, user) => {

	let req = await fetch(`${API_HOME}/payment/verifyCoupon/${code}`, {
		method: "GET",
		headers: {
			"Content-type": "application/json",
			authorization: `Bearer ${user.token}`,
		}
	});

	const resp = await req.json();

	return { success: req.ok, data: resp };
}

export const getPlans = async (user) => {
	let req = await fetch(`${API_HOME}/payment/plans`, {
		method: "GET",
		headers: {
			"Content-type": "application/json",
			authorization: `Bearer ${user.token}`,
		}
	});

	const resp = await req.json();

	return { success: req.ok, data: resp };
}


export const getSubscription = async (user) => {
	let req = await fetch(`${API_HOME}/payment/subscription`, {
		method: "GET",
		headers: {
			"Content-type": "application/json",
			authorization: `Bearer ${user.token}`,
		}
	});

	const resp = await req.json();

	return { success: req.ok, data: resp };
}

export const cancelSubscription = async (user) => {
	let req = await fetch(`${API_HOME}/payment/subscription/cancel`, {
		method: "POST",
		headers: {
			"Content-type": "application/json",
			authorization: `Bearer ${user.token}`,
		}
	});

	const resp = await req.json();

	return { success: req.ok, data: resp };;
}


// ======================================================
// Pagseguro
// ======================================================

export const getSession = async (user) => {
	let req = await fetch(`${API_HOME}/payment/session`, {
		method: "GET",
		headers: {
			"Content-type": "application/json",
			authorization: `Bearer ${user.token}`,
		}
	});

	const resp = await req.json();

	return { success: req.ok, data: resp.id._text };;
}

export const pay = async (payload: any, user) => {

	let req = await fetch(`${API_HOME}/payment/WithCC`, {
		method: "POST",
		headers: {
			"Content-type": "application/json",
			authorization: `Bearer ${user.token}`,
		},
		body: JSON.stringify(payload),
	});

	const resp = await req.json();

	return { success: req.ok, data: resp };
}