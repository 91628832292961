import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../State";
import { API_HOME, MEDIA_HOME } from "../../config";
import * as Sentry from "@sentry/react";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonRouterLink,
	IonFooter,
	IonFab,
	IonFabButton,
	IonIcon,
	useIonRouter,
} from "@ionic/react";
import { menuController } from "@ionic/core";
import { add } from "ionicons/icons";
import styled, { css } from "styled-components";

import { CarsList, Car } from "../../components/CarsList";
import { format } from "date-fns";
import pt from "date-fns/locale/pt";
import { useHistory } from "react-router";

const Wrapper = styled.div`
	padding: 0 15px;
`;

const ProfileMainWrapper = styled.div`
	display: flex;
	margin-top: 30px;
	margin-bottom: 32px;
`;

const Avatar = styled.img`
	height: 94px;
	object-fit: cover;
	width: 94px;
	border-radius: 50%;
`;

const EditProfileButton = styled(IonRouterLink)`
	color: #596e79;
	font-size: 14px;
`;

const ProfileDetails = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 30px;
	padding-top: 10px;
	width: calc(100% - 30px - 94px);
`;

const ProfileName = styled.div`
	font-size: 20px;
	font-weight: 700;
`;

const ProfileJoinedDate = styled.div`
	margin-top: auto;
	margin-bottom: 10px;
	font-size: 12px;
	color: #c5c5c5;
	font-weight: 400;
`;

const ProfileSummary = styled.div`
	display: flex;
	justify-content: space-between;
	border-radius: 8px;
	background-color: #f0eeee;
	padding: 10px 13px;
	margin-bottom: 32px;
`;

const SummaryBlock = styled(IonRouterLink)`
	//width: 33.333333333333333333333333333333%;
	text-align: center;
	flex: 1;
`;

const SummaryTitle = styled.h2`
	color: #595959;
	font-size: 14px;
	margin: 0;
	margin-bottom: 5px;
`;

const SummaryValue = styled.p`
	color: #596e79;
	font-size: 20px;
	font-weight: 800;
	padding: 0;
	margin: 0;
`;

const SectionTitle = styled.h1`
	margin: 0;
	padding: 0;
	color: #2f2f2f;
	font-weight: 800;
	font-size: 18px;
	margin-bottom: 12px;

	& ion-router-link {
		color: #2f2f2f;
	}
`;

const ActionButton = styled(IonRouterLink)`
	font-weight: 800;
	font-size: 16px;
	padding: 18px;
	flex: 1;
	text-align: center;
	background: #d63d2f;
	border-radius: 72px;
	color: #fff;
	display: inline-block;

	//margin-left: 24px;
`;

const CarImage = styled.img`
	width: 100%;
	height: 20vh;
	object-fit: cover;
`;



const Profile: React.FC = ({ }: any) => {
	const { state, dispatch } = useContext(AppContext);
	const history = useHistory();

	// console.log("Profile sees this state", state);
	const { cars, user, matches, chats } = state;

	const chatCount = [...new Set(chats?.map((c) => c.channel))]?.length || 0;

	const verify = async () => {
		if (cars?.length > 0 && !user?.subscription?.is_active) {
			history.push("/tabs/funcpremium");
		}
		else {
			history.push("/tabs/cars/addcar");
		}	
	}

	useEffect(() => {
		//if (!state.cars) {
		fetch(`${API_HOME}/car`, {
			headers: {
				authorization: `Bearer ${state?.user?.token}`,
			},
		})
			.then((resp) => resp.json())
			.then((cars) => {
				dispatch({
					type: "loadCars",
					cars,
				});
			})
			.catch((err) => {
				Sentry.captureException(err);
			});
		//}
	}, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton onClick={() => menuController.open()}>
							<img src="assets/btnLeftMenu.svg" />
						</IonButton>
					</IonButtons>
					<IonTitle>Perfil</IonTitle>
					<IonButtons slot="end">
						<IonButton routerLink="/edit-profile">
							<img src="assets/iconOption.svg" />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				{cars?.length > 0 && (
					<IonFab vertical="center" edge={true} horizontal="end" slot="fixed">
						<IonFabButton onClick={verify}>
							<IonIcon icon={add} style={{ pointerEvents: 'none' }} />
						</IonFabButton>
					</IonFab>
				)}
				<Wrapper>
					<ProfileMainWrapper>
						{user?.id && <Avatar src={user.profile_picture} />}
						<ProfileDetails>
							<ProfileName>{`${user?.first_name} ${user?.last_name}`}</ProfileName>
							<EditProfileButton routerLink="/edit-profile">
								Editar Perfil
							</EditProfileButton>
							<ProfileJoinedDate>
								Entrou em{" "}
								{format(new Date(user?.createdAt || null), "MMMM 'de' yyyy", {
									locale: pt,
								})} <br/>
								{user?.subscription?.is_active ? <IonRouterLink routerLink="/tabs/payment">Plano Premium</IonRouterLink> : <IonRouterLink routerLink="/tabs/premium">Plano Gratuito</IonRouterLink>}
							</ProfileJoinedDate>
						</ProfileDetails>
					</ProfileMainWrapper>
					<ProfileSummary>
						<SummaryBlock routerLink="/tabs/cars">
							<SummaryTitle>Carros Ativos</SummaryTitle>
							<SummaryValue>{cars?.length || 0}</SummaryValue>
						</SummaryBlock>
						<SummaryBlock routerLink="/tabs/match">
							<SummaryTitle>Matchs</SummaryTitle>
							<SummaryValue>{matches?.length || 0}</SummaryValue>
						</SummaryBlock>

						<SummaryBlock routerLink="/tabs/chat">
							<SummaryTitle>Chat</SummaryTitle>
							<SummaryValue>{chatCount}</SummaryValue>
						</SummaryBlock>
					</ProfileSummary>
					<SectionTitle>
						<IonRouterLink routerLink="/tabs/cars">
							Meus Carros <img src="assets/chevronDown.svg" alt="" />
						</IonRouterLink>
					</SectionTitle>
					{!cars?.length && (
						<ActionButton routerLink="/tabs/cars/addcar">
							Adicione seu primeiro carro!
						</ActionButton>
					)}

					{cars?.map((car, i) => {
						return (
							<div key={i}>
								<IonRouterLink routerLink="/tabs/cars">
									<CarImage
										src={`${MEDIA_HOME}/car/${car.id}/front_small.jpg`}
									/>
								</IonRouterLink>
							</div>
						);
					})}
				</Wrapper>
			</IonContent>
			<IonFooter></IonFooter>
		</IonPage>
	);
};

export default Profile;