import React, { useRef, useState, useEffect, useContext } from "react";
import { AppContext } from "../State";
import { API_HOME, mileageFactor, priceFactor } from "../config";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
	formatCurrency,
	formatMileage
} from "../common/util";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonRouterLink,
	useIonModal,
	useIonAlert,
	IonLoading,
} from "@ionic/react";

import {
	PageUI,
	PageBackground,
	Panel,
	PageSubTitle,
} from "../components/ui";

import { menuController } from "@ionic/core";
import { municipios as municipiosDB } from "../common/municipios";
import styled from "styled-components";


const Buttons = styled.div`
	padding: 1rem 0;
	display: flex;

	& ion-router-link {
		margin-left: 5px;
		margin-right: 5px;
	}
`;

const BackButton = styled(IonRouterLink)`
	display: flex;
	width: 60px;
	height: 60px;
	background-color: #ededed;
	border-radius: 50%;
	align-items: center;
	justify-content: center;

	& img {
		width: 29px;
		height: 14px;
	}
`;

const ActionButton = styled(IonRouterLink)`
	font-weight: 800;
	cursor: pointer;
	font-size: 16px;
	padding: 16px 0px;    
        display: flex;
        align-items: center;
        justify-content: center;
	flex: 1;
	text-align: center;
	background: #d63d2f;
	border-radius: 72px;
	color: #fff;
	display: block;
	text-transform: uppercase;
	//margin-left: 24px;
`;

const InputBox = styled.div`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 15px !important;
	padding: 1rem !important;
	margin-bottom: 6px;
`;

const FilterInputBox = styled(InputBox)`
	background-color: #fafafa !important;
	color: #6b6b6b !important;
	border-radius: 15px !important;
	padding: 1rem !important;
	margin-bottom: 6px;
`;

const Input = styled.input`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
	width: 100%;
	border: none;
	outline: none;
	padding-top: 10px;
	padding-bottom: 10px;

	&::placeholder {
		color: #b5b5b5;
	}
`;

const Select = styled.select`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
	width: 100%;
	border: none;
	outline: none;
	padding-top: 10px;
	padding-bottom: 10px;

	&::placeholder {
		color: #b5b5b5;
	}
`;

const UploadedThumbnails = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	& img {
		width: 50px !important;
		height: 50px;
		object-fit: cover;
		margin: 1rem;
		border-radius: 20%;
	}
`;

const Row = styled.div`
	display: flex;
`;

const Col = styled.div`
	&:first-child {
		width: 6rem;
		margin-right: 1rem;
	}

	&:last-child {
		width: calc(68%);
	}
`;

const HalfCol = styled.div`
	width: 50%;
	&:first-child {
		width: calc(50% - 1rem);
		margin-right: 1rem;
	}
`;

const CarDetails: any = ({ match }: any) => {
	const { state, dispatch } = useContext(AppContext);
	const { user, cars } = state;
	const history = useHistory();

	const car = cars.find((c) => c.id === parseInt(match.params.id));



	// console.log("Editing this car: ", car);


	const contentRef = useRef<HTMLIonContentElement | null>(null);

	const [mileage, setMileage] = useState(car?.mileage || 1);
	const [priceRange, setPriceRange] = useState(car?.price_range || 1);
	const [images, setImages] = useState({});
	const [showLoading, setShowLoading] = useState(false);
	const [presentAlert] = useIonAlert();
	const [successAlert] = useIonAlert();

	const handleDismiss = () => {

	};



	if (!car) {
		return null;
	}

	const editCar = async () => {
		try {
			const payload = {
				price_range: priceRange,
				mileage: mileage,
				images: Object.entries(images).map((k: any) => {
					return { name: k[0], path: k[1].uploadedFilename };
				}),
			};

			setShowLoading(true);

			const req = await fetch(`${API_HOME}/car/${car.id}`, {

				method: "PUT",
				headers: {
					"Content-type": "application/json",
					authorization: `Bearer ${state.user.token}`,
				},
				body: JSON.stringify(payload),
			});

			const resp = await req.json();

			setShowLoading(false);

			if (!resp?.id) {
				if (resp.error) {
					let msg;

					if (resp.error.type === "desired.exists") {
						msg = "Já existem filtros cadastrados para este carro";
					} else {
						Sentry.captureException(new Error(resp.error));
						msg = resp.error;
					}
					return error(msg);
				}
			} else {
				dispatch({
					type: "updateCar",
					car: resp
				});

				presentAlert({
					header: "Sucesso",
					message: "Suas alterações foram salvas",
					buttons: [
						{
							text: "Ok",
							handler: () => {
								history.replace("/tabs/cars");
							},
						},
					],
				});
			}

			// console.log("Resp was: ", resp);
		} catch (err) {
			setShowLoading(false);
			// console.log("Err was: ", err);
		}
	};

	const clearForm = () => {
		setMileage(car.mileage);
		setPriceRange(car.price_range);
		setImages({});
		setShowLoading(false);
	};



	const error = (message) => {
		presentAlert({
			header: "Erro",
			message,
			buttons: ["Ok"],
		});
	};

	const removeCar = () => {
		presentAlert({
			header: "Excluir carro",
			message: "Tem certeza de que deseja excluir este carro? A operação não poderá ser desfeita.",
			buttons: [
				"Cancelar",
				{
					text: "Continuar e excluir",
					handler: removeCarHandler
				}
			],
		});
	}

	const removeCarHandler = async () => {
		// console.log("remove car handler called");
		try {
			setShowLoading(true);
			const req = await fetch(`${API_HOME}/car/${car.id}`, {
				method: "DELETE",
				headers: {
					authorization: `Bearer ${state.user.token}`,
				},
			});

			const resp = await req.json();

			setShowLoading(false);

			if (!resp.success) {
				Sentry.captureException(new Error(resp.error));
				return error(resp.error);
			} else {

				// console.log("success called, placing alert");
				dispatch({
					type: "deleteCar",
					id: parseInt(match.params.id),
				});

				successAlert({
					header: "Sucesso",
					message: "Seu carro foi excluído.",
					buttons: [
						{
							text: "Ok",
							handler: () => {
								history.replace("/tabs/cars");
							},
						},
					],
				});
			}

			// console.log("Resp was: ", resp);
		} catch (err) {
			setShowLoading(false);
			// console.log("Err was: ", err);
			error(err);
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton onClick={() => menuController.open()}>
							<img src="assets/btnLeftMenu.svg" />
						</IonButton>
					</IonButtons>
					<IonTitle>Editar Carro</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen ref={contentRef} scrollEvents={true}>
				<PageUI>
					<PageBackground style={{ paddingTop: "5%" }}>
						<Panel>
							<form autoComplete="off">
								<>
									<PageSubTitle>
										Editando{" "}
										{`${car?.carModel?.carMake?.name || ''} ${car?.carModel?.name || "Outro"}`}
									</PageSubTitle>
									<FilterInputBox>
										<div>
											<p>Preço</p>
											<div style={{ margin: "1rem 0", textAlign: "left" }}>
												{`${formatCurrency(
													priceRange === 1
														? priceRange
														: (priceRange - 1) * priceFactor
												)} ${priceRange < 41 ? `a ${formatCurrency(
													priceRange === 1
														? priceFactor
														: priceRange * priceFactor
												)}` : `ou mais`}`}
											</div>
											<Input
												type="range"
												name="price"
												min="1"
												max="41"
												step="1"
												value={priceRange}

											/>
										</div>
									</FilterInputBox>
									<FilterInputBox>
										<div>
											<p>Quilometragem</p>
											<div style={{ margin: "1rem 0", textAlign: "left" }}>
												{`${formatMileage(
													mileage <= 1 ? 0 : (mileage - 1) * mileageFactor
												)} ${mileage < 21 ? `a ${formatMileage(
													mileage <= 1 ? mileageFactor : mileage * mileageFactor
												)} Km` : `Km ou mais`}`}
											</div>
											<Input
												type="range"
												name="mileage"
												min="0"
												max="21"
												step="1"
												value={mileage}

											/>
										</div>
									</FilterInputBox>


									<FilterInputBox>
										<div>
											Ano:
											{car?.year}

										</div>
									</FilterInputBox>
									<FilterInputBox>
										<div>
											Combustivel: {car?.fuel_type}

										</div>
									</FilterInputBox>
									<FilterInputBox>
										<div>
											Cambio: {car?.shift_type}

										</div>
									</FilterInputBox>
									<FilterInputBox>
										<div>
											Carroceria:
											{car?.body_type}

										</div>
									</FilterInputBox>
									<FilterInputBox>
										<div>
											Categoria:
											{car?.category}
										</div>
									</FilterInputBox>


									{car?.carOptionals && car?.carOptionals.length > 0 && (<FilterInputBox>
										<div>
											Opcionais
											{car?.carOptionals && car?.carOptionals.map(op => {
												<Input
													value={op}
												/>
											})}
										</div>
									</FilterInputBox>
									)}



									<Buttons>
										<BackButton
											routerLink="/home"
											className="backButton"
										>
											<img src="assets/backArrow.svg" />
										</BackButton>

									</Buttons>
								</>
							</form>
						</Panel>
					</PageBackground>
				</PageUI>
				<IonLoading
					isOpen={showLoading}
					onDidDismiss={() => setShowLoading(false)}
					message={"Salvando alterações..."}
				/>
			</IonContent>
		</IonPage>
	);
};

export default CarDetails;
