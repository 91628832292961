import styled from 'styled-components';

export const PageUI = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`

export const PageBackground = styled.div`
	padding: 0;
	height: 100%;
	display: grid;
	position: relative;
	align-items: center;
	background: #da5744;
	justify-content: center;
	grid-template-columns: 1fr;
	grid-template-rows: 25% 75%;
`

export const Logo = styled.img`
	width: 100%;
	max-width: 40%;
	margin: 0 auto;
	padding: 8px 0;
`;

export const Panel = styled.div`
	height: 100%;
	display: grid;
	text-align: left;
	padding: 8px 16px;
	align-items: center;;
	justify-content: center;
	background-color: #fff;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	border-radius: 30px 30px 0px 0px;
`;

export const PageTitle = styled.h1`
	margin: 0;
	padding: 0;
	color: #000;
	font-weight: 800;
	`;

export const PageSubTitle = styled.p`
	margin: 0;
	padding: 0;
	color: #898a8d;
	font-size: 16px;
`;

export const StyledNativeInput = styled.input`
	width: 100%;
	border: none;
	outline: none;
	margin-bottom: 0;
	padding: 0.5rem 1rem;
	padding-left: 0;
	border-bottom: solid 1px rgba(199, 199, 199, 24%);
`;

export const ForgotPassword = styled.p`
	margin: 0;
	text-align: center;

	& ion-router-link {
		color: #000;
		display: block;
		font-weight: 700;
		text-decoration: underline;
	}
`;

export const InputWrapper = styled.div`
    border-bottom: solid 1px rgba(199, 199, 199, 24%);
    display: flex;
`;
