import { IonButton, IonIcon, IonModal, IonSlide, IonSlides } from "@ionic/react";
import styled, { css } from "styled-components";
import { ActionButton } from "../common/input";
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";

import { Button, PageBackground, PageUI } from "./ui";
import React, { useRef, useState } from "react";

export const Buttons = styled.div`
	gap: 16px;
	width: 100%;
	display: grid;
	max-width: 85%;
	margin: 0 auto;
	margin-top: 8px;
	text-align: center;
	align-items: center;
	justify-content: center;
	grid-template-rows: auto;
	grid-template-columns: 1fr;

	p {
		margin: 0;
	}

	a {
		cursor: pointer;
		text-decoration: underline;
	}
`;

export const Img = styled.img`
	@media (max-height: 715px){
		width: 42.5vh !important;
	}
	@media (min-height: 716px){
		width: 47.5vh !important;
	}
`;

export const Tutorial: React.FC<{
	count: number;
	onDismiss: (notShowAgain?: boolean) => void;
}> = ({ onDismiss }) => {
	const tutorials = [
		{ url: '01_Home' }, { url: '02_Carro' }, { url: '03_Desejado' }, { url: '04_Home_Like' },
		{ url: '05_Premium' }, { url: '06_Match' }
	];

	const [disablePrevBtn, setDisablePrevBtn] = useState(true);
	const [disableNextBtn, setDisableNextBtn] = useState(false);

	const Slide = useRef<any>(null);

	const handleSlideChange = async () => {
		const swiper = await Slide.current.getSwiper();
		setDisablePrevBtn(swiper.activeIndex === 0);
		setDisableNextBtn(swiper.activeIndex === swiper.slides.length - 1);
	}

	const next = async () => {
		// console.log('next');

		const swiper = await Slide.current.getSwiper();
		swiper.slideNext();
	}

	const prev = async () => {
		// console.log('prev');
		const swiper = await Slide.current.getSwiper();
		swiper.slidePrev();
	}


	return (
		<PageUI>
			{/* <img src={`assets/tutorial/01_Home.jpg`} /> */}
			<IonSlides pager={true} ref={Slide} onIonSlideDidChange={handleSlideChange}>
				{tutorials.map((item, i) => {
					return (
						<IonSlide key={i}>
							<Img src={`assets/tutorial/${item.url}.jpg`}></Img>
							<button className="button-next" style={{
								position: 'absolute',
								right: '0px',
								margin: '2px',
								backgroundColor: 'whitesmoke',
								borderRadius: '50px'
							}} onClick={next} disabled={disableNextBtn}>
								<IonIcon size="large" icon={chevronForwardOutline} style={{ pointerEvents: 'none' }}>
								</IonIcon>
							</button>
							<button className="button-prev" style={{
								position: 'absolute',
								left: '0px',
								margin: '2px',
								backgroundColor: 'whitesmoke',
								borderRadius: '50px'
							}} onClick={prev} disabled={disablePrevBtn}>
								<IonIcon size="large" icon={chevronBackOutline} style={{ pointerEvents: 'none' }}>
								</IonIcon>
							</button>
						</IonSlide>
					)
				})}
			</IonSlides>
			<Buttons>
				<ActionButton onClick={() => { onDismiss() }}>
					Fechar
				</ActionButton>
				<p onClick={() => { onDismiss(true) }}>
					<a>Não mostrar novamente!</a>
				</p>
			</Buttons>
		</PageUI>
	)
};