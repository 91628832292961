import { IonButton, IonRouterLink } from '@ionic/react';
import styled from 'styled-components';

export const StyledSelect = styled.select`
background-color: #f5f5f5 !important;
color: #6b6b6b !important;
border-radius: 10px !important;
padding-left: 1rem !important;
margin-bottom: 6px;
width: 100%;
border: none;
outline: none;
padding-top: 10px;
padding-bottom: 10px;

&::placeholder {
    color: #b5b5b5;
}
`;


export const Buttons = styled.div`
	padding: 1rem 0;
	display: flex;
`;

export const StyledInput = styled.input`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
	width: 100%;
	border: none;
	outline: none;
	padding-top: 10px;
	padding-bottom: 10px;

	&::placeholder {
		color: #b5b5b5;
	}
`;

export const BackButton = styled(IonRouterLink)`
	display: flex;
	width: 60px;
	height: 60px;
	background-color: #ededed;
	border-radius: 50%;
	align-items: center;
	justify-content: center;

	& img {
		width: 29px;
		height: 14px;
	}
`;

export const SocialMediaButton = styled(IonButton)`
	--background: #d63d2f;
	--background-activated:  #d63d2f;
	--background-focused:  #d63d2f;
	--background-hover: #d63d2f;
	--background-activated-opacity: 1;
	--background-focused-opacity: 1;
	--background-hover-opacity: 1;
	
	& :click {
		background-color: #d63d2f;
		color:#D63D24;
		--background-hover-opacity: .7;
	}
`;

export const SignUpButton = styled(IonRouterLink)`
	font-weight: 800;
	font-size: 16px;
	padding: 16px 0px;    
        display: flex;
        align-items: center;
        justify-content: center;
	flex: 1;
	text-align: center;
	background: #d63d2f;
	border-radius: 72px;
	color: #fff;
	display: block;
	text-transform: uppercase;
	margin-left: 24px;
`;

export const SuggestionsBox = styled.div`
	border: solid 1px black;
	border-radius: 5px;
	margin: 0 2rem;
	margin-bottom: 0.5rem;
	max-height: 20vh;
	overflow-y: scroll;
	padding: 0 1rem;
	background-color: #f5f5f5;
	color: #6b6b6b;

	& p {
		cursor: pointer;
		margin: 0;
		padding: 0.5rem 0;
	}
`;


