import styled, { css } from "styled-components";
import {
	IonButton,
	IonCol,
} from "@ionic/react";

import { PageTitle } from "../../components/ui";

export const small = window.innerHeight < 600;

export const StyledPage = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;

export const CarBackground = styled.div`
	padding: 0;
	height: 100%;
	display: grid;
	position: relative;
	align-items: center;
	justify-content: center;
	grid-template-columns: 1fr;
	grid-template-rows: 25% 75%;
	background: linear-gradient(180deg, #d63d2f 57.34%, #d6752f 100%);
`;

export const CarCard = styled.div`
	height: 100%;
	display: grid;
	text-align: left;
	padding: 8px 16px;
	align-items: center;
	justify-content: center;
	grid-template-columns: 1fr;
	background-color: #f6f6f6;
	grid-template-rows: 15% 60% 25%;
	border-radius: 30px 30px 0px 0px;

	&>div:nth-child(2) {
		height: 100%;
	}
`;

export const ComoFunciona = styled.div`
	${(props) =>
		props.color ?
			css`background: ${props.color}!important;` :
			css`background: rgba(0, 0, 0, 0) linear-gradient(rgb(214, 61, 47) 57.34%, rgb(214, 117, 47) 100%) repeat scroll 0% 0%;`
	}

	gap: 0;
	display: grid;
	padding: 8px 12px;
	align-items: center;;
	justify-content: center;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	border-radius: 30px 30px 0px 0px;
`;

export const ComoFuncionaTitle = styled.h2`
	margin: 0;
	font-size: 22px;
	font-weight: 400;
	color: #ffffff;
	line-height: 28px;
	font-style: normal;
	padding-bottom: 8px;
`;

export const StyledTitle = styled(PageTitle)`
	${(props) =>
		props.color &&
		css`
			color: ${props.color}!important;
	`}
			
		margin: 0;
		width: 100%;
		padding: 8px 16px;
`;

export const InputBox = styled.div`
	margin-bottom: 6px;
	padding: 1rem !important;
	color: #6b6b6b !important;
	border-radius: 15px !important;
	background-color: #f5f5f5 !important;
`;

export const FilterInputBox = styled(InputBox)`
	margin-bottom: 6px;
	padding: 1rem !important;
	color: #6b6b6b !important;
	border-radius: 15px !important;
	background-color: #fafafa !important;
`;

export const Input = styled.input`
	width: 100%;
	border: none;
	outline: none;
	padding-top: 10px;
	margin-bottom: 6px;
	padding-bottom: 10px;
	color: #6b6b6b !important;
	padding-left: 1rem !important;
	border-radius: 10px !important;
	background-color: #f5f5f5 !important;

	&::placeholder {
		color: #b5b5b5;
	}
`;

export const PageBackground = styled.div`
	padding: 0;
	height: 100%;
	display: grid;
	position: relative;
	align-items: center;;
	justify-content: center;
	grid-template-columns: 1fr;
	grid-template-rows: 40% 60%;
	background: white;
	`;

export const Welcome = styled.div`
	height: 100%;
	display: grid;
	align-items: center;;
	justify-content: center;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
`;

export const Panel = styled.div`
	height: 100%;
	display: grid;
	text-align: left;
	padding: 8px 16px;
	align-items: center;;
	justify-content: center;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	border-radius: 30px 30px 0px 0px;
	background: linear-gradient(rgb(214, 61, 47) 57.34%, rgb(214, 117, 47) 100%) 0% 0% repeat scroll rgba(0, 0, 0, 0);

	&>div {
		height: 100%;
    display: grid;
		align-items: center;;
    justify-content: center;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
	}
`;




export const CarContent = styled.div`
	width: 85%;
	height: 100%;
	margin: 0 auto;
	border-radius: 10px;
	background-color: white;
	padding: 10px 15px 0px 15px;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.11);
	
	@media (max-width: 290px) {
		width: 100%;
	}
`;




export const Logo = styled.img`
	width: 100%;
	max-width: 40%;
	margin: 0 auto;
	padding: 8px 0;
`;

export const ReactionCol = styled(IonCol)`
	width: 50%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;

export const ReactionButton = styled(IonButton)`
	width: 80%;
`;

export const LogoHeader = styled.div`
	display: flex;
	margin-bottom: 12px;
`;

export const Bullet = styled.p`
  margin: 0px;
	display: flex;
	font-size: 16px;
	color: #ffffff;
	font-weight: 300;
	align-items: center;

	& img {
		margin-right: 5px;
	}
`;

export const OResto = styled.p`
	font-size: 18px;
	color: #ffffff;
	font-weight: bold;
	text-align: center;
	font-style: normal;
`;

export const Daremos = styled.p`
	margin: 0;
	font-size: 16px;
	color: #ffffff;
	padding-top: 8px;
	font-weight: bold;
	font-style: normal;
`;

export const Avatar = styled.img`
	width: 32px;
	height: 32px;
	object-fit: cover;
	border-radius: 50%;
`;

export const CarTitle = styled.div`
	height:10%;
	color: #000;
	font-size: 16px;
	font-weight: 600;
`;

export const ButtonActions = styled.div`
    width: 100%;
    height: 100%;
`;
