import React, { useContext, useState } from "react";

import * as Sentry from "@sentry/react";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonInput,
	IonRouterLink,
	useIonAlert,
	IonLoading,
	IonGrid,
	IonRow,
	IonCol,
	useIonViewWillEnter
} from "@ionic/react";

import { API_HOME, MEDIA_HOME } from "../../config";
import { AppContext } from "../../State";

import ProfilePictureUploader from "../../components/ProfilePictureUploader";

import { phoneNumberMask } from "../../common/masks";

import {
	PageUI,
	PageBackground,
	Panel,
	PageTitle,
	PageSubTitle,
} from "../../components/ui";

import styled from "styled-components";
import { useHistory } from "react-router";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

const StyledPanel = styled(Panel)`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const Buttons = styled.div`
	padding: 1rem 0;
	display: flex;
	margin-top: auto;
`;

const StyledInput = styled(IonInput)`
	background-color: #f5f5f5 !important;
	color: #6b6b6b !important;
	border-radius: 10px !important;
	padding-left: 1rem !important;
	margin-bottom: 6px;
`;

const BackButton = styled(IonRouterLink)`
	display: flex;
	width: 60px;
	height: 60px;
	background-color: #ededed;
	border-radius: 50%;
	align-items: center;
	justify-content: center;

	& img {
		width: 29px;
		height: 14px;
	}
`;

const ActionButton = styled(IonRouterLink)`
font-weight: 800;
cursor: pointer;
font-size: 18px;
padding: 18px 0px;
flex: 1;
text-align: center;
background: #d63d2f;
border-radius: 30px;
color: #fff;
display: block;
text-transform: uppercase;

margin: 10px 20px 10px 20px;
`;

const Row = styled.div`
	display: flex;
	align-items: center;
`;

const Col = styled.div`
	width: 60%;

	&:nth-child(2) {
		width: 40%;
	}
`;

const Avatar = styled.img`
	border-radius: 50%;
	object-fit: cover;
	height: 50px;
	max-width: 70px;
	margin: 0 auto;
	display: block;
`;

const Fields = styled.div`
	margin-top: 1rem;
`;

const EditProfile: React.FC = () => {
	const { state, dispatch } = useContext(AppContext);
	const { user } = state;
	const [image, setImage] = useState<any>(null);
	const [presentAlert] = useIonAlert();
	const [email, setEmail] = useState<any>(user.email);
	const [phone, setPhone] = useState<any>(user.phone_number ? phoneNumberMask(user.phone_number) : "");
	const [showLoading, setShowLoading] = useState(false);
	const [isLocalUser, setIsLocalUser] = useState(false);
	const history = useHistory();

	
	useIonViewWillEnter(() => {
		FirebaseAuthentication.getCurrentUser().then((user) => {
			if(user.user?.uid) {
				setIsLocalUser(user.user?.uid.length < 20);
			}
		});
	});

	const error = (message) => {
		presentAlert({
			header: "Erro",
			message,
			buttons: ["Ok"],
		});
	};

	const saveProfile = () => {
		if (email) {
			setShowLoading(true);
			// console.log("Saving the profile", { password, passwordConfirm, image });

			const payload: any = {};

			if (!image && !email && !phone) {
				return error("Nenhum campo foi alterado.");
			}

			if (image) {
				// debugger
				const formData = new FormData();
				formData.append("image", image, image.name);

				fetch(`${API_HOME}/user/picture`, {
					method: "POST",

					headers: {
						authorization: `Bearer ${user.token}`,
					},
					body: formData,
				})
					.then((resp) => resp.json())
					.then((resp) => {
						// console.log("upload responded with", resp);

						setShowLoading(false);

						presentAlert({
							header: "Sucesso",
							message: "Suas alterações foram salvas.",
							buttons: ["Ok"],
						});

						if (resp.success) {
							dispatch({
								type: "setProfilePicture",
								profile_picture: `${MEDIA_HOME}/${resp.file.path
									.replace("\\\\", "\\")
									.replace(/\\/g, "/")
									.replace("images/", "")}`,
							});

							//history.replace("/tabs/home");
						} else {
							Sentry.captureException(
								new Error("Falha ao salvar a foto de perfil")
							);
							//error("Falha ao salvar a foto de perfil");
						}
					})
					.catch((err) => {
						console.error("upload errored with", err);
						error(`Falha ao subir a imagem ${JSON.stringify(err)}`);
						setShowLoading(false);
						Sentry.captureException(err);
					});
			}

			if (email) payload.email = email;

			if (phone) {
				let phoneAux = phone.replaceAll('(', '');
				phoneAux = phoneAux.replaceAll(')', '');
				phoneAux = phoneAux.replaceAll('-', '');
				phoneAux = phoneAux.replace(/\s/g, '');
				// console.log('phoneAux', phoneAux);

				payload.phone_number = phoneAux;
			}

			fetch(`${API_HOME}/user`, {
				method: "PUT",
				headers: {
					"Content-type": "application/json",
					authorization: `Bearer ${user.token}`,
				},
				body: JSON.stringify(payload),
			})
				.then((resp) => resp.json())
				.then((resp) => {
					// console.log(resp);

					setShowLoading(false);
					if (!resp.error) {
						presentAlert({
							header: "Sucesso",
							message: "Suas alterações foram salvas.",
							buttons: ["Ok"],
						});

						return;
					}

					error(`Falha atualizar o perfil`);
				})
				.catch((err) => {
					console.error("upload errored with", err);
					setShowLoading(false);
					error(`Falha atualizar o perfil`);
					Sentry.captureException(err);
				});


			// console.log("Saving this payload: ", payload)
		} else {
			error(`Email é obrigatório`);
		}
	};

	const removeAccount = () => {
		presentAlert({
			header: "Excluir conta",
			message: "Tem certeza de que deseja excluir sua conta? Esta ação não poderá ser desfeita.",
			buttons: [
				"Cancelar",
				{
					text: "Continuar e excluir",
					handler: removeAccountHandler
				}
			],
		});
	}

	const removeAccountHandler = () => {
		setShowLoading(true);
		fetch(`${API_HOME}/user`, {
			method: "DELETE",
			headers: {
				authorization: `Bearer ${user.token}`,
			},
		})
			.then((resp) => resp.json())
			.then((resp) => {
				setShowLoading(false);
				presentAlert({
					header: "Sucesso",
					message: "Sua conta foi excluída. Retornando.",
					buttons: ["Ok"],
				});
				// GoogleAuth.signOut().catch(e => {
				// 	console.error(e);
				// });
				dispatch({
					type: "signOut",
				});
			})
			.catch((err) => {
				console.error("delete user errored with", err);
				error(`Falha atualizar o perfil`);
				setShowLoading(false);
				Sentry.captureException(err);
			});
	}

	return (
		<IonPage>
			{/*<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/tabs/tab2" text="Voltar" />
					</IonButtons>
					<IonTitle>Editar</IonTitle>
				</IonToolbar>
			</IonHeader>*/}
			<IonContent fullscreen>
				<PageUI style={{ height: "100%" }}>
					<PageBackground style={{ paddingTop: "20%", height: "100%" }}>
						<StyledPanel>
							<Row>
								<Col>
									<PageTitle>Editar Perfil</PageTitle>
									<PageSubTitle></PageSubTitle>
								</Col>
								<Col>
									<ProfilePictureUploader
										onSelect={(image) => {
											// console.log("onSelect received", image);
											//image is a blob
											setImage(image);
										}}
										initialImage={user.profile_picture}
										height={50}
										label={
											<>
												Alterar <br /> Foto de perfil
											</>
										}
									/>
								</Col>
							</Row>

							<Fields><StyledInput
								placeholder={"Email"}
								type="email"
								value={email}
								disabled={true}
							></StyledInput>	
								<StyledInput
									placeholder={"Telefone"}
									type="tel"
									value={phone}
									onIonChange={(event) => {
										setPhone(phoneNumberMask(event.detail.value));
									}}
								></StyledInput>
								<IonRow>
									{ isLocalUser ? (
									<IonCol>
										<ActionButton style={{
											backgroundColor: "#cf222e",
											fontSize: "0.8rem",
											padding: "0.5rem",
											width: "9rem",
											marginLeft: "auto",
											marginRight: "auto",
											marginTop: "3rem",
										}}
										onClick={() => history.push('/redefinir-senha')}>Redefinir senha</ActionButton>
									</IonCol>
									) : ''}
									<IonCol>
										<ActionButton
											style={{
												backgroundColor: "#cf222e",
												fontSize: "0.8rem",
												padding: "0.5rem",
												width: "9rem",
												marginLeft: "auto",
												marginRight: "auto",
												marginTop: "3rem",
											}}
											onClick={removeAccount}
										>Excluir conta</ActionButton>
									</IonCol>
								</IonRow>						
								
							</Fields>

							<Buttons>
								<BackButton routerLink="/tabs/profile" className="backButton">
									<img src="assets/backArrow.svg" />
								</BackButton>
								<ActionButton onClick={saveProfile}>Salvar</ActionButton>
							</Buttons>
						</StyledPanel>
					</PageBackground>
				</PageUI>
				<IonLoading
					isOpen={showLoading}
					onDidDismiss={() => setShowLoading(false)}
					message={"Salvando..."}
				/>
			</IonContent>
		</IonPage>
	);
};

export default EditProfile;
