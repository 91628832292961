import styled from "styled-components";

const ProfilePicture = styled.img`
	width: ${(props) => `${props.height || 70}px`};
	height: ${(props) => `${props.height || 70}px`};
	max-width: 100%;
	object-fit: cover;
	max-height: 100%;
	background-color: #c4c4c4;
	border-radius: 50%;
	display: block;
	position: relative;
`;

const ThumbnailWrapper = styled.div`
	position: relative;
`;

interface StyledThumbnailProps {
	height?: number;
}

const OnlineIndicator = styled.div<StyledThumbnailProps>`
	width: ${(props) => `${Math.round((props.height || 70) * 0.3428571429)}px`};
	height: ${(props) => `${Math.round((props.height || 70) * 0.3428571429)}px`};

	border: solid 3px white;
	border-radius: 50%;
	background-color: #54d969;
	position: absolute;
	bottom: 0;
	left: 0;
`;

const StyledThumbnail = styled.div<StyledThumbnailProps>``;

const Thumbnail = (props: {
	online: boolean;
	src: string;
	width?: number;
	height?: number;
}) => {
	return (
		<StyledThumbnail>
			<ThumbnailWrapper>
				<ProfilePicture src={props.src} height={props.height || undefined} />
				{/* {props.online && <OnlineIndicator height={props.height || undefined} />} */}
			</ThumbnailWrapper>
		</StyledThumbnail>
	);
};

export default Thumbnail;
