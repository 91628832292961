import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
} from "@ionic/react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";

const Privacidade = styled.div`
	& ol,
	& ol li {
		list-style-position: inside;
		padding: 0;
		font-weight: 800;
	}

	& ol.indent {
		margin-left: 1rem;
	}

	& ol.indent li {
		margin-bottom: 0.5rem;
	}

	& ol.font-normal li {
		font-weight: 400 !important;
	}
`;

const PrivacyPolicy: React.FC = () => {

	const history = useHistory();
	const back = () => {
		// console.log('back');
		
		history.goBack();
	}

	return (
		<IonPage>
			<IonContent fullscreen>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start" onClick={back}>
							<IonBackButton defaultHref="/tabs/home" text="Voltar" />
						</IonButtons>
						<IonTitle>Política de Privacidade</IonTitle>
					</IonToolbar>
				</IonHeader>
				<div style={{ padding: "0 2rem" }}>
					<Privacidade>
						<h1>Política de Privacidade</h1>
						<p>Bem-vindo à Política de Privacidade da AUTOTROCA. </p>
						<p>
							Agradecemos por nos confiar as suas informações, nosso objetivo é
							manter sempre essa confiança. Este processo tem início com a
							explicação das informações que coletamos, o motivo pelo qual as
							coletamos, como são usadas as suas escolhas em relação às suas
							informações. Esta Política descreve as práticas de privacidade em
							linguagem simples, mantendo a terminologia técnica e legal a um
							mínimo.
						</p>
						<p>Esta Política de Privacidade entra em vigor em 01/10/2021.</p>
						<ol start={1}>
							<li>Quem somos</li>
						</ol>
						<p>AUTOTROCA</p>
						<p>Santo André – São Paulo – Brasil</p>
						<p>
							<a href="mailto:suporte@autotroca.com.br">
								suporte@autotroca.com.br
							</a>
						</p>
						<p>CNPJ 42.225.871/0001-53</p>
						<ol start={2}>
							<li>Aplicação da presente Política de Privacidade</li>
						</ol>
						<p>
							Esta Política de Privacidade aplica-se a websites, plataforma,
							eventos e outros serviços operados pela AUTOTROCA. Por uma questão
							de simplicidade, referimo-nos a todos como "Serviços" nesta
							Política de Privacidade.
						</p>
						<ol start={3}>
							<li>Informações que coletamos</li>
						</ol>
						<p>
							As informações pessoais que coletamos são básicas e totalmente
							sigilosas.
						</p>
						<p>
							As informações dos veículos que serão cadastrados para ofertar em
							troca também são básicas, mas informações adicionais poderão ser
							inseridas a critério do usuário.
						</p>
						<p>
							Dependendo da ação realizada, ao se cadastrar, navegar, adquirir
							ou solicitar produtos e/ou serviços da AUTOTROCA, serão
							requisitadas informações sobre:
						</p>
						<ul>
							<li>Identificação individual do cliente;</li>
							<li>Veículo(s) ofertado(s) no Portal;</li>
							<li>Veículo(s) pesquisado(s) no Portal;</li>
							<li>Veículo(s) de propriedade do internauta;</li>
							<li>Preferências do cliente;</li>
							<li>Opiniões do internauta;</li>
							<li>
								Acesso do internauta (exemplo: data e horário de realização do
								acesso);
							</li>
							<li>
								Perfil de acesso do internauta (exemplo: links clicados, páginas
								visitadas).
							</li>
						</ul>
						<ol start={4}>
							<li>Para quais finalidades os dados pessoais são coletados</li>
						</ol>
						<p>
							Nos termos da Lei Geral de Proteção de Dados (Lei nº 13.709/18), a
							AUTOTROCA realiza a coleta de seus dados pessoais com finalidades
							específicas e de acordo com as bases legais previstas na
							respectiva Lei, tais como: para o devido cumprimento das
							obrigações legais e regulatórias e para o exercício regular de
							direitos.
						</p>
						<p>
							A AUTOTROCA, na condição de controlador dos dados nos termos da
							legislação, poderá tratar, coletar, armazenar e compartilhar seus
							dados pessoais e informações cadastrais, financeiras e de
							operações ativas e passivas e serviços contratados para: (i)
							garantir maior segurança e prevenir fraudes; (ii) assegurar sua
							adequada identificação, qualificação e autenticação; (iii)
							prevenir atos relacionados à lavagem de dinheiro e outros atos
							ilícitos; (iv) aperfeiçoar o atendimento e os produtos e serviços
							prestados; (v) fazer ofertas de produtos e serviços adequados e
							relevantes aos seus interesses e necessidades de acordo com o seu
							perfil; e (vi) outras hipóteses baseadas em finalidades legítimas,
							como apoio e promoção de atividades da AUTOTROCA e para a
							prestação de serviços que beneficiem os clientes.
						</p>
						<p>
							Ressaltamos que novos serviços online, disponibilizados pela
							AUTOTROCA, estarão automaticamente sujeitos à Política de
							Privacidade vigente à época de sua utilização.
						</p>
						<p>
							Queremos oferecer a melhor experiência durante a sua navegação em
							nossa plataforma e site na internet.
						</p>
						<ol start={5}>
							<li>Uso de cookies</li>
						</ol>
						<p>
							Usamos Cookies para que você tenha a melhor experiência em nosso
							portal. Mas o que são Cookies? Cookies são pequenos arquivos
							gravados no dispositivo de acesso de usuário, enquanto este navega
							na internet, são armazenados em seu navegador e ajudam a armazenar
							suas preferências e a personalizar seu acesso.
						</p>
						<p>
							Assim, podemos usar cookies e coletar, tratar, armazenar
							informações de sua navegação, para:
						</p>
						<ul>
							<li>permitir que a navegação seja mais eficiente e rápida;</li>
							<li>
								aperfeiçoar sua usabilidade, experiência e interatividade na
								utilização dos nossos portais, sites, plataforma, e-mails e
								durante a sua navegação na internet;
							</li>
							<li>
								fazer ofertas e/ou te dar informações mais assertivas e
								relevantes às suas necessidades e interesses;
							</li>
							<li>
								buscar maior eficiência em relação à frequência e continuidade
								da nossa comunicação com você;
							</li>
							<li>responder suas dúvidas e solicitações;</li>
							<li>
								realizar pesquisas de comunicação e marketing de relacionamento,
								para melhorar nossos produtos e serviços, bem como apuração de
								estatísticas em geral.
							</li>
						</ul>
						<p>
							Lembramos que você pode, a qualquer momento, ativar em seu
							navegador mecanismos para informá-lo quando os cookies estiverem
							acionados ou, ainda, impedir que sejam ativados.
						</p>
						<p>
							Você pode desativar os cookies não-essenciais para a utilização
							dos Serviços através das preferências do seu navegador. Sem eles,
							sua navegação pode se tornar limitada e algumas funcionalidades
							dos sites podem ficar comprometidas. Veja as definições sobre
							cookies em cada um dos navegadores.
						</p>
						<p>
							<a href="https://privacy.microsoft.com/pt-BR/windows-10-microsoft-edge-and-privacy">
								Internet Explorer
							</a>
							/
							<a href="https://support.mozilla.org/pt-BR/kb/desative-cookies-no-firefox-parar-rastreamento">
								Firefox
							</a>
							/
							<a href="https://support.google.com/accounts/answer/61416?hl=pt-BR">
								Google Chrome
							</a>
							/
							<a href="https://support.apple.com/kb/PH21411?viewlocale=pt_BR&amp;locale=en_US">
								Safari
							</a>
						</p>
						<ol start={6}>
							<li>Tratamento de informações pessoais</li>
						</ol>
						<p>
							Para o fornecimento dos Serviços online aos seus clientes, a
							AUTOTROCA adota recursos avançados visando a proteção das
							informações pessoais dos usuários e de seus Serviços.
						</p>
						<p>
							As informações de caráter pessoal dos usuários dos Serviços da
							AUTOTROCA, entendendo-se por informações pessoais todas aquelas
							que forem relacionadas a pessoa natural identificada ou
							identificável, inclusive informações pessoais sensíveis (que
							tratem sobre origem racial ou étnica, convicção religiosa, opinião
							política, filiação a sindicato ou a organização de caráter
							religioso, filosófico ou político, dado referente à saúde ou à
							vida sexual, dado genético ou biométrico, quando vinculado a uma
							pessoa natural), como: o nome completo do usuário, endereço físico
							e eletrônico, número de telefone, RG, CPF, biometria, número de
							cartão de crédito, situação financeira e patrimonial, preferências
							e padrões de acesso ("informações pessoais"), não são divulgadas
							pela AUTOTROCA, exceto nas hipóteses expressamente mencionadas
							nesta Política.
						</p>
						<p>
							Tais informações são coletadas pela AUTOTROCA e armazenadas
							utilizando-se rígidos padrões de sigilo e integridade, bem como
							controles de acesso físico e lógico, observando-se sempre os mais
							elevados princípios éticos e legais.
						</p>
						<p>
							Uma vez provida das informações pessoais a respeito do usuário, a
							AUTOTROCA poderá utilizar, de acordo com o seu interesse legítimo,
							os dados do cliente/usuário/internauta para o fim de enviar
							publicidade, direcionada por e-mail ou por quaisquer outros meios
							de comunicação, contendo informações sobre a AUTOTROCA.
							Entretanto, fica reservado ao cliente/usuário/internauta o direito
							de, a qualquer momento, inclusive no ato da disponibilização das
							informações pessoais, informar a AUTOTROCA, por meio dos canais de
							comunicação disponíveis para o cadastramento de tais informações,
							do não interesse em receber tais anúncios, inclusive por e-mail,
							hipótese em que a AUTOTROCA interromperá tais envios no menor
							tempo possível. Para cancelar sua inscrição, consulte as
							instruções presentes no rodapé dos nossos e-mails.
						</p>
						<ol start={7}>
							<li>Como respondemos aos requerimentos legais</li>
						</ol>
						<p>
							Quando alguma autoridade requerer o acesso a seus dados pessoais e
							este pedido estiver respaldado por lei vigente, a AUTOTROCA
							compartilhará as suas informações. Nestes casos, a AUTOTROCA irá
							fornecer as informações estritamente necessárias para o
							cumprimento do pedido ou de nosso programa de integridade.
						</p>
						<ol start={8}>
							<li>Exercício de direitos</li>
						</ol>
						<p>
							Em cumprimento à regulamentação aplicável, quando de sua vigência,
							no que diz respeito ao tratamento de dados pessoais, em especial a
							Lei Geral de Proteção de Dados (Lei nº 13.709/2018), a AUTOTROCA
							respeita e garante a você, a possibilidade de apresentação de
							solicitações baseadas nos seguintes direitos:
						</p>
						<ol className="font-normal indent">
							<li>a confirmação da existência de tratamento;</li>
							<li>o acesso aos dados;</li>
							<li>
								a correção de dados incompletos, inexatos ou desatualizados;
							</li>
							<li>
								a anonimização, bloqueio ou eliminação de dados desnecessários,
								excessivos ou tratados em desconformidade com a Lei;
							</li>
							<li>a portabilidade dos dados a outro fornecedor;</li>
							<li>
								a eliminação dos dados tratados com consentimento do Usuário,
								exceto nas hipóteses de guarda legal e outras dispostas em Lei;
							</li>
							<li>
								a obtenção de informações sobre as entidades públicas ou
								privadas com as quais a AUTOTROCA compartilhou seus dados;
							</li>
							<li>
								a informação sobre a possibilidade de não fornecer o seu
								consentimento, bem como de ser informado sobre as consequências,
								em caso de negativa;
							</li>
							<li>a revogação do consentimento;</li>
							<li>
								a oposição aos tratamentos realizados com fundamento em uma das
								hipóteses de dispensa de consentimento, em caso de eventual
								descumprimento ao disposto na Lei Geral de Proteção de Dados;
							</li>
							<li>
								a revisão de decisões tomadas unicamente com base em tratamento
								automatizado de dados pessoais que afetem os interesses dos
								titulares de dados, respeitados os segredos comercial da
								AUTOTROCA, tais como, mas não limitando-se, àqueles pautados em
								critérios de prevenção a fraudes ou de decorrentes de execução
								de contrato.
							</li>
						</ol>
						<p>
							A AUTOTROCA empreenderá todos os esforços para atender tais
							pedidos no menor espaço de tempo possível. No entanto, mesmo em
							caso de requisição de exclusão, será respeitado o prazo de
							armazenamento mínimo de informações de usuários de aplicações de
							internet, determinado pela legislação brasileira, dentre outras
							determinações legais aplicáveis.
						</p>
						<ol start={9}>
							<li>Como tratamos as suas informações de forma segura</li>
						</ol>
						<p>
							O acesso às informações pessoais coletadas, armazenadas ou de
							outra forma tratadas pela AUTOTROCA é restrito aos profissionais
							autorizados ao uso direto dessas informações, e necessário à
							prestação de seus Serviços, sendo limitado o uso para outras
							tarefas.
						</p>
						<p>
							É exigido, também, de toda organização ou do indivíduo contratado
							para a prestação de serviços de apoio, que sejam cumpridas as
							Políticas de Segurança internas e o Código de Ética adotado pela
							AUTOTROCA.
						</p>
						<p>
							A AUTOTROCA poderá revelar as informações pessoais que tenha
							recebido nas seguintes hipóteses:
						</p>
						<ul>
							<li>
								sempre que estiver obrigada a revelá-las, seja em virtude de
								dispositivo legal, ato de autoridade competente, ordem ou
								mandado judicial;
							</li>
							<li>aos órgãos que administrem cadastros de consumidores;</li>
						</ul>
						<p>
							A AUTOTROCA trabalha exaustivamente para assegurar que as
							informações divulgadas para os clientes sejam verdadeiras e
							íntegras, contando com controles apurados de monitoramento das
							informações fornecidas. Sua participação no processo é revisar as
							informações, valores e informativos e enviar para o usuário, um
							comunicado de qualquer discrepância nas informações fornecidas.
						</p>
						<p>
							A AUTOTROCA não responderá por prejuízos que possam advir do
							vazamento das informações pessoais por violação ou quebra das
							barreiras de segurança de internet por terceiros como "hackers" ou
							"crackers".
						</p>
						<p>
							Além dos casos acima citados, havendo a necessidade ou interesse
							em repassar a terceiros,{" "}
							<strong>
								<u>exceto aqueles já mencionados acima</u>
							</strong>
							, dados de identificação individual dos
							clientes/usuários/internautas, a AUTOTROCA lhes solicitará
							autorização prévia. Informações que não sejam de identificação
							individual (como demográficas, por exemplo), poderão ser
							repassadas a anunciantes, fornecedores, patrocinadores e
							parceiros, com o objetivo de melhorar a qualidade dos produtos e
							serviços oferecidos pela AUTOTROCA.
						</p>
						<p>
							Aos terceiros que, porventura receberem da AUTOTROCA informações
							de qualquer natureza, sobre os internautas que acessam o seu
							Portal, cabe igualmente, a responsabilidade de zelar pelo sigilo e
							segurança de referidas informações.
						</p>
						<p>
							A AUTOTROCA se empenha expressivamente para prover segurança e
							sigilo das informações que capta. Contudo, para que as medidas
							adotadas tenham eficácia, faz-se necessário que cada
							cliente/usuário/internauta também tenha atitude responsável, sendo
							cuidadoso com os dados de sua identificação individual sempre que
							acessar a internet, informando-os somente em operações em que
							exista a proteção de dados, nunca divulgando sua identificação de
							usuário e sempre desconectando a conta do Portal AUTOTROCA tão
							logo deixe de acessá-la, principalmente se dividir o computador
							com outra(s) pessoa(s).
						</p>
						<p>
							A AUTOTROCA assegura que as informações (textos, imagens, sons
							e/ou aplicativos) contidas nos seus sites estão de acordo com a
							legislação e normativos que regulam os direitos autorais, marcas e
							patentes, não sendo permitidas modificações, cópias, reproduções
							ou quaisquer outras formas de utilização para fins comerciais sem
							o consentimento prévio e expresso da AUTOTROCA.
						</p>
						<p>
							A AUTOTROCA não se responsabiliza por eventuais danos e/ou
							problemas decorrentes da demora, interrupção ou bloqueio nas
							transmissões de dados ocorridos na Internet.
						</p>
						<ol start={10}>
							<li>Extensão dos efeitos</li>
						</ol>
						<p>
							Os termos da Política de Privacidade aqui expostos serão aplicados
							exclusivamente às informações pessoais e informações pessoais
							sensíveis, conforme acima definido, que venham a ser
							disponibilizadas à AUTOTROCA, pelo cliente/usuário/internauta,
							para a utilização de seus produtos e serviços. Por consequência, a
							Política de Privacidade aqui exposta não será aplicável a qualquer
							outro serviço online que não os disponibilizados pela AUTOTROCA,
							incluídos aqueles sites que estejam de alguma forma vinculados ao
							AUTOTROCA, por meio de links ou quaisquer outros recursos
							tecnológicos, e, ainda, a quaisquer outros sites que, de qualquer
							forma, venham a ser conhecidos ou utilizados pela AUTOTROCA.
						</p>
						<p>
							Nesse sentido, alertarmos aos usuários que os referidos sites
							podem conter política de privacidade diversa da adotada pela
							AUTOTROCA ou podem até mesmo não adotar qualquer política nesse
							sentido, não se responsabilizando, a AUTOTROCA, por qualquer
							violação aos direitos de privacidade dos usuários que venha a ser
							praticada pelos referidos sites.
						</p>
						<ol start={11}>
							<li>Interpretação dos termos</li>
						</ol>
						<p>
							As palavras e os termos constantes desta Política de Privacidade
							aqui não expressamente definidos, grafados em português ou em
							qualquer outro idioma, bem como, quaisquer outros de linguagem
							técnica e/ou financeira ou não, que, eventualmente, durante a
							vigência do presente instrumento, no cumprimento de direitos e
							obrigações assumidos por ambas as partes, sejam utilizados para
							identificar a prática de quaisquer atos, deverão ser compreendidos
							e interpretados em consonância com o conceito internacionalmente
							consagrado.
						</p>
						<ol start={12}>
							<li>Anexos</li>
						</ol>
						<p>
							A presente Política de Privacidade, para todos os fins e efeitos
							de direito, faz parte integrante e indissociável do Termo de
							Adesão da AUTOTROCA.
						</p>
						<ol start={13}>
							<li>Solicitações e Reclamações</li>
						</ol>
						<p>
							Caso você tenha alguma solicitação ou reclamação, pedimos que
							entre em contato pelo nossa Central de Atendimento. Ao entrar em
							contato, pediremos alguns dados pessoais para confirmarmos a sua
							identidade.
						</p>
						<p>
							Eventualmente, outras informações podem ser solicitadas para
							confirmar a sua identidade com o objetivo de melhor atender à sua
							solicitação ou reclamação, havendo dúvida sobre a veracidade ou
							legitimidade das informações informadas.
						</p>
						<ol start={14}>
							<li>Disposições Gerais</li>
						</ol>
						<p>
							Eventuais omissões ou meras tolerâncias das partes no exigir o
							estrito e pleno cumprimento desta Política de Privacidade e/ou de
							prerrogativas decorrentes dele ou da lei, não constituirão novação
							ou renúncia, nem afetarão o exercício de quaisquer direitos aqui
							previstos, que poderão ser plena e integralmente exercidos, a
							qualquer tempo.
						</p>
						<p>
							Caso se perceba que uma disposição é nula, as disposições
							restantes desta Política de Privacidade permanecerão em pleno
							vigor e um termo válido substituirá o termo nulo, refletindo nossa
							intenção, tanto quanto possível.
						</p>
						<p>
							<strong>Segurança e Proteção de dados transacionais</strong>
						</p>
						<p>
							As transações efetuadas para escolha da forma e efetivação do
							pagamento, independente da forma escolhida, são realizadas em
							ambiente seguro e certificado por empresa especializada em
							segurança de dados para a internet. Todas as informações
							necessárias à realização do pagamento são criptografadas.{" "}
						</p>
						<p>
							Outra medida de segurança é a não coleta do número e da data de
							validade de cartão de crédito, e de outras informações específicas
							para pagamento pela AUTOTROCA. Estes dados são informados
							diretamente para as instituições financeiras e não são armazenados
							ou utilizados para qualquer outro fim.
						</p>
						pOs negócios realizados no Portal AUTOTROCA por um usuário, bem como
						as informações associadas a estas operações, são exclusivas e só
						podem ser acessadas por este usuário.
						<p>
							<strong>Identificação do Usuário</strong>
						</p>
						<p>
							Ao se cadastrar no Portal AUTOTROCA, cada usuário recebe uma
							identificação única, identificação esta que passa a ser requerida
							e autenticada nos demais acessos ao Portal. Essa identificação,
							para os fins de direito, serve como assinatura de concordância com
							qualquer proposta realizada nesse Portal.
						</p>
						<p>
							A identificação de usuário é exclusiva, intransferível e
							criptografada para ser transmitida ao servidor da AUTOTROCA, sendo
							a senha armazenada em banco de dados na sua forma criptografada,
							de maneira irreversível.
						</p>
						<p>
							<strong>
								<u>
									A AUTOTROCA poderá confirmar os dados pessoais informados pelo
									cliente/usuário/internauta, consultando órgãos públicos,
									empresas especializadas, cadastros restritivos ou centrais de
									risco. As informações que a AUTOTROCA obtiver destas entidades
									serão tratadas de forma confidencial.
								</u>
							</strong>
						</p>
						<p>
							Sem prejuízo do disposto anteriormente, o cliente/usuário garante
							e responde pela veracidade, exatidão, vigência e autenticidade dos
							dados pessoais, e se compromete a mantê-los devidamente
							atualizados. Por consequência, a AUTOTROCA não possui qualquer
							responsabilidade no caso de inserção de dados falsos ou inexatidão
							dos dados pessoais introduzidos por outros
							clientes/usuários/internautas.
						</p>
						<p>
							<strong>Alterações na política</strong>
						</p>
						<p>
							A AUTOTROCA poderá alterar esta Política de Privacidade a qualquer
							momento, em virtude de alterações na legislação ou nos serviços,
							em decorrência da utilização de novas ferramentas tecnológicas ou,
							ainda, sempre que, a exclusivo critério da AUTOTROCA, tais
							alterações se façam necessárias, razão pela qual recomendamos a
							leitura periódica desta Política de Privacidade.
						</p>
						<p>
							<strong>
								A utilização dos serviços disponibilizados pela AUTOTROCA por
								qualquer cliente/usuário implicará em expressa aceitação quanto
								aos termos e condições da Política de Privacidade vigente na
								data de sua utilização.
							</strong>
						</p>
						<p>
							<strong>
								A AUTOTROCA recomenda aos clientes/usuários que não concordem
								com a Política de Privacidade vigente a não utilização dos
								serviços deste Portal.
							</strong>
						</p>
						<p>
							<strong>Dúvidas e sugestões</strong>
						</p>
						<p>
							Caso haja qualquer dúvida ou sugestão sobre esta Política de
							Privacidade, entre em contato conosco através do Fale Conosco.
						</p>
						<p>
							Caso se perceba que esta Política de Privacidade não esteja sendo
							cumprida, a não conformidade detectada deverá ser apontada ao
							responsável por meio do Fale Conosco.
						</p>
						<ol start={16}>
							<li>Lei aplicável e resolução de conflitos</li>
						</ol>
						<p>
							Toda e qualquer controvérsia oriunda dos termos expostos na
							presente Política de Privacidade serão solucionados de acordo com
							a lei brasileira, sendo competente o foro da cidade de Santo
							André, Estado de São Paulo, com exclusão de qualquer outro por
							mais privilegiado que seja.
						</p>
						<p>
							Fica claro, ainda, que a utilização de Serviços e as ordens
							comandadas fora do território brasileiro, ou ainda as decorrentes
							de operações iniciadas no exterior podem estar sujeitas também à
							legislação e jurisdição das autoridades dos países onde forem
							comandadas ou iniciadas.
						</p>
						<ol start={17}>
							<li>Dispositivos Móveis</li>
						</ol>
						<p>
							Para garantir uma eficiente utilização da plataforma da AUTOTROCA,
							sem que o seu uso fique prejudicado, é importante que você nos
							forneça as autorizações descritas abaixo:
						</p>
						<p>
							<strong>a) Localização</strong>
						</p>
						<p>
							Necessário para validação da cidade em que o usuário se encontra
							com o objetivo de facilitar a busca de veículos na AUTOTROCA.
						</p>
						<p>
							<strong>
								b) Acessar fotos, mídias e arquivos do seu dispositivo.
							</strong>
						</p>
						<p>
							Necessário para criação ou edição de anúncios e manutenção do
							perfil.
						</p>
						<p>
							A permissão abaixo será solicitada no momento que o cliente
							desejar inserir fotos em seu cadastro.
						</p>
						<ul>
							<li>
								<strong>Tirar fotos</strong> ou seleção da Galeria. Necessário
								para inserir fotos no cadastro.
							</li>
						</ul>
						<p style={{ textAlign: "center" }}>
							Atualização: Setembro de 2021. <br />
							Copyright © {new Date().getFullYear()}. <br />
							AUTOTROCA <br />
							Todos os direitos reservados.
						</p>
					</Privacidade>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default PrivacyPolicy;
