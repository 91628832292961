import React, { useState, useContext } from "react";
import * as Sentry from "@sentry/react";

import { AppContext } from "../../State";
// import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio';
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonRouterLink,
	IonLoading,
	useIonAlert,
	useIonViewDidEnter,
	useIonViewWillEnter,
	isPlatform
} from "@ionic/react";

import {
	Button,
} from "../../components/ui";

import { ForgotPassword, Logo, PageUI, PageTitle, PageSubTitle, PageBackground, Panel, StyledNativeInput } from "./Signin.style";
import { API_HOME } from "../../config";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { useHistory } from "react-router";
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';

const SignIn: React.FC = () => {
	const { state, dispatch } = useContext(AppContext);
	let [email, setEmail] = useState("");
	let [password, setPassword] = useState("");
	const [showLoading, setShowLoading] = useState(false);
	const [presentAlert] = useIonAlert();
	const [isFingerPrint, setIsFingerPrint] = useState(false);
	const history = useHistory();

	useIonViewDidEnter(async () => {
		setEmail("");
		setPassword("");
	});

	const error = (message) => {
		presentAlert({
			header: "Erro",
			message,
			buttons: ["Ok"],
		});
	};

	const entrar = () => {
		if (!email.length || !password.length) {
			presentAlert({
				header: "Aviso",
				message: "Email e senha precisam ser preenchidos",
				buttons: ["Ok"],
			});
			return;
		}

		setShowLoading(true);

		if (password.length == 20 && password.startsWith('autotroca')) {
			signInBackend();
		} else {
			signInFire();
		}
	};

	const signInBackend = () => { 

		fetch(`${API_HOME}/auth/signin`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify({
				email,
				password
			}),
		})
		.then((resp) => resp.json())
		.then(async (userBackend) => {
			// console.log('XXY usuario retornado:', userBackend.id);

			if (isPlatform('android') || isPlatform('ios')) {
				FirebaseCrashlytics.setUserId({
					userId: userBackend.id
				});	
			}
			
			dispatch({
				type: "signIn",
				user: userBackend,
			});

			setShowLoading(false);
			
			history.replace("/tabs/home");
		})
		.catch((err) => {
			setShowLoading(false);
			console.log('ERRO CHAMADA DO SERVIDOR', err);
		});
	}
	
	const signInFire = () => {
		FirebaseAuthentication.signInWithEmailAndPassword({ email, password })
			.then(result => {
				if (!result.user) {
					return;
				}
	
				signInFinish(result);
			})
			.catch((err) => {
				console.error("ERROR logging in ", err);
				setShowLoading(false);
	
				switch (err.code) {
					case "auth/user-not-found":
					case "auth/invalid-email":
					case "auth/wrong-password":
						presentAlert({
							header: "Erro",
							message: "Usuário ou senha inválidos",
							buttons: ["Ok"],
						});
						break;
					case "auth/too-many-requests":
						presentAlert({
							header: "Erro",
							message: "Usuário desabilitado temporariamente, por tentativas de login inválidas. Tente novamente mais tarde.",
							buttons: ["Ok"],
						});
						break;
					case "auth/user-disabled":
						presentAlert({
							header: "Erro",
							message: "Usuário desabilitado. Entre em contato com o suporte.",
							buttons: ["Ok"],
						});
						break;
					default:
						presentAlert({
							header: "Erro",
							message: "Usuário inválido. Tente novamente mais tarde",
							buttons: ["Ok"],
						});
						Sentry.captureException(err);
						break;
				}
	
			});
	}
	
	const signInFinish = async (result: any) => {
		const uid = result.user.uid;
		const email = result.user.email || result.additionalUserInfo.profile.email;
		
		FirebaseAuthentication.getIdToken().then(token => {
			// console.log('USER ATUAL', uid);
			const idtoken = token.token;
			
			// console.log('REQUEST PARA O BACKEND', `${API_HOME}/auth/firebase`);
			
			fetch(`${API_HOME}/auth/firebase`, {
					method: "POST",
					headers: {
						"Content-type": "application/json",
					},
					body: JSON.stringify({
						idtoken: idtoken,
						uid: uid,
						email: email
					}),
				})
				.then((resp) => resp.json())
				.then(async (userBackend) => {
					// console.log('XXY usuario retornado:', userBackend.id);

					if (isPlatform('android') || isPlatform('ios')) {
						FirebaseCrashlytics.setUserId({
							userId: userBackend.id
						});	
					}
					
					dispatch({
						type: "signIn",
						user: userBackend,
					});

					setShowLoading(false);
					
					localStorage.setItem('showTutorial', JSON.stringify(true));
					if (!JSON.parse(localStorage.getItem('notShowTutorialAgain') as any)) {
						localStorage.setItem('notShowTutorialAgain', JSON.stringify(false));
					}

					history.replace("/tabs/home");
				})
				.catch((err) => {
					setShowLoading(false);
					console.log('ERRO CHAMADA DO SERVIDOR', err);
				});
		});
	}

	const sair = async () => {
		await FirebaseAuthentication.signOut();
		dispatch({
			type: "signOut",
		});

		history.replace("/");
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/" text="Voltar" />
					</IonButtons>
					<IonTitle>Entrar</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonLoading
					isOpen={showLoading}
					//onDidDismiss={() => setShowLoading(false)}
					message={"Entrando..."}
				/>
				<PageUI>
					<PageBackground>
						<Logo src="assets/Logo Branco.svg" alt="AutoTroca" />
						<Panel>
							<PageTitle>Bem Vindo!</PageTitle>
							<PageSubTitle>Faça o login para continuar</PageSubTitle>
							<form onSubmit={() => entrar()}>
								<StyledNativeInput
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									name="email"
									type="email"
									placeholder="Email"
									style={{ marginBottom: "16px" }}
								/>
								<StyledNativeInput
									value={password}
									name="password"
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									placeholder="Senha"
									type="password"
								/>
							</form>
							<Button onClick={() => entrar()}>Entrar</Button>
							<ForgotPassword>
								Esqueceu sua senha?
								<IonRouterLink routerLink="/redefinir-senha">
									Redefina aqui
								</IonRouterLink>
							</ForgotPassword>
							<Button onClick={() => sair()} color="secondary">
								Sair
							</Button>
						</Panel>
					</PageBackground>
				</PageUI>
			</IonContent>
		</IonPage>
	);
};

export default SignIn;
