import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonButton,
} from "@ionic/react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";

const Privacidade = styled.div`
	& ol,
	& ol li {
		list-style-position: inside;
		padding: 0;
		font-weight: 800;
	}

	& ol.indent {
		margin-left: 1rem;
	}

	& ol.indent li {
		margin-bottom: 0.5rem;
	}

	& ol.font-normal li {
		font-weight: 400 !important;
	}
`;

const PrivacyPolicy: React.FC = () => {

	const history = useHistory();
	const back = () => {
		// console.log('back');
		
		history.goBack();
	}

	return (
		<IonPage>
			<IonContent fullscreen>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={() => history.push('/tabs/vote')}>
								<img src="assets/backArrow.svg" />
							</IonButton>
						</IonButtons>
						<IonTitle>Regras</IonTitle>
					</IonToolbar>
				</IonHeader>
				<div style={{ padding: "0 2rem" }}>
					<Privacidade>
						<h1>Campanha Project Car BR</h1>
						{/* <p>Bem-vindo à Política de Privacidade da AUTOTROCA. </p> */}
						<ul>
								<li>Para candidatar-se à troca de carros da campanha "Project Car Brasil" é necessário adquidir o Plano Premium Autotroca e dar SuperLike (estrelinha azul) no veículo participante.</li>
								<li>O carro será escolhido pelos próprios usuários através de enquete aberta no app Autotroca, com inicio e duração divulgadas no canal Project Car Brasil.</li>
								<li>As exigências do veículo para participação são: documentação totalmente em dia e boa mecânica em condições de rodar. Caso o veículo vencedor não cumpra tais diretrizes, será escolhido o próximo mais votado e assim sucessivamente.</li>
								<li>Podem participar usuários de todo o Brasil ficando estabelecido que, cada parte (usuário e Autotroca), arcarão com os custos do envio de seus respectivos veículos ao seu novo destino. Dependendo da distância, negociações futuras de encontro pessoal poderão serem feitas.</li>
								<li>Carros que possuam valor de mercado inferior ao da campanha, serão trocados sem qualquer desembolso da diferença por conta do ganhador. Para carros de valor superior, deverá haver negociação entre as partes.</li>
							</ul>
					</Privacidade>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default PrivacyPolicy;
