import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
} from "@ionic/react";

import styled from "styled-components";

const Suporte = styled.div`
	& ol,
	& ol li {
		list-style-position: inside;
		padding: 0;
		font-weight: 800;
	}

	& ol.indent {
		margin-left: 1rem;
	}

	& ol.indent li {
		margin-bottom: 0.5rem;
	}

	& ol.font-normal li {
		font-weight: 400 !important;
	}
`;

const Support: React.FC = () => {
	return (
		<IonPage>
			<IonContent fullscreen>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/tabs/home" text="Voltar" />
						</IonButtons>
						<IonTitle>Contato</IonTitle>
					</IonToolbar>
				</IonHeader>
				<div style={{ padding: "0 2rem" }}>
					<Suporte>
						<p>
                        Dúvidas, sugestões ou problemas com sua conta: <a href="mailto:suporte@autotroca.com.br">suporte@autotroca.com.br</a>
                        </p>
					
						<p>
                        Suporte financeiro ou problemas com seu Plano Premium: <a href="mailto:premium@autotroca.com.br">premium@autotroca.com.br</a>
                        </p>

						<p>
						Acessoria de imprensa: <a href="mailto:imprensa@autotroca.com.br">imprensa@autotroca.com.br</a>
						</p>

						<p>
							Whatsapp: <a href="https://api.whatsapp.com/send?phone=5511914022855&text=Olá" target="_blank">+55 11 91402 2855</a>
						</p>
					</Suporte>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Support;
