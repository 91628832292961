import { API_HOME } from "../config";
import * as Sentry from "@sentry/react";

export const getCarsService = async (user) => {
  try {
    // console.log("AppShell will load cars");
    const cars = await fetch(`${API_HOME}/car`, {
      headers: {
        authorization: `Bearer ${user.token}`,
      },
    });

    return cars.json();
  } catch (err) {
    Sentry.captureException(err);
  }
};
