//https://css-tricks.com/finger-friendly-numerical-inputs-with-inputmode/
import React, { useState, useEffect, useContext } from "react";
import { validate as validateCpf } from "gerador-validador-cpf";
import removeAccents from "remove-accents";
import * as Sentry from "@sentry/react";
import * as EmailValidator from "email-validator";
import { downloadOutline } from "ionicons/icons";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonLoading,
	IonRouterLink,
	useIonAlert,
	IonIcon,
	useIonViewDidEnter,
	IonModal,
	IonButton
} from "@ionic/react";


import { API_HOME, MEDIA_HOME } from "../../config";
import { AppContext } from "../../State";
import { Checkbox, ActionButton } from "../../common/input";
import SearchableSelect from "../../common/SearchableSelect";
import { estados } from "../../common/estados";
import { municipios as municipiosDB } from "../../common/municipios";
import { birthDateMask, cpfMask, phoneNumberMask } from "../../common/masks";
import { PageUI, PageBackground, Panel, PageSubTitle, PageTitle } from "../../components/ui";
import ProfilePictureUploader from "../../components/ProfilePictureUploader";
import { BackButton, Buttons, SignUpButton, StyledInput, StyledSelect } from "./SignUp.style";
import { useHistory } from "react-router";

const Field = ({
	placeholder,
	value,
	update,
	values,
	name,
	selectedState,
	autoComplete,
	type = "text",
}: any) => {
	const [focused, setFocused] = useState(true);

	let inputType = "text";

	if (type === "password") {
		inputType = "password";
	}

	if (type === "email") {
		inputType = "email";
	}

	return (
		<>
			<StyledInput
				type={inputType}
				value={value}
				name={name || undefined}
				required={type === "email" || undefined}
				autoComplete={autoComplete || undefined}
				inputMode={
					["phone_number", "birthdate", "cpf"].includes(type)
						? "numeric"
						: "text"
				}
				pattern={
					["phone_number", "birthdate", "cpf"].includes(type)
						? "[0-9]"
						: undefined
				}
				onFocus={() => {

					setFocused(true);
				}}
				onBlur={() => {

				}}
				onChange={(e) => {
					if (type === "cpf") {
						update(cpfMask(e.target.value));
					} else if (type === "birthdate") {
						update(birthDateMask(e.target.value));
					} else if (type === "phone_number") {
						update(phoneNumberMask(e.target.value));
					} else {
						update(e.target.value);
					}
				}}
				placeholder={placeholder}
			/>

		</>
	);
};

const SignUp: React.FC = () => {
	const history = useHistory();
	const [name, setName] = useState("");
	const [last_name, setLastName] = useState("");
	const [cpf, setCpf] = useState("");
	const [birthdate, setBirthdate] = useState("");
	const [city, setCity] = useState(0);
	const [state, setState] = useState(0);
	const [phone_number, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [confirmEmail, setConfirmEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [referal, setReferal] = useState("");
	const [referalOther, setReferalOther] = useState("");
	const [image, setImage] = useState("");
	const [showLoading, setShowLoading] = useState(false);
	const [presentAlert] = useIonAlert();
	const { state: appState, dispatch } = useContext(AppContext);
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [notRetailer, setNotRetailer] = useState(false);
	const [awaitingVerification, setAwaitingVerification] = useState(
		(window as any).location.href.includes("verifyTest")
	);
	const [showModal, setShowModal] = useState(false);
	const [appWidth, setAppWidth] = useState(0);
	const [appHeight, setAppHeight] = useState(0);

	const FACEBOOK_PERMISSIONS = ['email', 'public_profile'];

	const sortedMunicipios = municipiosDB.sort((a, b) => {
		if (a.capital === 1 && b.capital === 0) return -1;
		if (removeAccents(a.nome) < removeAccents(b.nome)) {
			return -1;
		}
		if (removeAccents(a.nome) > removeAccents(b.nome)) {
			return 1;
		}
		return 0;
	});


	const filteredGeoCities = sortedMunicipios.filter((municipio, i) => {
		return municipio.codigo_uf === state;
	});

	useIonViewDidEnter(() => {
		initializeForm();
	});

	const initializeForm = () => {
		if (!appState?.signUpForm) return;
		const form = appState?.signUpForm;
		// console.log("initiallizing form with this state", form);
		setName(form?.name || "");
		setLastName(form?.last_name || "");
		setCpf(form?.cpf || "");
		setBirthdate(form?.birthdate || "");
		setCity(parseInt(form?.city) || 0);
		setState(parseInt(form?.state) || 0);
		setPhoneNumber(form?.phone_number || "");
		setEmail(form?.email || "");
		setPassword(form?.password || "");
		setPasswordConfirm(form?.passwordConfirm || "");
		setReferal(form?.referal || "");
		setReferalOther(form?.referalOther || "");
		setTermsAccepted(form?.termsAccepted || false);
		setNotRetailer(form?.notRetailer || false);
	};

	useEffect(() => {
		const formData = {
			name,
			last_name,
			cpf,
			birthdate,
			city,
			state,
			phone_number,
			email,
			password,
			passwordConfirm,
			referal,
			referalOther,
			image,
			termsAccepted,
			notRetailer
		};

		// console.log("sign up form changed to", formData);

		dispatch({
			type: "signUpFormChanged",
			formData
		});


	}, [
		name,
		last_name,
		cpf,
		birthdate,
		city,
		state,
		phone_number,
		email,
		password,
		passwordConfirm,
		referal,
		referalOther,
		image,
		termsAccepted,
		notRetailer
	]);

	useEffect(() => {
		// console.log("App state is now", appState);
	}, [appState]);

	useEffect(() => {
		setTimeout(() => {
			const x = (window as any).document.querySelector("body").offsetWidth;
			const y = (window as any).document.querySelector("body").offsetHeight;

			setAppWidth(x);
			setAppHeight(y);

			// console.log("Setting app bounds to ", x, y);
		}, 200);
	}, []);

	useEffect(() => {
		//setCity(0);
	}, [state]);

	const error = (message) => {
		presentAlert({
			header: "Erro",
			message,
			buttons: ["Ok"],
		});
	};

	const uploadPicture = (image, tokenProp) => {
		// console.log("uploading picture, appState is", appState);

		var formData = new FormData();
		formData.append("image", image, image.name);

		fetch(`${API_HOME}/user/picture`, {
			method: "POST",
			headers: {
				authorization: `Bearer ${tokenProp}`,
			},
			body: formData,
		})
			.then((resp) => resp.json())
			.then((resp) => {
				// console.log("upload responded with", resp);

				setShowLoading(false);
				setAwaitingVerification(true);

				presentAlert({
					header: "Cadastro realizado com sucesso",
					message: "Enviamos um link de verificação no seu email. ",
					buttons: ["Ok"],
				});

				if (resp.success) {
					dispatch({
						type: "setProfilePicture",
						profile_picture: `${MEDIA_HOME}/${resp.file.path
							.replace("\\\\", "\\")
							.replace(/\\/g, "/")
							.replace("images/", "")}`,
					});

					//history.replace("/tabs/home");
				} else {
					Sentry.captureException(
						new Error("Falha ao salvar a foto de perfil")
					);
					//error("Falha ao salvar a foto de perfil");
				}
			})
			.catch((err) => {
				console.error("upload errored with", err);
				error(`Falha ao subir a imagem ${JSON.stringify(err)}`);
				setShowLoading(false);
				Sentry.captureException(err);
			});
	};

	const signup = () => {
		if (
			!name.length ||
			!cpf.length ||
			!birthdate.length ||
			city === 0 ||
			state === 0 ||
			!phone_number.length ||
			!email.length ||
			!password.length ||
			!passwordConfirm.length
		) {
			return error("Todos os campos precisam ser preenchidos");
		}

		if (email !== confirmEmail) {
			return error(`O campo "Confirme o email" deve ser igual o campo "Email"`);
		}

		const [fullName, first_name, last_name] =
			removeAccents(name).match(/([a-zA-Z]+) (.*)/) || [];

		if (!notRetailer || !termsAccepted) {
			return error("É necessário aceitar nossos termos e condições.");
		}

		if (!first_name || !last_name) {
			return error("É necessário preencher o nome completo");
		}

		if (!image) {
			return error("Uma foto de perfil precisa ser selecionada");
		}

		if (!new RegExp(/^\d{2}\/\d{2}\/\d{4}$/).test(birthdate)) {
			return error("A data de nascimento precisa seguir o formato dd/mm/aaaa");
		}

		if (!validateCpf(cpf)) {
			return error("CPF inválido");
		}

		if (phone_number.replace(/\D/g, "").length !== 11) {
			return error("Número de celular inválido");
		}

		if (!EmailValidator.validate(email)) {
			return error("Email inválido");
		}

		if (passwordConfirm !== password) {
			return error("Senha e confirmação não equivalem");
		}

		const [allBirthdateGroups, day, month, year] =
			birthdate.match(/^(\d{2})\/(\d{2})\/(\d{4})$/) || [];

		const payload = {
			first_name,
			last_name,
			cpf: cpf.replace(/\D/g, ""),
			birthdate: `${year}/${month}/${day}`,
			city: municipiosDB.find(
				(municipio) =>
					municipio.codigo_ibge === city && municipio.codigo_uf === state
			)?.codigo_ibge,
			state,
			phone_number: phone_number.replace(/\D/g, ""),
			email,
			password,
			referal,
			referalOther,
		};

		setShowLoading(true);

		fetch(`${API_HOME}/user`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify(payload),
		})
			.then((resp) => resp.json())
			.then((resp) => {
				setShowLoading(false);
				if (resp.error) {
					Sentry.captureException(new Error(resp.error));
					// console.error("Server errored with", resp.error);
					error(resp.error.message || resp.error);
				} else {
					// console.log("Server successed with", resp);
					dispatch({
						type: "signUpFormChanged",
						formData: {}
					});

					if (resp.token) {
						uploadPicture(image, resp.token);
					}
				}
			})
			.catch((err) => {
				Sentry.captureException(err);
				console.error("Server errored with", err);
				error(
					"Houve um erro ao conectar com o servidor" + " " + JSON.stringify(err)
				);
				setShowLoading(false);
			});

		// console.log(payload);
		/*;
		setTimeout(() => {
			;
		}, 1000);*/
	};

	const queryParams = new URLSearchParams(window.location.search);
	const key = queryParams.get("key");

	useEffect(() => {
		if (key) {
			setShowLoading(true);
			fetch(`${API_HOME}/user/verify?key=${key}`)
				.then((resp) => resp.json())
				.then((resp) => {
					setShowLoading(false);
					if (resp.token) {
						presentAlert({
							header: "Tudo pronto!",
							message: "Seu cadastro foi verificado com sucesso.",
							buttons: ["Ok"],
							onDidDismiss: () => {
								dispatch({
									type: "signIn",
									user: resp,
								});
								
								history.replace("/tabs/home");
							},
						});
					} else {
						// console.log("Unexpected response from server: ", resp);
						error(
							"Não foi possível validar a sua requisição. Token já verificado ou expirado."
						);
					}

					// console.log("resp from server was", resp);
					setShowLoading(false);
				})
				.catch((err) => {
					setShowLoading(false);
					Sentry.captureException(err);
					error("Falha ao verificar.");
				});
		}
	}, []);

	const Form = (
		<>
			<PageSubTitle>Preencha os campos abaixo: </PageSubTitle>
			<form autoComplete="att-ac">
				<Field

					autoComplete="att-nome2"
					value={name}
					update={setName}
					placeholder="Nome Completo"
				/>
				<Field
					type="cpf"
					name="cpf"
					autoComplete="off"
					value={cpf}
					update={setCpf}
					placeholder="CPF"
				/>
				<Field
					name="birthdate"
					value={birthdate}
					type="birthdate"
					update={setBirthdate}
					placeholder="Data de Nascimento"
				/>
				<SearchableSelect
					placeholder="Estado"
					values={estados}
					value={state}
					labelKey="uf"
					onInvalidMessage="Você precisa selecionar um estado"
					onSelect={(value) => {
						// console.log("onSelect for geoState received", value);
						setState(value?.codigo_uf);
					}}
				/>
				{state !== 0 && (

					<SearchableSelect
						placeholder="Cidade"
						value={city}
						values={filteredGeoCities}
						labelKey="nome"
						onInvalidMessage="Você precisa selecionar uma cidade"
						onSelect={(value) => {
							// console.log("onSelect for make received", value);
							setCity(value?.codigo_ibge);
						}}
					/>
				)}
				<Field
					name="phone_number"
					value={phone_number}
					autoComplete="att-phone"
					type="phone_number"
					update={setPhoneNumber}
					placeholder="Celular com DDD"
				/>
				{/*<Field placeholder="Anexar Foto Perfil" />*/}
				<Field
					name="email"
					value={email}
					autoComplete="att-aroba"
					type="email"
					update={setEmail}
					placeholder="Email"
				/>
				<Field
					name="confirmEmail"
					value={confirmEmail}
					autoComplete="att-aroba"
					type="email"
					update={setConfirmEmail}
					placeholder="Confirme o email"
				/>
				<Field
					name="password"
					type="password"
					value={password}
					update={setPassword}
					placeholder="Senha"
				/>
				<Field
					name="password_confirm"
					type="password"
					value={passwordConfirm}
					update={setPasswordConfirm}
					placeholder="Confirmar Senha"
				/>

				<StyledSelect
					value={referal}
					onChange={(e) => setReferal(e.target.value)}
				>
					<option value="0">Como nos conheceu?</option>
					<option value="10">YouTube</option>
					<option value="11">Instagram</option>
					<option value="2">Facebook</option>
					<option value="12">Google</option>
					<option value="14">LinkedIn</option>
					<option value="3">AGBabolato</option>
					<option value="5">Opinião Sincera</option>
					<option value="4">Nalata Driver</option>
					<option value="6">Project Car BR</option>
					<option value="7">Insta Verde TV</option>
					<option value="9">89FM</option>
					<option value="8">Carro com Tudo</option>
					<option value="1">Indicação</option>
					<option value="13">Outro - Escreva</option>
				</StyledSelect>
				{referal === "13" && (
					<Field
						name="referal-other"
						type="text"
						value={referalOther}
						update={setReferalOther}
						placeholder="Como nos conheceu"
					/>
				)}

				<ProfilePictureUploader
					onSelect={(image) => {
						// console.log("onSelect received", image);
						setImage(image);
					}}
				/>

				<Checkbox
					isDisabled={false}
					name="termsAccept"
					checked={termsAccepted}
					label={
						<span>
							Li e aceito os{" "}
							<IonRouterLink routerLink="/termos">
								Termos e Condições
							</IonRouterLink>
							.
						</span>
					}
					onChange={() => {
						setTermsAccepted(!termsAccepted);
					}}
				/>

				<Checkbox
					name="notRetailer"
					checked={notRetailer}
					label={
						<span>
							Declaro não ter relação com qualquer tipo de comércio de veículo.
						</span>
					}
					onChange={() => {
						setNotRetailer(!notRetailer);
					}}
					isDisabled={false}
				/>

				<Buttons>
					<BackButton routerLink="/" className="backButton">
						<img src="assets/backArrow.svg" />
					</BackButton>
					<SignUpButton onClick={signup} className="signupButton">
						Cadastrar
					</SignUpButton>
				</Buttons>
			</form>
		</>
	);

	const AwaitingVerification = (
		<div
			className="verificationDiv"
			style={{ marginTop: "2rem", textAlign: "center" }}
		>
			Verifique seu email para prosseguir. <p>Alguns provedores podem colocá-lo na pasta <i>spam</i>.</p>
		</div>
	);

	const Verify = () => {
		return null;
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/" text="Voltar" />
					</IonButtons>
					<IonTitle>Cadastrar</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<PageUI>
					<PageBackground style={{ paddingTop: "1rem" }}>
						<Panel>
							<PageTitle>Cadastrar-se</PageTitle>
							{key ? (
								<Verify />
							) : awaitingVerification ? (
								AwaitingVerification
							) : (
								Form
							)}
						</Panel>
					</PageBackground>
				</PageUI>
				<IonLoading
					isOpen={showLoading}
					onDidDismiss={() => setShowLoading(false)}
					message={key ? "Verificando..." : "Cadastrando..."}
				/>
			</IonContent>

		</IonPage>
	);
};

export default SignUp;
