import { Route } from "react-router-dom";
import { IonApp, IonIcon, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import AppShell from "./AppShell";
import AppTabs from "./components/AppTabs";
import Menu from "./components/Menu";
import Landing from "./pages/landing/Landing";
import ForgotPassword from "./pages/ForgotPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import VotePolicy from "./pages/VotePolicy";
import Support from "./pages/Support";
import ServiceTerms from "./pages/ServiceTerms";
import SignUp from "./pages/signup/SignUp";
import SignIn from "./pages/signin/SignIn";
import EditProfile from "./pages/profile/EditProfile";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./global.css";

import { AppContextProvider } from "./State";
import PrivateRoute from "./PrivateRoute";

const App: React.FC = () => {
	return (
		<AppContextProvider>
			<IonApp>
				<IonReactRouter>
					<AppShell>
						<Menu />
						<IonRouterOutlet id="main-router-outlet" animated={true} mode="ios">
							<Route exact path="/" component={Landing} />
							<Route path="/redefinir-senha" component={ForgotPassword} />
							<Route path="/politica-privacidade" component={PrivacyPolicy} />
							<Route path="/vote-policy" component={VotePolicy} />
							<Route path="/support" component={Support} />
							<Route path="/termos" component={ServiceTerms} />
							<Route path="/cadastrar" component={SignUp} />
							<Route path="/entrar" component={SignIn} />
							<PrivateRoute path="/edit-profile" component={EditProfile} />
							<PrivateRoute path="/tabs" render={() => <AppTabs />} />
						</IonRouterOutlet>
					</AppShell>
				</IonReactRouter>
			</IonApp>
		</AppContextProvider>
	);
};

export default App;
