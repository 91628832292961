import React, { useRef, useState, useEffect, useContext } from "react";
import { AppContext } from "../../State";
import { API_HOME, mileageFactor, priceFactor } from "../../config";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  formatCurrency,
  formatMileage,
} from "../../common/util";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonRouterLink,
  useIonAlert,
  IonLoading,
  IonBackButton,
  IonFooter
} from "@ionic/react";

import {
  PageUI,
  PageBackground,
  Panel,
  PageSubTitle,
} from "../../components/ui";

import { menuController } from "@ionic/core";
import SearchableSelect from "../../common/SearchableSelect";
import { estados } from "../../common/estados";
import { municipios as municipiosDB } from "../../common/municipios";
import { Checkbox } from "../../common/input";
import styled, { css } from "styled-components";
import removeAccents from "remove-accents";
import VersionSelect from "../../common/VersionSelect ";

const sortedMunicipios = municipiosDB.sort((a, b) => {
  if (a.capital === 1 && b.capital === 0) return -1;
  if (removeAccents(a.nome) < removeAccents(b.nome)) {
    return -1;
  }
  if (removeAccents(a.nome) > removeAccents(b.nome)) {
    return 1;
  }
  return 0;
});

const Buttons = styled.div`
  padding: 1rem 0;
  display: flex;
`;

const BackButton = styled(IonRouterLink)`
  display: flex;
  width: 60px;
  height: 60px;
  background-color: #ededed;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  & img {
    width: 29px;
    height: 14px;
  }
`;

const ActionButton = styled(IonRouterLink)`
  font-weight: 800;
  cursor: pointer;
  font-size: 18px;
  padding: 18px 0px;
  flex: 1;
  text-align: center;
  background: #d63d2f;
  border-radius: 30px;
  color: #fff;
  display: block;
  text-transform: uppercase;

  margin: 10px 20px 10px 20px;
`;

const InputBox = styled.div`
  background-color: #f5f5f5 !important;
  color: #6b6b6b !important;
  border-radius: 15px !important;
  padding: 1rem !important;
  margin-bottom: 6px;
`;

const FilterInputBox = styled(InputBox)`
  background-color: #fafafa !important;
  color: #6b6b6b !important;
  border-radius: 15px !important;
  padding: 1rem !important;
  margin-bottom: 6px;
`;

const Input = styled.input`
  background-color: #f5f5f5 !important;
  color: #6b6b6b !important;
  border-radius: 10px !important;
  padding-left: 1rem !important;
  margin-bottom: 6px;
  width: 100%;
  border: none;
  outline: none;
  padding-top: 10px;
  padding-bottom: 10px;

  &::placeholder {
    color: #b5b5b5;
  }
`;

const Select = styled.select`
  background-color: #f5f5f5 !important;
  color: #6b6b6b !important;
  border-radius: 10px !important;
  padding-left: 1rem !important;
  margin-bottom: 6px;
  width: 100%;
  border: none;
  outline: none;
  padding-top: 10px;
  padding-bottom: 10px;

  &::placeholder {
    color: #b5b5b5;
  }
`;

const UploadedThumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & img {
    width: 50px !important;
    height: 50px;
    object-fit: cover;
    margin: 1rem;
    border-radius: 20%;
  }
`;

const Row = styled.div`
  display: flex;
`;

const Col = styled.div`
  &:first-child {
    width: 6rem;
    margin-right: 1rem;
  }

  &:last-child {
    width: calc(68%);
  }
`;

const HalfCol = styled.div`
  width: 50%;
  &:first-child {
    width: calc(50% - 1rem);
    margin-right: 1rem;
  }
`;

const EditDesiredCar: any = ({ match, fromAdd }: any) => {
  const { state, dispatch } = useContext(AppContext);
  const { user, cars } = state;

  const car = cars.find((c) => c.id === parseInt(match.params.id));
  const desiredCar = car?.desiredCar;

  // console.log("Editing this desired car: ", desiredCar);

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const [geoState, setGeoState] = useState(desiredCar?.state);
  const [geoCity, setGeoCity] = useState(desiredCar?.city);

  const [makesList, setMakesList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [startingYear, setStartingYear] = useState<any>(0);
  const [maxYear, setMaxYear] = useState<any>(0);
  const [mileage, setMileage] = useState(desiredCar?.mileage || 0);
  const [mileageEntered, setMileageEntered] = useState(
    desiredCar?.mileage ? true : false
  );
  const [priceRange, setPriceRange] = useState(desiredCar?.price_range || 0);
  const [priceEntered, setPriceEntered] = useState(
    desiredCar?.priceEntered ? true : false
  );
  const [vehicleType, setVehicleType] = useState(null);
  const [shift, setShift] = useState(desiredCar?.shift_type || 1);
  const [fuel, setFuel] = useState(desiredCar?.fuel_type || 1);
  const [body, setBody] = useState(desiredCar?.body_type || []);
  const [version, setVersion] = useState();
  const [versionList, setVersionList] = useState([]);
  const [category, setCategory] = useState(desiredCar?.category || undefined);
  const [airConditioning, setAirConditioning] = useState(
    desiredCar?.carOptionals?.find((opt) => opt.optional === 1) ? true : false
  );
  const [assistedDriving, setAssistedDriving] = useState(
    desiredCar?.carOptionals?.find((opt) => opt.optional === 2) ? true : false
  );
  const [airbag, setAirbag] = useState(
    desiredCar?.carOptionals?.find((opt) => opt.optional === 3) ? true : false
  );
  const [abs, setAbs] = useState(
    desiredCar?.carOptionals?.find((opt) => opt.optional === 4) ? true : false
  );
  const [versionType, setVersionType] = useState(desiredCar?.carModel?.id);
  // console.log(desiredCar);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [motorcycleMakesList, setMotorcycleMakesList] = useState([]);
  const [motorcycleModelsList, setMotorcycleModelsList] = useState([]);
  const [motorcycleMake, setMotorcycleMake] = useState(
    desiredCar?.carModel?.carMake?.id
  );
  const [make, setMake] = useState(desiredCar?.carMake?.id);
  const [model, setModel] = useState(desiredCar?.carModel?.id);
  const [images, setImages] = useState({});

  const [showLoading, setShowLoading] = useState(false);

  const [presentAlert] = useIonAlert();
  
  const [showWelcome, setShowWelcome] = useState(
    fromAdd
  );
  const [hatch, setHatch] = useState(
    desiredCar?.desiredbodytypes?.find((opt) => opt.bodytypeid === 1)
      ? true
      : false
  );
  const [sedan, setSedan] = useState(
    desiredCar?.desiredbodytypes?.find((opt) => opt.bodytypeid === 2)
      ? true
      : false
  );
  const [SUV, setSUV] = useState(
    desiredCar?.desiredbodytypes?.find((opt) => opt.bodytypeid === 3)
      ? true
      : false
  );
  const [perua, setPerua] = useState(
    desiredCar?.desiredbodytypes?.find((opt) => opt.bodytypeid === 4)
      ? true
      : false
  );
  const [pickup, setPickup] = useState(
    desiredCar?.desiredbodytypes?.find((opt) => opt.bodytypeid === 5)
      ? true
      : false
  );
  const [utilitario, setUtilitario] = useState(
    desiredCar?.desiredbodytypes?.find((opt) => opt.bodytypeid === 6)
      ? true
      : false
  );
  const [coupe, setCoupe] = useState(
    desiredCar?.desiredbodytypes?.find((opt) => opt.bodytypeid === 7)
      ? true
      : false
  );
  const [conversivel, setConversivel] = useState(
    desiredCar?.desiredbodytypes?.find((opt) => opt.bodytypeid === 8)
      ? true
      : false
  );
  const [minivan, setMinivan] = useState(
    desiredCar?.desiredbodytypes?.find((opt) => opt.bodytypeid === 9)
      ? true
      : false
  );
  /* filters begin */
  const [filterMakeModel, setFilterMakeModel] = useState(
    desiredCar?.carMake?.id || desiredCar?.carModel?.id ? true : false
  );
  const [filterGeo, setFilterGeo] = useState(
    desiredCar?.state || desiredCar?.city ? true : false
  );
  const [filterMileage, setFilterMileage] = useState(
    desiredCar?.mileage ? true : false
  );
  const [filterOptionals, setFilterOptionals] = useState(
    desiredCar?.carOptionals?.length > 0 ? true : false
  );
  const [filterShift, setFilterShift] = useState(
    desiredCar?.shift_type ? true : false
  );
  const [filterFuel, setFilterFuel] = useState(
    desiredCar?.fuel_type ? true : false
  );
  const [filterBody, setFilterBody] = useState(
    desiredCar?.body_type ? true : false
  );
  const [filterCategory, setFilterCategory] = useState(
    desiredCar?.category ? true : false
  );

  const handlesetFilterCategory = () => {
    setFilterCategory(!filterCategory);
    setCategory(undefined);
  }

  /* filters end */
  useEffect(() => {
    setMake(desiredCar?.carMake?.id || null);
    setModel(desiredCar?.carModel?.id || null);
    setVersionType(desiredCar?.version || null);
  }, [vehicleType]);

  useEffect(() => {
    // console.log("Loading makes");

    fetch(`${API_HOME}/car/makes`, {
      headers: {
        authorization: `Bearer ${state.user.token}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data?.length) {
          data.push({ id: 999999, name: "Outra" });
          setMakesList(data);
          // console.log("Loaded this list of makes", data);
        }
      })
      .catch((err) => {
        console.error("Error fetching car makes", err);
        Sentry.captureException(err);
      });
    fetch(`${API_HOME}/car/motomakes`, {})
      .then((resp) => resp.json())
      .then((data) => {
        data.push({ id: 999999, name: "Outro" });
        setMotorcycleMakesList(data);
        // console.log(
        //   "For the make",
        //   make,
        //   "We loaded this list of models",
        //   data
        // );
      })
      .catch((err) => {
        console.error("Error fetching car models", err);
        Sentry.captureException(err);
      });
  }, []);

  useEffect(() => {
    if (!make) {
      setModel(null);
      setVersionType(null);
    }

    fetch(`${API_HOME}/car/models/${make}`, {
      headers: {
        authorization: `Bearer ${state.user.token}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data?.length) {
          data.push({ id: 999999, name: "Outro" });
          setModelsList(data);
          // console.log(
          //   "For the make",
          //   make,
          //   "We loaded this list of models",
          //   data
          // );
        }
      })
      .catch((err) => {
        console.error("Error fetching car models", err);
        Sentry.captureException(err);
      });
  }, [make]);

  useEffect(() => {
    if (!make) {
      setModel(null);
    }
    if (model) {
      fetch(`${API_HOME}/car/versions/${model}?make=${make}`, {
        headers: {
          authorization: `Bearer ${state.user.token}`,
        },
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data?.length) {
            data.push({ id: 999999, name: "Outro" });
            setVersionList(data);
            // console.log(
            //   "For the mdel",
            //   make,
            //   "We loaded this list of versions",
            //   data
            // );
          }
        })
        .catch((err) => {
          console.error("Error fetching car models", err);
          Sentry.captureException(err);
        });
    }
  }, [model]);

  const updateDesiredCar = async () => {
    const filters = {};

    filters["starting_year"] = startingYear;
    filters["max_year"] = maxYear;
    filters["price_range"] = priceRange;

    if (!filterGeo) {
      filters["state"] = null;
      filters["city"] = null;
    } else {
      filters["state"] = !isNaN(geoState) ? geoState : null;
      filters["city"] = !isNaN(geoCity) ? geoCity : null;
    }

    if (filterMakeModel) {
      const invalid: string[] = [];

      if (!make) {
        invalid.push("Marca");
      }

      if (invalid.length) {
        return error(
          `${invalid.join(" e ")} precisa${
            invalid.length > 1 ? "m" : ""
          } ser informado${invalid.length > 1 ? "s" : ""} para este filtro`
        );
      }

      filters["make"] = vehicleType == 2 ? motorcycleMake : make;

      filters["model"] = model;
      filters["version"] = versionType;
    } else {
      filters["make"] = null;
      filters["model"] = null;
    }

    filters["mileage"] = mileage;
    filters["shift_type"] = filterShift ? shift : null;
    filters["fuel_type"] = filterFuel ? fuel : null;
    //filters["body_type"] = filterBody ? body : null;
    filters["category"] = filterCategory && category ? category : null;
    filters["vehicleType"] = vehicleType ? vehicleType : null;
    const optionals: number[] = [];

    if (airConditioning) {
      optionals.push(1);
    }

    if (assistedDriving) {
      optionals.push(2);
    }

    if (airbag) {
      optionals.push(3);
    }

    if (abs) {
      optionals.push(4);
    }

    if (optionals.length) {
      filters["optionals"] = optionals;
    }

    const bodyTypes: number[] = [];

    if (hatch) {
      bodyTypes.push(1);
    }

    if (sedan) {
      bodyTypes.push(2);
    }
    if (SUV) {
      bodyTypes.push(3);
    }
    if (perua) {
      bodyTypes.push(4);
    }

    if (pickup) {
      bodyTypes.push(5);
    }

    if (utilitario) {
      bodyTypes.push(6);
    }
    if (conversivel) {
      bodyTypes.push(7);
    }

    if (coupe) {
      bodyTypes.push(8);
    }

    if (minivan) {
      bodyTypes.push(9);
    }

    if (bodyTypes.length) {
      filters["body_type"] = bodyTypes;
    }
    // console.log("filters", filters);
    if (Object.keys(filters).length > 0) {
      try {
        setShowLoading(true);
        const req = await fetch(`${API_HOME}/car/${match.params.id}/desired`, {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            authorization: `Bearer ${state.user.token}`,
          },
          body: JSON.stringify(filters),
        });

        const resp = await req.json();

        setShowLoading(false);
        const body_type = bodyTypes;
        if (resp.error) {
          Sentry.captureException(new Error(resp.error));
          return error(resp.error);
        } else {
          resp.body_type = body_type;

          // console.log(resp);
          dispatch({
            type: "setDesiredCar",
            ownedCarId: parseInt(match.params.id),
            desiredCar: resp,
          });

          await dispatch({
            type: "setSelectedDesiredCar",
            desiredCar: resp,
          });
          await dispatch({
            type: "setSelectedCar",
            selectedCar: resp.carId,
          });

          history.replace("/tabs/cars");
          history.push(`/tabs/home`, [resp]);
        }
      } catch (err) {
        setShowLoading(false);
        console.error("Err was: ", err);
      }
    } else {
      return error("Você precisa selecionar no mínimo um filtro");
    }
  };

  const clearForm = () => {
    setGeoState(desiredCar?.state);
    setGeoCity(desiredCar?.city);
    setMake(desiredCar?.carMake?.id);
    setModel(desiredCar?.carModel?.id);
    setStartingYear(desiredCar?.starting_year || 0);
    setMaxYear(desiredCar?.max_year || 0);
    setMileage(desiredCar?.mileage || 0);
    setPriceRange(desiredCar?.price_range || 0);
    setShift(desiredCar?.shift_type || 1);
    setFuel(desiredCar?.fuel_type || 1);
    setBody(desiredCar?.body_type || 1);
    setCategory(desiredCar?.category || undefined);
    setVehicleType(desiredCar?.vehicleType || 1);

    setAirConditioning(
      desiredCar?.carOptionals?.find((opt) => opt.optional === 1) ? true : false
    );
    setAssistedDriving(
      desiredCar?.carOptionals?.find((opt) => opt.optional === 2) ? true : false
    );
    setAirbag(
      desiredCar?.carOptionals?.find((opt) => opt.optional === 3) ? true : false
    );
    setAbs(
      desiredCar?.carOptionals?.find((opt) => opt.optional === 4) ? true : false
    );
    setMileageEntered(desiredCar?.mileage ? true : false);
    setPriceEntered(desiredCar?.priceEntered ? true : false);
    setImages({});
  };

  useEffect(() => {
    clearForm();
  }, []);

  useEffect(() => {
    if (motorcycleMake) {
      fetch(`${API_HOME}/car/models/${motorcycleMake}`, {
        headers: {
          authorization: `Bearer ${state.user.token}`,
        },
      })
        .then((resp) => resp.json())
        .then((data) => {
          data.push({ id: 999999, name: "Outro" });
          setMotorcycleModelsList(data);
          // console.log(
          //   "For the make",
          //   motorcycleMake,
          //   "We loaded this list of models",
          //   data
          // );
        })
        .catch((err) => {
          console.error("Error fetching car models", err);
          Sentry.captureException(err);
        });
    }
  }, [motorcycleMake]);

  const error = (message) => {
    presentAlert({
      header: "Erro",
      message,
      buttons: ["Ok"],
    });
  };

  const filteredGeoCities = sortedMunicipios.filter((municipio, i) => {
    return municipio.codigo_uf === geoState;
  });

  const changeValueFuel = (number) => {
    switch (number) {
      case 1:
        return "Gasolina";
        break;
      case 2:
        return "Álcool";
        break;
      case 3:
        return "Flex";
        break;
      case 4:
        return "Diesel";
        break;
      case 5:
        return "Híbrido";
        break;
      case 6:
        return "Elétrico";
        break;

      default:
        break;
    }
  };

  const changeValueShiftType = (number) => {
    switch (number) {
      case 1:
        return "Manual";
        break;
      case 2:
        return "Automático";
        break;
      case 3:
        return "Automatizado";
        break;
      case 1:
        return "Manual";
        break;
      case 2:
        return "Automático";
        break;
      case 3:
        return "Automatizado";
        break;
      default:
        break;
    }
  };

  const changeValueBodyType = (number) => {
    switch (number) {
      case 1:
        return "Hatch";
        break;
      case 2:
        return "Sedan";
        break;
      case 3:
        return "SUV";
        break;
      case 4:
        return "Perua";
        break;
      case 5:
        return "Pick-up";
        break;
      case 6:
        return "Utilitário";
        break;
      case 7:
        return "Coupê";
        break;
      case 8:
        return "Conversível";
        break;
      case 9:
        return "Minivan";
        break;
      default:
        break;
    }
  };

  const changeValueCategory = (number) => {
    switch (number) {
      case 1:
        return "Urbano";
        break;
      case 2:
        return "4x4";
        break;
      case 3:
        return "Antigo";
        break;
      case 4:
        return "Família";
        break;
      case 5:
        return "Esportivo";
        break;
      case 6:
        return "Luxo";
        break;
      default:
        break;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {/* <IonButtons slot="start">
            <IonButton onClick={() => menuController.open()}>
              <img src="assets/btnLeftMenu.svg" />
            </IonButton>
          </IonButtons> */}
          <IonButtons slot="start">
						<IonBackButton defaultHref="/tabs/home" text="Voltar" />
					</IonButtons>
          <IonTitle>{showWelcome ? 'Carro desejado' : 'Editar Filtros'}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen ref={contentRef} scrollEvents={true}>
        <PageUI>
          <PageBackground style={{ paddingTop: "5%" }}>
            <Panel>
              <form autoComplete="off">
                {showWelcome && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Seu carro foi cadastrado!
                    </p>
                    <p style={{ margin: "2rem 0" }}>
                      Conte-nos agora sobre as características do carro que você
                      deseja na troca.
                    </p>

                    <ActionButton
                      onClick={() => {
                        setShowWelcome(false);
                      }}
                    >
                      Vamos lá
                    </ActionButton>

                    <p style={{ marginTop: "2rem" }}>
                      <span>Dica: </span>
                      <span style={{ color: "#999" }}>
                        busque sempre um equilibrio na quantidade de filtros,
                        evitando critérios amplos ou restritos demais.
                      </span>
                    </p>
                  </div>
                )}

                {!showWelcome && (
                  <>
                    <PageSubTitle>
                      Selecione os critérios desejados:
                    </PageSubTitle>
                    <FilterInputBox>
                      <div>
                        <p>Preço</p>
                        <div style={{ margin: "1rem 0", textAlign: "left" }}>
                          {priceRange < 41
                            ? `Até ${formatCurrency(priceRange * priceFactor)}`
                            : `Sem restrição`}
                        </div>
                        <Input
                          type="range"
                          name="price"
                          min="1"
                          max="41"
                          step="1"
                          value={priceRange}
                          onChange={(event) => {
                            setPriceRange(parseInt(event.target.value));
                          }}
                          onMouseUp={() => {
                            setPriceEntered(true);
                          }}
                          onTouchEnd={() => {
                            setPriceEntered(true);
                          }}
                        />
                      </div>
                    </FilterInputBox>
                    <FilterInputBox>
                      <p>
                        Ano{" "}
                        {startingYear === 0 && maxYear === 0
                          ? "a partir de"
                          : "entre"}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Select
                          value={startingYear}
                          onChange={(event) => {
                            const value = parseInt(event.target.value);

                            if (value === 0) {
                              // ano inicial é 2021
                              if (maxYear !== 0) {
                                // ano máximo não é 2021
                                setMaxYear(value);
                              }
                            } else {
                              if (maxYear !== 0 && value > maxYear) {
                                setMaxYear(value);
                              }
                            }

                            setStartingYear(value);
                          }}
                        >
                          {[...new Array(100)].map((a, b) => {
                            const currentYear = new Date().getFullYear();
                            const yearLabel =
                              currentYear - b === currentYear
                                ? `Ano atual (${currentYear})`
                                : currentYear - b;
                            const yearValue =
                              currentYear - b === currentYear
                                ? 0
                                : currentYear - b;
                            return (
                              <option key={b} value={yearValue}>
                                {yearLabel}
                              </option>
                            );
                          })}
                        </Select>
                        {!(startingYear === 0 && maxYear === 0) && (
                          <>
                            <p style={{ width: "3rem", textAlign: "center" }}>
                              e
                            </p>
                            <Select
                              value={maxYear}
                              onChange={(event) => {
                                setMaxYear(parseInt(event.target.value));
                              }}
                            >
                              {[...new Array(100)]
                                .filter((y, i) => {
                                  return (
                                    new Date().getFullYear() - i >= startingYear
                                  );
                                })
                                .map((a, b) => {
                                  const currentYear = new Date().getFullYear();
                                  const yearLabel =
                                    currentYear - b === currentYear
                                      ? `Ano atual (${currentYear})`
                                      : currentYear - b;
                                  const yearValue =
                                    currentYear - b === currentYear
                                      ? 0
                                      : currentYear - b;
                                  return (
                                    <option key={b} value={yearValue}>
                                      {yearLabel}
                                    </option>
                                  );
                                })}
                            </Select>
                          </>
                        )}
                      </div>
                    </FilterInputBox>
                    <FilterInputBox>
                      <Checkbox
                        name="filterGeo"
                        checked={filterGeo}
                        label="Localização"
                        onChange={() => {
                          setFilterGeo(!filterGeo);
                        }}
                      />
                      {filterGeo && (
                        <Row>
                          <Col>
                            <SearchableSelect
                              placeholder="Estado"
                              values={estados}
                              value={geoState}
                              labelKey="uf"
                              onInvalidMessage="Você precisa selecionar um estado"
                              onSelect={(value) => {
                                // console.log(
                                //   "onSelect for geoState received",
                                //   value
                                // );
                                setGeoState(value?.codigo_uf);
                              }}
                            />
                          </Col>

                          <Col>
                            {geoState > 0 && (
                              <SearchableSelect
                                placeholder="Cidade"
                                value={geoCity}
                                values={filteredGeoCities}
                                labelKey="nome"
                                onInvalidMessage="Você precisa selecionar uma cidade"
                                onSelect={(value) => {
                                  // console.log(
                                  //   "onSelect for make received",
                                  //   value
                                  // );
                                  setGeoCity(value?.codigo_ibge);
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      )}
                    </FilterInputBox>
                    <FilterInputBox>
                      <p>Tipo de veículo</p>
                      <SearchableSelect
                        placeholder="Carro ou Moto"
                        values={[
                          { name: "Carro", id: 1 },
                          { name: "Moto", id: 2 },
                        ]}
                        value={vehicleType}
                        onInvalidMessage="Você precisa selecionar o tipo de veículo"
                        onSelect={(value) => {
                          // console.log("onSelect for make received", value);
                          setVehicleType(value?.id);
                        }}
                      />
                    </FilterInputBox>
                    {vehicleType == 1 && (
                      <FilterInputBox>
                        <Checkbox
                          name="filterMakeModel"
                          checked={filterMakeModel}
                          label="Marca / Modelo"
                          onChange={() => {
                            setFilterMakeModel(!filterMakeModel);
                          }}
                        />
                        {filterMakeModel && (
                          <>
                            <SearchableSelect
                              placeholder="Marca"
                              values={makesList}
                              value={make}
                              onInvalidMessage="Você precisa selecionar a marca do carro"
                              onSelect={(value) => {
                                // console.log(
                                //   "onSelect for make received",
                                //   value
                                // );
                                setMake(value?.id);
                              }}
                            />
                            {make && make !== 999999 && (
                              <SearchableSelect
                                placeholder="Modelo"
                                values={modelsList}
                                value={model}
                                onInvalidMessage="Você precisa selecionar o modelo do carro"
                                onSelect={(value) => {
                                  // console.log(
                                  //   "onSelect for model received",
                                  //   value
                                  // );
                                  setModel(value?.id);
                                }}
                              />
                            )}

            
                          </>
                        )}
                      </FilterInputBox>
                    )}
                    {vehicleType == 2 && (
                      <FilterInputBox>
                        <Checkbox
                          name="filterMakeModel"
                          checked={filterMakeModel}
                          label="Marca / Modelo"
                          onChange={() => {
                            setFilterMakeModel(!filterMakeModel);
                          }}
                        />
                        {filterMakeModel && (
                          <>
                            <SearchableSelect
                              placeholder="Marca"
                              values={motorcycleMakesList}
                              value={motorcycleMake}
                              onInvalidMessage="Você precisa selecionar a marca do carro"
                              onSelect={(value) => {
                                // console.log(
                                //   "onSelect for make received",
                                //   value
                                // );
                                setMotorcycleMake(value?.id);
                                setMake(value?.id);
                              }}
                            />
                            {motorcycleMake && motorcycleMake !== 999999 && (
                              <SearchableSelect
                                placeholder="Modelo"
                                values={motorcycleModelsList}
                                value={model}
                                onInvalidMessage="Você precisa selecionar o modelo do carro"
                                onSelect={(value) => {
                                  // console.log(
                                  //   "onSelect for model received",
                                  //   value
                                  // );
                                  setModel(value?.id);
                                }}
                              />
                            )}

                
                          </>
                        )}
                      </FilterInputBox>
                    )}

                    <FilterInputBox>
                      <p>Quilometragem</p>
                      <div>
                        <div style={{ margin: "1rem 0", textAlign: "left" }}>
                          {mileage < 21
                            ? `Até ${formatMileage(
                                mileage === 0
                                  ? mileageFactor
                                  : mileage * mileageFactor
                              )}  Km`
                            : "Sem restrição"}
                        </div>
                        <Input
                          type="range"
                          name="mileage"
                          min="1"
                          max="21"
                          step="1"
                          value={mileage == 0 ? 1 : mileage}
                          onChange={(event) => {
                            setMileage(parseInt(event.target.value));
                          }}
                          onMouseUp={() => {
                            setMileageEntered(true);
                          }}
                          onTouchEnd={() => {
                            setMileageEntered(true);
                          }}
                        />
                      </div>
                    </FilterInputBox>

                    <FilterInputBox>
                      <Checkbox
                        name="filterShift"
                        checked={filterShift}
                        label="Câmbio"
                        onChange={() => {
                          setFilterShift(!filterShift);
                        }}
                      />
                      {filterShift && (
                        <Select
                          value={shift}
                          onChange={(event) => {
                            setShift(parseInt(event.target.value));
                          }}
                        >
                          {["Manual", "Automático", "Automatizado"].map(
                            (type, i) => (
                              <option key={i} value={i + 1}>
                                {type}
                              </option>
                            )
                          )}
                        </Select>
                      )}
                    </FilterInputBox>

                    <FilterInputBox>
                      <Checkbox
                        name="filterFuel"
                        checked={filterFuel}
                        label="Combustível"
                        onChange={() => {
                          setFilterFuel(!filterFuel);
                        }}
                      />
                      {filterFuel && (
                        <Select
                          value={fuel}
                          onChange={(event) => {
                            setFuel(parseInt(event.target.value));
                          }}
                        >
                          {[
                            "Gasolina",
                            "Álcool",
                            "Flex",
                            "Diesel",
                            "Híbrido",
                            "Elétrico",
                          ].map((type, i) => (
                            <option key={i} value={i + 1}>
                              {type}
                            </option>
                          ))}
                        </Select>
                      )}
                    </FilterInputBox>

                    {vehicleType == 1 && (
                      <div>
                        <FilterInputBox>
                          <div>Carroceria</div>
                          {/*(Ar Condicionado; Direção Assistida; AirBag; ABS*/}
                          <Checkbox
                            name="bodytype_hatch"
                            checked={hatch}
                            label="Hatch"
                            onChange={() => {
                              setHatch(!hatch);
                            }}
                          />
                          <Checkbox
                            name="bodytype_sedan"
                            checked={sedan}
                            label="Sedan"
                            onChange={() => {
                              setSedan(!sedan);
                            }}
                          />
                          <Checkbox
                            name="bodytype_suv"
                            checked={SUV}
                            label="SUV"
                            onChange={() => {
                              setSUV(!SUV);
                            }}
                          />
                          <Checkbox
                            name="bodytype_perua"
                            checked={perua}
                            label="Perua"
                            onChange={() => {
                              setPerua(!perua);
                            }}
                          />
                          <Checkbox
                            name="bodytype_pickup"
                            checked={pickup}
                            label="Pick-up"
                            onChange={() => {
                              console.log("pickup", pickup);
                              setPickup(!pickup);
                            }}
                          />
                          <Checkbox
                            name="bodytype_utilitario"
                            checked={utilitario}
                            label="Utilitário"
                            onChange={() => {
                              setUtilitario(!utilitario);
                            }}
                          />
                          <Checkbox
                            name="bodytype_coupe"
                            checked={coupe}
                            label="Coupê"
                            onChange={() => {
                              setCoupe(!coupe);
                            }}
                          />
                          <Checkbox
                            name="bodytype_conversivel"
                            checked={conversivel}
                            label="Conversível"
                            onChange={() => {
                              setConversivel(!conversivel);
                            }}
                          />{" "}
                          <Checkbox
                            name="bodytype_minivan"
                            checked={minivan}
                            label="Minivan"
                            onChange={() => {
                              setMinivan(!minivan);
                            }}
                          />
                        </FilterInputBox>

                        <FilterInputBox>
                          <Checkbox
                            name="filterCategory"
                            checked={filterCategory}
                            label="Categoria"
                            onChange={() => {
                              handlesetFilterCategory();
                            }}
                          />
                          {filterCategory && (
                            <Select
                              value={category}
                              onChange={(event) => {
                                setCategory(parseInt(event.target.value));
                              }}
                            >
                              {[
                                {value: undefined, desc: "Selecione"},
                                {value: 1, desc: "Urbano"},
                                {value: 2, desc: "4x4"},
                                {value: 3, desc: "Antigo"},
                                {value: 4, desc: "Família"},
                                {value: 5, desc: "Esportivo"},
                                {value: 6, desc: "Luxo"},
                              ].map((type, i) => (
                                <option key={i} value={type.value}>
                                  {type.desc}
                                </option>
                              ))}
                            </Select>
                          )}
                        </FilterInputBox>

                        <FilterInputBox>
                          <div>Opcionais</div>
                          {/*(Ar Condicionado; Direção Assistida; AirBag; ABS*/}
                          <Checkbox
                            name="airconditioning"
                            checked={airConditioning}
                            label="Ar condicionado"
                            onChange={() => {
                              setAirConditioning(!airConditioning);
                            }}
                          />
                          <Checkbox
                            name="assisteddriving"
                            checked={assistedDriving}
                            label="Direção assistida"
                            onChange={() => {
                              setAssistedDriving(!assistedDriving);
                            }}
                          />
                          <Checkbox
                            name="airbag"
                            checked={airbag}
                            label="AirBag"
                            onChange={() => {
                              setAirbag(!airbag);
                            }}
                          />
                          <Checkbox
                            name="abs"
                            checked={abs}
                            label="ABS"
                            onChange={() => {
                              setAbs(!abs);
                            }}
                          />
                        </FilterInputBox>
                      </div>
                    )}
                    {vehicleType == 2 && (
                      <FilterInputBox>
                        <Checkbox
                          name="filterCategory"
                          checked={filterCategory}
                          label="Categoria"
                          onChange={() => {
                            handlesetFilterCategory();
                          }}
                        />
                        {filterCategory && (
                          <Select
                            value={category}
                            onChange={(event) => {
                              setCategory(parseInt(event.target.value));
                              contentRef?.current?.scrollToBottom();
                            }}
                          >
                            {[
                              {value: undefined, desc: "Selecione"},
                              {value: 1, desc: "CUSTOM"},
                              {value: 2, desc: "ELÉTRICA"},
                              {value: 3, desc: "ESPORTIVA"},
                              {value: 4, desc: "MINO CROSS"},
                              {value: 5, desc: " NAKED"},
                              {value: 6, desc: "OFFROAD"},
                              {value: 7, desc: "QUADRICICLO"},
                              {value: 8, desc: "SCOOTER"},
                              {value: 9, desc: "STREET"},
                              {value: 10, desc: "SUPERMOTARD"},
                              {value: 11, desc: "TOURING"},
                              {value: 12, desc: "TRAIL"},
                              {value: 13, desc: "TRIAL"},
                              {value: 14, desc: "UTILITÁRIA"},
                              {value: 15, desc: "TRICICLO"},
                            ].map((type, i) => (
                              <option key={i} value={type.value}>
                                {type.desc}
                              </option>
                            ))}
                          </Select>
                        )}
                      </FilterInputBox>
                    )}

                  </>
                )}
              </form>
            </Panel>
          </PageBackground>
        </PageUI>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Cadastrando seu carro..."}
        />
      </IonContent>
      <IonFooter>
      <ActionButton onClick={updateDesiredCar}>Concluir</ActionButton>
      </IonFooter>
    </IonPage>
  );
};

export default EditDesiredCar;
