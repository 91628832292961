import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../State";

import {
	IonIcon,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
	IonBadge,
} from "@ionic/react";

import styled from "styled-components";

import { Route } from "react-router-dom";

import Home from "../pages/home/Home";
import Profile from "../pages/profile/Profile";
import AddCar from "../pages/profile/AddCar";
// import AddDesiredCar from "../pages/profile/AddDesiredCar";
import EditDesiredCar from "../pages/profile/EditDesiredCar";
import EditCar from "../pages/profile/EditCar";
import MyCars from "../pages/mycars/MyCars";
import Matches from "../pages/match/Matches";
import Match from "../pages/match/Match";
import Chats from "../pages/chat/Chats";
import Chat from "../pages/chat/Chat";
import CarDetails from "../pages/CarDetails";
import Payment from "../pages/payment/Payment";

import HomeNoCars from "../pages/home/Home.NoCars";
import Premium from "../pages/profile/Premium";
import FuncPremium from "../pages/profile/FuncPremium";

import LikedYou from "../pages/CarsLikedYou/LikedYou";
import FindCars from "../pages/profile/FindCars";
import FindCarsFilter from "../pages/profile/FindCarsFilter";
import FindCarsDetail from "../pages/profile/FindCarsDetail";

import Vote from "../pages/profile/Vote";

const StyledIonIcon = styled(IonIcon)`
	&.tab-selected {
		color: green !important;
	}
`;
const App: React.FC = () => {
	const { state } = useContext(AppContext);
	const { user } = state;
	
	const [hrefLikes, setHrefLikes] = useState("/tabs/funcpremium");
	
	const { matches, chats, cars } = state;
	const enabled = cars && cars.length > 0;
	
	const newMatches =
		matches?.reduce((carry, current) => {
			return (carry += current.read === false ? 1 : 0);
		}, 0) || 0;

	const newChats = 
		chats?.reduce((carry, current) => {
			return (carry += current.read === false && current.to == user?.id ? 1 : 0);
		}, 0) || 0;
		
	useEffect(() => {
		if (user?.subscription?.is_active) {
			// console.log("PLANO", true);

			setHrefLikes("/tabs/likes");
		} else {
			setHrefLikes("/tabs/funcpremium");
		}
		
	}, []);
	

	return (
		<IonTabs>
			<IonRouterOutlet id="tab-router-outlet">
				<Route exact path="/tabs/home">
					<Home />
				</Route>
				<Route exact path="/tabs/likes">
					<LikedYou />
				</Route>
				<Route exact path="/tabs/profile">
					<Profile />
				</Route>
				<Route exact path="/tabs/premium">
					<Premium />
				</Route>	
				<Route exact path="/tabs/funcpremium">
					<FuncPremium />
				</Route>				
				<Route exact path="/tabs/FindCars">
					<FindCars />
				</Route>
				<Route exact path="/tabs/vote">
					<Vote />
				</Route>
				<Route exact path="/tabs/FindCarsFilter">
					<FindCarsFilter />
				</Route>
				<Route exact path="/tabs/FindCarsFilter">
					<FindCarsFilter />
				</Route>
				<Route exact path="/tabs/FindCarsDetail">
					<FindCarsDetail />
				</Route>
				<Route exact path="/cardetails">
					<CarDetails />
				</Route>
				<Route exact path="/tabs/payment">
					<Payment />
				</Route>
				<Route
					exact
					path="/tabs/cars/edit/desired/:id"
					render={(props) => <EditDesiredCar {...props} />}
				></Route>
				<Route
					exact
					path="/tabs/cars/add/desired/:id"
					render={(props) => <EditDesiredCar { ...props } fromAdd={true} />}
				></Route>

				<Route
					exact
					path="/tabs/cars/edit/:id"
					render={(props) => <EditCar {...props} />}
				></Route>

				<Route
					exact
					path="/tabs/cars/addcar"
					render={(props) => <AddCar />}
				></Route>
				<Route exact path="/tabs/cars">
					<MyCars />
				</Route>
				<Route exact path="/tabs/match">
					<Matches />
				</Route>
				<Route
					path="/tabs/match/:id"
					render={(props) => <Match {...props} />}
				></Route>
				<Route exact path="/tabs/chat">
					<Chats />
				</Route>

				<Route
					path="/tabs/chat/:id"
					render={(props) => <Chat {...props} />}
				></Route>
				<Route exact path="/tabs/payment/:id"
					render={(props) => <Payment {...props} />}>
				</Route>
			</IonRouterOutlet>

			<IonTabBar slot="bottom">
				<IonTabButton tab="home" href="/tabs/home" className="color-btn-tabs">
					<StyledIonIcon icon={"assets/menu/home.svg"} />
				</IonTabButton>
				<IonTabButton tab="likes" href={hrefLikes} disabled={!enabled} className="color-btn-tabs">
					<StyledIonIcon icon={"assets/menu/like.svg"} />				
					{/* {hrefLikes} */}
				</IonTabButton>
				<IonTabButton tab="profile" href="/tabs/profile" className="color-btn-tabs">
					<StyledIonIcon icon={"assets/menu/user.svg"} />
				</IonTabButton>
				
				<IonTabButton tab="cars" href="/tabs/cars" className="color-btn-tabs">
					<StyledIonIcon icon={"assets/menu/car.svg"} />
				</IonTabButton>
				<IonTabButton  tab="matchs" href="/tabs/match" disabled={!enabled} className="color-btn-tabs">
					<StyledIonIcon icon={"assets/menu/match.svg"} />
					{newMatches > 0 && <IonBadge color="primary">{newMatches}</IonBadge>}
				</IonTabButton>
				<IonTabButton tab="chats" href="/tabs/chat" disabled={!enabled} className="color-btn-tabs">
					<StyledIonIcon icon={"assets/menu/chat.svg"} />
					{newChats > 0 && <IonBadge color="primary">{newChats}</IonBadge>}
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	);
};

export default App;