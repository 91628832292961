import React, { useState, useContext, useEffect } from "react";
import { API_HOME, MEDIA_HOME, mileageFactor, priceFactor } from "../../config";
import { formatMileage } from "../../common/util";
import * as Sentry from "@sentry/react";
import TinderCard from 'react-tinder-card'

import { AppContext } from "../../State";

import { Car, Detail } from "../../components/Car";
import { BtnsReactions } from "../../components/BtnsReactions";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  useIonViewDidEnter,
  useIonAlert,
  IonIcon,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonRow,
  IonSpinner,
  IonCol,
  useIonModal,
  useIonViewWillEnter,
} from "@ionic/react";

import { menuController } from "@ionic/core";

import { Panel, PageSubTitle } from "../../components/ui";

import { list } from "ionicons/icons";
import { ActionButton, Checkbox } from "../../common/input";
import { Col, CarImage } from "../mycars/MyCarsComponents";
import { useHistory } from "react-router";

import HomeNoCars from "./Home.NoCars";
import HomeController from "./Home.Controller";
import {
  changeValueFuel,
  changeValueShiftType,
  changeValueBodyType,
  changeValueCategory,
} from "./Home.Helpers";

import {
  StyledTitle,
  StyledPage,
  ComoFunciona,
  CarBackground,
  Avatar,
  InputBox,
  FilterInputBox,
  Input,
  CarCard
} from "./Home.style";
import { getLikesReceived } from "../../sevices/GetLikesService";
import { ModalRealTimeMatch } from "../../components/ModalRealTimeMatch";
import { Tutorial } from "../../components/Tutorial";

import { getPosibleMatchs } from "../../sevices/GetPossibleMatchService";

let index = 0;
const Home: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  
  const { user, selectedDesiredCar, selectedCar } = state;
  
  const [move, setmove] = useState<any>(false);
  const [receivedLikes, setReceivedLikes] = useState<any>([]);
  const [carList, setCarList] = useState<any>([]);
  const [carModalMatch, setCarModalMatch] = useState<any>({ car: null, desiredCar: null, type: null, matchId: null });
  const history = useHistory();

  const [myCar, setMyCar] = useState(
    selectedDesiredCar || {
      id: 0,
      year: 0,
      carModel: {
        name: "0",
        carMake: { name: "" },
        versiontypes: ''
      },
      mileage: 0,
      fuel_type: 1,
      body_type: 1,
      shift_type: 1,
      category: 1,
      carOptionals: [],
      price_range: 1,
      vehycleType: 0,
      city: 0,
      municipio: { nome: "", estado: { nome: "" } },
    }
  );

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [left, setleft] = useState(false);

  const [right, setright] = useState(false);
  const [isBack, setIsBack] = useState(false);

  const [disableBtnreactions, setdisableBtnreactions] = useState(false);

  const [up, setUp] = useState(false);
  const [idLikeOrDislike, setIdLikeOrDislike] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [showModalMatch, setShowModalMatch] = useState(false);

  const [present, dismiss] = useIonModal(Tutorial, { onDismiss: (notShowAgain) => handleDimiss(notShowAgain) });

  let controller : HomeController;
  const hasValidSubscription = user?.subscription?.is_active ;

  const handleDimiss = function (notShowAgain: boolean) {
    if (notShowAgain) localStorage.setItem('notShowTutorialAgain', JSON.stringify(true));
    dismiss();
  }

  const [car, setCar] = useState({
    id: 0,
    year: 0,
    carModel: {
      name: "0",
      carMake: { name: "" },
      versiontypes: ''
    },
    mileage: 0,
    fuel_type: 1,
    body_type: 1,
    shift_type: 1,
    category: 1,
    comment: "",
    carOptionals: [
      {
        active: null,
        carId: 127,
        desiredCarId: null,
        id: 451,
        inactive_reason: null,
        optional: 1,
      },
    ],
    price_range: 1,
    vehycleType: 0,
    city: 0,
    municipio: { nome: "", estado: { nome: "" } },
    carPagreg: 200
  });

  let childRefs: any =
    Array(carList.length)
      .fill(0)
      .map((i) => React.createRef())

  let gbMove = false;
  
  window.addEventListener('touchmove', function (event: any) {
    if (event.target.id === 'carImage') {gbMove = true} else {gbMove = false};
  });

  useIonViewWillEnter(async () => {
    setLoading(true);
    
    try {     
      let showTutorial = JSON.parse(localStorage.getItem('showTutorial') as any);
      let notShowTutorialAgain = JSON.parse(localStorage.getItem('notShowTutorialAgain') as any);
      
      if (showTutorial && !notShowTutorialAgain) {
        present({ cssClass: 'modal-tutorial' });
        localStorage.removeItem('showTutorial')
      }
      
      controller = new HomeController(state, user, dispatch); 
      controller.loadCars(handleCarChange);
      controller.loadMatchs();
      controller.loadChats(setLoaded);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoading(false);
      setLoaded(true);
    }

  });
  
  useEffect(() => {
    if (state?.cars && selectedDesiredCar) {
      // debugger;

      const selectedCar = state?.cars?.find(
        (c) => c.id === parseInt(selectedDesiredCar?.carId)
      );

      if (selectedCar?.id) {
        handleCarChange(selectedCar?.id);
      } else {
        // garantir que tenha sempre um carro selecionado, no caso o primeiro do array
        const selectedCar2 = state?.cars[0];
        handleCarChange(selectedCar2?.id);
      }
    }
    else if (state?.cars) {
      // garantir que tenha sempre um carro selecionado, no caso o primeiro do array
      const selectedCar = state?.cars[0];
      handleCarChange(selectedCar?.id);
    }
  }, [selectedDesiredCar, state?.cars]);

  const handleCarChange = async (carId) => {
    // debugger;
    const car: any = state?.cars?.find(c => c?.id === carId);
    if (!car) {
      return;
    }
    
    setMyCar(car);
    setLoading(true);
    dispatch({ type: "setSelectedCar", selectedCar: car });

    const desiredCar = car?.desiredCar;

    console.warn('desiredCar', desiredCar);

    if (desiredCar) {
      const {
        body_types,
        carId,
        carMake,
        carModel,
        carOptionals,
        category,
        city,
        createdAt,
        fuel_type,
        id,
        inactive_reason,
        make,
        max_year,
        mileage,
        model,
        price_range,
        shift_type,
        starting_year,
        vehicleType,
        updatedAt,
        desiredbodytypes,
        year,
        version,
      } = desiredCar;

      let body_type;
      if (desiredbodytypes && desiredbodytypes.length > 0)
        body_type = desiredbodytypes.map((o) => o["bodytypeid"]);

      let resp = await getPosibleMatchs(user, car, body_type, carId, carMake, carModel, carOptionals, category, city, createdAt, fuel_type, id, inactive_reason, make, max_year, mileage, model, price_range, shift_type, starting_year, vehicleType, updatedAt, year, version, desiredCar);

      console.warn('FILTRO', resp);

      resp = resp.data;

      await getLikesReceived(state, [car?.id], setReceivedLikes);

      if (receivedLikes.length > 0) {
        let list = new Array<any>();
        let receivedLikesIndex = 0;

        resp.forEach((c, i) => {
          list.push(c);
          if (receivedLikesIndex >= receivedLikes.length) return;

          if (i % 4 === 0) {
            list.push(receivedLikes[receivedLikesIndex]?.car);
            receivedLikesIndex++;
          }
        });
        await setCarList(list);
      } else {
        await setCarList(resp);
      }

      let firstIndex: number = 0;
      index = 0;
      setCar(resp[0]);
      childRefs =
        Array(carList.length)
          .fill(0)
          .map((i) => React.createRef())
    }

    setLoading(false);
    setLoaded(true);
  };

  const back = async () => {
    //debugger;
    if (!hasValidSubscription) {      
      history.push("/tabs/FuncPremium");  
      return;  
    } else {
      setIsBack(true);
      setTimeout(() => {
        setIsBack(false);
      }, 300)

      if (index > 0) {
        setdisableBtnreactions(true);
        const i = index - 1;

        setCar(carList[i]);

        const req = await fetch(`${API_HOME}/match/returnchoice/${idLikeOrDislike}`, {
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            authorization: `Bearer ${state.user.token}`,
          },
        });
        setdisableBtnreactions(false);
        setIdLikeOrDislike(null);
      }
    }
  }

  const superlike = async () => {
    if (!hasValidSubscription) {      
      history.push("/tabs/FuncPremium");  
      return;  
    } else {
      if (index < carList.length && car) {
        setdisableBtnreactions(true);
        const req = await fetch(`${API_HOME}/match/superLike`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            authorization: `Bearer ${state.user.token}`,
          },
          body: JSON.stringify({
            carA: selectedCar,
            carB: car
          }),
        });
        const resp = await req.json();
        setIdLikeOrDislike(resp.cars[1].id);

        setCarModalMatch({ car: resp.cars[0].id, desiredCar: resp.cars[1].id, type: 'superlike', matchId: resp.match.id });
        setShowModalMatch(true);

      } else {
        index++;
      }
    }
  };

  const like = async () => {
    if (index < carList.length && car) {
      setdisableBtnreactions(true);
      const carId = myCar.id;
      const desiredCarId = carList[index].id;
      const i = index + 1;

      const req = await fetch(`${API_HOME}/match/start`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${state.user.token}`,
        },
        body: JSON.stringify({
          carId: carId,
          desiredCarId: desiredCarId,
        }),
      });
      const resp = await req.json();
      setIdLikeOrDislike(resp.match.id);

      const isMatch = resp?.match?.carIdA && resp?.match?.carIdB && resp?.match?.userIdA && resp?.match?.userIdB;

      if (isMatch) {
        setCarModalMatch({ car: resp.match.carIdA, desiredCar: resp.match.carIdB, type: 'like', matchId: resp.match.id });
        setShowModalMatch(true);
        return;
      }

      index++;

      setCar(carList[i]);
      setdisableBtnreactions(false);
    } else {
      index++;
    }
  };

  const dislike = async () => {
    // console.log('desired car',carList[index])
    if (index < carList.length && car) {
      
      setdisableBtnreactions(true);
      const req = await fetch(`${API_HOME}/match/dislike`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${state.user.token}`,
        },
        body: JSON.stringify({
          carA: myCar,
          carB: carList[index],
        }),
      });
      const resp = await req.json();
      setIdLikeOrDislike(resp.match.id);
      index++;
      setCar(carList[index]);
      setdisableBtnreactions(false);
    } else {
      index++;
    }
  };

  const onSwipe = (direction) => {
    if(!gbMove) return;

    if (direction === 'left') {
      return dislike();
    }
    if (direction === 'right') {
      return  like();
    }
  }

  const setDirection = (dir) => {
    if (dir === 'left') {
      setleft(true);
      setTimeout(() => {
        setleft(false);

      }, 100)
    }
    if (dir === 'right') {
      setright(true);
      setTimeout(() => {
        setright(false);

      }, 100)
    }
    if (dir === 'up') {
      setUp(true);
      setTimeout(() => {
        setUp(false);

      }, 100)
    }
  }

  const swipe = (dir) => {
    setDirection(dir);
    gbMove = true;
    onSwipe(dir);
  };

  const onCardLeftScreen = (myIdentifier) => {
    dislike();
  }

  return (
    <IonPage>
      <IonHeader className="height">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => menuController.open()}>
              <img src="assets/btnLeftMenu.svg" />
            </IonButton>
          </IonButtons>
          <IonTitle>Home</IonTitle>
          {user?.id && (
            <IonButtons slot="end">
              <IonButton routerLink="/tabs/profile">
                <Avatar src={user.profile_picture} />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen class="no-scroll" scroll-y="false">
        {(loading || !loaded) && (
          <IonSpinner
            color={"primary"}
            style={{
              display: "block",
              margin: "auto",
              width: "75%",
              height: "100%",
            }}
            name="crescent"
          />
        )}
        {!loading && loaded && state?.cars?.length == 0 && <HomeNoCars />}
        {!loading && 
          loaded &&
          state?.cars?.length > 0 &&
          <StyledPage>
            <CarBackground>
              <img
                style={{
                  width: "100%",
                  maxWidth: "40%",
                  margin: "0 auto",
                  padding: "8px 0"
                }}
                src="assets/icon/logo_branco.svg"
              />
              <CarCard>
                <IonRow>
                  <IonCol size="10">
                    {" "}
                    {state?.cars && (
                      <IonSelect
                        cancelText="Cancelar"
                        style={{
                          height: '100%',
                          borderRadius: "30px",
                          justifyContent: "center",
                          background: '#a5a3a33d',
                          boxShadow: "5px 5x 5px rgba(0, 0, 0, 0.4)",
                        }}
                        value={myCar?.id}
                        onIonChange={(e) => handleCarChange(e.detail.value)}
                      >
                        {state?.cars.map((car, id) => (
                          <IonSelectOption key={id} value={car.id}>
                            {car?.carModel?.carMake?.name +
                              " " +
                              car?.carModel?.name}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    )}
                  </IonCol>
                  <IonCol size="2">
                    {myCar && (
                      <Col style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}>
                        <img
                          onClick={() => {
                            history.push(
                              `/tabs/cars/edit/desired/${myCar?.id}`
                            );
                          }}
                          style={{ cursor: "pointer" }}
                          src="assets/iconOption.svg"
                          alt="menu"
                        />
                      </Col>
                    )}
                  </IonCol>
                </IonRow>
                {!loading &&
                  user &&
                  state?.cars?.length > 0 &&
                  carList.length > 0 &&
                  carList.length > index &&
                  loaded && (
                    <>  
                      <TinderCard  className={left ? 'transform-l' : right ? 'transform-r' : up ? 'transform-up' : isBack ? 'transform-r' : ''} 
                          onSwipe={(evt)=>{gbMove = true; onSwipe(evt);}}
                          onCardLeftScreen={() => onCardLeftScreen('fooBar')}
                          swipeRequirementType='position'
                          swipeThreshold={100}
                          preventSwipe={['right', 'left', 'up', 'down']} 
                          ref={childRefs[index]}>
                        <Car car={car} setShowModal={setShowModal} getgbmove={move} setgbmove={setmove}></Car>
                      </TinderCard>
                      <>               
                        <Detail>
                          {/* <div>
                            <IonIcon icon={list} 
                            onClick={() => { 
                              // console.log('Click list detail');
                              setShowModal(true); }}  
                            style={{color: '#d63d2f',  top: -1, padding: '6px 7px 0px 6px', pointerEvents: 'none' }}/>
                          </div> */}
                        </Detail>
                      </>
                      <BtnsReactions
                        car={car}
                        isDisabled={disableBtnreactions}
                        idLikeOrDislike={idLikeOrDislike}
                        swipe={swipe}
                        back={back}
                        superlike={superlike}
                      ></BtnsReactions>
                    </>
                  )}
                  {!loading &&
                    state?.cars?.length > 0 &&
                    index > 0 &&
                    carList.length <= index &&
                    loaded && (

                      <ComoFunciona color="trasnparent">
                        <StyledTitle color="#d63d2f">
                          Todos veículos com as especificações selecionadas foram
                          visualizados.
                        </StyledTitle>
                      </ComoFunciona>

                    )}
                    {/* {`carregando ${loading}`} */}
                    {!loading &&
                      state?.cars?.length > 0 &&
                      carList.length == 0 &&
                      index == 0 && 
                      loaded && (
                        <ComoFunciona color="trasnparent">
                          <StyledTitle color="#d63d2f">
                            Não foi encontrado nenhum veículo com as especificações
                            selecionadas
                          </StyledTitle>
                        </ComoFunciona>
                      )}
              </CarCard>
            </CarBackground>
          </StyledPage>
        }
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <Panel style={{zIndex: 99999999}}>
            <form autoComplete="off">
              <>
                <PageSubTitle>
                  {`${car?.carModel?.carMake?.name || ""} ${car?.carModel?.name || "Outro"
                    }`}
                </PageSubTitle>
                <CarImage
                  src={`${MEDIA_HOME}/car/${car?.id}/front_small.jpg`}
                />
                <CarImage src={`${MEDIA_HOME}/car/${car?.id}/back_small.jpg`} />
                <CarImage
                  src={`${MEDIA_HOME}/car/${car?.id}/right_small.jpg`}
                />
                <CarImage src={`${MEDIA_HOME}/car/${car?.id}/left_small.jpg`} />
                <CarImage
                  src={`${MEDIA_HOME}/car/${car?.id}/interior_small.jpg`}
                />
                <CarImage
                  src={`${MEDIA_HOME}/car/${car?.id}/engine_small.jpg`}
                />
                <FilterInputBox>
                  <div>
                    <p>Preço:</p>
                    {"Entre R$" +
                      (car?.price_range - 1) * priceFactor +
                      " e  R$" +
                      car?.price_range * priceFactor}
                  </div>
                </FilterInputBox>
                <FilterInputBox>
                  <p>Quilometragem:</p>
                  <div>
                    <div style={{ margin: "1rem 0", textAlign: "left" }}>
                      {car?.mileage < 21
                        ? `Até ${formatMileage(
                          car?.mileage === 0
                            ? mileageFactor
                            : car?.mileage * mileageFactor
                        )}  Km`
                        : "Acima de 100.000 Km"}
                    </div>
                    <Input
                      type="range"
                      name="mileage"
                      min="1"
                      max="21"
                      step="1"
                      value={car?.mileage}
                      readOnly
                    />
                  </div>
                </FilterInputBox>
                <FilterInputBox>
                  <div> Ano: {car?.year} </div>
                </FilterInputBox>
                <FilterInputBox>
                  <div> Cidade: {car?.municipio?.nome} </div>
                </FilterInputBox>
                <FilterInputBox>
                  <div> Estado {car?.municipio?.estado.nome} </div>
                </FilterInputBox>
                <FilterInputBox>
                  <div>Combustivel: {changeValueFuel(car?.fuel_type)}</div>
                </FilterInputBox>
                <FilterInputBox>
                  <div>Cambio: {changeValueShiftType(car?.shift_type)}</div>
                </FilterInputBox>
                <FilterInputBox>
                  <div>
                    Carroceria:
                    {changeValueBodyType(car?.body_type)}
                  </div>
                </FilterInputBox>
                <FilterInputBox>
                  <div>
                    Categoria:
                    {changeValueCategory(car?.category)}
                  </div>
                </FilterInputBox>
                <FilterInputBox>
                  <div>Versão: {car?.carModel?.versiontypes}</div>
                </FilterInputBox>
                {car?.comment && car?.comment?.length > 0 && (
                  <FilterInputBox>
                    <div>Comentários: {car?.comment}</div>
                  </FilterInputBox>
                )}
                {car?.carOptionals && car?.carOptionals?.length > 0 && (
                  <FilterInputBox>
                    <div>
                      Opcionais:
                      {/*(Ar Condicionado; Direção Assistida; AirBag; ABS*/}
                      <Checkbox
                        name="airconditioning"
                        checked={car?.carOptionals?.find(
                          (op) => op.optional == 1
                        )}
                        label="Ar condicionado"
                        onChange={() => { }}
                        isDisabled={true}
                      />
                      <Checkbox
                        name="assisteddriving"
                        checked={car?.carOptionals?.find(
                          (op) => op.optional == 2
                        )}
                        label="Direção assistida"
                        onChange={() => { }}
                        isDisabled={true}
                      />
                      <Checkbox
                        name="airbag"
                        checked={car?.carOptionals.find(
                          (op) => op.optional == 3
                        )}
                        label="AirBag"
                        onChange={() => { }}
                        isDisabled={true}
                      />
                      <Checkbox
                        name="abs"
                        checked={car?.carOptionals.find(
                          (op) => op.optional == 4
                        )}
                        label="ABS"
                        onChange={() => { }}
                        isDisabled={true}
                      />
                    </div>
                  </FilterInputBox>
                )}
                <InputBox>
                  <ActionButton
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Fechar
                  </ActionButton>
                </InputBox>
              </>
            </form>
          </Panel>
        </IonModal>
        {/* Modal tutorial da aplicação */}
        {/* <Tutorial showModal={true} className="modal-tutorial"></Tutorial> */}
        {/* Modal match em tempo real */}
        <ModalRealTimeMatch
          showModal={showModalMatch}
          car={carModalMatch}
          setOpen={(evt) => {
            
            setShowModalMatch(evt);
            index = index + 1;
            setCar(carList[index]);
            setdisableBtnreactions(false);
          }}>
        </ModalRealTimeMatch>
      </IonContent>
    </IonPage>
  );
};

export default Home;